import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';
import {
  MartOrderDeliveryPayload,
  MartOrderDetailDto,
  MartOrderQueryStatus,
  MartSimpleOrderDto,
} from './order-modal';

export const MartOrderQueryStatusOptions = [
  { label: '全部', value: MartOrderQueryStatus.All },
  { label: '未审核', value: MartOrderQueryStatus.NotApproved },
  { label: '待商家确认', value: MartOrderQueryStatus.ConfirmPending },
  { label: '待发货', value: MartOrderQueryStatus.DeliverPending },
  { label: '已发货', value: MartOrderQueryStatus.AlreadyDeliver },
  { label: '已关闭', value: MartOrderQueryStatus.Close },
  { label: '订单取消处理', value: MartOrderQueryStatus.ProgressCancel },
];

export async function queryCasOrdersApi(
  params: PagingRequest
): Promise<PagingResponse<MartSimpleOrderDto>> {
  const res: any = await httpClient.get('/cas/orders', { params });
  return res;
}

export async function queryCasOrderDetailApi(
  orderNo: string
): Promise<MartOrderDetailDto> {
  const res: any = await httpClient.get(`/cas/orders/${orderNo}`);
  return res.data;
}

export async function confirmCasOrderApi(
  orderNo: string
): Promise<MartOrderDetailDto> {
  const res: any = await httpClient.post(`/cas/orders/${orderNo}/confirm`);
  return res.data;
}

export async function deliveryCasOrderApi(
  payload: MartOrderDeliveryPayload
): Promise<any> {
  const res: any = await httpClient.post(
    `/cas/orders/${payload.ordersId}/delivery`,
    payload
  );
  return res.data;
}

export async function queryCasOrderCompanyListApi(
  keyword: string
): Promise<{ id: number; shipperCode: string; shipperName: string }[]> {
  const res: any = await httpClient.get(`/cas/orders/company-list`, {
    params: { keyword },
  });
  return res.data;
}

export async function getCasOrderArchivePrint(
  orderNo: number
): Promise<string> {
  const res: any = await httpClient.get(
    `/cas/orders/${orderNo}/orderArchivePrint`
  );
  return res.data;
}

export async function getCasOrderDeliveryPrint(
  orderNo: number,
  deliveryIds: number[]
): Promise<string> {
  const res: any = await httpClient.post(
    `/cas/orders/${orderNo}/orderDeliveryPrint`,
    deliveryIds
  );
  return res.data;
}
