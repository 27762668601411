import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Form, Radio, Select, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import useMessage from 'antd/es/message/useMessage';
import { useEffect } from 'react';
import { CmtModalButton } from '../../../components/CmtModalButton';
import { InfiniteSelect } from '../../../components/InfiniteSelect';
import {
  GPOOrderDto,
  GpoOrderMetaDto,
  updateOrderMeta,
} from '../../../services/gpo/order.service';
import { queryProjectApi, Project } from '../../../services/project.service';
import { queryActiveUsersApi, UserDto } from '../../../services/user.service';
import styles from './index.module.less';
import { OrderBizLogisticsInput } from './OrderBizLogisticsInput';

export function OrderMetaForm(props: {
  meta: GpoOrderMetaDto;
  order: GPOOrderDto;
  showFields: string[];
  projectId: string;
  onCancel?: () => void;
  onSuccess?: (data: GpoOrderMetaDto) => void;
}) {
  const [messageApi, context] = useMessage();
  const { data, loading, run } = useRequest(updateOrderMeta, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      props.onSuccess?.(data);
    },
    onError(e, params) {
      messageApi.error(e.message);
    },
  });
  const [form] = Form.useForm();
  const sellServiceTypes = [
    '无需售后',
    '公司售后',
    '供应商售后',
    '厂家售后',
    '电商平台售后',
  ].map((x) => {
    return { label: x, value: x };
  });
  useEffect(() => {
    form.setFieldsValue(props.meta);
  }, [props.meta]);
  return (
    <div style={{ padding: '12px 0 0 0' }}>
      {context}
      <Form
        disabled={loading}
        layout="vertical"
        form={form}
        onFinish={() => {
          form.validateFields().then(() => {
            const v = form.getFieldsValue();
            const payload: any = {
              orderGuid: props.order.orderGuid,
              orderCode: props.order.orderCode,
              projectId: props.projectId,
            };
            props.showFields.forEach((k) => {
              payload[k] = v[k];
            });
            run(payload);
          });
        }}
      >
        <Form.Item
          label="商务资料"
          name="note1"
          hidden={!props.showFields.includes('note1')}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          label="商务资料物流（发件手机号和收件手机号任选其一）"
          name="note2"
          hidden={!props.showFields.includes('note2')}
        >
          <OrderBizLogisticsInput />
        </Form.Item>
        <Form.Item
          label="备注"
          name="note3"
          hidden={!props.showFields.includes('note3')}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          label="合同/验收报告"
          name="note4"
          hidden={!props.showFields.includes('note4')}
        >
          <Radio.Group
            options={[
              { label: '已回传', value: '已回传' },
              { label: '未回传', value: '未回传' },
              { label: '电子签章', value: '电子签章' },
            ]}
          ></Radio.Group>
        </Form.Item>
        <Form.Item
          label="业务跟进"
          name="note5"
          hidden={!props.showFields.includes('note5')}
        >
          <InfiniteSelect
            api={queryActiveUsersApi}
            option={(item: UserDto) => {
              return { label: item.name, value: item.name };
            }}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              保存
            </Button>
            <Button type="default" onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export function OrderMetaEdit(props: {
  order: GPOOrderDto;
  meta: GpoOrderMetaDto;
  content: React.ReactNode;
  projectId: string;
  showFields: string[];
  icon?: React.ReactNode;
  onSuccess?: (data: GpoOrderMetaDto) => void;
}) {
  const [messageApi, context] = useMessage();
  return (
    <CmtModalButton
      title={props.order.orderCode}
      width={600}
      trigger={({ show }) => {
        return (
          <Space size={'small'} className={styles['edit']} onClick={show}>
            {context}
            {props.content}
            {props.icon ? (
              <span className={styles['action']}>{props.icon}</span>
            ) : (
              <EditOutlined className={styles['action']} />
            )}
          </Space>
        );
      }}
    >
      {({ show, hide }) => {
        return (
          <OrderMetaForm
            meta={props.meta}
            showFields={props.showFields}
            projectId={props.projectId}
            order={props.order}
            onCancel={hide}
            onSuccess={(v) => {
              props.onSuccess?.(v);
              messageApi.success(`保存成功`);
              hide();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
