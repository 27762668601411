import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useDynamicList } from 'ahooks';
import {
  Button,
  Col,
  Divider,
  Empty,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { useEffect, useState } from 'react';
import { InfiniteSelect } from '../../../components/InfiniteSelect';
import { LogisticDetailView } from '../../../components/LogisticDetailView';
import { queryLogisticProvidersApi } from '../../../services/logistics.service';

export interface OrderBizLogistics {
  providerName: string;
  zipCode: string;
  receiverPhone: string;
  senderPhone: string;
}

export function OrderBizLogisticsInput(props: {
  value?: string;
  onChange?: (val: string) => void;
}) {
  const [formValue, setFormValue] = useState<OrderBizLogistics>(
    {} as OrderBizLogistics
  );
  const { list, remove, getKey, insert, resetList } =
    useDynamicList<OrderBizLogistics>([]);
  const [message, context] = useMessage();
  useEffect(() => {
    const output = JSON.stringify(list);
    props.onChange?.(output);
  }, [list.length]);
  useEffect(() => {
    resetList(JSON.parse(props.value || '[]'));
  }, [props.value]);
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      {context}
      <Row gutter={6} style={{ width: '100%' }}>
        <Col span={5} style={{ display: 'flex', alignItems: 'center' }}>
          物流
        </Col>
        <Col span={6}>快递号</Col>
        <Col span={5}>发件手机号</Col>
        <Col span={5}>收件手机号</Col>
        <Col span={2}></Col>
      </Row>
      <Row key={'head'} gutter={6} style={{ width: '100%' }}>
        <Col span={5}>
          <InfiniteSelect
            placeholder="物流"
            api={queryLogisticProvidersApi}
            onChange={(v) => setFormValue({ ...formValue, providerName: v })}
            option={(item: { name: string; code: string }) => {
              return { label: item.name, value: item.name };
            }}
            value={formValue?.providerName}
          />
        </Col>
        <Col span={6}>
          <Input
            placeholder="快递号"
            onChange={(v) =>
              setFormValue({ ...formValue, zipCode: v.target.value })
            }
            value={formValue?.zipCode}
          />
        </Col>
        <Col span={5}>
          <Input
            placeholder="发件手机号"
            onChange={(v) =>
              setFormValue({ ...formValue, senderPhone: v.target.value })
            }
            value={formValue?.senderPhone}
          />
        </Col>
        <Col span={5}>
          <Input
            placeholder="收件手机号"
            onChange={(v) =>
              setFormValue({ ...formValue, receiverPhone: v.target.value })
            }
            value={formValue?.receiverPhone}
          />
        </Col>
        <Col span={2}>
          <Button
            htmlType="button"
            onClick={() => {
              if (
                formValue.providerName &&
                formValue.zipCode &&
                (formValue.receiverPhone || formValue.senderPhone)
              ) {
                insert(list.length + 1, {
                  providerName: formValue?.providerName || '',
                  zipCode: formValue?.zipCode || '',
                  senderPhone: formValue?.senderPhone || '',
                  receiverPhone: formValue?.receiverPhone || '',
                });
                setFormValue({} as OrderBizLogistics);
              } else {
                message.error(
                  '物流、快递号必填，发件手机号和收件手机号任选其一'
                );
              }
            }}
          >
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <Divider />
      <Typography.Text type="secondary">已添加物流</Typography.Text>
      {list.map((x, index) => {
        return (
          <Row key={getKey(index)} gutter={6} style={{ width: '100%' }}>
            <Col span={5}>
              <Typography.Text>{x.providerName}</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text>{x.zipCode}</Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text>{x.senderPhone}</Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text>{x.receiverPhone}</Typography.Text>
            </Col>
            <Col span={2}>
              <Space>
                <LogisticDetailView data={x}></LogisticDetailView>
                <Button
                  type={'default'}
                  htmlType="button"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <MinusOutlined />
                </Button>
              </Space>
            </Col>
          </Row>
        );
      })}
      {!list.length && <Empty />}
      {/* <Button
      type="primary"
      htmlType="button"
      onClick={() => {
        runUpdate(props.category.id, {
          brandTrottles: JSON.stringify(list || []),
        });
      }}
    >
      保存
    </Button> */}
    </Space>
  );
}
