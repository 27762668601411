import { Tooltip } from 'antd';
import { RJOrderStatus } from '../../services/rjmart/order-modal';
import {
  RJProductStatuOptions,
  RJProductStatus,
} from '../../services/rjmart/product-modal';

export function RJMProductStatusName(props: {
  data: RJProductStatus;
  rejectComment?: string;
}) {
  const item = RJProductStatuOptions.find((x) => x.value === props.data);
  return (
    <Tooltip
      title={props.rejectComment}
      getPopupContainer={(e) => e.parentNode as any}
    >
      {item?.name || props.data}
    </Tooltip>
  );
}

export function RJMORderStatusName(props: { data: number }) {
  const item = RJOrderStatus.find((x) => x.value === props.data);
  return <>{item?.name || props.data}</>;
}
