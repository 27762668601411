import { BarsOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import { Button, Form, Input, Segmented, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import {
  getAgreementStatusName,
  GPOAgreementDto,
  GPOAgreementStatus,
  queryAgreementApi,
} from '../../../../services/gpo/agreement.service';
import { GPOPlatform } from '../../../../services/gpo/standard-goods.service';

export function AgreementListPage() {
  const tableRef = useRef<any>(null);
  useTitle('我的协议');
  const catGroupOptions = [
    {
      label: '集采商城',
      value: GPOPlatform.JiCai,
    },
    {
      label: '货物超市',
      value: GPOPlatform.GoodMarket,
    },
    {
      label: '疫情防控馆',
      value: GPOPlatform.YiQing,
    },
  ];
  const columns: ColumnsType<GPOAgreementDto> = [
    {
      title: '协议编号',
      dataIndex: 'agreementCode',
    },
    {
      title: '协议名称',
      dataIndex: 'agreementName',
    },
    {
      title: '入驻区域',
      dataIndex: 'regionName',
    },
    {
      title: '入驻区域ID',
      dataIndex: 'regionGuid',
    },
    {
      title: '协议状态',
      dataIndex: 'agreementStatus',
      render(value: GPOAgreementStatus, record, index) {
        return getAgreementStatusName(value);
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '系统设置' },
        { name: '我的协议' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title={
          <Segmented
            defaultValue={
              catGroupOptions.find((x) => x.label === GPOPlatform.JiCai)?.label
            }
            options={catGroupOptions.map((x) => {
              return x.label;
            })}
            onChange={(key) => {
              const group = catGroupOptions.find((x) => x.label === key)?.value;
              tableRef.current.form.setFieldsValue({ platform: group });
              tableRef.current.submit();
            }}
          />
        }
        description=""
        rowKey="id"
        api={queryAgreementApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="platform"
                  hidden
                  initialValue={GPOPlatform.JiCai}
                >
                  <Input></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
