export enum MartOrderQueryStatus {
  /**
   * 全部
   */
  All = '-1',
  /**
   * 未审核
   */
  NotApproved = '6',
  /**
   * 待商家确认
   */
  ConfirmPending = '1',
  /**
   * 代发货
   */
  DeliverPending = '2',
  /**
   * 已发货
   */
  AlreadyDeliver = '3',
  /**
   * 已关闭
   */
  Close = '4',
  /**
   * 订单取消处理
   */
  ProgressCancel = '5',
}

export const MartOrderTypes = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '普通订单',
    value: '0',
  },
  {
    label: '积分换购订单',
    value: '1',
  },
  {
    label: '团购订单',
    value: '2',
  },
  {
    label: '免税订单',
    value: '3',
  },
  {
    label: '服务订单',
    value: '4',
  },
  {
    label: 'ATCC订单',
    value: '5',
  },
  {
    label: '仪器维修订单',
    value: '6',
  },
  {
    label: '危化品订单',
    value: '7',
  },
  {
    label: '招投标订单',
    value: '9',
  },
  {
    label: '智能制造',
    value: '10',
  },
];

export const MartOrderStatus = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '待商家确认',
    value: '0',
  },
  {
    label: '处理中',
    value: '1',
  },
  {
    label: '交易成功',
    value: '2',
  },
  {
    label: '交易失败',
    value: '3',
  },
];

export const MartOrderMonitorStatus = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '否',
    value: '0',
  },
  {
    label: '是',
    value: '1',
  },
];

export const MartOrderPayway = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '课题组自结',
    value: '4',
  },
  {
    label: '研究所统一结算',
    value: '6',
  },
];

export const MartOrderDeliveryStatus = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '未验货',
    value: '1',
  },
  {
    label: '已验货',
    value: '2',
  },
];

export const MartOrderSettlementStatus = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '已结算',
    value: '1',
  },
  {
    label: '未结算',
    value: '0',
  },
];

export const MartOrderMoreSearchTypes = [
  {
    label: '订单号',
    value: '0',
  },
  {
    label: '课题组',
    value: '1',
  },
  {
    label: '商品编码',
    value: '4',
  },
  {
    label: '商品名称',
    value: '3',
  },
];

export interface MartOrderQueryParams {
  params: {
    /**
     * 状态类型
     */
    tabIndex: MartOrderQueryStatus;
    /**
     * 采购单位
     */
    institutes: string;
    /**
     * 订单类型 MartOrderTypes
     */
    type: string;
    /**
     * 订单状态 MartOrderStatus
     */
    status: string;
    /**
     * 是否代结算 MartOrderMonitorStatus
     */
    monitorStatus: string;
    /**
     * 结算方式 MartOrderPayway
     */
    payWay: string;
    brandId: string;
    /**
     * 验货状态 MartOrderDeliveryStatus
     */
    deliveryStatus: string;
    /**
     * 订购人
     */
    buyerId: string;
    addressName: string;
    /**
     * 是否结算 MartOrderSettlementStatus
     */
    whetherSettlement: string;
    /**
     * 更多类型 MartOrderMoreSearchTypes
     */
    searchType: string;
    search: string;
    noDisplayFail: 1;
    payWayStatus: 1;
  };
  /**
   * 日期范围 '2023-07-04 - 2023-08-15'
   */
  dateList: string;
  startDate: string;
  endDate: string;
  page: number;
  count: number;
}

export interface MartOrderProductVo {
  activityId: number;
  amount: number;
  deleted: number;
  id: number;
  medalTypeId: number;
  oldPriceDiscount: number;
  ordersId: number;
  parentId: number;
  priceDiscount: number;
  productAuthorizeCode: string;
  productBissnessType: number;
  productBrandId: number;
  productBrandName: string;
  productBrokerage: number;
  productCateId: number;
  productCode: string;
  productCoin: number;
  productDanger: number;
  productDiscount: number;
  productFavor: number;
  productForbidden: number;
  productId: number;
  productImg: string;
  productMaker: string;
  productMktPrice: number;
  productName: string;
  productNoStock: number;
  productPrice: number;
  productSalePrice: number;
  productSpec: string;
  productSupplierId: number;
  productTaxCode: string;
  productTaxName: string;
  productUnit: string;
  productUseCoin: number;
  receivedAmount: number;
  syncStatus: number;
  type: number;
}

export interface MartSimpleOrderDto {
  /**
   * 下单时间
   */
  addTime: string;
  addressCity: string;
  addressCounty: string;
  /**
   * 收货人
   */
  addressName: string;
  addressState: string;
  attachmentNum: number;
  buyerId: number;
  canCancelInfo: boolean;
  canDeliver: boolean;
  /**
   * 销售
   */
  contactName: string;
  contractAttachment: string;
  dangerStatus: number;
  deliveryStatus: number;
  flowAuditStatus: number;
  from: string;
  id: number;
  institutes: number;
  /**
   * 采购单位名称
   */
  institutesName: string;
  isContainRequirement: number;
  /**
   * 代结算
   */
  monitorStatus: number;
  note: string;
  ordersGoodsList: MartOrderProductVo[];
  /**
   * 结算方式
   */
  payWayName: string;

  paymentStatus: number;
  sn: string;
  sourceType: number;
  status: number;
  statusInfo: string;
  supplierId: number;
  supplierSign: string;
  taskGroupName: string;
  tasks: string;
  tasksId: number;
  totalAllPrice: number;
  totalFreight: number;
  type: number;
  typeInfo: string;
  /**
   * 结算状态
   */
  whetherSettlement: string;
}
export interface MartOrderDetailDto extends MartSimpleOrderDto {
  /**
   * 状态历史
   */
  statusHistory?: { action: string; date: string }[];
  /**
   * 订单日志
   */
  orderLogs?: { action: string; date: string; actor: string }[];
  /**
   * 物流信息
   */
  deliveryLogs?: any[];
  /**
   * 验货信息
   */
  verifyInfo?: {
    basicInfo: { name: string; value: string }[];
    /**
     * 验货商品table html
     */
    productsTable: { name: string; value: string }[][];
  };
  /**
   * 发货单ID
   */
  deliveryIds?: number[];
  mainDetails?: { name: string; value: string }[];

  /**
   * 商品清单
   */
  productList?: { name: string; value: string }[][];

  /**
   * 价格统计
   */
  priceAmountList?: { name: string; value: string }[];
}

/**
 *订单状态枚举
 */
export enum MartOrderStatusEnum {
  ConfirmPending = 0,
  Processing = 1,
  Success = 2,
  Failed = 3,
}

export interface MartOrderDeliveryPayload {
  ordersId: number;
  /**
   * 默认模块
   */
  name: string;
  /**
   * 配送方式 0： 物流配送  1： 商家自配送
   */
  selfDelivery: 0 | 1;
  /**
   * 发货人
   */
  addrName: string;
  /**
   * 物流单号
   */
  code: string;
  /**
   * 物流公司
   */
  companyName: string;
  /**
   * 送货人
   */
  deliveryMan: string;
  /**
   * 送货人电话
   */
  deliveryMobile: string;
  /**
   * 配送备注
   */
  note: string;
  /**
   * 商品信息
   */
  deliveryGoodsList: {
    checked: boolean;
    goodsId: number;
    productId: number;
    goodsMark: string;
    productCateId: number;
    productCode: string;
    productName: string;
    productImg: string;
    productSpec: string;
    productPrice: number;
    /**
     * 发货数量
     */
    productAmount: number;
  }[];
  /**
   * 发票附件
   */
  attachments: string[];
}
