import {
  AlertOutlined,
  HeartOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Popover, Button, Spin, Typography, Tooltip } from 'antd';
import { useContext, useEffect, useMemo } from 'react';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';
import {
  ProjectPlatforms,
  queryProjectApi,
} from '../../services/project.service';
import { ServiceHealthyItem } from './ServiceHealthyItem';

function ServiceHealthyList() {
  const [globalContext] = useContext(GlobalContext);
  const { loading, run, data: projects } = useRequest(queryProjectApi);
  useEffect(() => {
    const organizationId = globalContext.userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.organizationId
    )?.v;
    if (!organizationId) {
      return;
    }
    run({ pageSize: 100, current: 1, organizationId });
  }, [
    globalContext.userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.organizationId
    )?.v,
  ]);

  return (
    <Spin spinning={loading} size="small" tip="记载中...">
      {projects?.list.map((x) => {
        return (
          <ServiceHealthyItem
            key={x.id}
            project={x}
            path={`${x.platform}/utils/service-healthy`}
          />
        );
      })}
    </Spin>
  );
}

export function ServiceHealthyButton() {
  return (
    <Popover
      content={<ServiceHealthyList></ServiceHealthyList>}
      trigger="click"
    >
      <Tooltip title="执行器状态" placement="left">
        <Button type="text">
          <AlertOutlined />
        </Button>
      </Tooltip>
    </Popover>
  );
}
