import { Result } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  checkFeatureToggle,
  useFeatureToggle,
} from '../../../components/FeatureToggle/use-feature-toggle';
import { Permission } from '../../../components/Permission';
import { checkPermission } from '../../../components/Permission/use-permission';
import { GlobalContext } from '../../../context';
import { ProjectInfo } from '../../../gpo/components/ProjectInfo';
import { SubLayout } from '../../../layouts/sub-layout/sub-layout';

export function GpoSingleProjectHome() {
  const [globalContext] = useContext(GlobalContext);
  const productManagementFeature = useFeatureToggle(
    'org.features',
    'rjm.products'
  );
  const orderFeature = useFeatureToggle('org.features', 'rjm.orders');
  const [projectNavItems, setProjectNavItems] = useState<ItemType[]>([]);

  const buildNav = () => {
    if (!globalContext?.userMetaData) {
      return;
    }

    const productManagementMenus: any[] = [
      {
        label: <NavLink to="products">线上商品库</NavLink>,
        key: 'products',
        action: 'products.listing',
      },
      // {
      //   label: <NavLink to="local">本地商品库</NavLink>,
      //   key: 'local-products',
      //   action: 'products.local',
      // },
    ];
    const productManagement: ItemType = {
      label: '商品管理',
      key: 'product-management',
      children: productManagementMenus.filter((x) => {
        return checkPermission('rjm', x.action, globalContext.userMetaData);
      }),
    };

    const orderManagementMenus: any[] = [
      {
        label: <NavLink to="orders">订单管理</NavLink>,
        key: 'orders',
        action: 'order.listing',
      },
    ];
    const orderManagement: ItemType = {
      label: '交易管理',
      key: 'order-management',
      children: orderManagementMenus.filter((x) => {
        return checkPermission('rjm', x.action, globalContext.userMetaData);
      }),
    };

    const nav: ItemType[] = [];
    if (productManagementFeature && productManagement.children.length) {
      nav.push(productManagement);
    }

    if (orderFeature && orderManagement.children.length) {
      nav.push(orderManagement);
    }

    setProjectNavItems(nav);
  };
  useEffect(buildNav, [globalContext.userMetaData]);
  return (
    <Permission
      resource="rjm"
      action="read"
      error={
        <Result
          status={'error'}
          title="您没有权限访问"
          subTitle={'请联系管理员调整权限'}
        ></Result>
      }
    >
      <SubLayout
        menuAddOn={<ProjectInfo />}
        menuItems={projectNavItems}
      ></SubLayout>
    </Permission>
  );
}
