import { useRequest, useTitle } from 'ahooks';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  ConvertNumberToChineseCurrency,
  ConvertNumberToCurrency,
} from '../../../../common/utils/convert-number-to-currency';
import {
  queryContractApi,
  queryContractDetailApi,
} from '../../../../services/gpo/contract.service';
import { queryOrderDetailApi } from '../../../../services/gpo/order.service';
import print from './print.module.less';

export const PrintableReceivingForm = React.forwardRef<
  any,
  { orderGuid: string; projectId: string }
>((props, ref) => {
  const { data, loading, run } = useRequest(queryOrderDetailApi, {
    manual: true,
    debounceWait: 100,
    onSuccess(data, params) {
      runContract({
        contractId: data.contractGuid,
        projectId: props.projectId,
      });
    },
  });
  const {
    data: contract,
    loading: loadingContract,
    run: runContract,
  } = useRequest(queryContractDetailApi, {
    manual: true,
    debounceWait: 100,
  });
  useTitle(`${data?.orderCode} / ${data?.orgName} / ${data?.supplierName}`, {
    restoreOnUnmount: true,
  });
  useEffect(() => {
    if (props.orderGuid && props.projectId) {
      run({
        projectId: props.projectId,
        orderGuid: props.orderGuid,
      });
    }
  }, [props.orderGuid, props.projectId]);

  return (
    <Spin spinning={loading}>
      <div className={print['table']} ref={ref} contentEditable={true}>
        <style type="text/css" media="print">
          {
            '\
  @page { size: a4; margin: 1cm 0.5cm; }\
'
          }
        </style>
        <table
          style={{
            textAlign: 'center',
            padding: '0',
            width: '100%',
          }}
        >
          <tr>
            <td style={{ fontSize: 24, paddingBottom: 12 }}>项目验收报告</td>
          </tr>
        </table>
        <table style={{ width: '100%' }}>
          <tr>
            <td style={{ textAlign: 'left' }}></td>
            <td style={{ textAlign: 'right' }}>
              合同编号：{contract?.contractCode}
            </td>
          </tr>
        </table>
        <table
          className={print['bordered']}
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ width: '100%' }}
        >
          <tr>
            <td width={100} align="center">
              采购单位
            </td>
            <td colSpan={4}>{data?.orgName}</td>
          </tr>
          <tr>
            <td width={100} align="center">
              供应商
            </td>
            <td colSpan={4}>{data?.supplierName}</td>
          </tr>
          <tr>
            <td width={100} align="center">
              序号
            </td>
            <td align="center">技术规格、标准及要求 </td>
            <td align="center">数量</td>
            <td align="center">单价</td>
            <td align="center">小计</td>
          </tr>
          {data?.orderGoodsVOList.map((item, index) => (
            <tr>
              <td align="center">{index + 1}</td>
              <td>{item.goodsName}</td>
              <td align="center" width={50}>
                {item.qty}
              </td>
              <td align="center">{ConvertNumberToCurrency(item.finalPrice)}</td>
              <td align="center">
                {ConvertNumberToCurrency(item.agreementTotal)}
              </td>
            </tr>
          ))}
          <tr>
            <td align="center">合计</td>
            <td colSpan={3} align="center">
              大写：{ConvertNumberToChineseCurrency(data?.goodsTotal || 0)}
            </td>
            <td align="center">
              {ConvertNumberToCurrency(data?.goodsTotal || 0)}
            </td>
          </tr>
          <tr>
            <td align="center">验收内容</td>
            <td colSpan={4}>
              <table>
                <tr>
                  <td>品牌产地是否正确：□</td>
                  <td>数量是否正确：□</td>
                </tr>
                <tr>
                  <td>规格型号是否正确：□</td>

                  <td>配置是否正确：□</td>
                </tr>
                <tr>
                  <td>安装调试是否正常：□</td>
                  <td>包装是否完好：□</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <p>
                我单位对合同项下
                <span
                  style={{ textDecoration: 'underline', padding: '2px 5px' }}
                >
                  {data?.supplierName}
                </span>
                提供的货物和服务进行了验收
              </p>
              <p>验收结论：</p>
              <br />
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 28,
                }}
              >
                全部货物运行调试一切正常，
              </p>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 28,
                }}
              >
                一次性验收合格。
              </p>
              <br />
              <br />
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                我单位对{data?.supplierName}的安装及服务表示非常满意。
              </p>
              <br />
              <br />
              <p>
                <span>验收成员（签字）：</span>
                <span style={{ float: 'right' }}>
                  采购单位：（盖章） &nbsp;&nbsp;
                </span>
              </p>
              <p style={{ float: 'right' }}>________年____月____日</p>
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <p>供货公司项目负责人（签字）：</p>
              <p style={{ textAlign: 'right' }}>
                供货公司单位：（盖章） &nbsp;&nbsp;
              </p>
              <p style={{ textAlign: 'right' }}>________年____月____日</p>
              <br />
            </td>
          </tr>
        </table>
      </div>
    </Spin>
  );
});
