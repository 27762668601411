import { Result } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  checkFeatureToggle,
  useFeatureToggle,
} from '../../../components/FeatureToggle/use-feature-toggle';
import { Permission } from '../../../components/Permission';
import { checkPermission } from '../../../components/Permission/use-permission';
import { GlobalContext } from '../../../context';
import { SubLayout } from '../../../layouts/sub-layout/sub-layout';
import { ProjectInfo } from '../../components/ProjectInfo';

export function GpoSingleProjectHome() {
  const [globalContext] = useContext(GlobalContext);
  const jicaiFeature = useFeatureToggle('org.features', 'gpo.jicai');
  const goodmarketFeature = useFeatureToggle('org.features', 'gpo.goodmarket');
  const reportFeature = useFeatureToggle('org.features', 'gpo.report');
  const [projectNavItems, setProjectNavItems] = useState<ItemType[]>([]);

  const buildNav = () => {
    if (!globalContext?.userMetaData) {
      return;
    }
    const dashboard: any = {
      label: <NavLink to={'welcome'}>效能分析</NavLink>,
      key: 'dashboard',
    };
    const jiCaiMenus: any[] = [
      {
        label: <NavLink to="standard-library">标准商品库</NavLink>,
        key: 'standard-library',
        action: 'jicai.standardLibrary',
      },
      {
        label: <NavLink to="jicai-on-sale-products">我的在售商品</NavLink>,
        key: 'jicai-on-sale-products',
        action: 'jicai.myOnSaleGoods',
      },
      {
        label: <NavLink to="local-jicai-on-sale-products">本地商品库</NavLink>,
        key: 'local-jicai-on-sale-products',
        action: 'jicai.local',
      },
    ];
    const jicai: ItemType = {
      label: '集采商城',
      key: 'JiCaiMall',
      children: jiCaiMenus.filter((x) => {
        return checkPermission('gpo', x.action, globalContext.userMetaData);
      }),
    };

    const goodMarketMenus: any[] = [
      {
        label: <NavLink to={'goodmarket-products'}>线上商品</NavLink>,
        key: 'goodmarket-products',
        action: 'goodmarket.online',
      },
      {
        label: <NavLink to={'draft-products'}>临时商品库</NavLink>,
        key: 'draft-products',
        action: 'goodmarket.draftProducts',
      },
      {
        label: <NavLink to={'local-goodmarket-products'}>本地商品库</NavLink>,
        key: 'local-goodmarket-products',
        action: 'goodmarket.local',
      },
    ];

    const goodMarket: ItemType = {
      label: '货物超市',
      key: 'goodMarket',
      children: goodMarketMenus.filter((x) => {
        return checkPermission('gpo', x.action, globalContext.userMetaData);
      }),
    };

    const orderMenus: any[] = [
      {
        label: <NavLink to={'orders'}>订单中心</NavLink>,
        key: 'orders',
        action: 'order.all',
        group: 'org.features',
        feature: 'gpo.order',
      },
      {
        label: <NavLink to={'contracts'}>我的合同</NavLink>,
        key: 'contracts',
        action: 'contract.all',
        group: 'org.features',
        feature: 'gpo.contract',
      },
    ];

    const order: ItemType = {
      label: '订单管理',
      key: 'orderManagement',
      children: orderMenus.filter((x) => {
        return (
          checkFeatureToggle(x.group, x.feature, globalContext.userMetaData) &&
          checkPermission('gpo', x.action, globalContext.userMetaData)
        );
      }),
    };

    const settingMenus: any[] = [
      {
        label: <NavLink to={'categories'}>商品分类</NavLink>,
        key: 'categories',
        action: 'category.all',
        group: 'org.features',
        feature: 'gpo.category',
      },
      {
        label: <NavLink to={'agreements'}>我的协议</NavLink>,
        key: 'agreements',
        action: 'agreement.all',
        group: 'org.features',
        feature: 'gpo.agreement',
      },
    ];

    const setting: ItemType = {
      label: '系统设置',
      key: 'systemSettings',
      children: settingMenus.filter((x) => {
        return (
          checkFeatureToggle(x.group, x.feature, globalContext.userMetaData) &&
          checkPermission('gpo', x.action, globalContext.userMetaData)
        );
      }),
    };

    const nav = [];
    console.log('jicaifeature', jicaiFeature);
    if (jicaiFeature) {
      nav.push(jicai);
    }
    if (goodmarketFeature) {
      nav.push(goodMarket);
    }
    if (
      orderMenus.some(
        (x) =>
          checkFeatureToggle(x.group, x.feature, globalContext.userMetaData) &&
          checkPermission('gpo', x.action, globalContext.userMetaData)
      )
    ) {
      nav.push(order);
    }

    if (
      settingMenus.some(
        (x) =>
          checkFeatureToggle(x.group, x.feature, globalContext.userMetaData) &&
          checkPermission('gpo', x.action, globalContext.userMetaData)
      )
    ) {
      nav.push(setting);
    }
    if (reportFeature && false) {
      nav.push(dashboard);
    }

    setProjectNavItems(nav);
  };
  useEffect(buildNav, [globalContext.userMetaData]);
  return (
    <Permission
      resource="gpo"
      action="read"
      error={
        <Result
          status={'error'}
          title="您没有权限访问"
          subTitle={'请联系管理员调整权限'}
        ></Result>
      }
    >
      <SubLayout
        menuAddOn={<ProjectInfo />}
        menuItems={projectNavItems}
      ></SubLayout>
    </Permission>
  );
}
