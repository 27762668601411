import { useTitle } from 'ahooks';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Input, Select, Space, Form, Typography, Tag } from 'antd';
import {
  MartBatchTask,
  MartBatchtaskStatus,
} from '../../../../services/cas/product-modal';
import {
  BarsOutlined,
  CheckCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useRef } from 'react';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';

import { queryCasMartProductBatchTaskApi } from '../../../../services/cas/product.service';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';

export function ProductBatchTasksPage() {
  const params = useParams();
  useTitle('批量任务', { restoreOnUnmount: true });
  const tableRef = useRef<any>(null);

  const columns: ColumnsType<MartBatchTask> = [
    {
      title: '#',
      key: 'index',
      width: 40,
      render(value, record, index) {
        return (
          <Typography.Text type={'secondary'}>
            {(index + 1).toString().padStart(3, '0')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '操作类型',
      dataIndex: 'typeText',
    },
    {
      title: '当前/总数',
      dataIndex: 'current',
      render(value, record, index) {
        return `${record.current}/${record.total}`;
      },
    },
    {
      title: '操作时间',
      dataIndex: 'created',
    },
    {
      title: '结果',
      dataIndex: 'status',
      render: (value, record) => {
        return value === MartBatchtaskStatus.Completed ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            已结束
          </Tag>
        ) : (
          <Tag icon={<SyncOutlined spin />} color="processing">
            处理中
          </Tag>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: 'CAS', icon: <BarsOutlined /> },
        { name: '商品管理' },
        { name: '批量任务' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="批量任务"
        description=""
        rowKey="id"
        selectable={true}
        api={queryCasMartProductBatchTaskApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
