import { DefaultLocale } from 'react-js-cron';

export const DEFAULT_LOCALE_CN: DefaultLocale = {
  everyText: '每',
  emptyMonths: '每月',
  emptyMonthDays: '月每天',
  emptyMonthDaysShort: '月第',
  emptyWeekDays: '周每天',
  emptyWeekDaysShort: '周第',
  emptyHours: '每小时',
  emptyMinutes: '每分钟',
  emptyMinutesForHourPeriod: '每',
  yearOption: '年',
  monthOption: '月',
  weekOption: '周',
  dayOption: '日',
  hourOption: '时',
  minuteOption: '分',
  rebootOption: '重启',
  prefixPeriod: '每',
  prefixMonths: '的',
  prefixMonthDays: '的',
  prefixWeekDays: '的',
  prefixWeekDaysForMonthAndYearPeriod: '且',
  prefixHours: '的',
  prefixMinutes: ':',
  prefixMinutesForHourPeriod: '的',
  suffixMinutesForHourPeriod: '分钟',
  errorInvalidCron: '无效表达式',
  clearButtonText: '重置',
  weekDays: [
    // Order is important, the index will be used as value
    '周日', // Sunday must always be first, it's "0"
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
  ],
  months: [
    // Order is important, the index will be used as value
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    '周日', // Sunday must always be first, it's "0"
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
  ],
  // Order is important, the index will be used as value
  altMonths: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
};
