export enum RJQueryOrderStatus {
  /**
   * 新订单
   */
  New = 8,
  /**
   * 待收货
   */
  Shipped = 5,
  /**
   * 待发货
   */
  ShipPending = 40,
  /**
   * 已完成
   */
  Completed = 110,
  /**
   * 待结算
   */
  SettlePending = 80,
}

export const RJQueryOrderStatusOptions = [
  { name: '新订单', value: RJQueryOrderStatus.New },
  { name: '待收货', value: RJQueryOrderStatus.Shipped },
  { name: '待发货', value: RJQueryOrderStatus.ShipPending },
  { name: '已完成', value: RJQueryOrderStatus.Completed },
  { name: '待结算', value: RJQueryOrderStatus.SettlePending },
];

export const RJOrderStatus = [
  {
    value: 0,
    name: '待对接方确认',
  },
  {
    value: -1,
    name: '对接失效',
  },
  {
    value: 3,
    name: '订单关闭',
  },
  {
    value: 4,
    name: '待发货',
  },
  {
    value: 5,
    name: '待收货',
  },
  {
    value: 6,
    name: '待结算',
  },
  {
    value: 7,
    name: '已评价',
  },
  {
    value: 8,
    name: '待确认',
  },
  {
    value: 9,
    name: '采购人申请取消',
  },
  {
    value: 10,
    name: '结算中',
  },
  {
    value: 11,
    name: '完成',
  },
  {
    value: 12,
    name: '结算审批',
  },
  {
    value: 13,
    name: '结算付款',
  },
  {
    value: 15,
    name: '待结算',
  },
  {
    value: 16,
    name: '待结算经费',
  },
  {
    value: 17,
    name: '结算经费失败',
  },
  {
    value: 19,
    name: '供应商申请取消',
  },
  {
    value: 20,
    name: '订单验收审批',
  },
  {
    value: 21,
    name: '统一报帐人驳回',
  },
  {
    value: 22,
    name: '待设备处验收审核',
  },
];

export interface RJDangerousTypes {
  value: number;
  name: string;
}

export enum RJDeliveryType {
  /**商家自送 */
  SupplierDeliver = 1,
  /**物流公司配送*/
  LogisticsCompany = 2,
}
export enum RJDeliveryTypeProxy {
  /**普通配送 */
  Normal = 0,
  /**代配送 */
  Delegated = 1,
}
export interface RJOrderQueryParams {
  /**
   * 分页参数，第几页
   */
  page: number;
  /**
   *分页参数，每页多少个 MAX 200
   */
  size: number;
  /**
   * 订单号，只支持精确搜索
   */
  orderNo?: string;
  /**
   * 采购人
   */
  buyuserName?: string;
  /**
   * 采购部门id
   */
  departmentId?: number;
  /**
   * 开始时间，格式 yyyy-MM-dd HH:mm:ss
   */
  startTime?: string;
  /**
   * 结束时间，格式 yyyy-MM-dd HH:mm:ss
   */
  endTime?: string;
  /**
   * 订单状态
   */
  orderStatus?: RJQueryOrderStatus;
}
export interface RJSimpleOrderDto {
  orderNo: string;
  orderDate: string;
  status: number;
  totalPrice: number;
  discountedPrice: number;
  paymentPrice: number;
  carryFee: number;
  orgName: string;
  departmentName: string;
  buyerName: string;
  openOrderDetailVoList: {
    fgoodname: string;
    fgoodcode: string;
    fspec: string;
    fbrand: string;
    funit: string;
    fquantity: number;
    fprice: number;
    casNo: string;
  }[];
}
export interface RJOrderDetailDto extends RJSimpleOrderDto {
  /**
   * 采购人Id
   */
  buyerId: number;
  /**
   *是否补录
   */
  additionalRecord: number;
  /**
   * 买家留言
   */
  orderRemark: string;
  /**
   * 商家备注
   */
  suppRemark: string;
  /**
   * deliveryType
   */
  deliveryType: RJDeliveryType;
  /**
   * 发货日期
   */
  fdeliverydate: string;
  /**
   * 发货说明
   */
  deliveryInfo: string;
  /**
   * 物流单号
   */
  deliveryNo: string;
  /**
   * 物流公司
   */
  logisticsCompany: string;
  /**
   * 采购人手机
   */
  contactphone: string;
  /**
   * 收货人
   */
  flastreceiveman: string;
  /**
   * 收货人手机
   */
  fbuyertelephone: string;
  /**
   * 收货人地址
   */
  fbiderdeliveryplace: string;
  address: {
    /**代配送地址省份 */
    provinceProxy: string;
    /**代配送地址城市
     */
    cityProxy: string;
    /**代配送地址区域
     */
    regionProxy: string;
    /**代配送地址详细地址
     */
    addressProxy: string;
    /**配送方式 */
    deliveryTypeProxy: RJDeliveryTypeProxy;
  };
}
