import { RocketOutlined, PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { message, Dropdown, Button } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { queryInternalActionsApi } from '../../services/task.service';
export function InternalActionButton(props: {
  slot: string;
  children: ReactNode;
}) {
  const [items, setItems] = useState<any>([]);
  const { loading, run: getActionInfo } = useRequest(queryInternalActionsApi, {
    manual: true,
    onBefore() {},
    onSuccess(actions) {
      const items = actions.list.map((x) => {
        return {
          key: x.id,
          label: (
            <NavLink to={'../create-task/' + x.id}>
              <RocketOutlined /> {x.internalName || x.name}
            </NavLink>
          ),
        };
      });
      setItems([{ key: 0, label: '快捷任务', disabled: true }, ...items]);
    },
    onError({ message: text }) {
      message.error('获取任务内容失败' + text);
    },
  });

  useEffect(() => {
    getActionInfo({
      current: 1,
      pageSize: 50,
      slot: props.slot,
    });
  }, []);
  return (
    <Dropdown menu={{ items }} placement="bottomRight" arrow>
      <Button type="default" loading={loading}>
        {props.children}
      </Button>
    </Dropdown>
  );
}
