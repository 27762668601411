import { useRequest } from 'ahooks';
import Cascader, { DefaultOptionType } from 'antd/es/cascader';
import { useEffect, useState } from 'react';
import { BaseCategory, CategoryId } from '../../../common/dtos/base-category';
import {
  CmtCascader,
  CmtCascaderOption,
} from '../../../components/CmtCascader';
import { buildCategoryTree } from '../../../services/gpo/category.service';
import { queryCasGroups } from '../../../services/cas/product.service';

export function CasGroupCascader(props: {
  queryParams: { projectId: string };
  style?: React.CSSProperties;
  allowClear?: boolean;
  onChange?: (val: CategoryId[] | CategoryId | null) => void;
  value?: CategoryId[] | CategoryId;
  showCheckedStrategy?: any;
  single?: boolean;
}) {
  const [options, setOptions] = useState<CmtCascaderOption[]>([]);
  const [currentValue, setCurrentValue] = useState<CategoryId[][]>([]);
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const createOptionTree = (res: BaseCategory[]) => {
    const opts = buildCategoryTree(res);
    setOptions(opts);
    if (!props.value) {
      return;
    }
    const value = !props.single
      ? (props.value as CategoryId[])
      : [props.value as CategoryId];
    if (value?.length) {
      const val: CategoryId[][] = value.map((x) => getValuePath(x, res));
      if (val.length) {
        setCurrentValue(val);
      }
    }
  };
  const { loading, run } = useRequest(queryCasGroups, {
    manual: true,
    debounceWait: 300,
    onSuccess: createOptionTree,
  });

  const getValuePath = (id: CategoryId, cats: BaseCategory[]) => {
    const path: CategoryId[] = [];
    const getCategoryPath = (id: CategoryId) => {
      const item = cats.find((x) => {
        return x.platformId === id;
      });
      if (item) {
        path.push(id);
        if (item.platformParentId) {
          getCategoryPath(item.platformParentId);
        }
      }
      return;
    };
    getCategoryPath(id);
    return path.reverse();
  };

  useEffect(() => {
    run(props.queryParams);
  }, [props.queryParams.projectId]);

  // 开启多选
  const onMultipleChange = (changedVal: any) => {
    setCurrentValue(changedVal);
    if (!props.single) {
      const val = changedVal as number[][];
      const output: number[] = val.length
        ? val.map((x) => x[x.length - 1])
        : [];
      props.onChange?.(output.length ? output : null);
    } else {
      const val = changedVal as CategoryId[];
      props.onChange?.(val.length ? val[val.length - 1] : null);
    }
  };

  return (
    <CmtCascader
      style={props.style}
      data={options}
      value={currentValue}
      onChange={onMultipleChange}
      loading={loading}
      multiple={!props.single}
      allowClear={props.allowClear}
      showSearch={{ filter }}
      showCheckedStrategy={Cascader.SHOW_CHILD}
    />
  );
}
