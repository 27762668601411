import { useDebounceFn, useRequest, useThrottle } from 'ahooks';
import {
  Checkbox,
  Divider,
  Form,
  InputNumber,
  message,
  Popover,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';
import { GPOAgreementTypes } from '../../../../common/dtos/AddGoodTypes';
import {
  GpoCategory,
  GpoCategoryGroup,
  updateGpoCategoryAutomationSettings,
} from '../../../../services/gpo/category.service';
import { CateogryBrandTrottlesSettings } from './category-brand-trottles-input';
const { Text } = Typography;

export function CategoryAutomationSettings(props: {
  data: GpoCategory;
  group: GpoCategoryGroup;
  onSuccess?: () => void;
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      autoPriceAdjustmentThrottle: props.data.autoPriceAdjustmentThrottle,
      brandTrottles: props.data.brandTrottles,
    });
  }, [props.data]);

  const id = props.data.id;

  const { loading, run: runUpdate } = useRequest(
    updateGpoCategoryAutomationSettings,
    {
      debounceWait: 200,
      manual: true,
      onSuccess() {
        // props.onSuccess?.();
        message.success('配置成功');
      },
      onError(e, params) {
        message.error('设置失败：' + e.message);
      },
    }
  );

  const { run: submit } = useDebounceFn(
    () => {
      form.validateFields().then(() => {
        runUpdate(id, form.getFieldsValue());
      });
    },
    { wait: 500 }
  );
  return (
    <Space size={'small'}>
      <Form
        key={`form${id}`}
        layout="inline"
        form={form}
        disabled={loading}
        onChange={submit}
      >
        <FormItem
          key={`autoPriceAdjustmentThrottle${id}`}
          name="autoPriceAdjustmentThrottle"
          label="调价上限"
          required={false}
          colon={false}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: 80 }}
            placeholder="折扣"
            size="small"
            addonAfter="%"
            min={0}
            max={100}
            onChange={submit}
          />
        </FormItem>
      </Form>
      <CateogryBrandTrottlesSettings
        category={props.data}
        agreementType={
          props.group === GpoCategoryGroup.JiCai
            ? GPOAgreementTypes.JiCaiMall
            : GPOAgreementTypes.GoodMarket
        }
        projectId={props.data.projectId}
        onSuccess={props.onSuccess}
      />
    </Space>
  );
}
