import { Space, Typography } from 'antd';
import { CmtAvator } from '../../../components/CmtAvatar';
import styles from './index.module.less';
const { Text } = Typography;

export function ProductName(props: {
  name: string;
  picturePath: string;
  goodsPriceGuid?: string;
  extends?: React.ReactNode;
  onClick?: () => void;
}) {
  const origin = props?.picturePath ? new URL(props.picturePath).origin : '';
  return (
    <Space>
      <CmtAvator url={props.picturePath} />
      <Text
        className={styles['name']}
        onClick={() => {
          props.goodsPriceGuid
            ? window.open(
                `${origin}/gpmall-main-web/goodslibrary/goodsDetails?goodspriceguid=${props.goodsPriceGuid}`
              )
            : props.onClick?.();
        }}
      >
        {props.name} {props.extends}
      </Text>
    </Space>
  );
}
