import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Descriptions,
  Space,
  Spin,
  Table,
  Tabs,
  Image,
  Timeline,
  Typography,
  Alert,
  Divider,
  Button,
  Result,
} from 'antd';
import { useEffect, useState } from 'react';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { queryRJMartOrderDetailApi } from '../../../../services/rjmart/order.service';
import { RJMORderStatusName } from '../../../components/status-name';

const { Text } = Typography;

export function OrderDetailView(props: {
  orderNo: string | undefined;
  projectId: string;
}) {
  const { data, loading, run, refresh } = useRequest(
    queryRJMartOrderDetailApi,
    {
      manual: true,
      debounceWait: 300,
    }
  );

  useEffect(() => {
    if (props.orderNo && props.projectId) {
      run(props.orderNo);
    }
  }, [props.orderNo]);

  return (
    <Spin spinning={loading}>
      {data?.data && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions
            title={'订单信息'}
            bordered
            column={1}
            labelStyle={{ width: 150 }}
          >
            <Descriptions.Item label="订单编号">
              {data?.data?.orderNo}
            </Descriptions.Item>
            <Descriptions.Item label="订单状态">
              <RJMORderStatusName data={data?.data?.status} />
            </Descriptions.Item>
            <Descriptions.Item label="订单总额">
              {ConvertNumberToCurrency(data.data.totalPrice)}
            </Descriptions.Item>
            <Descriptions.Item label="订单优惠">
              {ConvertNumberToCurrency(data.data.discountedPrice)}
            </Descriptions.Item>
            <Descriptions.Item label="订单应付">
              {ConvertNumberToCurrency(data.data.paymentPrice)}
            </Descriptions.Item>
            <Descriptions.Item label="订单运费">
              {ConvertNumberToCurrency(data.data.carryFee)}
            </Descriptions.Item>
            <Descriptions.Item label="下单时间">
              {data.data.orderDate}
            </Descriptions.Item>
            <Descriptions.Item label="买家留言">
              {data.data.orderRemark}
            </Descriptions.Item>
            <Descriptions.Item label="商家备注">
              {data.data.suppRemark}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title={'采购单位信息'}
            bordered
            column={1}
            labelStyle={{ width: 150 }}
          >
            <Descriptions.Item label="采购单位">
              {data.data.orgName}
            </Descriptions.Item>
            <Descriptions.Item label="采购课题组">
              {data.data.departmentName}
            </Descriptions.Item>
            <Descriptions.Item label="采购人">
              {data.data.buyerName}
            </Descriptions.Item>
            <Descriptions.Item label="采购人电话">
              {data.data.contactphone}
            </Descriptions.Item>
            <Descriptions.Item label="收货人">
              {data.data.flastreceiveman}
            </Descriptions.Item>
            <Descriptions.Item label="收货人电话">
              {data.data.fbuyertelephone}
            </Descriptions.Item>
            <Descriptions.Item label="收货人地址">
              {data.data.fbiderdeliveryplace}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title={'物流信息'}
            bordered
            column={1}
            labelStyle={{ width: 150 }}
          >
            <Descriptions.Item label="物流单号">
              {data.data.deliveryNo}
            </Descriptions.Item>
            <Descriptions.Item label="物流公司">
              {data.data.logisticsCompany}
            </Descriptions.Item>
            <Descriptions.Item label="发货时间">
              {data.data.fdeliverydate}
            </Descriptions.Item>
            <Descriptions.Item label="发货说明">
              {data.data.deliveryInfo}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      )}
    </Spin>
  );
}
