import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadFile, UploadProps } from 'antd';
import { useEffect, useState } from 'react';
import { getUploadActionHeaders } from '../../../../../services/gpo/goodmarket.service';
import { getUploadActionUrl } from '../../../../../services/rjmart/product-mamagement.service';
import { PictureListItem } from '../../../../../services/rjmart/product-modal';

export function CreationPictureUpload(props: {
  value?: PictureListItem[];
  onChange?: (val: PictureListItem[]) => void;
}) {
  const headers = getUploadActionHeaders();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    emitChanges(newFileList);
  };
  const emitChanges = (fileList: UploadFile<{ url: string }>[]) => {
    console.log(fileList);
    if (!fileList.every((x) => x.status === 'done')) {
      return;
    }
    const val: PictureListItem[] = fileList
      .filter((x) => x.status !== 'error')
      .map((x, index) => {
        return {
          photo: x.response?.url || '',
        };
      });
    console.log('emit', val);
    props.onChange?.(val);
  };
  useEffect(() => {
    const list: UploadFile[] =
      props.value?.map((x, index) => {
        return {
          uid: x.photo,
          name: x.photo,
          response: { url: x.photo || '' },
          status: 'done',
          url: x.photo,
        };
      }) || [];
    setFileList(list);
  }, [props.value]);

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  return (
    <>
      <Upload
        accept="image/*"
        name="file"
        action={getUploadActionUrl}
        headers={headers}
        listType="picture-card"
        multiple={true}
        fileList={fileList}
        onChange={handleChange}
        onPreview={handlePreview}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>点击上传</div>
        </div>
      </Upload>
      <Modal
        open={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        bodyStyle={{ padding: 0 }}
      >
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}
