import { useSize } from 'ahooks';
import { Breadcrumb } from 'antd';
import React, { ReactNode, useRef } from 'react';

export function BlankWrapper(props: {
  paddingSize?: number;
  children: React.ReactNode;
  breadcrumb?: { name: string; icon?: ReactNode; href?: string }[];
}) {
  const containerRef = useRef<any>();
  const breadcrumbRef = useRef<any>();
  const containerSize = useSize(containerRef);
  const breadcrumSize = useSize(breadcrumbRef);
  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
        padding: props.paddingSize === undefined ? 12 : props.paddingSize,
      }}
    >
      {props.breadcrumb?.length ? (
        <section
          key={'breadcrumb'}
          style={{ paddingBottom: 10 }}
          ref={breadcrumbRef}
        >
          <Breadcrumb>
            {props.breadcrumb.map((x) => (
              <Breadcrumb.Item key={x.name} href={x.href}>
                {x.icon}
                {x.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </section>
      ) : null}
      <section
        style={{
          height:
            (containerSize?.height || 0) - (breadcrumSize?.height || 0) - 24,
        }}
        key={'content'}
      >
        {props.children}
      </section>
    </div>
  );
}
