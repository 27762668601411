import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  changeJiCaiOnSalProductFrozenApi,
  changeJiCaiOnSalProductStatusApi,
  GpoJiCaiOnSaleProductDto,
  GpoJiCaiOnSaleProductStatus,
} from '../../../../services/gpo/jicai.service';

const { Text, Paragraph } = Typography;
export function ChangeFrozenButton(props: {
  data: GpoJiCaiOnSaleProductDto;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={changeJiCaiOnSalProductFrozenApi}
      title={
        <Space direction="vertical">
          <Text strong>{`确定${
            props.data.frozen ? '解除冻结' : '冻结'
          }商品吗？`}</Text>
          <Paragraph type={'secondary'} style={{ maxWidth: 300 }}>
            <div>正在操作的商品：</div>
            {props.data.name}
            <div>
              <Text type="danger">提示：冻结商品将不会执行自动化任务</Text>
            </div>
          </Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run(data);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="text"
          shape="round"
          size="small"
          disabled={loading}
          loading={loading}
        >
          {props.data.frozen && (
            <Text type="warning">
              <StarFilled />
            </Text>
          )}
          {!props.data.frozen && <StarOutlined />}
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
