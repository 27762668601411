import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAllRJMCategoriesApi } from '../../services/rjmart/category.service';

export function RJMCategoryName(props: { id: number }) {
  const params = useParams();
  const { data, run } = useRequest(getAllRJMCategoriesApi, {
    debounceWait: 100,
    manual: true,
  });
  useEffect(() => {
    run({ projectId: params.projectId || '' });
  }, []);
  const item = data?.find((x) => x.platformId === props.id);
  return <>{item?.platformName}</>;
}
