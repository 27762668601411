import { ReactNode, useContext, useEffect, useState } from 'react';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';
import { usePermission } from './use-permission';

export function Permission(props: {
  children: ReactNode;
  error?: ReactNode;
  resource: string;
  action: string;
}) {
  const hasPermission = usePermission(props.resource, props.action);
  return hasPermission ? (
    <>{props.children}</>
  ) : props.error ? (
    <>{props.error}</>
  ) : null;
}
