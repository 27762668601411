import { useTitle } from 'ahooks';
import { NavLink, useParams } from 'react-router-dom';
import { ProductName } from '../../../../gpo/components/ProductName';
import { Button, Input, Select, Space, Form, Typography } from 'antd';
import {
  MartProductSaleStatus,
  MartSimpleProductDto,
} from '../../../../services/cas/product-modal';
import {
  BarsOutlined,
  SyncOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useRef } from 'react';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { InternalActionButton } from '../../../../components/InternalActionButton';
import { BatchListActionButton } from '../../../../components/batch-list-action-button';

import {
  CasKeywordTypes,
  CasProductStatus,
  publishCasProductApi,
  queryCasMartProductsApi,
  removeCasProductApi,
  unPublishCasProductApi,
} from '../../../../services/cas/product.service';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { CasCategoryCascader } from '../../../components/CategoryCascader';
import { CasBrandSelect } from '../../../components/CasBrandSelect';
import { CasGroupCascader } from '../../../components/GroupCascader';
import { EditButton } from './edit-button';
import { EditPriceButton } from './edit-price-button';

export function ProductListPage() {
  const params = useParams();
  useTitle('商品列表', { restoreOnUnmount: true });
  const tableRef = useRef<any>(null);

  const columns: ColumnsType<MartSimpleProductDto> = [
    {
      title: '#',
      key: 'index',
      width: 40,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Typography.Text type={'secondary'}>
            {(index + 1).toString().padStart(3, '0')}
          </Typography.Text>
        );
      },
    },
    {
      title: '货号',
      dataIndex: 'code',
      width: 140,
      fixed: 'left',
    },
    {
      title: '名称',
      dataIndex: 'id',
      width: 400,
      fixed: 'left',
      render(value, record, index) {
        return (
          <ProductName
            picturePath={record.img}
            name={record.name}
            onClick={() => {
              window.open(
                `https://www.casmart.com.cn/product-details/page/${record.supplierId}/${record.id}`
              );
            }}
            extends={
              <Space direction="vertical" style={{ display: 'block' }}>
                <Typography.Text
                  type={record.code.includes('未知') ? 'danger' : 'secondary'}
                >
                  <small>货号：{record.code}</small>
                </Typography.Text>
                <Typography.Text
                  type={record.spec.includes('未知') ? 'danger' : 'secondary'}
                >
                  <small>规格：{record.spec}</small>
                </Typography.Text>
              </Space>
            }
          />
        );
      },
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      width: 120,
    },
    {
      title: '商城价/本站价',
      dataIndex: 'price',
      width: 100,
      render: (value, record) => {
        return (
          <Space>
            {ConvertNumberToCurrency(value)}
            <EditPriceButton data={record} />
          </Space>
        );
      },
    },
    {
      title: '市场价',
      width: 100,
      dataIndex: 'mktPrice',
      render: (value, record) => {
        return ConvertNumberToCurrency(value);
      },
    },

    {
      title: '库存',
      width: 100,
      dataIndex: 'stockAmount',
    },
    {
      title: '分类',
      width: 100,
      dataIndex: 'cateName',
    },
    {
      title: '单位',
      width: 60,
      dataIndex: 'unit',
    },
    {
      title: '状态',
      width: 60,
      dataIndex: 'insaleStatusInfo',
    },
    {
      title: '审核',
      width: 60,
      dataIndex: 'auditStatusInfo',
    },
    {
      title: '操作日期',
      dataIndex: 'addTime',
      width: 140,
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: 50,
      render(value, record, index) {
        return (
          <Space>
            <EditButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: 'CAS', icon: <BarsOutlined /> },
        { name: '商品管理' },
        { name: '商品列表' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="商品列表"
        description=""
        rowKey="id"
        selectable={true}
        api={queryCasMartProductsApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="projectId"
                  initialValue={params.projectId}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item name={'cateId'} label={'品目'} initialValue={''}>
                  <CasCategoryCascader
                    allowClear
                    single
                    queryParams={{
                      projectId: params.projectId || '',
                    }}
                    style={{ width: 140 }}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item name={'stationId'} label={'分组'} initialValue={''}>
                  <CasGroupCascader
                    allowClear
                    single
                    queryParams={{
                      projectId: params.projectId || '',
                    }}
                    style={{ width: 140 }}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item name="brandId" label={'品牌'}>
                  <CasBrandSelect
                    allowClear
                    style={{ width: 120 }}
                    queryParams={{
                      projectId: params.projectId || '',
                    }}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item name={'tabIndex'} label={'状态'} initialValue={''}>
                  <Select
                    style={{ width: 100 }}
                    onChange={submit}
                    options={CasProductStatus}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name={'searchType'}
                  label={'关键词'}
                  initialValue={''}
                >
                  <Select
                    style={{ width: 100 }}
                    options={CasKeywordTypes}
                  ></Select>
                </Form.Item>
                <Form.Item name={'search'} label={''}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入关键词"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              <NavLink to={'../create-product'}>
                <Button type="default">
                  <PlusOutlined /> 添加
                </Button>
              </NavLink>
              <InternalActionButton slot="casmart-product-listing">
                <ThunderboltOutlined /> 采集
              </InternalActionButton>
            </Space>
          );
        }}
        secondaryExtends={({ selectedRows: rows }) => {
          const selectedRows: MartSimpleProductDto[] = rows;
          return (
            <Space>
              <Button
                type="default"
                shape="round"
                htmlType={'button'}
                onClick={() => tableRef.current.selectAll()}
              >
                全选
              </Button>
              <BatchListActionButton
                data={selectedRows}
                actionName={'下架'}
                action={async (item) => {
                  return await unPublishCasProductApi([item.id]);
                }}
                enable={(d) =>
                  d.every((x) =>
                    [MartProductSaleStatus.Publish].includes(x.insaleStatus)
                  )
                }
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />

              <BatchListActionButton
                data={selectedRows}
                actionName={'上架'}
                action={async (item) => {
                  return await publishCasProductApi([item.id]);
                }}
                enable={(d) =>
                  d.every((x) =>
                    [MartProductSaleStatus.Unpublish].includes(x.insaleStatus)
                  )
                }
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />
              <BatchListActionButton
                data={selectedRows}
                actionName={'删除'}
                action={async (item) => {
                  return await removeCasProductApi([item.id]);
                }}
                enable={(d) =>
                  d.every((x) =>
                    [MartProductSaleStatus.Unpublish].includes(x.insaleStatus)
                  )
                }
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
