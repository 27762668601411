import { useTitle } from 'ahooks';
import { useParams } from 'react-router-dom';
import {
  MartOrderDeliveryStatus,
  MartOrderMoreSearchTypes,
  MartOrderQueryStatus,
  MartOrderSettlementStatus,
  MartOrderStatus,
  MartSimpleOrderDto,
} from '../../../../services/cas/order-modal';
import {
  MartOrderQueryStatusOptions,
  queryCasOrdersApi,
} from '../../../../services/cas/order.service';
import {
  BarsOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Space,
  Input,
  Select,
  Button,
  Form,
  Drawer,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { OrderProductLink } from './order-product-link';
import { CasBrandSelect } from '../../../components/CasBrandSelect';
import { OrderDetailView } from './order-detail';
import { CmtWaterMark } from '../../../../components/CmtWaterMark';
import { ConfirmOrderAction } from './confirm-order-button';
import { DeliverProductButton } from './deliver-product-button';
import { PrintOrderArchiveButton } from './PrintOrderArchiveButton';
import { PrintOrderDeliveryButton } from './PrintOrderDeliveryButton';

export function OrderListPage() {
  const params = useParams();
  useTitle('订单管理', { restoreOnUnmount: true });
  const tableRef = useRef<any>(null);
  const [selectedOrder, setSelectedOrder] = useState<MartSimpleOrderDto | null>(
    null
  );
  const columns: ColumnsType<MartSimpleOrderDto> = [
    {
      title: '订单编号',
      dataIndex: 'sn',
      width: 200,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedOrder(record);
            }}
          >
            {value}
          </Button>
        );
      },
    },
    {
      title: '订单商品',
      dataIndex: 'orderNo',
      width: 400,
      fixed: 'left',
      render: (value, record, index) => {
        return (
          <Space size={'small'} direction="vertical" style={{ width: '100%' }}>
            {record.ordersGoodsList.map((x) => (
              <OrderProductLink key={x.id} vo={x} />
            ))}
          </Space>
        );
      },
    },
    {
      title: '采购单位',
      dataIndex: 'institutesName',
      render(value, record, index) {
        return (
          <Space direction={'vertical'}>
            <span>{record.institutesName}</span>
            <small>课题组：{record.taskGroupName}</small>
            <small>收件人：{record.addressName}</small>
          </Space>
        );
      },
    },
    {
      title: '总价(元)',
      dataIndex: 'totalAllPrice',
      render(value, record, index) {
        return (
          <Space direction={'vertical'}>
            <span>{ConvertNumberToCurrency(value)}</span>
            <small>
              {record.payWayName}
              {record.payWayName === '统一结算' ? (
                <Tooltip title="此订单为统一结算订单，请按结算单金额开票，拒收随货邮寄发票">
                  <Typography.Link>
                    <InfoCircleOutlined />
                  </Typography.Link>
                </Tooltip>
              ) : (
                ''
              )}
            </small>
          </Space>
        );
      },
    },
    // {
    //   title: '销售',
    //   dataIndex: 'contactName',
    // },
    {
      title: '状态',
      dataIndex: 'statusInfo',
    },
    {
      title: '下单时间',
      dataIndex: 'addTime',
      width: 150,
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: 120,
      render(value, record, index) {
        return (
          <Space direction="vertical">
            <ConfirmOrderAction
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            />
            <DeliverProductButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: 'CAS', icon: <BarsOutlined /> },
        { name: '交易管理' },
        { name: '订单管理' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="订单管理"
        description=""
        rowKey="id"
        api={queryCasOrdersApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="projectId"
                  initialValue={params.projectId}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item name={'tabIndex'} label={'页签'} initialValue={''}>
                  <Select
                    allowClear
                    style={{ width: 120 }}
                    onChange={submit}
                    options={MartOrderQueryStatusOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'status'} label={'状态'} initialValue={''}>
                  <Select
                    allowClear
                    style={{ width: 120 }}
                    onChange={submit}
                    options={MartOrderStatus}
                  ></Select>
                </Form.Item>
                <Form.Item name="brandId" label={'品牌'}>
                  <CasBrandSelect
                    allowClear
                    style={{ width: 120 }}
                    queryParams={{
                      projectId: params.projectId || '',
                    }}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item
                  name={'deliveryStatus'}
                  label={'验货状态'}
                  initialValue={''}
                >
                  <Select
                    allowClear
                    style={{ width: 120 }}
                    onChange={submit}
                    options={MartOrderDeliveryStatus}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name={'whetherSettlement'}
                  label={'结算状态'}
                  initialValue={''}
                >
                  <Select
                    allowClear
                    style={{ width: 120 }}
                    onChange={submit}
                    options={MartOrderSettlementStatus}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name={'searchType'}
                  label={'关键词'}
                  initialValue={''}
                >
                  <Select
                    style={{ width: 100 }}
                    onChange={submit}
                    options={MartOrderMoreSearchTypes}
                  ></Select>
                </Form.Item>
                <Form.Item name={'search'} label={''}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入关键词"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
      <Drawer
        width={800}
        open={!!selectedOrder}
        title={`${selectedOrder?.sn}订单详情`}
        onClose={() => setSelectedOrder(null)}
        destroyOnClose={true}
        extra={
          selectedOrder && (
            <Space>
              <PrintOrderArchiveButton
                data={selectedOrder}
                projectId={params.projectId || ''}
              />{' '}
              <PrintOrderDeliveryButton
                data={selectedOrder}
                projectId={params.projectId || ''}
              />
            </Space>
          )
        }
      >
        <CmtWaterMark>
          <OrderDetailView
            orderNo={selectedOrder?.sn}
            projectId={params.projectId || ''}
          />
        </CmtWaterMark>
      </Drawer>
    </BlankWrapper>
  );
}
