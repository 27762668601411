import { Button, message, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  changeQuotedStateApi,
  GPOStandardGoodDto,
} from '../../../../services/gpo/standard-goods.service';

const { Text, Paragraph } = Typography;
export function BatchAddStandardGoodsButton(props: {
  data: GPOStandardGoodDto[];
  supplierAgreementGuid: string;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={changeQuotedStateApi}
      successMessage="批量添加商品成功"
      placement={'top'}
      title={
        <Space direction="vertical">
          <Text strong>{`确定添加${props.data.length}个商品吗？`}</Text>
          <Paragraph type={'secondary'} style={{ maxWidth: 300 }}>
            <div>
              <Text type="danger">提示：该操作立刻同步到平台。</Text>
            </div>
          </Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        const ids = data.map((x) => x.goodsPriceGuid).join(',');
        run({
          goodsPriceGuid: ids,
          supplierAgreementGuid: props.supplierAgreementGuid,
        });
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="default"
          shape="round"
          disabled={loading}
          loading={loading}
        >
          批量添加 ({props.data.length})
        </Button>
      )}
    </CmtPopConfirmAction>
  );
  return null;
}
