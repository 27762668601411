import {
  DesktopOutlined,
  FieldTimeOutlined,
  FileOutlined,
  HddOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { NavLink, useRoutes } from 'react-router-dom';
import { SubLayout } from '../../layouts/sub-layout/sub-layout';

export function AdminSettingsPage() {
  const projectNavItems: ItemType[] = [
    // {
    //   label: <NavLink to="ecs">执行器</NavLink>,
    //   icon: <HddOutlined />,
    //   key: 'ecs',
    // },
    {
      label: <NavLink to="settings">组织设置</NavLink>,
      icon: <HomeOutlined />,
      key: 'settings',
    },
    {
      label: <NavLink to="members">成员管理</NavLink>,
      icon: <UserOutlined />,
      key: 'members',
    },
    // {
    //   label: <NavLink to="crons">Crons</NavLink>,
    //   icon: <FieldTimeOutlined />,
    //   key: 'crons',
    // },
    {
      label: <NavLink to="logs">操作日志</NavLink>,
      icon: <FileOutlined />,
      key: 'logs',
    },
    {
      label: <NavLink to="roles">关于</NavLink>,
      icon: <InfoCircleOutlined />,
      key: 'roles',
    },
  ];
  return <SubLayout menuItems={projectNavItems}></SubLayout>;
}
