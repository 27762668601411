import { Select, Spin, message } from 'antd';
import { RJDeliveryTimes } from '../../../../../services/rjmart/product-modal';
import { CategoryId } from '../../../../../common/dtos/base-category';
import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { queryUnitAndSpecification } from '../../../../../services/rjmart/product-mamagement.service';

export function RJMUnitSelect(props: {
  value?: number;
  style?: React.CSSProperties;
  queryParams: { projectId: string };
  onChange?: (val: number) => void;
}) {
  const key = 'RJMUnitSelect';
  const [messageApi, contextHolder] = message.useMessage();
  const {
    run: runQueryUnitAndSpecification,
    loading: loadingUnit,
    data: unitAndSpecification,
  } = useRequest(queryUnitAndSpecification, {
    manual: true,
    debounceWait: 300,
    onError(e, params) {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
        duration: 3,
      });
    },
    onFinally() {},
  });
  useEffect(() => {
    if (props.queryParams?.projectId) {
      runQueryUnitAndSpecification(props.queryParams);
    }
  }, [props.queryParams.projectId]);
  return (
    <Spin spinning={loadingUnit}>
      {contextHolder}
      <Select
        value={props.value}
        onChange={props.onChange}
        options={unitAndSpecification?.unitList.map((x) => {
          return { label: x, value: x };
        })}
      ></Select>
    </Spin>
  );
}
