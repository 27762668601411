import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Button,
  message,
  Spin,
  Tooltip,
  Form,
  InputNumber,
  Typography,
  Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import { useParams } from 'react-router-dom';
import {
  MartProductPayload,
  MartProductSaleStatus,
  MartSimpleProductDto,
} from '../../../../services/cas/product-modal';
import { saveCasProductPrice } from '../../../../services/cas/product.service';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';

function ChangeProductPriceForm(props: {
  data: MartSimpleProductDto;
  projectId: string;
  onSuccess?: (res: boolean) => void;
}) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { data } = props;
  const key = 'updatable';
  const { run: runSave, loading } = useRequest(saveCasProductPrice, {
    manual: true,
    debounceWait: 300,
    onBefore() {
      messageApi.open({
        key,
        type: 'loading',
        content: '正在保存，请稍后...',
        duration: 0,
      });
    },
    onSuccess(res) {
      messageApi.open({
        key,
        type: 'success',
        content: '保存成功',
        duration: 5,
      });
      props.onSuccess?.(true);
    },
    onError(e, params) {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
        duration: 5,
      });
    },
    onFinally() {},
  });

  useEffect(() => {
    form.setFieldsValue({
      id: data.id,
      price: data.price,
      tempPrice: data.price,
    });
  }, [data.id]);
  const onFinish = (value: any) => {
    value.tempPrice = value.tempPrice.toString();
    runSave(value);
  };

  return (
    <Spin spinning={loading}>
      {contextHolder}
      <Form
        form={form}
        labelAlign={'left'}
        layout="vertical"
        onFinish={onFinish}
      >
        <Typography.Paragraph type="danger">
          注：更新商品价格，第二天生效
        </Typography.Paragraph>
        <Form.Item label="市场价格">
          <Typography>{ConvertNumberToCurrency(data.mktPrice)}</Typography>
        </Form.Item>
        <Form.Item label="原价格">
          <Typography>{ConvertNumberToCurrency(data.price)}</Typography>
        </Form.Item>
        <Form.Item name={'id'} hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item name={'price'} hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item label="销售价格" name={'tempPrice'} hasFeedback={true}>
          <InputNumber min={0} max={data.mktPrice} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button onClick={() => props.onSuccess?.(false)}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export function EditPriceButton(props: {
  data: MartSimpleProductDto;
  onSuccess?: () => void;
}) {
  const params = useParams();
  return (
    <CmtModalButton
      title={`【${props.data.code}】${props.data.name}`}
      width={'500px'}
      trigger={({ show }) => {
        return (
          <Tooltip title={'修改销售价格'}>
            <Button type="text" size="small" onClick={show}>
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeProductPriceForm
            data={props.data}
            projectId={params.projectId || ''}
            onSuccess={(res) => {
              hide();
              res && props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
