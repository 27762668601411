import { ArrowLeftOutlined } from '@ant-design/icons';
import { useRequest, useSessionStorageState } from 'ahooks';
import { Typography, Spin, Space, Button, Image } from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GlobalContext } from '../../../context';
import { getSingleProjectApi } from '../../../services/project.service';
import styles from './index.module.less';

const { Text, Title } = Typography;
export function ProjectInfo() {
  const param = useParams();
  const navigate = useNavigate();
  const [lastProjectId, setLastProjectId] = useSessionStorageState<string>(
    'cmt-selected-pid',
    { defaultValue: '' }
  );
  const [globalContext, setGlobalContext] = useContext(GlobalContext);

  const { data, run, loading } = useRequest(getSingleProjectApi, {
    manual: true,
    debounceWait: 200,
    onSuccess: (res) => {
      setGlobalContext({ ...globalContext, currentProject: res });
    },
  });
  useEffect(() => {
    if (param.projectId) {
      run(param.projectId);
    }
  }, []);
  return (
    <Spin spinning={loading}>
      <Text className={styles['prefix']}>
        <Space>
          <Button
            type="text"
            size="small"
            style={{ marginLeft: -12 }}
            onClick={() => {
              setLastProjectId('');
              navigate(`/${data?.platform}/projects`);
            }}
          >
            <ArrowLeftOutlined />
          </Button>
          {loading && <span>加载中...</span>}
          {!loading && data && (
            <Space>
              <Image preview={false} height={36} src={data.logoUrl} />
              {/* {data.name} */}
            </Space>
          )}
        </Space>
      </Text>
    </Spin>
  );
}
