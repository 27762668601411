import { BarsOutlined, SyncOutlined, PictureOutlined } from '@ant-design/icons';
import { useRequest, useTitle } from 'ahooks';
import {
  Avatar,
  Button,
  Cascader,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtAvator } from '../../../../components/CmtAvatar';
import { CmtTable } from '../../../../components/CmtTable';
import {
  getGoodQuotedStatesName,
  GoodQuotedStates,
  GoodQuotedStatesOptions,
  GPOStandardGoodDto,
  queryStandardGoodsApi,
} from '../../../../services/gpo/standard-goods.service';
import { GpoJiCaiDynamicAgreementCategorySelect } from '../../../components/JiCaiAgreementCategorySelect';
import { GpoJiCaiAgreementSelect } from '../../../components/JiCaiAgreementSelect';
import { BatchAddStandardGoodsButton } from './batch-add-button';
import { GoodsBrandSelect } from '../../../components/GoodsBrandSelect';
import { GPOAgreementTypes } from '../../../../common/dtos/AddGoodTypes';
const { SHOW_CHILD } = Cascader;

const { Text } = Typography;

export function StandardGoodsPage() {
  const params = useParams();
  const tableRef = useRef<any>(null);
  useTitle('标准商品库');
  const statusOptions = [
    { value: '', label: '全部' },
    ...GoodQuotedStatesOptions,
  ];

  const columns: ColumnsType<GPOStandardGoodDto> = [
    {
      title: '商品编号',
      dataIndex: 'goodsCode',
      key: 'goodsCode',
      width: 200,
      fixed: 'left',
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Space>
            <CmtAvator url={record.picturePath} /> <Text>{value}</Text>
          </Space>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'quoted',
      key: 'quoted',
      width: 120,
      render: (value) => {
        return <Space>{getGoodQuotedStatesName(value.toString())}</Space>;
      },
    },
    {
      title: '品目',
      dataIndex: 'goodsClassName',
      key: 'goodsClassName',
      width: 180,
    },
    {
      title: '品牌',
      dataIndex: 'goodsBrandName',
      key: 'goodsBrandName',
      width: 180,
    },
    {
      title: '市场价',
      dataIndex: 'currentPrice',
      key: 'currentPrice',
      width: 120,
      render: (value) => {
        return ConvertNumberToCurrency(value);
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 150,
    },
    // {
    //   title: '操作',
    //   width: 100,
    //   key: 'actions',
    //   render: (value, record, index) => {
    //     return (
    //       <Space size={'small'}>
    //         <ChangeStandardGoodsStatusButton data={record} />
    //       </Space>
    //     );
    //   },
    // },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '集采商城' },
        { name: '标准商品库' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="标准商品库"
        description=""
        rowKey="goodsPriceGuid"
        api={queryStandardGoodsApi}
        manual={true}
        colums={columns}
        selectable={true}
        getCheckboxProps={(record: GPOStandardGoodDto) => {
          return {
            disabled: record.quoted.toString() === GoodQuotedStates.Published,
          };
        }}
        secondaryExtends={({ selectedRows, formRef }) => {
          return (
            <Space>
              <Button
                type="default"
                shape="round"
                htmlType={'button'}
                onClick={() => tableRef.current.selectByFilter((x: GPOStandardGoodDto) => x.quoted.toString() === GoodQuotedStates.Unpublished)}
              >
                全选未添加
              </Button>
              <BatchAddStandardGoodsButton
                data={selectedRows}
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
                supplierAgreementGuid={formRef.getFieldValue(
                  'supplierAgreementGuid'
                )}
              />
            </Space>
          );
        }}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name={'supplierAgreementGuid'}
                  label={'协议'}
                  required
                >
                  <GpoJiCaiAgreementSelect
                    style={{ width: 160 }}
                    onChange={submit}
                    queryParams={{ projectId: params.projectId || '' }}
                  ></GpoJiCaiAgreementSelect>
                </Form.Item>
                <Form.Item
                  name={'goodsClassGuid'}
                  label={'品目'}
                  required
                  dependencies={['supplierAgreementGuid']}
                >
                  <GpoJiCaiDynamicAgreementCategorySelect
                    style={{ width: 120 }}
                    queryParams={{
                      projectId: params.projectId || '',
                      agreementId: formRef.getFieldValue(
                        'supplierAgreementGuid'
                      ),
                    }}
                    showCheckedStrategy={SHOW_CHILD}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item
                  name={'goodsBrandGuid'}
                  label={'品牌'}
                  required
                  dependencies={['goodsClassGuid']}
                >
                  <GoodsBrandSelect
                    style={{ width: 120 }}
                    onChange={submit}
                    allowClear={true}
                    queryParams={{
                      agreementType: GPOAgreementTypes.JiCaiMall.toString(),
                      projectId: params.projectId || '',
                      goodsClassGuid: formRef.getFieldValue('goodsClassGuid'),
                    }}
                  ></GoodsBrandSelect>
                </Form.Item>
                <FormItem>
                  <Divider type="vertical" />
                </FormItem>
                <Form.Item name={'quoted'} label={'状态'} initialValue={''}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions}
                  ></Select>
                </Form.Item>
                {/* <Form.Item name={'goodsBrandGuid'} label={'品牌'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入品牌"
                    onChange={submit}
                    allowClear
                  ></Input> 
                </Form.Item>*/}
                <Form.Item name={'name'} label={'名称'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入商品名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
      {/* <Drawer
        width={'40%'}
        open={!!selectedOrder}
        title={`${selectedOrder?.contractCode}合同详情`}
        onClose={() => setSelectedOrder(null)}
        destroyOnClose={true}
        extra={
          <Space>
            <Button type="default" disabled>
              撤销
            </Button>
          </Space>
        }
      >
        <CmtWaterMark>
          <ContractView
            contractId={selectedOrder?.contractGuid || ''}
            projectId={params.projectId || ''}
          ></ContractView>
        </CmtWaterMark>
      </Drawer> */}
    </BlankWrapper>
  );
}
