import { Input, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { NamePath } from 'antd/es/form/interface';
import { useEffect, useState } from 'react';
import { GPOGoodClassParamDto } from '../../../../../common/dtos/AddGoodTypes';

export function CreationParamInput(props: {
  goodsClassParamGuid: string;
  namePath?: NamePath;
  defs: GPOGoodClassParamDto[];
}) {
  const [def, setDef] = useState<GPOGoodClassParamDto>();
  useEffect(() => {
    if (props.goodsClassParamGuid && props.defs.length) {
      const def1 = props.defs.find(
        (x) => x.goodsClassParamGuid === props.goodsClassParamGuid
      );
      setDef(def1);
    }
  }, [props.goodsClassParamGuid, props.defs]);
  return def ? (
    <FormItem
      label={def.paramName || '属性x'}
      name={props.namePath}
      rules={[
        { required: def.needInput === 1, message: `请输入${def.paramName}` },
      ]}
    >
      {def.paramOptionList ? (
        <Select
          options={def.paramOptionList.map((x) => {
            return {
              label: x.optionName,
              value: x.optionValue,
            };
          })}
        ></Select>
      ) : (
        <Input />
      )}
    </FormItem>
  ) : (
    <>没有找到参数定义</>
  );
}
