import {
  CloseOutlined,
  DisconnectOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Space, Tooltip, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  cancelReviewRJMProductApi,
  publishRJMProductApi,
  unPublishRJMProductApi,
} from '../../../../services/rjmart/product-mamagement.service';
import {
  RJProductStatus,
  RJSimpleProductDto,
} from '../../../../services/rjmart/product-modal';

const { Text, Paragraph } = Typography;
export function CancelReviewButton(props: {
  data: RJSimpleProductDto;
  onSuccess?: () => void;
}) {
  if (![RJProductStatus.ApprovalPending].includes(props.data.status)) {
    return <></>;
  }
  return (
    <CmtPopConfirmAction
      service={cancelReviewRJMProductApi}
      title={
        <Space direction="vertical">
          <Text strong>{`确定取消审核吗`}</Text>
          <Paragraph type={'secondary'} style={{ maxWidth: 300 }}>
            <div>正在操作的商品：</div>
            {props.data.name}
            <div>
              <Text type="danger">
                提示：该操作立刻同步到平台，请谨慎操作。
              </Text>
            </div>
          </Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run([data.id]);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Tooltip title={`取消审核`}>
          <Button type="text" size="small" disabled={loading} loading={loading}>
            <DisconnectOutlined />
          </Button>
        </Tooltip>
      )}
    </CmtPopConfirmAction>
  );
}
