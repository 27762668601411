import { useRequest } from 'ahooks';
import { ProductName } from '../../../../gpo/components/ProductName';
import { queryRJMartProductsApi } from '../../../../services/rjmart/product-mamagement.service';
import { useEffect, useState } from 'react';
import { RJSimpleProductDto } from '../../../../services/rjmart/product-modal';
import { Typography } from 'antd';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
const { Text } = Typography;

export function OrderProductLink(props: { vo: any }) {
  const [record, setRecord] = useState<RJSimpleProductDto>();
  const { loading, run } = useRequest(queryRJMartProductsApi, {
    debounceWait: 300,
    manual: true,
    onSuccess(data, params) {
      if (data.list?.length) {
        setRecord(data.list[0]);
      }
    },
  });
  useEffect(() => {
    run({
      pageSize: 1,
      current: 1,
      productName: props.vo.fgoodname,
      productNum: props.vo.fgoodcode,
    });
  }, [props.vo]);
  return (
    <ProductName
      picturePath={record?.photo || ''}
      name={record?.name || props?.vo?.fgoodname}
      onClick={() => {
        window.open(
          `https://www.rjmart.cn/#/productDetail?productId=${record?.id}`
        );
      }}
      extends={
        <Text code>
          {ConvertNumberToCurrency(props.vo.fprice)}x{props.vo.fquantity}
        </Text>
      }
    />
  );
}
