import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ActionModal } from '../../components/ActionModal';
import { CmtModalButton } from '../../components/CmtModalButton';
import { Plan } from '../../services/plan.service';

export function PlanEditButton(props: { data: Plan; onSuccess?: () => void }) {
  return (
    <CmtModalButton
      title={`编辑计划`}
      width={600}
      trigger={({ show }) => {
        return (
          <Tooltip title="编辑计划">
            <Button type="text" size="small" onClick={show}>
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ show, hide }) => {
        return (
          <ActionModal
            onOk={(done) => {
              hide();
              if (done) {
                props.onSuccess?.();
              }
            }}
            value={props.data}
            okButtonText="保存"
          ></ActionModal>
        );
      }}
    </CmtModalButton>
  );
}
