import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  Descriptions,
  Popover,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { useEffect } from 'react';
import { ContractView } from '../../../gpo/components/ContractView';
import { OrderShippingInfoView } from '../../../gpo/pages/sub-pages/order/order-shipping-info-view';
import {
  GPOOrderDto,
  queryOrderDetailWithoutMetaApi,
  queryOrderShippingInfoApi,
} from '../../../services/gpo/order.service';

export function ContractInfoView(props: {
  order: GPOOrderDto;
  projectId: string;
}) {
  const { data, loading, run, refresh } = useRequest(
    queryOrderShippingInfoApi,
    {
      manual: true,
    }
  );
  useEffect(() => {
    if (props.order) {
      run({ orderGuid: props.order.orderGuid, projectId: props.projectId });
    }
  }, [props.order]);
  return (
    <Spin spinning={loading}>
      <div style={{ width: 400, minHeight: 240 }}>
        {!props.order.contractGuid && (
          <Result title="未起草合同" status={'warning'}></Result>
        )}
        {props.order.contractGuid && (
          <ContractView
            hideDetail={true}
            hideActions={true}
            hidePrice={true}
            contractId={props.order.contractGuid}
            projectId={props.projectId}
          />
        )}
      </div>
    </Spin>
  );
}

export function ContractInfoButton(props: {
  order: GPOOrderDto;
  projectId: string;
}) {
  return (
    <Popover
      title={''}
      zIndex={999}
      content={
        <ContractInfoView order={props.order} projectId={props.projectId} />
      }
    >
      <div style={{ cursor: 'pointer' }}>
        {props.order.contractGuid ? (
          <Typography.Text>查看合同</Typography.Text>
        ) : (
          <Typography.Text type="danger">未起草合同</Typography.Text>
        )}
      </div>
    </Popover>
  );
}
