import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';

export enum GPOContractAuditStatus {
  /**
   * 草稿
   */
  Draft = 0,
  /**
   * 已发送
   */
  Sent = 1,
  /**
   * 单位已确认, doSign = '0',
   */
  BuyerConfirmed = 20,
  /**
   * 待盖章, doSign = 1
   */
  SignPending = 21,
  /**
   * 供应商已盖章
   */
  SupplierSigned = 3,
  /**
   * 单位已盖章
   */
  BuyerSigned = 4,
  /**
   * 已取消
   */
  Cancelled = -1,
}

export const GPOContractStatusOptions = [
  { label: '草稿', value: GPOContractAuditStatus.Draft },
  { label: '已发送', value: GPOContractAuditStatus.Sent },
  { label: '单位已确认', value: GPOContractAuditStatus.BuyerConfirmed },
  { label: '待盖章', value: GPOContractAuditStatus.SignPending },
  { label: '供应商已盖章', value: GPOContractAuditStatus.SupplierSigned },
  { label: '单位已盖章', value: GPOContractAuditStatus.BuyerSigned },
  { label: '已取消', value: GPOContractAuditStatus.Cancelled },
];

export function getContractStatusName(
  status: GPOContractAuditStatus,
  doSign: GPOContractQueryBuyerDoSignTypes
) {
  const hasDoSign = GPOContractStatusOptions.find(
    (x) => x.value.toString() === `${status}${doSign}`
  )?.label;
  if (hasDoSign) {
    return hasDoSign;
  }
  return GPOContractStatusOptions.find((x) => x.value === status)?.label || '';
}

/**
 * 是否需要单位盖章， 单位确认阶段为0
 */
export enum GPOContractQueryBuyerDoSignTypes {
  Do = 1,
  NotDo = 0,
}

export interface GPOContractQueryDto {
  pageNumber: number;
  pageSize: number;
  sortName: string | '';
  sortOrder: string | '';
  auditStatus: GPOContractAuditStatus | '';
  /**
   * 合同编号
   */
  contractCode: string | '';
  /**
   * 采购单位
   */
  buyerOrgName: string | '';
  /**
   * 计划编号
   */
  buyPlanCode: string | '';
  pageNum: number;
  /**
   * 单位盖章状态
   */
  doSign?: GPOContractQueryBuyerDoSignTypes;
}

export enum GPOContractTypes {
  /**
   * 普通合同
   */
  Normal = 1,
}
/**
 * 合同对象定义
 */
export interface GPOContractDto {
  /**
   * 合同ID
   */
  contractGuid: string;
  /**
   * 合同类型
   */
  contractType: GPOContractTypes;
  /**
   * 合同编号
   */
  contractCode: string;
  /**
   * 合同名称
   */
  contractName: string;
  auditStatus: GPOContractAuditStatus;
  /**
   * 合同总额
   */
  totalMoney: number;
  feedBack: 0;
  startDate: string;
  endDate: string;
  signDate: string;
  signAddress: string;
  buyerOrgGuid: string;
  buyerOrgName: string;
  supplierGuid: string;
  supplierName: string;
  sureTime: string;
  createUserGuid: string;
  createUser: string;
  createDate: string;
  moDiUserGuid: string;
  moDiUser: string;
  moDiDate: string;
  remark: string | null;
  payDate: string | null;
  orderName: null;
  orderCode: string;
  orderGuid: string;
  platform: string | '1';
  pdfFilePath: string | null;
}

export interface GPOContractDetailDto extends GPOContractDto {
  /**
   * 合同详情
   */
  contractContentVO: {
    /**
     * 合同详情HTML
     */
    content: string;
    contractGuid: string;
  };
}

/**
 * 起草草稿的内容
 */
export interface GPODefaultContractFormDto extends GPOContractDto {
  /**
   * 起草合同的内容
   */
  contractContent: string;
  /**
   * contractContent 中需要填写的内容
   */
  contractExpressDTO?: {
    [key: string]: string;
  } | null;
}

export async function queryContractApi(
  params: PagingRequest
): Promise<PagingResponse<GPOContractDto>> {
  const res: any = await httpClient.get('/gpo/contracts', {
    params,
  });
  return res;
}

export async function queryContractDetailApi(params: {
  contractId: string;
  projectId: string;
}): Promise<GPOContractDetailDto> {
  const res: any = await httpClient.get(`/gpo/contracts/${params.contractId}`, {
    params: { projectId: params.projectId },
  });
  return res;
}

export async function getContractPdfBuffer(params: {
  file: string;
}): Promise<any> {
  const res: any = await httpClient.get(`/gpo/contracts/pdf`, {
    params: { file: params.file },
  });
  return res;
}

export async function queryDraftContractApi(params: {
  orderGuid: string;
}): Promise<GPODefaultContractFormDto> {
  const res: any = await httpClient.get(
    `/gpo/contracts/draft/${params.orderGuid}`
  );
  return res;
}

export async function saveDraftContractApi(
  payload: GPODefaultContractFormDto
): Promise<GPODefaultContractFormDto> {
  const res: any = await httpClient.post(`/gpo/contracts/draft`, payload);
  return res;
}

export async function executeContractActionApi(payload: {
  contractGuid: string;
  action: string;
}): Promise<GPODefaultContractFormDto> {
  const res: any = await httpClient.post(
    `/gpo/contracts/${payload.contractGuid}/actions/${payload.action}`
  );
  return res;
}
