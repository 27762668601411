import { InputProps } from 'antd';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import { DEFAULT_LOCALE_CN } from './locale';

export interface CmtCronInputProps {
  value?: string;
  onChange?: (val: string) => void;
}
export function CmtCronInput(props: CmtCronInputProps) {
  return (
    <Cron
      value={props.value || ''}
      locale={DEFAULT_LOCALE_CN}
      defaultPeriod={'week'}
      setValue={(v: string) => props.onChange?.(v)}
    />
  );
}
