import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';

export async function queryLogisticProvidersApi(
  params: PagingRequest
): Promise<PagingResponse<{ name: string; code: string }>> {
  const res: PagingResponse<{ name: string; code: string }> =
    await httpClient.get('/logistics/providers');
  if (params._searchKeyword) {
    res.list = res.list.filter((x) => x.name.includes(params._searchKeyword));
  }
  return res;
}

export async function queryLogisticDetailApi(params: {
  providerName: string;
  zipCode: string;
  receiverPhone: string;
  senderPhone: string;
}): Promise<{
  detail: {
    cityName: string;
    opMessage: string;
    opTime: string;
    opDesc?: string;
    addressText: string;
    opCode?: string;
  }[];
}> {
  const res: any = await httpClient.post('/logistics/details', params);
  return res;
}
