import { Tooltip, Modal, Button, ModalProps } from 'antd';
import React, { ReactNode, useState } from 'react';

export interface CmtModalButtonProps {
  title: ReactNode;
  width?: number | string;
  footer?: (actions: { show: () => void; hide: () => void }) => ReactNode;
  children: (actions: { show: () => void; hide: () => void }) => ReactNode;
  trigger: (actions: { show: () => void; hide: () => void }) => ReactNode;
  onVisibleChange?: (state: boolean) => void;
  maskClosable?: boolean;
  onClose?: () => void;
}

export function CmtModalButton(props: CmtModalButtonProps) {
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
    props.onVisibleChange?.(true);
  };
  const hide = () => {
    props.onClose?.();
    setOpen(false);
    props.onVisibleChange?.(false);
  };

  let width = props.width || 800;
  let bodyStyle: React.CSSProperties = {
    maxHeight: 'calc(90vh)',
    overflow: 'auto',
  };

  return (
    <>
      {props.trigger({ show, hide })}
      <Modal
        open={open}
        title={props.title}
        onCancel={hide}
        destroyOnClose={true}
        centered={true}
        maskClosable={props.maskClosable || false}
        width={width}
        footer={props.footer?.({ show, hide }) || null}
      >
        <div style={bodyStyle}>{props.children({ show, hide })}</div>
      </Modal>
    </>
  );
}
