import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  changeJiCaiOnSalProductStatusApi,
  GpoJiCaiOnSaleProductDto,
  GpoJiCaiOnSaleProductStatus,
} from '../../../../services/gpo/jicai.service';

const { Text, Paragraph } = Typography;
export function ChangeJiCaiStatusButton(props: {
  data: GpoJiCaiOnSaleProductDto;
  onSuccess?: () => void;
}) {
  const isPublish = props.data.valid === GpoJiCaiOnSaleProductStatus.Published;
  return (
    <CmtPopConfirmAction
      service={changeJiCaiOnSalProductStatusApi}
      title={
        <Space direction="vertical">
          <Text strong>{`确定${isPublish ? '下架' : '上架'}商品吗？`}</Text>
          <Paragraph type={'secondary'} style={{ maxWidth: 300 }}>
            <div>正在操作的商品：</div>
            {props.data.name}
            <div>
              <Text type="danger">
                提示：该操作立刻同步到平台，请谨慎操作。
              </Text>
            </div>
          </Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run(data);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Tooltip title={`${isPublish ? '下架' : '上架'}商品`}>
          <Button type="text" size="small" disabled={loading} loading={loading}>
            {isPublish ? <DownloadOutlined /> : <UploadOutlined />}
          </Button>
        </Tooltip>
      )}
    </CmtPopConfirmAction>
  );
}
