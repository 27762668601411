import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';

export interface DraftProductDto {
  id: string;
  projectId: string;

  organizationId: string;

  /**
   * 分类ID
   */
  goodsClassGuid: string;

  /**
   * 品牌ID
   */
  goodsBrandGuid: string;

  /**
   * 分类
   */
  goodsClassName: string;

  /**
   * 品牌ID
   */
  goodsBrandName: string;
  /**
   * 图片地址
   */
  picturePath: string;

  /**
   * 商品编号
   */
  goodsCode: string;

  /**
   * 市场价
   */
  marketPrice: string;

  /**
   * 保存对象字符串， 添加商品dto json 字符串
   */
  raw: string;
  /**
   * 名称
   */
  name: string;

  /**
   * 来源
   */
  source: string;

  /**
   * 加入原因
   */
  reason: string;

  createdAt: string;
}

export async function queryDraftProductsApi(
  params: PagingRequest
): Promise<PagingResponse<DraftProductDto>> {
  const res: any = await httpClient.get('/gpo/draft-products', { params });
  return res;
}

export async function batchDeleteDraftProductsApi(
  ids: string[]
): Promise<PagingResponse<DraftProductDto>> {
  const res: any = await httpClient.request({
    method: 'DELETE',
    url: '/gpo/draft-products',
    data: ids,
  });
  return res;
}
