import { FileAddOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Form, message, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { CmtModalButton } from '../../../components/CmtModalButton';
import {
  queryDraftContractApi,
  saveDraftContractApi,
} from '../../../services/gpo/contract.service';

export function DraftContactView(props: {
  orderGuid: string;
  callback?: (success: boolean) => void;
}) {
  const [messageApi, contextHolder] = message.useMessage();

  const { loading, run, data } = useRequest(queryDraftContractApi, {
    debounceWait: 200,
    manual: true,
    onError(e, params) {
      messageApi.error(e.message, 3);
    },
  });
  const { loading: saving, run: runSave } = useRequest(saveDraftContractApi, {
    debounceWait: 200,
    manual: true,
    onSuccess() {
      props.callback?.(true);
    },
    onError(e, params) {
      messageApi.error(e.message, 3);
    },
  });
  useEffect(() => {
    if (props.orderGuid) {
      run({ orderGuid: props.orderGuid });
    }
  }, [props.orderGuid]);
  return (
    <Spin spinning={loading || saving}>
      {contextHolder}
      {data?.contractContent && (
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              try {
                const val: { [key: string]: string } = {};
                (e.target as HTMLFormElement)
                  .querySelectorAll('input')
                  .forEach((x) => {
                    if (!x.value) {
                      throw new Error('请检查表单');
                    }
                    const name = x
                      .getAttribute('name')
                      ?.toString()
                      .replace('contractExpress.', '');
                    if (name) {
                      val[name] = x.value;
                    }
                  });
                runSave({
                  ...data,
                  contractExpressDTO: val,
                  orderGuid: props.orderGuid,
                });
              } catch (error: any) {
                messageApi.error(error.message, 3);
              }
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data?.contractContent || '',
              }}
            ></div>
            <div style={{ paddingLeft: '2em' }}>
              <Space>
                <Button type="primary" htmlType="submit" size="large">
                  保存
                </Button>
                <Button
                  type="default"
                  htmlType="button"
                  size="large"
                  onClick={() => props.callback?.(false)}
                >
                  取消
                </Button>
              </Space>
            </div>
          </form>
        </div>
      )}
    </Spin>
  );
}

export function DraftContactButton(props: {
  orderGuid: string;
  callback?: (success: boolean) => void;
}) {
  return (
    <CmtModalButton
      width={1000}
      title="起草合同"
      trigger={({ show }) => {
        return (
          <Button type="default" onClick={show}>
            <FileAddOutlined /> 起草合同
          </Button>
        );
      }}
    >
      {({ hide }) => {
        return (
          <DraftContactView
            orderGuid={props.orderGuid}
            callback={(res) => {
              hide();
              props.callback?.(res);
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
