import { BarsOutlined, SyncOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import { Button, Drawer, Form, Input, Select, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { CmtWaterMark } from '../../../../components/CmtWaterMark';
import {
  getContractStatusName,
  GPOContractDto,
  GPOContractStatusOptions,
  GPOContractTypes,
  queryContractApi,
} from '../../../../services/gpo/contract.service';
import { ContractView } from '../../../components/ContractView';
import { PrintContractButton } from '../../../components/PrintContractButton';

export function ContractListPage() {
  const params = useParams();
  const [selectedOrder, setSelectedOrder] = useState<GPOContractDto | null>(
    null
  );
  useTitle('我的合同');
  const statusOptions = [
    { value: '', label: '全部' },
    ...GPOContractStatusOptions,
  ];
  const tableRef = useRef<any>(null);
  const columns: ColumnsType<GPOContractDto> = [
    {
      title: '合同编号',
      dataIndex: 'contractCode',
      key: 'contractCode',
      width: 140,
    },
    {
      title: '合同名称',
      dataIndex: 'contractName',
      key: 'contractName',
    },
    {
      title: '采购单位',
      dataIndex: 'buyerOrgName',
      key: 'buyerOrgName',
    },
    {
      title: '合同类型',
      dataIndex: 'contractType',
      key: 'contractType',
      width: 120,
      render: (val: GPOContractTypes) => {
        switch (val) {
          case GPOContractTypes.Normal:
            return '普通合同';
          default:
            return '未知类型';
        }
      },
    },
    {
      title: '合同状态',
      dataIndex: 'auditStatus',
      key: 'auditStatus',
      width: 120,
      render: (value, record) => {
        return getContractStatusName(value, (record as any).doSign);
      },
    },
    {
      title: '合同金额',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      width: 120,
      render: (value) => {
        return ConvertNumberToCurrency(value);
      },
    },

    {
      title: '更新',
      dataIndex: 'moDiDate',
      key: 'moDiDate',
      width: 150,
    },
    {
      title: '操作',
      width: 80,
      key: 'actions',
      render: (value, record, index) => {
        return (
          <Button type="link" onClick={() => setSelectedOrder(record)}>
            详情
          </Button>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '订单管理' },
        { name: '我的合同' },
      ]}
    >
      <CmtTable
        title="我的合同"
        ref={tableRef}
        description="我的合同数据直接读取自智慧云，可能会存在波动"
        rowKey="contractGuid"
        api={queryContractApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="projectId"
                  initialValue={params.projectId}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  name={'auditStatus'}
                  label={'状态'}
                  initialValue={''}
                >
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'contractCode'} label={'编号'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入合同编号"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'buyerOrgName'} label={'采购单位'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入采购单位"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
      <Drawer
        width={800}
        open={!!selectedOrder}
        title={`${selectedOrder?.contractCode}合同详情`}
        onClose={() => setSelectedOrder(null)}
        destroyOnClose={true}
        extra={
          <Space>
            {selectedOrder?.contractGuid && (
              <PrintContractButton
                contractGuid={selectedOrder.contractGuid || ''}
                projectId={params.projectId || ''}
              />
              // <CmtPrint
              //   trigger={({ show }) => (
              //     <Button type="primary" onClick={show}>
              //       打印
              //     </Button>
              //   )}
              // >
              //   {({ ref }) => (
              //     <PrintableContract
              //       ref={ref}
              //       contractId={selectedOrder?.contractGuid || ''}
              //       projectId={params.projectId || ''}
              //     />
              //   )}
              // </CmtPrint>
            )}
          </Space>
        }
      >
        <CmtWaterMark>
          <ContractView
            contractId={selectedOrder?.contractGuid || ''}
            projectId={params.projectId || ''}
            refresh={() => {
              setSelectedOrder(null);
              tableRef.current?.refresh();
            }}
          ></ContractView>
        </CmtWaterMark>
      </Drawer>
    </BlankWrapper>
  );
}
