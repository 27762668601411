import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { GPOGoodFormTemplateDto } from '../../../../common/dtos/AddGoodTypes';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  getModifitionGoodForm,
  GoodSellStatus,
  GpoGoodMarketProduct,
} from '../../../../services/gpo/goodmarket.service';
import { ProductForm } from './product-form';

function ChangeGoodMarketProductForm(props: {
  data: GpoGoodMarketProduct;
  onSuccess?: (res: boolean) => void;
}) {
  const { data } = props;
  const [goodForm, setGoodForm] = useState<GPOGoodFormTemplateDto>();

  const { loading, run } = useRequest(getModifitionGoodForm, {
    manual: true,
    debounceWait: 300,
    onSuccess: (res) => {
      setGoodForm(res);
    },
    onError(e) {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (data.sellStatus === GoodSellStatus.Unpublished) {
      run({ goodsGuid: data.goodsGuid });
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '20px 0', width: '100%', minHeight: 200 }}>
        {goodForm && (
          <ProductForm
            goodForm={goodForm}
            onCancel={() => props.onSuccess?.(false)}
            extendAction={() => (
              <Button onClick={() => props.onSuccess?.(false)}>关闭</Button>
            )}
          ></ProductForm>
        )}
      </div>
    </Spin>
  );
}

export function EditButton(props: {
  data: GpoGoodMarketProduct;
  onSuccess?: () => void;
}) {
  if (props.data.sellStatus === GoodSellStatus.Published) {
    return null;
  }
  return (
    <CmtModalButton
      title="修改商品"
      width={'95%'}
      trigger={({ show }) => {
        return (
          <Tooltip title={'修改商品'}>
            <Button type="text" size="small" onClick={show}>
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeGoodMarketProductForm
            data={props.data}
            onSuccess={(res) => {
              hide();
              res && props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
