import { Button } from 'antd';
import { PrintableOrderDelivery } from './PrintableOrderDelivery';
import { CmtPrint } from '../../../../../components/CmtPrint';
import { MartSimpleOrderDto } from '../../../../../services/cas/order-modal';

export function PrintOrderDeliveryButton(props: {
  data: MartSimpleOrderDto;
  projectId: string;
}) {
  return props?.data?.id ? (
    <CmtPrint
      width={'100%'}
      trigger={({ show }) => (
        <Button type="default" onClick={show}>
          发货单打印
        </Button>
      )}
    >
      {({ ref }) => (
        <PrintableOrderDelivery
          ref={ref}
          data={props?.data}
          projectId={props?.projectId || ''}
        />
      )}
    </CmtPrint>
  ) : (
    <></>
  );
}
