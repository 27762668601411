import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';

/**
 *  订单状态
 */
export enum GPOOrderStatus {
  /**
   * 已取消
   */
  Cancelled = -1,
  /**
   * 待确认
   */
  ConfirmPending = 1,
  /**
   * 已确认
   */
  Confirmed = 2,
  /**
   * 已发货
   */
  Shipped = 3,
  /**
   * 已确认收货
   */
  Delivered = 4,

  /**
   * 已退货
   */
  Returned = 99,
}

export const GpoOrderStatusOptions = [
  { label: '待确认', value: GPOOrderStatus.ConfirmPending },
  { label: '已确认', value: GPOOrderStatus.Confirmed },
  { label: '已发货', value: GPOOrderStatus.Shipped },
  { label: '已确认收货', value: GPOOrderStatus.Delivered },
  { label: '已退货', value: GPOOrderStatus.Returned },
  { label: '已取消', value: GPOOrderStatus.Cancelled },
];

export function getOrderStatusName(status: GPOOrderStatus) {
  return GpoOrderStatusOptions.find((x) => x.value === status)?.label || '';
}

export interface GPOOrderGoodDto {
  orderGuid: string;
  orderGoodsGuid: string;
  goodsClassName: string;
  goodsBrandName: string;
  goodsCode: string;
  code: string;
  goodsKeepGuid: string;
  goodsPriceGuid: string;
  /**
   *  购买数量
   */
  qty: number;
  finalPrice: number;
  agreementTotal: number;
  goodsName: string;
  picturePath: string;
  goodsGuid: string;
  returnQty: number | null;
  changeQty: number | null;
}
export interface GPOOrderDto {
  linkmobile: null;
  orderGuid: string;
  /**
   * 订单编号
   */
  orderCode: string;
  /**
   * 订单状态
   * */
  auditStatus: GPOOrderStatus;
  createDate: string;
  supplierName: string;
  regionfullName: string;
  orgName: string;
  /**
   * 合同ID
   */
  contractGuid: string;
  /**
   * 发票ID
   */
  invoiceGuid: string;
  /**
   * 商品列表
   */
  orderGoodsVOList: GPOOrderGoodDto[];
  regionGuid: string;
  regionCode: string;
  agreementTotal: number;
  goodsTotal: number;
  supplierGuid: string;
  buyerOrgGuid: string;
  buyerOrgCode: string;
  deliveryAddress: string;
  /**
   * 物流ID， 已确认阶段，如果添加了物流信息则不为空， 已发货阶段为空， 订单detail没有这个属性
   */
  logisticsGuid: string | null;
  [key: string]: any;
}

export interface GpoOrderMetaDto {
  id: string;
  orderGuid: string;
  /**
   * 订单编号
   */
  orderCode: string;

  projectId: string;

  organizationId: string;

  note1: string;

  note2: string;

  note3: string;

  note4: string;
  note5: string;
}
export interface GPOOrderDetailsDto extends GPOOrderDto {
  orderInvoice?: GPOOrderInvoiceDto;
  orderShippingInfo?: GPOOrderShipInfoDto[];
  orderProcessList?: GPOOrderProcessStep[];
}
/**
 * 订单处理步骤
 */
export interface GPOOrderProcessStep {
  createOrgName: string;
  createDate: string;
  createUser: string;
  createUserGuid: string;
  content: string;
}

export enum GPOOrderShippingTypes {
  /**
   * 供应商自行送货
   */
  SupplierOwn = 1,
  /**
   * 快递
   */
  Express = 2,
  /**
   * 其他
   */
  Other = 3,
}

export const GPOOrderShippingTypeList = [
  {
    name: '供应商自行送货',
    value: GPOOrderShippingTypes.SupplierOwn,
  },
  {
    name: '快递',
    value: GPOOrderShippingTypes.Express,
  },
  {
    name: '其他',
    value: GPOOrderShippingTypes.Other,
  },
];

/**
 * 物流信息
 */
export interface GPOOrderShipInfoDto {
  logisticsGuid: string;
  /**
   * 物流编号
   */
  logisticsCode: string;
  /**
   * 物流名称
   */
  logisticsName: string;
  logisticsType: number;
  remark: string;
}
/**
 * 发票类型
 */
export declare enum GPOInvoiceTypes {
  /**
   * 增值税专用发票
   */
  ValueAddedTaxSpecialInvoice = 1,
  /**
   * 增值税普通发票
   */
  ValueAddedTaxInvoice = 2,
}
/**
 * 发票信息
 */
export interface GPOOrderInvoiceDto {
  title: string;
  type: GPOInvoiceTypes;
  /**
   * 税号
   */
  code: string;
  registerMobile: string;
  /**
   * 发票地址
   */
  address: string;
  /**
   * 用户iD
   */
  userGuid: string;
  /**
   * 银行名称
   */
  bankName: string;
  /**
   * 银行账号
   */
  bankAccount: string;
  /**
   * 联系人
   */
  linkMan: string;
  /**
   * 联系人电话
   */
  linkMobile: string;
  /**
   * Email
   */
  email: string;
}
/**
 * 交易方式
 */
export interface GPOTransactionMethodDto {
  transactionMethod: string;
  transactionMethodName: string;
}
export async function queryOrderApi(
  params: PagingRequest
): Promise<PagingResponse<GPOOrderDto>> {
  const res: any = await httpClient.get('/gpo/orders', {
    params,
  });
  return res;
}

export async function queryOrderSummaryApi(
  params: PagingRequest
): Promise<PagingResponse<GPOOrderDto>> {
  const res: any = await httpClient.get('/gpo/orders/summary', {
    params,
  });
  return res;
}

export async function updateOrderMeta(params: any) {
  const res: any = await httpClient.post('/gpo/orders/summary', params);
  return res;
}

export async function queryOrderDetailApi(params: {
  projectId: string;
  orderGuid: string;
}): Promise<GPOOrderDetailsDto> {
  const res: any = await httpClient.get(`/gpo/orders/${params.orderGuid}`, {
    params: { projectId: params.projectId },
  });
  return res;
}

export async function queryOrderDetailWithoutMetaApi(params: {
  projectId: string;
  orderGuid: string;
}): Promise<GPOOrderDetailsDto> {
  const res: any = await httpClient.get(
    `/gpo/orders/${params.orderGuid}/detail`,
    {
      params: { projectId: params.projectId },
    }
  );
  return res;
}

export async function queryOrderShippingInfoApi(params: {
  projectId: string;
  orderGuid: string;
}): Promise<GPOOrderShipInfoDto[]> {
  const res: any = await httpClient.get(
    `/gpo/orders/${params.orderGuid}/shipping-infos`,
    {
      params: { projectId: params.projectId },
    }
  );
  return res;
}

export async function executeOrderActionApi(payload: {
  orderGuid: string;
  action: string;
}): Promise<unknown> {
  const res: any = await httpClient.post(
    `/gpo/orders/${payload.orderGuid}/actions/${payload.action}`
  );
  return res;
}

export async function saveShoppingInfo(
  projectId: string,
  payload: GPOOrderShipInfoDto
): Promise<unknown> {
  const res: any = await httpClient.post(
    `/gpo/orders/shipping-infos`,
    payload,
    { params: { projectId } }
  );
  return res;
}

export async function saveInvoicePath(
  orderGuid: string,
  invoicePath: string
): Promise<unknown> {
  const res: any = await httpClient.post(`/gpo/orders/${orderGuid}/invoice`, {
    invoicePath,
  });
  return res;
}

export async function removeInvoicePath(orderGuid: string): Promise<unknown> {
  const res: any = await httpClient.delete(`/gpo/orders/${orderGuid}/invoice`);
  return res;
}

export function extractPhoneFromLogisticRemark(remark: string): {
  receiverPhone: string;
  senderPhone: string;
} {
  const res: any = { receiverPhone: '', senderPhone: '' };
  if (remark.trim()) {
    const map = new Map<string, string>();
    map.set('发件手机号', 'senderPhone');
    map.set('收件手机号', 'receiverPhone');
    const matches = remark.trim().matchAll(/\[([发|收]件手机号):(\d+)\]/g);
    for (const match of Array.from(matches)) {
      const k: string = match[1];
      const v: string = match[2];
      if (k && v && map.has(k)) {
        const key = map.get(k) || '';
        res[key] = v;
      }
    }
  }
  return res;
}
