import { useSize, useTitle } from 'ahooks';
import { useRef } from 'react';
import { BlankWrapper } from '../../../../components/BlankWrapper';

export function Welcome() {
  useTitle('项目中心');
  const containerRef = useRef<any>();
  const containerSize = useSize(containerRef);
  return (
    <BlankWrapper>
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      ></div>
    </BlankWrapper>
  );
}
