import { BaseCategory } from '../../common/dtos/base-category';
import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import { ENV_CONFIG } from '../../env-config';
import httpClient from '../http-client';
import {
  MartBrand,
  MartCategory,
  MartCategoryType,
  MartCategoryUnit,
  MartUserCategory,
} from './common-modal';
import {
  MartBatchTask,
  MartProductKeywordType,
  MartProductPayload,
  MartProductStatus,
  MartSimpleProductDto,
} from './product-modal';

export const CasProductStatus = [
  { label: '全部', value: MartProductStatus.All },
  { label: '已上架', value: MartProductStatus.Publish },
  { label: '已下架', value: MartProductStatus.Unpublish },
  { label: '系统下架', value: MartProductStatus.SystemOff },
  { label: '未审核', value: MartProductStatus.PendingApproval },
  { label: '审核不通过', value: MartProductStatus.ApprovalFailed },
];

export const CasKeywordTypes = [
  { label: '商品名称', value: MartProductKeywordType.ProductName },
  { label: '商品货号', value: MartProductKeywordType.ProductCode },
  { label: '热销商品', value: MartProductKeywordType.ProductBestSeller },
  { label: '生产企业', value: MartProductKeywordType.ProductFactory },
];

export interface CasPictureListItem {
  path: string;
  type: 1;
}

export async function queryCasMartProductsApi(
  params: PagingRequest
): Promise<PagingResponse<MartSimpleProductDto>> {
  const res: any = await httpClient.get('/cas/products', { params });
  return res;
}

let cacheCategories: BaseCategory[] = [];
export async function queryCasCatetoriesApi(params: {
  projectId: string;
}): Promise<BaseCategory[]> {
  if (!cacheCategories.length) {
    const { data } = await httpClient.get('/cas/products/categories', {
      params,
    });
    cacheCategories = data.map((x: MartCategory) => {
      return {
        platformId: x.id,
        platformName: x.name,
        platformParentId: x.parentId,
      };
    });
  }
  return cacheCategories;
}

let cacheBrands: MartBrand[] = [];
export async function queryCasBrands(params: {
  projectId: string;
}): Promise<MartBrand[]> {
  if (!cacheBrands.length) {
    const { data } = await httpClient.get('/cas/products/brands', {
      params,
    });
    cacheBrands = data;
  }
  return cacheBrands;
}

let cacheGroups: BaseCategory[] = [];
export async function queryCasGroups(params: {
  projectId: string;
}): Promise<BaseCategory[]> {
  if (!cacheGroups.length) {
    const res: { data: MartUserCategory[] } = await httpClient.get(
      '/cas/products/groups',
      {
        params,
      }
    );

    const data = res.data;
    cacheGroups = data.map((x: MartUserCategory) => {
      const parent = data.find((j) => j.name === x.parentName);
      return {
        platformId: x.id,
        platformName: x.name,
        platformParentId: parent?.id || 0,
      };
    });
  }
  return cacheGroups;
}

export async function publishCasProductApi(id: number[]) {
  const res: any = await httpClient.post('/cas/products/onSale', id);
  return res;
}

export async function unPublishCasProductApi(id: number[]) {
  const res: any = await httpClient.post('/cas/products/offShelves', id);
  return res;
}

export async function removeCasProductApi(id: number[]) {
  const res: any = await httpClient.post('/cas/products/deleteProductList', id);
  return res;
}

export async function queryCasTypeAndExtendAttrByCatId(params: {
  projectId: string;
  categoryId: number;
}): Promise<MartCategoryType[]> {
  const res: any = await httpClient.get(
    '/cas/products/queryTypeAndExtendAttrByCatId',
    {
      params,
    }
  );
  return res.data;
}

export async function queryCasCategoryUnits(params: {
  projectId: string;
  categoryId: number;
}): Promise<MartCategoryUnit[]> {
  const res: { data: any[] } = await httpClient.get(
    '/cas/products/queryCategoryUnits',
    {
      params,
    }
  );
  return res.data.reverse();
}

export function getCasUploadActionUrl() {
  const url = `${ENV_CONFIG.BASE_URL}/cas/products/creation-good-picture-upload`;
  return url;
}

export async function saveCasProduct(
  payload: MartProductPayload
): Promise<any> {
  const items = await httpClient.post('/cas/products/save-good', payload);
  return items.data;
}

export async function saveCasProductPrice(
  payload: any
): Promise<any> {
  const items = await httpClient.post('/cas/products/save-good-price', payload);
  return items.data;
}

export async function getProductDetail(
  payload: MartSimpleProductDto
): Promise<MartProductPayload> {
  const items = await httpClient.post(
    `/cas/products/details/${payload.id}`,
    payload
  );
  return items.data;
}

export async function queryCasMartProductBatchTaskApi(
  params: PagingRequest
): Promise<PagingResponse<MartBatchTask>> {
  const res: any = await httpClient.get('/cas/products/batchTasks', { params });
  return res;
}
