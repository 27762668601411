import { useRequest } from 'ahooks';
import { Badge, Button, message, Space, Tooltip, Typography } from 'antd';
import { getServiceHealthy } from '../../services/ecs.service';
import { Project } from '../../services/project.service';

export function ServiceHealthyItem(props: { path: string; project: Project }) {
  const key = 'ServiceHealthy';
  const [messageApi, contextHolder] = message.useMessage();
  const { data, loading, run } = useRequest(
    async () => {
      return await getServiceHealthy(props.path, props.project.id);
    },
    {
      debounceWait: 300,
      pollingInterval: 60 * 1000,
      onSuccess: (res) => {
        if (!res) {
          // messageApi.error({
          //   key,
          //   content: '执行器异常，请检查',
          //   duration: 3,
          // });
        } else {
          messageApi.destroy(key);
        }
      },
    }
  );

  return (
    <div>
      {contextHolder}
      <Button onClick={run} type="ghost">
        {data ? (
          <Space>
            <Badge
              status={
                loading
                  ? 'processing'
                  : data.status !== 200
                  ? 'warning'
                  : 'success'
              }
            />
            <Typography.Text>
              {props.project?.name}{' '}
              <Typography.Text type="secondary">
                <small>
                  (v{data?.version}: {data?.status})
                </small>
              </Typography.Text>
            </Typography.Text>
          </Space>
        ) : (
          <Space>
            <Badge status={loading ? 'processing' : 'error'} />
            <Typography.Text>{props.project?.name}</Typography.Text>
          </Space>
        )}
      </Button>
    </div>
  );
}
