import { useRequest } from 'ahooks';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { InfiniteSelect } from '../InfiniteSelect';
import {
  getSingleProjectApi,
  Project,
  queryProjectApi,
} from '../../services/project.service';
import {
  Action,
  queryActionsApi,
  querySingleActionApi,
} from '../../services/task.service';
import { JsonSchemaInput } from '../JsonSchemaInput';
import { useEffect, useState } from 'react';
import {
  createPlanApi,
  Plan,
  PlanType,
  PlanTypeOptions,
  updatePlanApi,
} from '../../services/plan.service';
import { CmtCronInput } from '../CmtCronInput';
import { ConvertJsonSchemaPayloadToString } from '../../common/utils/json-schema-payload';

const { Text } = Typography;

export function ActionModal(props: {
  onOk: (done: boolean) => void;
  value?: Plan;
  okButtonText?: string;
  cancelButtonText?: string;
}) {
  const [form] = Form.useForm();
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);
  const [actions, setAction] = useState<Action[]>([]);
  const [selectedType, setSelectedType] = useState<PlanType | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isCreationMode, setIsCreationMode] = useState(!props.value?.id);
  const { loading, run: createPlan } = useRequest(createPlanApi, {
    manual: true,
    debounceWait: 400,
    onSuccess(data, params) {
      message.success('任务创建成功');
      props.onOk(true);
    },
    onError({ message: text }, params) {
      message.error('任务创建失败：' + text);
    },
  });

  const { run: runLoadActions } = useRequest(queryActionsApi, {
    debounceWait: 200,
    onSuccess(data, params) {
      setAction(data.list);
    },
  });

  const { loading: updating, run: updatePlan } = useRequest(updatePlanApi, {
    manual: true,
    debounceWait: 400,
    onSuccess(data, params) {
      message.success('任务保存成功');
      props.onOk(true);
    },
    onError({ message: text }, params) {
      message.error('任务保存失败：' + text);
    },
  });

  const initForm = async () => {
    const plan = props.value;
    if (!plan) {
      form.setFieldsValue({ type: PlanType.Immediate });
      return;
    }
    setSelectedType(plan.type);
    const res = await Promise.all([
      querySingleActionApi(plan.actionId),
      getSingleProjectApi(plan.projectId),
    ]);
    const payload = JSON.parse(plan.payload);
    form.setFieldsValue({ ...plan, payload });
    if (res.length == 2) {
      setSelectedAction(res[0]);
      setSelectedProject(res[1]);
    }
  };
  useEffect(() => {
    initForm();
  }, [props.value?.id]);

  return (
    <Spin spinning={loading || updating}>
      <Space style={{ width: '98%', margin: '0 auto' }} direction="vertical">
        <Form
          form={form}
          layout={'vertical'}
          onFinish={(v) => {
            v.payload = ConvertJsonSchemaPayloadToString(v.payload);
            console.log('v', v);
            if (props.value?.id) {
              delete v['skipImmediateFire'];
              updatePlan(props.value.id, v);
            } else {
              createPlan(v);
            }
          }}
        >
          <Form.Item name={'skipImmediateFire'} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="执行项目"
            required
            name={'projectId'}
            rules={[{ required: true, message: '请选择要执行任务的项目' }]}
          >
            <InfiniteSelect
              disabled={!isCreationMode}
              style={{ width: '100%' }}
              api={queryProjectApi}
              onChange={(id, opt: any) => {
                setSelectedProject(opt.raw);
                form.setFieldValue(['actionId'], null);
                setSelectedAction(null);
              }}
              placeholder="请选择执行任务的项目"
              option={(item: Project) => {
                return {
                  label:
                    item.name +
                    `${!item.hasAuthValue ? '（未设置身份Token）' : ''}`,
                  value: item.id,
                  raw: item,
                  disabled: !item.hasAuthValue,
                };
              }}
            />
          </Form.Item>
          <Form.Item
            label="执行内容"
            rules={[{ required: true, message: '请选择任务内容' }]}
            required
            name="actionId"
          >
            {/* <InfiniteSelect
              disabled={!isCreationMode}
              style={{ width: '100%' }}
              api={queryActionsApi}
              placeholder="请选择要执行的任务"
              onChange={(v, opt: any) => {
                const action: Action = opt.raw;
                setSelectedAction(action);
              }}
              option={(item: Action) => {
                const disabled = selectedProject?.platform !== item.platform;
                return {
                  label: (
                    <div>
                      <div>
                        <Text type={disabled ? 'secondary' : undefined}>
                          {item.name}
                        </Text>
                      </div>
                      <Text type={'secondary'}>{item.description}</Text>
                    </div>
                  ),
                  raw: item,
                  disabled: disabled,
                  value: item.id,
                };
              }}
            /> */}
            <Select
              disabled={!isCreationMode}
              onChange={(v, opt: any) => {
                const action: Action = opt.raw;
                setSelectedAction(action);
              }}
              options={actions
                .filter((x) => x.platform === selectedProject?.platform)
                .map((item: Action) => {
                  return {
                    label: (
                      <div>
                        <div>
                          <Text>{item.name}</Text>
                        </div>
                        <Text type={'secondary'}>{item.description}</Text>
                      </div>
                    ),
                    raw: item,
                    value: item.id,
                  };
                })}
            ></Select>
          </Form.Item>
          <Form.Item
            label="任务名称"
            required
            name={'name'}
            rules={[
              { required: true, message: '请输入任务名称' },
              { type: 'string', max: 50, message: '名称最大长度为50个字符' },
            ]}
          >
            <Input placeholder="请输入任务名称" />
          </Form.Item>
          <Form.Item
            label="任务类型"
            required
            name={'type'}
            rules={[{ required: true }]}
          >
            <Select options={PlanTypeOptions} onChange={setSelectedType} />
          </Form.Item>
          {selectedType === PlanType.Cron && (
            <Form.Item
              label="执行周期"
              required
              dependencies={['type']}
              name={'cron'}
              rules={[
                {
                  required: true,
                  message: '请设置执行周期',
                },
              ]}
            >
              <CmtCronInput />
            </Form.Item>
          )}
          {selectedAction?.payloadSchema && (
            <>
              <Divider>
                <Text type={'secondary'}>附加参数</Text>
              </Divider>
              <Form.Item name={'payload'} dependencies={['projectId']}>
                <JsonSchemaInput
                  projectId={selectedProject?.id || ''}
                  schema={selectedAction?.payloadSchema}
                  layoutResponsive={true}
                />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading || updating}
                loading={loading || updating}
                onClick={() => {
                  form.setFieldValue('skipImmediateFire', false);
                }}
              >
                {props.okButtonText || '创建'}
              </Button>
              {isCreationMode &&
                form.getFieldValue('type') === PlanType.Immediate && (
                  <Button
                    type="default"
                    htmlType="submit"
                    disabled={loading || updating}
                    loading={loading || updating}
                    onClick={() => {
                      form.setFieldValue('skipImmediateFire', true);
                    }}
                  >
                    {props.okButtonText || '创建'}（不执行）
                  </Button>
                )}
              <Divider type="vertical" />
              <Button type="text" onClick={() => props.onOk?.(false)}>
                {props.cancelButtonText || '取消'}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Space>
    </Spin>
  );
}
