import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';
import { RJResponse, RJSimpleProductDto } from './product-modal';
import { RJOrderDetailDto } from './order-modal';

export async function queryRJMartOrderApi(
  params: PagingRequest
): Promise<PagingResponse<RJSimpleProductDto>> {
  const res: any = await httpClient.get('/rjmart/orders', { params });
  return res;
}

export async function queryRJMartOrderDetailApi(
  orderNo: string
): Promise<RJResponse<RJOrderDetailDto>> {
  const res: any = await httpClient.get(`/rjmart/orders/${orderNo}`);
  return res;
}

export async function confirmRJMartOrderApi(
  payload: any
): Promise<PagingResponse<any>> {
  const res: any = await httpClient.post(
    '/rjmart/orders/actions/confirmOrder',
    payload
  );
  return res;
}

export async function confirmRJMDelivertOrder(
  payload: any
): Promise<PagingResponse<any>> {
  const res: any = await httpClient.post(
    '/rjmart/orders/actions/confirmDelivertOrder',
    payload
  );
  return res;
}
