import { Space, Typography, Button, message } from 'antd';
import context from 'antd/es/app/context';
import { act } from 'react-dom/test-utils';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  MartOrderStatusEnum,
  MartSimpleOrderDto,
} from '../../../../services/cas/order-modal';
import { confirmCasOrderApi } from '../../../../services/cas/order.service';

export function ConfirmOrderAction(props: {
  data: MartSimpleOrderDto;
  onSuccess: () => void;
}) {
  const [messageApi, contextHolder] = message.useMessage();
  return props.data.status === MartOrderStatusEnum.ConfirmPending ? (
    <CmtPopConfirmAction
      service={confirmCasOrderApi}
      successMessage={`确认${props.data.sn}成功`}
      placement={'top'}
      title={
        <Space direction="vertical">
          <Typography.Text
            strong
          >{`确认订单${props.data.sn}吗？`}</Typography.Text>
        </Space>
      }
      onOk={({ data, run }) => {
        run(props.data.id);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="link"
          size="small"
          disabled={loading || !props.data}
          loading={loading}
          style={{ marginRight: 8 }}
          htmlType={'button'}
        >
          {contextHolder}
          确认订单
        </Button>
      )}
    </CmtPopConfirmAction>
  ) : null;
}
