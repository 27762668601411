import { BarsOutlined, SyncOutlined, PictureOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Avatar,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { ProductName } from '../../../../gpo/components/ProductName';
import {
  RJQueryOrderStatusOptions,
  RJSimpleOrderDto,
} from '../../../../services/rjmart/order-modal';
import { queryRJMartOrderApi } from '../../../../services/rjmart/order.service';
import { RJMORderStatusName } from '../../../components/status-name';
import { ConfirmOrderButton } from './confirm-order-button';
import { DeliverProductButton } from './deliver-product-button';
import { CmtWaterMark } from '../../../../components/CmtWaterMark';
import { OrderDetailView } from './order-detail';
import { OrderProductLink } from './order-product-link';

const { Text } = Typography;

export function OrderListPage() {
  const [selectedOrder, setSelectedOrder] = useState<RJSimpleOrderDto | null>(
    null
  );
  const params = useParams();
  useTitle('订单管理', { restoreOnUnmount: true });
  const statusOptions = [
    { value: '', name: '全部' },
    ...RJQueryOrderStatusOptions,
  ];
  const tableRef = useRef<any>(null);

  const columns: ColumnsType<RJSimpleOrderDto> = [
    {
      title: '订单编号',
      dataIndex: 'orderNo',
      width: 200,
      render(value, record, index) {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedOrder(record);
            }}
          >
            {value}
          </Button>
        );
      },
    },
    {
      title: '订单商品',
      dataIndex: 'orderNo',
      render: (value, record, index) => {
        return (
          <Space size={'small'} direction="vertical" style={{ width: '100%' }}>
            {record.openOrderDetailVoList.map((x) => (
              <OrderProductLink key={x.fgoodcode} vo={x} />
            ))}
          </Space>
        );
      },
    },
    {
      title: '采购单位',
      dataIndex: 'buyerName',
      render(value, record, index) {
        return (
          <Space direction={'vertical'}>
            <span>{record.buyerName}</span>
            <small>{record.departmentName}</small>
          </Space>
        );
      },
    },
    {
      title: '总价(元)',
      dataIndex: 'totalPrice',
      render(value, record, index) {
        return (
          <Space direction={'vertical'} size={'small'}>
            <span>总额：{ConvertNumberToCurrency(record.totalPrice)}</span>
            <span>优惠：{ConvertNumberToCurrency(record.discountedPrice)}</span>
            <span>应付：{ConvertNumberToCurrency(record.paymentPrice)}</span>
            <span>运费：{ConvertNumberToCurrency(record.carryFee)}</span>
          </Space>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      render(value, record, index) {
        return <RJMORderStatusName data={value} />;
      },
    },
    {
      title: '下单时间',
      dataIndex: 'orderDate',
      key: 'orderDate',
      width: 150,
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: 120,
      render(value, record, index) {
        return (
          <Space direction="vertical">
            <ConfirmOrderButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            />
            <DeliverProductButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '锐竞', icon: <BarsOutlined /> },
        { name: '交易管理' },
        { name: '订单管理' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="订单管理"
        description=""
        rowKey="id"
        api={queryRJMartOrderApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="projectId"
                  initialValue={params.projectId}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  name={'orderStatus'}
                  label={'状态'}
                  initialValue={''}
                >
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions.map((x) => {
                      return { label: x.name, value: x.value };
                    })}
                  ></Select>
                </Form.Item>
                <Form.Item name={'orderNo'} label={'订单编号'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入订单号"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'buyuserName'} label={'采购人'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入采购人"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'goodName'} label={'商品名称'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入商品名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
      <Drawer
        width={800}
        open={!!selectedOrder}
        title={`${selectedOrder?.orderNo}订单详情`}
        onClose={() => setSelectedOrder(null)}
        destroyOnClose={true}
      >
        <CmtWaterMark>
          <OrderDetailView
            orderNo={selectedOrder?.orderNo}
            projectId={params.projectId || ''}
          />
        </CmtWaterMark>
      </Drawer>
    </BlankWrapper>
  );
}
