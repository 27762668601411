import { useRequest } from 'ahooks';
import Cascader, { DefaultOptionType } from 'antd/es/cascader';
import { useEffect, useState } from 'react';
import { CategoryId } from '../../../common/dtos/base-category';
import {
  CmtCascader,
  CmtCascaderOption,
} from '../../../components/CmtCascader';
import { buildCategoryTree } from '../../../services/gpo/category.service';
import {
  getAllRJMCategoriesApi,
  RJMCategory,
} from '../../../services/rjmart/category.service';

export function RJMMultipleCategoryCascader(props: {
  queryParams: { projectId: string };
  style?: React.CSSProperties;
  allowClear?: boolean;
  onChange?: (val: CategoryId[]) => void;
  value?: CategoryId[];
  showCheckedStrategy?: any;
}) {
  const [options, setOptions] = useState<CmtCascaderOption[]>([]);
  const [currentValue, setCurrentValue] = useState<CategoryId[][]>([]);
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const createOptionTree = (res: RJMCategory[]) => {
    const opts = buildCategoryTree(res);
    setOptions(opts);
    if (props.value) {
      const groups: CategoryId[][] = props.value.map((x) =>
        getValuePath(x, res)
      );
      if (groups.length) {
        setCurrentValue(groups);
      }
      // setCurrentValue(props.value);
    }
  };
  const { loading, run } = useRequest(getAllRJMCategoriesApi, {
    manual: true,
    debounceWait: 300,
    onSuccess: createOptionTree,
  });

  const getValuePath = (id: CategoryId, cats: RJMCategory[]) => {
    const path: CategoryId[] = [];
    const getCategoryPath = (id: CategoryId) => {
      const item = cats.find((x) => {
        return x.platformId === id;
      });
      if (item) {
        path.push(id);
        if (item.platformParentId) {
          getCategoryPath(item.platformParentId);
        }
      }
      return;
    };
    getCategoryPath(id);
    return path.reverse();
  };

  useEffect(() => {
    run(props.queryParams);
  }, [props.queryParams.projectId]);
  const onChange = (groups: string[][]) => {
    const data = groups.map((x) => x[x.length - 1]);
    setCurrentValue(groups || []);
    props.onChange?.(data || []);
  };
  return (
    <CmtCascader
      style={props.style}
      data={options}
      value={currentValue}
      onChange={onChange}
      multiple={true}
      loading={loading}
      allowClear={props.allowClear}
      showSearch={{ filter }}
      showCheckedStrategy={props.showCheckedStrategy}
    />
  );
}
