import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import { wait } from '../../common/utils/wait';
import { ENV_CONFIG } from '../../env-config';
import httpClient from '../http-client';
import {
  RJDeliverCostTemplate,
  RJProductDetailDto,
  RJResponse,
  RJSimpleProductDto,
  RJSupportBrand,
  RJUnitAndSpecificationDef,
} from './product-modal';

export async function queryRJMartProductsApi(
  params: PagingRequest
): Promise<PagingResponse<RJSimpleProductDto>> {
  const res: any = await httpClient.get('/rjmart/products', { params });
  return res;
}

export async function getRJMartProductsDetailApi(
  id: number
): Promise<RJResponse<RJProductDetailDto>> {
  const res: any = await httpClient.get(`/rjmart/products/${id}`);
  return res;
}

export async function removeRJMProductApi(id: number[]) {
  const res: any = await httpClient.post(
    '/rjmart/products/deleteProductList',
    id
  );
  return res;
}
export async function publishRJMProductApi(id: number[]) {
  const res: any = await httpClient.post('/rjmart/products/onSale', id);
  await wait(1200);
  return res;
}

export async function unPublishRJMProductApi(id: number[]) {
  const res: any = await httpClient.post('/rjmart/products/offShelves', id);
  return res;
}

export async function cancelReviewRJMProductApi(id: number[]) {
  const res: any = await httpClient.post('/rjmart/products/cancelReview', id);
  return res;
}

export async function getSupportBrands(params: {
  projectId: string;
}): Promise<PagingResponse<RJSupportBrand>> {
  const items = await httpClient.get('/rjmart/products/getSupportBrands', {
    params,
  });
  const res: PagingResponse<RJSupportBrand> = {
    total: items.data.length,
    list: items.data,
    current: 1,
    pageSize: items.data.length,
  };
  return res;
}

export async function queryUnitAndSpecification(params: {
  projectId: string;
}): Promise<RJUnitAndSpecificationDef> {
  const items = await httpClient.get(
    '/rjmart/products/queryUnitAndSpecification',
    {
      params,
    }
  );
  return items.data;
}

export async function findCarryFeeTemplate(params: {
  projectId: string;
}): Promise<RJDeliverCostTemplate[]> {
  const items = await httpClient.get('/rjmart/products/findCarryFeeTemplate', {
    params,
  });
  return items.data;
}

export function getUploadActionUrl() {
  const url = `${ENV_CONFIG.BASE_URL}/rjmart/products/creation-good-picture-upload`;
  console.log('url', url);
  return url;
}

export async function saveRJMProduct(payload: any): Promise<any> {
  const items = await httpClient.post('/rjmart/products/save-good', payload);
  return items.data;
}
