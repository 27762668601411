import { Navigate, RouteObject } from 'react-router-dom';
import { ProjectGeneralSettings } from '../components/CmtProjects/project-settings/project-general-settings';
import { GpoSingleProjectHome } from './pages/project-home';
import { Projects } from './pages/projects';
import { OrderListPage } from './pages/sub-pages/order-list';
import { ProductFormPage } from './pages/sub-pages/product-edit-form/product-form-page';
import { ProductListPage } from './pages/sub-pages/product-listing';
import { Welcome } from './pages/sub-pages/welcome';
import { InternalTaskPage } from './pages/sub-pages/internal-task';
export const rjmartRouters: RouteObject[] = [
  {
    path: 'projects',
    element: <Projects />,
  },
  {
    path: 'projects/:projectId/settings',
    element: <ProjectGeneralSettings />,
  },
  {
    path: 'projects/:projectId',
    element: <GpoSingleProjectHome />,
    children: [
      {
        path: 'welcome',
        element: <Welcome />,
      },
      {
        path: 'products',
        element: <ProductListPage />,
      },
      {
        path: 'create-product',
        element: <ProductFormPage />,
      },
      {
        path: 'orders',
        element: <OrderListPage />,
      },
      {
        path: 'local',
        element: <ProductListPage />,
      },
      {
        path: 'create-task/:actionId',
        element: <InternalTaskPage />,
      },
      {
        path: '',
        element: <Navigate to={'products'} replace />,
      },
    ],
  },
  {
    path: '',
    element: <Navigate to={'projects'} replace />,
  },
];
