import { BarsOutlined } from '@ant-design/icons';
import { useRequest, useTitle } from 'ahooks';
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Result,
  Space,
  Spin,
  Steps,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ConvertJsonSchemaPayloadToString } from '../../../../common/utils/json-schema-payload';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { JsonSchemaInput } from '../../../../components/JsonSchemaInput';
import { EventList } from '../../../../pages/tasks/event-list';
import { createInternalPlanApi } from '../../../../services/plan.service';
import {
  Action,
  querySingleActionApi,
} from '../../../../services/task.service';

export function InternalTaskPage() {
  useTitle('采集商品', { restoreOnUnmount: true });
  const [form] = Form.useForm();
  const param = useParams();
  const [current, setCurrent] = useState(0);
  const { loading, run: getActionInfo } = useRequest(querySingleActionApi, {
    manual: true,
    onBefore() {},
    onSuccess(data) {
      setSelectedAction(data);
      form.setFieldsValue({ payload: null });
    },
    onError({ message: text }) {
      message.error('获取任务内容失败' + text);
    },
  });

  const { loading: creating, run: createPlan } = useRequest(
    createInternalPlanApi,
    {
      manual: true,
      debounceWait: 400,
      onSuccess(data, params) {
        form.resetFields();
        setCurrent(1);
      },
      onError({ message: text }, params) {
        message.error('任务创建失败：' + text);
      },
    }
  );
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);
  useEffect(() => {
    if (param?.actionId) {
      getActionInfo(param.actionId);
    }
  }, [param.actionId, param.projectId]);

  const stepItems = [
    {
      title: `任务设置`,
      content: (
        <Card>
          <Typography.Title level={3}>
            {selectedAction?.internalName || selectedAction?.name}{' '}
            <small
              style={{
                fontSize: 12,
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.45)',
              }}
            >
              {selectedAction?.description}
            </small>
          </Typography.Title>
          <Form
            form={form}
            layout={'vertical'}
            onFinish={(v) => {
              v.payload = ConvertJsonSchemaPayloadToString(v.payload);
              v.actionId = param.actionId;
              createPlan(v);
            }}
          >
            <Form.Item
              label="任务名称"
              required
              name={'name'}
              rules={[
                { required: true, message: '请输入任务名称' },
                { type: 'string', max: 50, message: '名称最大长度为50个字符' },
              ]}
            >
              <Input placeholder="请输入任务名称" />
            </Form.Item>
            {!loading && selectedAction?.payloadSchema && param.actionId && (
              <Form.Item name={'payload'} dependencies={['actionId']}>
                <JsonSchemaInput
                  projectId={param.projectId || ''}
                  schema={selectedAction?.payloadSchema}
                  layoutResponsive={false}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading || creating}
                  loading={loading || creating}
                >
                  提交执行
                </Button>
                <NavLink to={'../products'}>
                  <Button type="default">取消</Button>
                </NavLink>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
    {
      title: '提交',
      content: (
        <Card>
          <Result
            status="success"
            title={'创建成功，任务正在执行中'}
            subTitle="可在右上角的任务历史中查看任务状态"
            extra={[
              <Button type="primary" onClick={() => setCurrent(0)}>
                继续添加
              </Button>,
              <NavLink to={'../products'}>
                <Button type="default">线上商品库</Button>
              </NavLink>,
            ]}
          ></Result>
        </Card>
      ),
    },
  ];
  return (
    <BlankWrapper breadcrumb={[]}>
      <Spin spinning={loading}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Card>
            <Steps current={current} items={stepItems}></Steps>
          </Card>
          <div className="steps-content">{stepItems[current].content}</div>
        </Space>
      </Spin>
    </BlankWrapper>
  );
}
