import { DollarCircleOutlined, UserOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import { Row, Col, Space, Card, Typography } from 'antd';
import { BlankWrapper } from '../../../components/BlankWrapper';
import { BasicInfoForm } from './basic-info-form';

export function OrganizationProfilePage() {
  useTitle('组织设置', { restoreOnUnmount: true });
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '组织管理', icon: <DollarCircleOutlined /> },
        { name: '组织设置' },
      ]}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="基本信息">
          <BasicInfoForm></BasicInfoForm>
        </Card>
      </Space>
    </BlankWrapper>
  );
}
