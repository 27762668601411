import { SecurityScanOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Col, Result, Row } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { NavLink, useRoutes } from 'react-router-dom';
import { BlankWrapper } from '../../../components/BlankWrapper';
import Logo from '@/logo.svg';
import { ENV_CONFIG } from '../../../env-config';
import { useTitle } from 'ahooks';

export function RolesManagementPage() {
  useTitle('关于', { restoreOnUnmount: true });
  return (
    <BlankWrapper>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ width: 400 }}>
          <Result icon={<Logo />} subTitle={ENV_CONFIG.SLOGAN}></Result>
        </Card>
      </div>
    </BlankWrapper>
  );
}
