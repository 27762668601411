import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Card,
  Col,
  Input,
  Row,
  Typography,
  Form,
  Space,
  Affix,
  Button,
  InputNumber,
  Checkbox,
  Select,
  Upload,
  message,
  FormInstance,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ReactNode, useEffect, useState } from 'react';
import {
  GPOAgreementTypes,
  GPOGoodClassParamDto,
  GPOGoodFormTemplateDto,
  GPOMainPicutreTypes,
} from '../../../../../common/dtos/AddGoodTypes';
import { CreationParamInput } from './creation-param-input';
import { CreationPictureUpload } from './creation-picture-upload';
import { useRequest } from 'ahooks';
import { saveGood } from '../../../../../services/gpo/goodmarket.service';
import { REGEXS } from '../../../../../common/utils/formats';
import { Editor } from '../../../../../components/Editor';

export function ProductForm(props: {
  goodForm: GPOGoodFormTemplateDto;
  formValue?: any;
  reupload?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  extendAction?: (actions: {
    getPayload: () => any;
    form: FormInstance<any>;
  }) => ReactNode;
  hasBack?: boolean;
  /**
   * 默认勾选服务承诺
   */
  checkPromiseGuids?: boolean;
  containerStyle?: React.CSSProperties;
}) {
  const key = 'updatable';
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [flatParamDefs, setFlatParamDefs] = useState<GPOGoodClassParamDto[]>(
    []
  );
  const initForm = (defs: GPOGoodClassParamDto[]) => {
    const { goodForm } = props;
    const promiseGuids =
      [...goodForm.goodsPromiseOneList, ...goodForm.goodsPromiseTwoList]
        ?.filter((x) => props.checkPromiseGuids || x.checked)
        .map((x) => x.promiseGuid) || [];
    const formData = {
      goodsGuid: goodForm.goods.goodsGuid || '',
      goodsClassName: goodForm.goods.goodsClassName,
      goodsClassGuid: goodForm.goods.goodsClassGuid,
      goodsBrandName: goodForm.goods.goodsBrandName,
      goodsBrandGuid: goodForm.goods.goodsBrandGuid,
      goodsSource: GPOAgreementTypes.GoodMarket,
      code: goodForm.goods.code,
      version: goodForm.goods.version,
      name: goodForm.goods.name,
      upcCode: goodForm.goods.upcCode,
      picturePath: goodForm.goods.picturePath,
      salesPrice: goodForm.goods.salesPrice,
      marketPrice: goodForm.goods.marketPrice || '',
      promiseGuids: promiseGuids,
      promiseFilePath: goodForm.goods.promiseFilePath || '',
      goodsContent: goodForm.goods.goodsContent,
      inventory: goodForm.goods.inventory || '',
      goodsExternalPriceList: goodForm.goods.goodsExternalPriceList || [
        {
          externalUrl: '',
          mallName: '',
          picturePath: '',
          type: 1,
          proofFilePath: '',
          price: '',
          sort: 1,
        },
      ],
      goodsParamValueList:
        defs?.map((x) => {
          return {
            goodsClassParamGuid: x.goodsClassParamGuid,
            paramName: x.paramName,
            paramTypeGuid: x.paramTypeGuid,
            paramValue: x.paramValue,
          };
        }) || [],
      pictureArray:
        goodForm.goods.pictureList?.map((x) => {
          return {
            filePath: x.filePath,
            mainPct: x.mainPct,
          };
        }) || [],
      regionGuid: '',
      enableGoodsSupplyProof: goodForm.goods.enableGoodsSupplyProof || 0,
      goodsDesc: goodForm.goods.goodsDesc,
      serviceList: goodForm.goods.serviceList || [],
      partList: goodForm.goods.partList || [],
      ...props.formValue,
    };
    form.setFieldsValue(formData);
  };

  useEffect(() => {
    if (props.goodForm) {
      const defs: GPOGoodClassParamDto[] = [];
      props.goodForm.goodsClassParamTypeList.forEach((group) => {
        defs.push(...group.goodsClassParamList);
      });
      setFlatParamDefs(defs);
      initForm(defs);
    }
  }, [props.goodForm]);

  const { run: runSaveGood, loading } = useRequest(saveGood, {
    manual: true,
    debounceWait: 300,
    onBefore() {
      messageApi.open({
        key,
        type: 'loading',
        content: '正在保存，请稍后...',
        duration: 0,
      });
    },
    onSuccess(res) {
      messageApi.open({
        key,
        type: 'success',
        content: '保存成功',
        duration: 5,
      });
      props.onSuccess?.();
    },
    onError(e, params) {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
        duration: 5,
      });
    },
    onFinally() {},
  });

  const buildPayload = () => {
    const payload = form.getFieldsValue();
    payload.promiseGuids = payload.promiseGuids.join(',');
    let mainPic = payload.pictureArray.find(
      (x: any) => x.mainPct === GPOMainPicutreTypes.Yes
    );
    if (!mainPic && payload.pictureArray.length) {
      mainPic = payload.pictureArray[0];
      mainPic.mainPct = GPOMainPicutreTypes.Yes;
    }
    payload.picturePath = mainPic?.filePath || '';
    return payload;
  };

  const submitForm = () => {
    form.validateFields().then(
      () => {
        runSaveGood(buildPayload(), props.reupload);
      },
      (e) => {
        messageApi.open({
          key,
          type: 'error',
          content: '请检查表单',
          duration: 3,
        });
      }
    );
  };

  return (
    <div
      style={props.containerStyle ? props.containerStyle : { marginBottom: 30 }}
    >
      {contextHolder}
      <Form
        form={form}
        labelAlign={'left'}
        layout="vertical"
        disabled={loading}
        onFinish={submitForm}
        scrollToFirstError={true}
        onChange={() => {
          console.log(form.getFieldsValue());
        }}
      >
        <Space style={{ width: '100%' }} direction="vertical">
          <Card>
            <Typography.Title level={4}>商品信息</Typography.Title>
            <Typography.Paragraph type="secondary">
              商品型号一般由数字和字母组成，请勿填写品目、品牌名称及其他无关中文信息
            </Typography.Paragraph>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="商品类型" rules={[{ required: true }]}>
                  <Input disabled value={'经销商商品'} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="品目"
                  name="goodsClassName"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="品牌"
                  name="goodsBrandName"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="商品名称"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="商品型号"
                  name="code"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="市场价（元）"
                  name="salesPrice"
                  rules={[
                    { required: true },
                    { pattern: REGEXS.number, message: '格式不正确' },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="商品版本" name="version">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="条形码" name="upcCode">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="商品描述" name="goodsDesc">
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Typography.Title level={4}>价格信息</Typography.Title>
            <Typography.Paragraph type="secondary">
              价格信息说明：价格信息指该商品在其他电商平台的价格信息。
            </Typography.Paragraph>
            <Form.List name="goodsExternalPriceList">
              {(fields, { add, remove }) => (
                <Space style={{ width: '100%' }} direction="vertical">
                  {fields.map(({ key, name }, index) => (
                    <Row gutter={12} key={key}>
                      <Col span={7}>
                        <FormItem hidden name={[name, 'type']}>
                          <Input />
                        </FormItem>
                        <FormItem hidden name={[name, 'picturePath']}>
                          <Input />
                        </FormItem>
                        <FormItem hidden name={[name, 'proofFilePath']}>
                          <Input />
                        </FormItem>
                        <FormItem hidden name={[name, 'sort']}>
                          <Input value={index + 1} />
                        </FormItem>
                        <FormItem
                          label={`市场价格${index + 1}`}
                          name={[name, 'price']}
                          rules={[
                            { required: true },
                            { pattern: REGEXS.number, message: '格式不正确' },
                          ]}
                        >
                          <Input style={{ width: '100%' }} />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="价格来源"
                          name={[name, 'mallName']}
                          rules={[{ required: true }]}
                        >
                          <Select
                            options={props.goodForm.goodsPriceSourceOptionEntityList.map(
                              (x) => {
                                return { label: x.content, value: x.content };
                              }
                            )}
                            onChange={(val: string) => {
                              const type =
                                props.goodForm.goodsPriceSourceOptionEntityList.find(
                                  (x) => {
                                    return x.content === val;
                                  }
                                )?.type;
                              form.setFieldValue(
                                ['goodsExternalPriceList', key, 'type'],
                                type
                              );
                            }}
                          ></Select>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="网页链接"
                          name={[name, 'externalUrl']}
                          rules={[{ required: true }]}
                        >
                          <Input type="url" />
                        </FormItem>
                      </Col>
                      <Col span={1}>
                        <FormItem label={' '}>
                          <Button
                            type="text"
                            danger
                            onClick={() => remove(index)}
                            disabled={fields.length === 1}
                          >
                            <DeleteOutlined />
                          </Button>
                        </FormItem>
                      </Col>
                    </Row>
                  ))}
                  <Button
                    onClick={() =>
                      add({
                        externalUrl: '',
                        mallName: '',
                        picturePath: '',
                        type: 1,
                        proofFilePath: '',
                        price: '',
                        sort: fields.length + 1,
                      })
                    }
                    disabled={fields.length >= 3}
                  >
                    添加
                  </Button>
                </Space>
              )}
            </Form.List>
          </Card>
          <Card>
            <Typography.Title level={4}>商品图片</Typography.Title>
            <Typography.Paragraph type="secondary">
              允许上传的图片大小不超过5MB，第一张图将作为主图
            </Typography.Paragraph>
            <Row gutter={12}>
              <Col span={24}>
                <FormItem
                  name="pictureArray"
                  labelCol={{ span: 24 }}
                  shouldUpdate={true}
                  rules={[{ required: true, message: '请上传商品图片' }]}
                >
                  <CreationPictureUpload />
                </FormItem>
              </Col>
            </Row>
          </Card>

          <Card>
            <Typography.Title level={4}>商品参数</Typography.Title>
            <Row gutter={12}>
              <Form.List name="goodsParamValueList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <Col span={12} key={key}>
                        <CreationParamInput
                          goodsClassParamGuid={form.getFieldValue([
                            'goodsParamValueList',
                            key,
                            'goodsClassParamGuid',
                          ])}
                          defs={flatParamDefs}
                          namePath={[name, 'paramValue']}
                        />
                        <FormItem hidden name={[name, 'paramName']}>
                          <Input />
                        </FormItem>
                        <FormItem hidden name={[name, 'paramTypeGuid']}>
                          <Input />
                        </FormItem>
                        <FormItem hidden name={[name, 'goodsClassParamGuid']}>
                          <Input />
                        </FormItem>
                      </Col>
                    ))}
                  </>
                )}
              </Form.List>
            </Row>
          </Card>

          <Card>
            <Typography.Title level={4}>认证和服务信息</Typography.Title>
            <Row gutter={12}>
              <Col span={12}>
                <FormItem
                  name="promiseGuids"
                  // rules={[{ required: true, message: '请选择认证与服务承诺' }]}
                >
                  <Checkbox.Group
                    options={[
                      ...props.goodForm.goodsPromiseOneList,
                      ...props.goodForm.goodsPromiseTwoList,
                    ].map((x) => {
                      return { label: x.promiseName, value: x.promiseGuid };
                    })}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Typography.Title level={4}>商品详情</Typography.Title>
            <Row gutter={12}>
              <Col span={24}>
                <FormItem
                  name="goodsContent"
                  rules={[{ required: true, message: '请输入商品详情' }]}
                >
                  <Editor></Editor>
                </FormItem>
              </Col>
            </Row>
          </Card>
        </Space>
        <Affix
          offsetBottom={20}
          style={{ position: 'absolute', bottom: 0, right: 30 }}
        >
          <Space>
            {props.extendAction?.({ getPayload: buildPayload, form })}
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              提交GPO
            </Button>
            {props.hasBack && (
              <Button type="text" onClick={props.onCancel}>
                返回
              </Button>
            )}
          </Space>
        </Affix>
        {/* 隐藏字段 */}
        <FormItem hidden label="goodsClassGuid" name="goodsClassGuid">
          <Input />
        </FormItem>
        <FormItem hidden label="goodsSource" name="goodsSource">
          <Input />
        </FormItem>
        <FormItem hidden label="goodsBrandGuid" name="goodsBrandGuid">
          <Input />
        </FormItem>
        <FormItem
          hidden
          label="enableGoodsSupplyProof"
          name="enableGoodsSupplyProof"
        >
          <InputNumber />
        </FormItem>
        <FormItem hidden label="inventory" name="inventory">
          <Input />
        </FormItem>
        <FormItem hidden label="marketPrice" name="marketPrice">
          <Input />
        </FormItem>
        <FormItem hidden label="promiseFilePath" name="promiseFilePath">
          <Input />
        </FormItem>
        <FormItem hidden label="goodsGuid" name="goodsGuid">
          <Input />
        </FormItem>
        <FormItem hidden label="picturePath" name="picturePath">
          <Input />
        </FormItem>
      </Form>
    </div>
  );
}
