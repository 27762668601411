import { CarOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Spin, Alert, Timeline, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { queryLogisticDetailApi } from '../../services/logistics.service';
import { CmtModalButton } from '../CmtModalButton';

export function LogisticDetailView(props: {
  data: {
    providerName: string;
    zipCode: string;
    receiverPhone: string;
    senderPhone: string;
  };
}) {
  const [infos, setInfos] = useState<any>([]);
  const { data, loading, run, error } = useRequest(queryLogisticDetailApi, {
    manual: true,
    onSuccess(data, params) {
      setInfos(data.detail?.reverse() || []);
    },
    onError(e, params) {},
  });
  useEffect(() => {}, [props.data]);
  return (
    <CmtModalButton
      width={500}
      title={'物流信息'}
      trigger={({ show }) => {
        return (
          <Button onClick={show} size="small">
            <CarOutlined />
          </Button>
        );
      }}
      onVisibleChange={(visible) => {
        if (visible && !infos.length) {
          run({
            providerName: props.data.providerName,
            zipCode: props.data.zipCode,
            senderPhone: props.data.senderPhone,
            receiverPhone: props.data.receiverPhone,
          });
        }
      }}
    >
      {() => {
        return (
          <Spin spinning={loading}>
            {error ? (
              <Alert type="error" message={error.message}></Alert>
            ) : infos.length ? (
              <div style={{ padding: 12 }}>
                <Timeline mode="left">
                  {infos.map((x: any) => {
                    return (
                      <Timeline.Item>
                        <Typography.Text type="secondary">
                          {x.opTime}
                        </Typography.Text>
                        <div>{x.opMessage}</div>
                      </Timeline.Item>
                    );
                  })}
                </Timeline>
              </div>
            ) : (
              <Alert type="info" message={'暂无物流信息'}></Alert>
            )}
          </Spin>
        );
      }}
    </CmtModalButton>
  );
}
