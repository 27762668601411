import { HistoryOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';
import { SimpleInternalTaskList } from './simple-internal-task-list';

export function CmtInternalTaskStatus() {
  return (
    <Popover content={<SimpleInternalTaskList />} trigger="click">
      <Button type="text">
        <HistoryOutlined />
      </Button>
    </Popover>
  );
}
