import { useRequest } from 'ahooks';
import { Select } from 'antd';
import { useEffect } from 'react';
import { queryCasBrands } from '../../../services/cas/product.service';

export function CasBrandSelect(props: {
  queryParams: {
    projectId?: string;
  };
  mode?: 'multiple' | 'tags';
  value?: number;
  style?: React.CSSProperties;
  onChange?: (v: number) => void;
  allowClear?: boolean;
}) {
  const { data, loading, run } = useRequest(queryCasBrands, {
    debounceWait: 300,
    manual: true,
  });
  useEffect(() => {
    if (props.queryParams.projectId) {
      run({
        projectId: props.queryParams.projectId,
      });
    }
  }, [props.queryParams.projectId]);
  return (
    <Select
      style={props.style}
      mode={props.mode}
      loading={loading}
      onChange={(v: number, opt) => {
        props.onChange?.(v);
      }}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={data?.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      })}
      value={props.value}
      allowClear={props.allowClear}
      placeholder="品牌"
    ></Select>
  );
}
