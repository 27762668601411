import { useDebounceEffect, useRequest, useTitle } from 'ahooks';
import {
  message,
  Form,
  FormInstance,
  Space,
  Card,
  Col,
  Input,
  Row,
  Typography,
  Affix,
  Button,
  InputNumber,
  Tooltip,
  Select,
  Empty,
} from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { REGEXS } from '../../../../../common/utils/formats';
import { Editor } from '../../../../../components/Editor';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  MartProductPayload,
  MartProductSaleStatus,
} from '../../../../../services/cas/product-modal';
import { CasCategoryCascader } from '../../../../components/CategoryCascader';
import { CasBrandSelect } from '../../../../components/CasBrandSelect';
import {
  CasPictureListItem,
  queryCasCategoryUnits,
  queryCasTypeAndExtendAttrByCatId,
  saveCasProduct,
} from '../../../../../services/cas/product.service';
import { MartCategoryExtendAttr } from '../../../../../services/cas/common-modal';
import { CasGroupCascader } from '../../../../components/GroupCascader';
import { CreationPictureUpload } from './creation-picture-upload';
import { CasCategoryTypeSelect } from '../../../../components/CategoryTypeSelect';
import { CasCategoryUnitSelect } from '../../../../components/CategoryUnitSelect';
export function CasProductForm(props: {
  formValue?: MartProductPayload;
  projectId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  extendAction?: (actions: {
    getPayload: () => any;
    form: FormInstance<any>;
  }) => ReactNode;
}) {
  const key = 'updatable';
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [currentCategoryId, setCurrentCategoryId] = useState(0);
  const [formInited, setFormInited] = useState(false);
  const [currentCategoryType, setCurrentCategoryType] = useState(0);
  const [extAttrs, setExtAttrs] = useState<MartCategoryExtendAttr[]>([]);
  const { run: runSaveGood, loading } = useRequest(saveCasProduct, {
    manual: true,
    debounceWait: 300,
    onBefore() {
      messageApi.open({
        key,
        type: 'loading',
        content: '正在保存，请稍后...',
        duration: 0,
      });
    },
    onSuccess(res) {
      messageApi.open({
        key,
        type: 'success',
        content: '保存成功',
        duration: 5,
      });
      props.onSuccess?.();
    },
    onError(e, params) {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
        duration: 5,
      });
    },
    onFinally() {},
  });

  const {
    data: typesAndExtAttrs,
    run: runQueryTypes,
    loading: loadingTypes,
  } = useRequest(queryCasTypeAndExtendAttrByCatId, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {},
  });

  // const {
  //   data: units,
  //   run: runQueryUnits,
  //   loading: loadingUnits,
  // } = useRequest(queryCasCategoryUnits, {
  //   manual: true,
  //   debounceWait: 300,
  // });

  const submitForm = () => {
    form.validateFields().then(
      () => {
        // save
        const payload: MartProductPayload = { ...form.getFieldsValue() };
        const xmSelect: number[] = payload.productBasic.xmSelect?.length
          ? (payload.productBasic.xmSelect as any)
          : [];
        payload.productBasic.xmSelect = xmSelect.join(',');
        if (xmSelect) {
          payload.productCategoryList = xmSelect.map((x) => {
            const productId = payload.productBasic.id;
            return {
              supplierCategoryId: x.toString(),
              productId,
            };
          });
        }
        runSaveGood(payload);
      },
      (e) => {
        messageApi.open({
          key,
          type: 'error',
          content: '请检查表单',
          duration: 3,
        });
      }
    );
  };

  const initForm = async () => {
    if (props.formValue) {
      setCurrentCategoryId(Number(props.formValue?.productBasic.cateId) || 0);
      setCurrentCategoryType(Number(props.formValue?.productBasic.typeId) || 0);
    }
    // 拆解分组ID
    const xmSelect: number[] = props.formValue?.productBasic.xmSelect
      ? props.formValue?.productBasic.xmSelect.split(',').map((x) => Number(x))
      : [];
    form.setFieldsValue({
      ...props.formValue,
      productBasic: {
        insaleStatus: 0,
        giftStatus: '0',
        ...props.formValue?.productBasic,
        // 数据类型混乱导致
        cateId: Number(props.formValue?.productBasic.cateId) || 0,
        brandId: Number(props.formValue?.productBasic.brandId) || 0,
        typeId: Number(props.formValue?.productBasic.typeId) || 0,
        xmSelect: xmSelect,
      },
    });
    setFormInited(true);
  };

  useDebounceEffect(
    () => {
      initForm();
    },
    [props.formValue],
    {
      wait: 200,
    }
  );

  useEffect(() => {
    if (currentCategoryId) {
      runQueryTypes({
        projectId: props.projectId,
        categoryId: currentCategoryId,
      });
      // runQueryUnits({
      //   projectId: props.projectId,
      //   categoryId: currentCategoryId,
      // });
    }
  }, [currentCategoryId]);

  const initExtList = () => {
    if (currentCategoryType && typesAndExtAttrs?.length) {
      const type = typesAndExtAttrs?.find(
        (x) => x.typeId == currentCategoryType
      );
      const exts = type?.extendList || [];
      const formValueExts = props.formValue?.extendList || [];
      const formExts = exts.map((x) => {
        const fvext = formValueExts.find((y) => y.extendId === x.id.toString());
        return {
          extendId: x.id,
          extendName: x.name,
          value: fvext?.value || '',
        };
      });
      form.setFieldsValue({ extendList: formExts });
      setExtAttrs(exts);
    } else {
      setExtAttrs([]);
      form.setFieldsValue({ extendList: [] });
    }
  };

  useEffect(() => {
    initExtList();
  }, [currentCategoryType, typesAndExtAttrs]);

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        labelAlign={'left'}
        layout="vertical"
        disabled={loading}
        scrollToFirstError={true}
      >
        <Form.Item name={'productBasic'}>
          <Form.Item hidden name={['productBasic', 'img']}>
            <Input />
          </Form.Item>
          <Form.Item hidden name={['productBasic', 'id']}>
            <Input />
          </Form.Item>
          <Space style={{ width: '100%' }} direction="vertical">
            <Card>
              <Typography.Title level={4}>基本信息</Typography.Title>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="商品分类"
                    name={['productBasic', 'cateId']}
                    rules={[{ required: true }]}
                  >
                    <CasCategoryCascader
                      single={true}
                      queryParams={{
                        projectId: props.projectId,
                      }}
                      onChange={(id) => {
                        setCurrentCategoryId(id);
                        setCurrentCategoryType(0);
                        setExtAttrs([]);
                        form.setFieldValue(['productBasic', 'typeId'], null);
                        form.setFieldValue(['extendList'], []);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="商品类型"
                    name={['productBasic', 'typeId']}
                    rules={[{ required: true }]}
                  >
                    <CasCategoryTypeSelect
                      onChange={setCurrentCategoryType}
                      queryParams={{
                        projectId: props.projectId,
                        categoryId: currentCategoryId,
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name={['productBasic', 'name']}
                    label="商品名称"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="商品货号"
                    name={['productBasic', 'code']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="商品规格"
                    name={['productBasic', 'spec']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name={['productBasic', 'brandId']}
                    label="品牌"
                    rules={[{ required: true }]}
                  >
                    <CasBrandSelect
                      queryParams={{
                        projectId: props.projectId,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="计量单位"
                    name={['productBasic', 'unit']}
                    rules={[{ required: true }]}
                  >
                    {/* <Select
                      loading={loadingUnits}
                      options={units?.map((x) => {
                        return { label: x.name, value: x.name };
                      })}
                    ></Select> */}
                    <CasCategoryUnitSelect
                      queryParams={{
                        projectId: props.projectId,
                        categoryId: currentCategoryId,
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name={['productBasic', 'casCode']}
                    label="CAS号"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['productBasic', 'maker']}
                    label="生产企业"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card>
              <Typography.Title level={4}>销售信息</Typography.Title>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="本站价格"
                    name={['productBasic', 'price']}
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={0} style={{ width: '100%' }} disabled={!!props.formValue}/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="市场价格"
                    name={['productBasic', 'mktPrice']}
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name={['productBasic', 'deliveryCycle']}
                    label="交货周期"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: '100%' }}
                      addonAfter={
                        <Space>
                          天
                          <Tooltip
                            title={'交货周期：从确认订单到商品发货需要的天数'}
                          >
                            <InfoCircleFilled />
                          </Tooltip>
                        </Space>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="库存"
                    name={['productBasic', 'stockAmount']}
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="销售状态"
                    name={['productBasic', 'insaleStatus']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          label: '上架',
                          value: MartProductSaleStatus.Publish,
                        },
                        {
                          label: '下架',
                          value: MartProductSaleStatus.Unpublish,
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name={['productBasic', 'giftStatus']}
                    label="是否赠品"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        {
                          label: '是',
                          value: '1',
                        },
                        {
                          label: '否',
                          value: '0',
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="店铺商品分组"
                    name={['productBasic', 'xmSelect']}
                    rules={[{ required: false }]}
                  >
                    <CasGroupCascader
                      allowClear
                      queryParams={{
                        projectId: props.projectId || '',
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Space>
        </Form.Item>
        <Form.Item>
          <Card>
            <Typography.Title level={4}>扩展信息</Typography.Title>
            {!extAttrs.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            <Form.List name={'extendList'}>
              {(fields) => (
                <Row gutter={24}>
                  {fields.map((field, index) => {
                    const extendId = form.getFieldValue([
                      'extendList',
                      field.name,
                      'extendId',
                    ]);
                    const ext = extAttrs.find((x) => x.id === extendId);
                    return (
                      <Col span={12} key={field.key}>
                        <Form.Item name={[field.name, 'extendId']} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[field.name, 'extendName']} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'value']}
                          label={ext?.name}
                          rules={[{ required: ext?.isRequired === 1 }]}
                        >
                          <Input
                            placeholder={(ext as any)?.['extendDefault'] || ''}
                          />
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Form.List>
          </Card>
        </Form.Item>
        <Form.Item>
          <Card>
            <Typography.Title level={4}>商品展示</Typography.Title>
            <Form.Item name={'imgList'}>
              <CreationPictureUpload
                onChange={(items: CasPictureListItem[]) => {
                  form.setFieldValue(
                    ['productBasic', 'img'],
                    items.length ? items[0].path : ''
                  );
                }}
              />
            </Form.Item>
          </Card>
        </Form.Item>

        <Form.Item name={'productIntro'}>
          <Card>
            <Typography.Title level={4}>详情描述</Typography.Title>
            <Form.Item hidden name={['productIntro', 'productId']}>
              <Input />
            </Form.Item>
            <Form.Item name={['productIntro', 'content']}>
              <Editor />
            </Form.Item>
          </Card>
        </Form.Item>
        <Affix
          offsetBottom={20}
          style={{ position: 'absolute', bottom: 0, right: 30 }}
        >
          <Space>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              disabled={loading}
              onClick={() => {
                submitForm();
              }}
            >
              保存
            </Button>
          </Space>
        </Affix>
      </Form>
    </>
  );
}
