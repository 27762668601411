import { useRequest } from 'ahooks';
import Cascader, { DefaultOptionType } from 'antd/es/cascader';
import { useEffect, useState } from 'react';
import { CategoryId } from '../../../common/dtos/base-category';
import {
  CmtCascader,
  CmtCascaderOption,
} from '../../../components/CmtCascader';
import {
  GpoCategory,
  GpoCategoryGroup,
  getAllGpoCategoriesApi,
  buildCategoryTree,
} from '../../../services/gpo/category.service';

export function GpoCategoryCascader(props: {
  queryParams: { projectId: string; group: GpoCategoryGroup };
  style?: React.CSSProperties;
  allowClear?: boolean;
  onChange?: (val: any) => void;
  value?: string;
  showCheckedStrategy?: any;
}) {
  const [options, setOptions] = useState<CmtCascaderOption[]>([]);
  const [currentValue, setCurrentValue] = useState<CategoryId[]>([]);
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const createOptionTree = (res: GpoCategory[]) => {
    const opts = buildCategoryTree(res);
    setOptions(opts);
    if (props.value) {
      const val = getValuePath(props.value, res);
      if (val.length) {
        setCurrentValue(val);
      }
    }
  };
  const { loading, run } = useRequest(getAllGpoCategoriesApi, {
    manual: true,
    debounceWait: 300,
    onSuccess: createOptionTree,
  });

  const getValuePath = (id: CategoryId, cats: GpoCategory[]) => {
    const path: CategoryId[] = [];
    const getCategoryPath = (id: CategoryId) => {
      const item = cats.find((x) => {
        return x.platformId === id;
      });
      if (item) {
        path.push(id);
        if (item.platformParentId) {
          getCategoryPath(item.platformParentId);
        }
      }
      return;
    };
    getCategoryPath(id);
    return path.reverse();
  };

  useEffect(() => {
    run(props.queryParams);
  }, [props.queryParams.projectId]);
  const onChange = (val: string[]) => {
    setCurrentValue(val ? [...val] : []);
    props.onChange?.(val ? val[val.length - 1] : null);
  };
  return (
    <CmtCascader
      style={props.style}
      data={options}
      value={currentValue}
      onChange={onChange}
      loading={loading}
      allowClear={props.allowClear}
      showSearch={{ filter }}
      showCheckedStrategy={props.showCheckedStrategy}
    />
  );
}
