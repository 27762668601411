import { useRequest, useTitle } from 'ahooks';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import print from './print.module.less';
import { MartSimpleOrderDto } from '../../../../../services/cas/order-modal';
import { getCasOrderArchivePrint } from '../../../../../services/cas/order.service';

export const PrintableOrderArchive = React.forwardRef<
  any,
  { data: MartSimpleOrderDto; projectId: string }
>((props, ref) => {
  const [html, setHtml] = useState('');
  const { data, loading, run } = useRequest(getCasOrderArchivePrint, {
    manual: true,
    debounceWait: 100,
    onSuccess(data, params) {
      const dom = new DOMParser().parseFromString(data, 'text/html');
      const tableHtml = dom.querySelector('.container')?.innerHTML || '';
      setHtml(tableHtml);
    },
  });
  useTitle(props.data.sn || '', { restoreOnUnmount: true });
  useEffect(() => {
    if (props.data.id && props.projectId) {
      run(props.data.id);
    }
  }, [props.data, props.projectId]);

  return (
    <Spin spinning={loading}>
      <div className={print['printView']} ref={ref}>
        {/* <style type="text/css" media="print">
          {
            '\
  @page { size: a4; margin: 1cm 0.5cm; }\
'
          }
        </style> */}
        <div
          dangerouslySetInnerHTML={{
            __html: html || '',
          }}
        ></div>
      </div>
    </Spin>
  );
});
