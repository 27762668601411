import { LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../components/CmtPopConfirmAction';
import { Plan, runPlanApi } from '../../services/plan.service';
const { Text, Paragraph } = Typography;

export function PlanManualRunButton(props: { data: Plan }) {
  return (
    <CmtPopConfirmAction
      service={runPlanApi}
      title={
        <Space direction="vertical">
          <Text strong>触发确认</Text>
          <Paragraph style={{ maxWidth: 300 }}>
            确定手动执行这个计划吗?
            <div>
              <Text type="secondary">
                提示：如果有相同的任务内容，将会进行排队
              </Text>
            </div>
          </Paragraph>
        </Space>
      }
      onOk={({ data, run, loading }) => {
        run(data.id);
      }}
      data={props.data}
    >
      {({ loading }) => (
        <>
          {!loading && (
            <Button type="link" size="small" disabled={loading}>
              <PlayCircleOutlined />
            </Button>
          )}
          {loading && (
            <Button type="link" size="small" disabled={true}>
              <LoadingOutlined />
            </Button>
          )}
        </>
      )}
    </CmtPopConfirmAction>
  );
}
