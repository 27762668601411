import { Navigate, RouteObject } from 'react-router-dom';
import { ProjectGeneralSettings } from '../components/CmtProjects/project-settings/project-general-settings';
import { GpoSingleProjectHome } from './pages/project-home';
import { Projects } from './pages/projects';
import { AgreementListPage } from './pages/sub-pages/agreement';
import { CategoryListPage } from './pages/sub-pages/category';
import { ContractListPage } from './pages/sub-pages/contract';
import { DraftProductPage } from './pages/sub-pages/draft-product';
import { GoodMarketProductListPage } from './pages/sub-pages/good-market-product';
import { LocalGoodMarketProductListPage } from './pages/sub-pages/good-market-product/local';
import { ProductFormPage } from './pages/sub-pages/good-market-product/product-form-page';
import { InternalTaskPage } from './pages/sub-pages/internal-task';
import { JiCaiOnSaleProductListPage } from './pages/sub-pages/jicai-on-sale-product';
import { LocalJiCaiOnSaleProductListPage } from './pages/sub-pages/jicai-on-sale-product/local';
import { OrderListPage } from './pages/sub-pages/order';
import { StandardGoodsPage } from './pages/sub-pages/standard-goods';
import { Welcome } from './pages/sub-pages/welcome';
export const gpoRouters: RouteObject[] = [
  {
    path: 'projects',
    element: <Projects />,
  },
  {
    path: 'projects/:projectId/settings',
    element: <ProjectGeneralSettings />,
  },
  {
    path: 'projects/:projectId',
    element: <GpoSingleProjectHome />,
    children: [
      {
        path: 'welcome',
        element: <Welcome />,
      },
      {
        path: 'orders',
        element: <OrderListPage />,
      },
      {
        path: 'categories',
        element: <CategoryListPage />,
      },
      {
        path: 'contracts',
        element: <ContractListPage />,
      },
      {
        path: 'agreements',
        element: <AgreementListPage />,
      },
      {
        path: 'jicai-on-sale-products',
        element: <JiCaiOnSaleProductListPage />,
      },
      {
        path: 'local-jicai-on-sale-products',
        element: <LocalJiCaiOnSaleProductListPage />,
      },
      {
        path: 'goodmarket-products',
        element: <GoodMarketProductListPage />,
      },
      {
        path: 'local-goodmarket-products',
        element: <LocalGoodMarketProductListPage />,
      },
      {
        path: 'create-goodmarket-product',
        element: <ProductFormPage />,
      },
      {
        path: 'standard-library',
        element: <StandardGoodsPage />,
      },
      {
        path: 'draft-products',
        element: <DraftProductPage />,
      },
      {
        path: 'create-task/:actionId',
        element: <InternalTaskPage />,
      },
      {
        path: '',
        element: <Navigate to={'standard-library'} replace />,
      },
    ],
  },
  {
    path: '',
    element: <Navigate to={'projects'} replace />,
  },
];
