import { useRequest } from 'ahooks';
import { ProductName } from '../../../../gpo/components/ProductName';
import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import {
  MartOrderProductVo,
  MartSimpleOrderDto,
} from '../../../../services/cas/order-modal';
const { Text } = Typography;

export function OrderProductLink(props: { vo: MartOrderProductVo }) {
  return (
    <ProductName
      picturePath={props.vo.productImg || ''}
      name={props.vo.productName}
      onClick={() => {
        window.open(
          `https://www.casmart.com.cn/product-details/page/${props.vo.productSupplierId}/${props.vo.productId}`
        );
      }}
      extends={
        <Text code>
          {ConvertNumberToCurrency(props.vo.productPrice)}x{props.vo.amount}
        </Text>
      }
    />
  );
}
