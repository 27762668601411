import { useRequest } from 'ahooks';
import { Space, Typography } from 'antd';
import { getUserRoles, UserDto } from '../../../services/user.service';

export function UserRoleInfo(props: { data: UserDto }) {
  const { data } = useRequest(getUserRoles);
  return (
    <Space size={'small'}>
      <Typography.Text>
        {data?.find((x) => x.name === props.data.role)?.displayName}
      </Typography.Text>
      {/* <Typography.Text code>*</Typography.Text> */}
    </Space>
  );
}
