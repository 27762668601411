import { Navigate, RouteObject } from 'react-router-dom';
import { AuditLogsPage } from './audit-logs';
import { CronManagementPage } from './cron';
import { EcsManagementPage } from './ecs';
import { RolesManagementPage } from './roles';
import { OrganizationProfilePage } from './settings';
import { UserManagementPage } from './users';

export const adminRouters: RouteObject[] = [
  {
    path: 'settings',
    element: <OrganizationProfilePage />,
  },
  {
    path: 'members',
    element: <UserManagementPage />,
  },
  // {
  //   path: 'ecs',
  //   element: <EcsManagementPage />,
  // },
  // {
  //   path: 'crons',
  //   element: <CronManagementPage />,
  // },
  {
    path: 'roles',
    element: <RolesManagementPage />,
  },
  {
    path: 'logs',
    element: <AuditLogsPage />,
  },
  {
    path: '',
    element: <Navigate to={'settings'} replace />,
  },
];
