import { useRequest } from 'ahooks';
import { Select } from 'antd';
import { useEffect } from 'react';
import { RJMCategoryCascader } from '../../../../../components/CategoryCascader';
import { InfiniteSelect } from '../../../../../../components/InfiniteSelect';
import { getSupportBrands } from '../../../../../../services/rjmart/product-mamagement.service';
import { RJSupportBrand } from '../../../../../../services/rjmart/product-modal';

export function RJMBrandSelect(props: {
  value?: number;
  style?: React.CSSProperties;
  queryParams: { projectId: string };
  allowClear?: boolean;
  onChange?: (v: number) => void;
}) {
  const { data, loading, run } = useRequest(getSupportBrands, {
    debounceWait: 300,
    manual: true,
  });
  useEffect(() => {
    if (props.queryParams.projectId) {
      run(props.queryParams);
    }
  }, [props.queryParams.projectId]);
  return (
    <Select
      style={props.style}
      loading={loading}
      onChange={(v, opt) => {
        props.onChange?.(v);
      }}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={data?.list?.map((x) => {
        return {
          label: x.cname || x.ename,
          value: x.brandId,
        };
      })}
      value={props.value}
      allowClear={props.allowClear}
      placeholder="品牌"
    ></Select>
  );
}
