import { Button, Drawer, Space } from 'antd';
import React, { ReactNode, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

export function CmtPrint(props: {
  contentEditable?: boolean;
  children: (param: { ref: React.MutableRefObject<any> }) => ReactNode;
  trigger: (param: { show: () => void }) => ReactNode;
  width?: number | string;
  onClose?: () => void;
}) {
  const [shown, setShow] = useState(false);
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {props.trigger({
        show: () => {
          setShow(true);
        },
      })}

      <Drawer
        width={'100vw'}
        height={'100vh'}
        open={shown}
        title="打印预览（打印前可编辑，但变更不会被保存）"
        destroyOnClose={true}
        onClose={() => {
          setShow(false);
          props.onClose?.();
        }}
        extra={
          <Space>
            <Button type="primary" onClick={() => handlePrint()}>
              打印
            </Button>
          </Space>
        }
      >
        <div style={{ width: props.width || 720, margin: '0 auto' }}>
          {props.children({ ref: componentRef })}
        </div>
      </Drawer>
    </>
  );
}
