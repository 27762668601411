import useUrlState from '@ahooksjs/use-url-state';
import {
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
  CloseSquareFilled,
  EyeOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PauseOutlined,
  PlusOutlined,
  SmallDashOutlined,
  StopFilled,
  StopTwoTone,
  SyncOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useTitle, useAntdTable, useRequest, useSize } from 'ahooks';
import {
  Typography,
  Space,
  Input,
  Button,
  Select,
  Card,
  Form,
  Table,
  Drawer,
  Tooltip,
  Dropdown,
  Progress,
  message,
  Popover,
  Result,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import { ConvertDate, HumanizeDate } from '../../common/utils/convert-date';
import { BlankWrapper } from '../../components/BlankWrapper';
import { CmtTable } from '../../components/CmtTable';
import { Project, queryProjectApi } from '../../services/project.service';
import {
  getTaskStatusName,
  queryTaskApi,
  Task,
  TaskStatus,
  TaskStatusOptions,
} from '../../services/task.service';
import { EventList } from './event-list';
import { ConfirmButton } from '../../components/ConfirmButton/confirm-button';
import { stopTaskApi } from '../../services/action.service';
import { InfiniteSelect } from '../../components/InfiniteSelect';
import { PlanParamView } from '../../components/PlanParamView';
import { StopTaskButton } from './stop-task-button';
import { PauseTaskButton } from './pause-task-button';
import { ResumeTaskButton } from './resume-task-button';
import { Permission } from '../../components/Permission';
import { CmtTaskStatusIcon } from '../../components/CmtTaskStatusIcon';

const { Text } = Typography;

export function TaskPage() {
  useTitle('运行记录');
  const tableRef = useRef<any>(null);
  const [selectedOrder, setSelectedOrder] = useState<Task | null>(null);
  const taskStatusOptions = [
    { value: '', label: '全部' },
    ...TaskStatusOptions,
  ];

  const columns: ColumnsType<Task> = [
    {
      title: '#',
      width: 30,
      dataIndex: 'status',
      fixed: 'left',
      render(value) {
        return (
          <Space size={'small'}>
            <CmtTaskStatusIcon v={value} />
          </Space>
        );
      },
    },
    {
      title: '项目',
      sorter: true,
      dataIndex: 'projectName',
      width: 100,
    },
    {
      title: '计划名称',
      dataIndex: 'name',
      sorter: true,
      width: 300,
      fixed: 'left',
      render(value, taskItem) {
        const record = JSON.parse(taskItem.jobPayload || '{}');
        return (
          <Space>
            <Text> {value}</Text>
            {record.payload && (
              <Popover
                content={
                  <PlanParamView
                    actionId={record.actionId}
                    projectId={record.projectId}
                    payload={JSON.stringify(record.payload)}
                  />
                }
                title={`【${taskItem.name}】参数`}
                trigger="hover"
                placement="right"
              >
                <Button shape="round" size={'small'}>
                  参数
                </Button>
              </Popover>
            )}
          </Space>
        );
      },
    },

    {
      title: '描述信息',
      width: 300,
      dataIndex: 'message',
    },
    {
      title: '进度',
      dataIndex: 'progress',
      width: 140,
      render: (value: number, record) => {
        let status: any = '';
        switch (record.status) {
          case TaskStatus.Stopped:
            status = 'exception';
            break;
          case TaskStatus.Completed:
            status = 'success';
            break;
          default:
            status = 'active';
        }
        return (
          <div style={{ width: '90%' }}>
            <Progress percent={value} size="small" status={status} />
          </div>
        );
      },
    },

    {
      title: '耗时',
      dataIndex: 'elsp',
      width: 100,
      render(value, record) {
        return HumanizeDate(record.createdAt, record.updatedAt);
      },
    },
    {
      title: '创建日期',
      dataIndex: 'createdAt',
      sorter: true,
      width: 150,
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      width: 150,
      // sorter: (a, b) => {
      //   return  new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
      // },
      sorter: true,
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      width: 100,
      key: 'actions',
      fixed: 'right',
      render: (value, record, index) => {
        return (
          <div>
            <Button
              type="link"
              size={'small'}
              onClick={() => setSelectedOrder(record)}
            >
              <HistoryOutlined />
            </Button>
            <PauseTaskButton
              data={record}
              onSuccess={() => tableRef.current?.refresh?.()}
            />
            <ResumeTaskButton
              data={record}
              onSuccess={() => tableRef.current?.refresh?.()}
            />
            <StopTaskButton
              data={record}
              onSuccess={() => tableRef.current?.refresh?.()}
            />
          </div>
        );
      },
    },
  ];

  return (
    <BlankWrapper>
      <Permission
        resource="task"
        action="read"
        error={
          <Result
            status={'error'}
            title="您没有权限访问"
            subTitle={'请联系管理员调整权限'}
          ></Result>
        }
      >
        <CmtTable
          ref={tableRef}
          title="任务运行记录"
          description="系统正在运行的所有任务"
          rowKey="id"
          colums={columns}
          pollingInterval={5000}
          api={queryTaskApi}
          extends={({ formRef, tableSearch }) => {
            const { submit } = tableSearch;
            return (
              <Space>
                <Form form={formRef} layout={'inline'}>
                  <Form.Item hidden name="internal" initialValue={'false'}>
                    <Input></Input>
                  </Form.Item>
                  <Form.Item
                    name={'projectId'}
                    label={'项目'}
                    initialValue={''}
                  >
                    <InfiniteSelect
                      defaultOptions={[{ label: '全部', value: '' }]}
                      style={{ width: 120 }}
                      api={queryProjectApi}
                      onChange={submit}
                      option={(item: Project) => {
                        return { label: item.name, value: item.id };
                      }}
                    />
                  </Form.Item>
                  <Form.Item name={'status'} label={'状态'} initialValue={''}>
                    <Select
                      style={{ width: 120 }}
                      onChange={submit}
                      options={taskStatusOptions}
                    ></Select>
                  </Form.Item>
                  <Form.Item name={'name'} label={'任务'}>
                    <Input
                      style={{ width: 120 }}
                      placeholder="输入计划名称"
                      onChange={submit}
                      allowClear
                    ></Input>
                  </Form.Item>
                </Form>
                <Button type="default" onClick={submit}>
                  <SyncOutlined /> 刷新
                </Button>
              </Space>
            );
          }}
        ></CmtTable>
        <Drawer
          width={'50%'}
          bodyStyle={{ padding: 0, width: '100%' }}
          open={!!selectedOrder}
          title={
            <div>
              任务事件详情（TaskId:
              <Typography.Text copyable>{selectedOrder?.id}</Typography.Text>）
            </div>
          }
          onClose={() => setSelectedOrder(null)}
          destroyOnClose={true}
        >
          <EventList taskId={selectedOrder?.id || ''} pollingInterval={5000} />
        </Drawer>
      </Permission>
    </BlankWrapper>
  );
}
