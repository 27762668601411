import { useRequest } from 'ahooks';
import { Col, message, Row, Select, Space, Spin, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import exp from 'constants';
import { useEffect, useState } from 'react';
import {
  getCreationBrands,
  getCreationCategories,
  getCreationCodes,
  getCreationVersions,
} from '../../../../../services/gpo/goodmarket.service';

interface Option extends DefaultOptionType {
  value: string;
  label: string;
}

export interface CreationCategoryCascaderOutputDto {
  goodsClassGuid: string;
  goodsBrandGuid: string;
  goodsClassName: string;
  goodsBrandName: string;
  code: string;
  spuGuid: string;
}

export function CreationCategoryCascader(props: {
  type: number;
  onChange?: (val: CreationCategoryCascaderOutputDto) => void;
  span?: number;
}) {
  const span = props.span || 6;
  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState<string>('');
  const [selectedBrandOption, setSelectedBrandOption] = useState<string>('');
  const [selectedCodeOption, setSelectedCodeOption] = useState<string>('');
  const [selectedVersionOption, setSelectedVersionOption] =
    useState<string>('');
  const [selectedSpuGuidOption, setSelectedSpuGuidOption] =
    useState<string>('');
  const [selectedGoodsClassNameOption, setSelectedGoodsClassNameOption] =
    useState<string>('');
  const [selectedGoodsBrandNameOption, setSelectedGoodsBrandNameOption] =
    useState<string>('');

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
  const [brandOptions, setBrandOptions] = useState<Option[]>([]);
  const [codeOptions, setCodeOptions] = useState<Option[]>([]);
  const [versionOptions, setVersionOptions] = useState<Option[]>([]);
  const { loading: loadingCategory, run: loadCategory } = useRequest(
    getCreationCategories,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: (res) => {
        const categores: Option[] = res.map((x) => {
          return {
            value: x.goodsClassGuid,
            label: x.goodsClassName,
          };
        });
        setCategoryOptions(categores);
      },
      onError(e) {
        message.error(e.message);
      },
    }
  );
  const { loading: loadingBrand, run: loadBrands } = useRequest(
    getCreationBrands,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(brands) {
        const items = brands.map((x) => {
          return {
            label: x.goodsBrandName,
            value: x.goodsBrandGuid,
          };
        });
        setBrandOptions(items);
      },
      onError(e) {
        message.error(e.message);
      },
    }
  );

  const { loading: loadingCode, run: loadCodes } = useRequest(
    getCreationCodes,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(codes) {
        const items = codes.map((x) => {
          return {
            label: x,
            value: x,
          };
        });
        setCodeOptions(items);
      },
      onError(e) {
        message.error(e.message);
      },
    }
  );

  const { loading: loadingVersions, run: loadVersion } = useRequest(
    getCreationVersions,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(vers) {
        const items = vers.map((x) => {
          return {
            label: `${x.version}(${x.spuGuid})`,
            value: x.version,
            spuGuid: x.spuGuid,
          };
        });
        setVersionOptions(items);
      },
      onError(e) {
        message.error(e.message);
      },
    }
  );

  useEffect(() => {
    if (props.type) {
      loadCategory({ type: props.type });
    }
  }, [props.type]);

  useEffect(() => {
    props.onChange?.({
      goodsClassGuid: selectedCategoryOption,
      goodsBrandGuid: selectedBrandOption,
      goodsClassName: selectedGoodsClassNameOption,
      goodsBrandName: selectedGoodsBrandNameOption,
      code: selectedCodeOption,
      spuGuid: selectedSpuGuidOption,
    });
  }, [
    selectedCategoryOption,
    selectedBrandOption,
    selectedCodeOption,
    selectedVersionOption,
  ]);

  const filterOption = (input: any, option: any) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };
  return (
    <Spin
      size="small"
      spinning={
        loadingCategory || loadingBrand || loadingCode || loadingVersions
      }
    >
      {' '}
      <Row gutter={6}>
        <Col span={span}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Typography.Text>品目</Typography.Text>
            <Select
              style={{ width: '100%' }}
              options={categoryOptions}
              loading={loadingCategory}
              showSearch
              value={selectedCategoryOption}
              filterOption={filterOption}
              onChange={(v: string, opt: any) => {
                setSelectedCategoryOption(v);
                setSelectedGoodsClassNameOption(opt.label);
                setSelectedBrandOption('');
                setSelectedGoodsBrandNameOption('');
                setSelectedCodeOption('');
                setSelectedVersionOption('');
                setSelectedSpuGuidOption('');
                setBrandOptions([]);
                setCodeOptions([]);
                setVersionOptions([]);
                loadBrands({ type: props.type, goodsClassGuid: v });
              }}
              defaultActiveFirstOption={true}
              placeholder="品目"
            ></Select>
          </Space>
        </Col>
        <Col span={span}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Typography.Text>品牌</Typography.Text>
            <Select
              style={{ width: '100%' }}
              options={brandOptions}
              loading={loadingBrand}
              showSearch
              disabled={!selectedCategoryOption}
              value={selectedBrandOption}
              filterOption={filterOption}
              onChange={(v: string, opt: any) => {
                setSelectedBrandOption(v);
                setSelectedGoodsBrandNameOption(opt.label);
                setSelectedCodeOption('');
                setSelectedVersionOption('');
                setSelectedSpuGuidOption('');
                setCodeOptions([]);
                setVersionOptions([]);
                loadCodes({
                  goodsClassGuid: selectedCategoryOption,
                  goodsBrandGuid: v,
                });
              }}
              placeholder="品牌"
            ></Select>
          </Space>
        </Col>
        <Col span={span}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Typography.Text>型号</Typography.Text>
            <Select
              style={{ width: '100%' }}
              options={codeOptions}
              loading={loadingCode}
              showSearch
              disabled={!selectedBrandOption}
              value={selectedCodeOption}
              filterOption={filterOption}
              onChange={(v: string) => {
                setSelectedCodeOption(v);
                setSelectedVersionOption('');
                setSelectedSpuGuidOption('');
                setVersionOptions([]);
                loadVersion({
                  goodsClassGuid: selectedCategoryOption,
                  goodsBrandGuid: selectedBrandOption,
                  goodsModal: v,
                });
              }}
              placeholder="型号"
              allowClear
            ></Select>
          </Space>
        </Col>
        <Col span={span}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Typography.Text>版本</Typography.Text>
            <Select
              style={{ width: '100%' }}
              options={versionOptions}
              loading={loadingVersions}
              showSearch
              disabled={!selectedCodeOption}
              value={selectedVersionOption}
              filterOption={filterOption}
              onChange={(v: string, option: any) => {
                setSelectedVersionOption(v);
                setSelectedSpuGuidOption(option.spuGuid || '');
              }}
              placeholder="版本"
              allowClear
            ></Select>
          </Space>
        </Col>
      </Row>
    </Spin>
  );
}
