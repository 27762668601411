import { CloseCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message } from 'antd';
import { ConfirmButton } from '../../components/ConfirmButton/confirm-button';
import { pauseTaskApi, stopTaskApi } from '../../services/action.service';
import { Task, TaskStatus } from '../../services/task.service';

export function PauseTaskButton(props: { data: Task; onSuccess?: () => void }) {
  const record = props.data;
  const [messageApi, contextHolder] = message.useMessage();
  const loadingMessageKey = 'pause-message-key';
  const { run, loading } = useRequest(pauseTaskApi, {
    manual: true,
    debounceWait: 200,
    onBefore: () => {
      messageApi.open({
        type: 'loading',
        key: loadingMessageKey,
        content: '正在暂停任务，请稍后。。。',
        duration: 0,
      });
    },
    onSuccess: (res) => {
      messageApi.open({
        type: 'success',
        key: loadingMessageKey,
        content: '任务已暂停',
        duration: 2,
      });
      props.onSuccess?.();
    },
    onError: (err) => {
      messageApi.open({
        type: 'error',
        key: loadingMessageKey,
        content: '任务暂停失败',
        duration: 2,
      });
    },
    onFinally: () => {
      // messageApi.destroy(loadingMessageKey);
    },
  });
  return record.status === TaskStatus.InProgress ? (
    <>
      {contextHolder}
      <ConfirmButton
        title={'暂停任务'}
        content={`确认暂停执行任务【${record.name}】吗? 暂停后正在执行的任务将继续执行，直至结束，未开始任务将暂停`}
        onOk={() => run({ taskId: record.id, projectId: record.projectId })}
      >
        {({ trigger }) => (
          <Button type="link" size="small" disabled={loading} onClick={trigger}>
            <PauseCircleOutlined />
          </Button>
        )}
      </ConfirmButton>
    </>
  ) : null;
}
