import { SendOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { Space, Button, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../components/CmtPopConfirmAction';
import {
  executeContractActionApi,
  GPOContractAuditStatus,
  GPOContractDto,
} from '../../../services/gpo/contract.service';

const actionDefs = [
  {
    name: '发送',
    action: 'send',
    icon: <SendOutlined />,
    enable: [GPOContractAuditStatus.Draft],
  },
  {
    name: '删除',
    action: 'delete',
    icon: <DeleteOutlined />,
    enable: [GPOContractAuditStatus.Draft],
  },
  {
    name: '撤销',
    action: 'revoke',
    icon: <UndoOutlined />,
    enable: [GPOContractAuditStatus.Sent],
  },
];

function ContractAction(props: {
  data: GPOContractDto;
  action: 'send' | 'delete' | 'revoke';
  onSuccess?: () => void;
}) {
  const act = actionDefs.find((x) => x.action === props.action);
  if (!act?.enable?.includes(props.data.auditStatus)) {
    return <></>;
  }
  return (
    <CmtPopConfirmAction
      service={executeContractActionApi}
      successMessage={`${act.name}成功`}
      placement={'top'}
      title={
        <Space direction="vertical">
          <Typography.Text strong>{`确定${act?.name}合同吗？`}</Typography.Text>
        </Space>
      }
      onOk={({ data, run }) => {
        run({ contractGuid: data.contractGuid, action: props.action });
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="default"
          shape="round"
          disabled={loading || !props.data}
          loading={loading}
          style={{ marginRight: 8 }}
          htmlType={'button'}
        >
          {act?.icon} {act?.name}
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}

export function ContractActions(props: {
  data: GPOContractDto;
  onSuccess?: () => void;
}) {
  return (
    <div>
      <ContractAction
        data={props.data}
        action="send"
        onSuccess={props.onSuccess}
      />
      <ContractAction
        data={props.data}
        action="delete"
        onSuccess={props.onSuccess}
      />
      <ContractAction
        data={props.data}
        action="revoke"
        onSuccess={props.onSuccess}
      />
    </div>
  );
}
