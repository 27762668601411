import {
  BranchesOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Empty,
  InputNumber,
  message,
  Popover,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  GpoCategory,
  GpoCategoryBrandTrottle,
  updateGpoCategoryAutomationSettings,
} from '../../../../services/gpo/category.service';
import { useDynamicList, useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { GoodsBrandSelect } from '../../../components/GoodsBrandSelect';
import { GPOAgreementTypes } from '../../../../common/dtos/AddGoodTypes';

export function CateogryBrandTrottlesForm(props: {
  category: GpoCategory;
  projectId: string;
  agreementType: GPOAgreementTypes;
  onSuccess?: (val: GpoCategoryBrandTrottle[]) => void;
}) {
  const { loading, run: runUpdate } = useRequest(
    updateGpoCategoryAutomationSettings,
    {
      debounceWait: 200,
      manual: true,
      onSuccess() {
        props.onSuccess?.(list);
        message.success('配置成功');
      },
      onError(e, params) {
        message.error('设置失败：' + e.message);
      },
    }
  );

  const { list, remove, getKey, insert, replace, resetList } =
    useDynamicList<GpoCategoryBrandTrottle>(
      JSON.parse(props.category?.brandTrottles || '[]')
    );
  const [selected, setSelected] = useState<{
    goodsBrandName: string;
    goodsBrandGuid: string;
  }>();
  useEffect(() => {
    if (props.category) {
      resetList(JSON.parse(props.category?.brandTrottles || '[]'));
    }
  }, [props.category]);
  return (
    <Spin spinning={loading}>
      <Space style={{ width: '100%' }} direction="vertical">
        <Typography.Text></Typography.Text>
        <Row key={'head'} gutter={12} style={{ width: '100%' }}>
          <Col span={22}>
            <GoodsBrandSelect
              style={{ width: '100%' }}
              queryParams={{
                agreementType: props.agreementType.toString(),
                projectId: props.projectId,
                goodsClassGuid: props.category?.platformId,
              }}
              allowClear
              onChangeWithRaw={setSelected}
              value={selected?.goodsBrandGuid}
            />
          </Col>
          <Col span={2}>
            <Button
              disabled={!selected}
              htmlType="button"
              onClick={() => {
                insert(list.length + 1, {
                  goodsBrandGuid: selected?.goodsBrandGuid || '',
                  goodsBrandName: selected?.goodsBrandName || '',
                  goodsClassGuid: props.category.platformId,
                  goodsClassName: props.category.platformName,
                  throttle: 0,
                });
                setSelected(undefined);
              }}
            >
              <PlusOutlined />
            </Button>
          </Col>
        </Row>
        <Divider />
        <Typography.Text type="secondary">
          已添加品牌（比例设置为0关闭调价）
        </Typography.Text>
        {list.map((x, index) => {
          return (
            <Row key={getKey(index)} gutter={12} style={{ width: '100%' }}>
              <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography.Text>{x.goodsBrandName}</Typography.Text>
              </Col>
              <Col span={10}>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="折扣"
                  addonAfter="%"
                  min={0}
                  max={100}
                  value={x.throttle}
                  onChange={(v) =>
                    v !== null && replace(index, { ...x, throttle: v })
                  }
                />
              </Col>
              <Col span={2}>
                <Button
                  type={'default'}
                  htmlType="button"
                  onClick={() => remove(index)}
                >
                  <MinusOutlined />
                </Button>
              </Col>
            </Row>
          );
        })}
        {!list.length && <Empty />}
        <Button
          type="primary"
          htmlType="button"
          onClick={() => {
            runUpdate(props.category.id, {
              brandTrottles: JSON.stringify(list || []),
            });
          }}
        >
          保存
        </Button>
      </Space>
    </Spin>
  );
}

export function CateogryBrandTrottlesList(props: { category: GpoCategory }) {
  const [val, setVal] = useState<GpoCategoryBrandTrottle[]>([]);

  useEffect(() => {
    setVal(JSON.parse(props.category.brandTrottles || '[]'));
  }, [props.category]);
  return (
    <div>
      {val.map((x, i) => {
        return (
          <Row key={i} justify={'space-between'}>
            <Col>{x.goodsBrandName}</Col>
            <Col>{x.throttle}%</Col>
          </Row>
        );
      })}
      {!val.length && <Empty></Empty>}
    </div>
  );
}

export function CateogryBrandTrottlesSettings(props: {
  category: GpoCategory;
  projectId: string;
  agreementType: GPOAgreementTypes;
  onSuccess?: (val: string) => void;
}) {
  const [val, setVal] = useState<GpoCategoryBrandTrottle[]>([]);
  useEffect(() => {
    if (props.category.brandTrottles) {
      setVal(JSON.parse(props.category.brandTrottles));
    }
  }, [props.category]);
  return (
    <CmtModalButton
      title={`【${props.category.platformName}】品牌调价比例设置`}
      trigger={({ show }) => {
        return (
          <Popover
            content={
              <CateogryBrandTrottlesList
                category={props.category}
              ></CateogryBrandTrottlesList>
            }
            title="品牌调价比例设置"
          >
            <Button type="text" size="small" onClick={show} htmlType="button">
              <BranchesOutlined />
            </Button>{' '}
          </Popover>
        );
      }}
      width={500}
    >
      {({ hide }) => {
        return (
          <CateogryBrandTrottlesForm
            onSuccess={(val) => {
              props.onSuccess?.(JSON.stringify(val || []));
              hide();
            }}
            agreementType={props.agreementType}
            projectId={props.projectId}
            category={props.category}
          />
        );
      }}
    </CmtModalButton>
  );
}
