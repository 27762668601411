import { SyncOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Button, Col, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { ConvertDate } from '../../common/utils/convert-date';
import { EventList } from '../../pages/tasks/event-list';
import { ResumeTaskButton } from '../../pages/tasks/resume-task-button';
import { queryMyTaskApi, Task } from '../../services/task.service';
import { CmtModalButton } from '../CmtModalButton';
import { CmtTaskStatusIcon } from '../CmtTaskStatusIcon';

export function SimpleInternalTaskList() {
  const [firstLoad, setFirstLoad] = useState(false);
  const { tableProps, search, refresh, data } = useAntdTable(queryMyTaskApi, {
    debounceWait: 300,
    pollingInterval: 5000,
    onSuccess() {
      setFirstLoad(true);
    },
  });
  const columns: ColumnsType<Task> = [
    {
      dataIndex: 'id',
      render(value, record, index) {
        return (
          <Space direction="vertical" size={'small'} style={{ width: '100%' }}>
            <Space>
              <CmtTaskStatusIcon v={record.status} />
              <Typography.Text>{record.name}</Typography.Text>
            </Space>
            <Row justify={'space-between'}>
              <Col>
                <Space>
                  <Typography.Text type="secondary">
                    {ConvertDate(record.createdAt, 'YYYY-MM-DD HH:mm:ss')}
                  </Typography.Text>
                  <Typography.Text>{record.message}</Typography.Text>
                </Space>
              </Col>
              <Col>
                <ResumeTaskButton data={record} onSuccess={refresh} />
                <CmtModalButton
                  maskClosable={true}
                  title="执行事件"
                  trigger={({ show }) => (
                    <Button type="text" onClick={show}>
                      <Typography.Text type="secondary">
                        查看事件
                      </Typography.Text>
                    </Button>
                  )}
                >
                  {() => (
                    <div style={{ height: '50vh', minHeight: 400 }}>
                      <EventList
                        containerPaddingSize={1}
                        taskId={record.id}
                        pollingInterval={5000}
                      ></EventList>
                    </div>
                  )}
                </CmtModalButton>
              </Col>
            </Row>
          </Space>
        );
      },
    },
  ];

  return (
    <Space style={{ width: 380 }} direction="vertical">
      <Row justify="space-between">
        <Col span={12}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            任务执行记录
          </Typography.Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button type="text" onClick={refresh}>
            <SyncOutlined />
          </Button>
        </Col>
      </Row>

      <Table
        size="small"
        columns={columns}
        bordered={false}
        {...tableProps}
        loading={tableProps.loading && !firstLoad}
      ></Table>
    </Space>
  );
}
