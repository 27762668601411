import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';
import { UserDto } from './user.service';

export async function getOrganization(): Promise<any> {
  const res: any = await httpClient.get(`/organizations/mine`);
  return res;
}

export async function updateOrganization(params: {
  name: string;
  force2fa: boolean;
}): Promise<any> {
  const res: any = await httpClient.patch(`/organizations/mine`, params);
  return res;
}

export interface AuditLog {
  id: string;
  organizationId: string;
  projectId: string;
  projectName: string;
  userId: string;
  userName: string;
  resource: string;
  action: string;
  remark: string;
  createdAt: Date;
  resourceName: string;
  actionName: string;
}

export async function queryAuditLogsApi(
  params: PagingRequest
): Promise<PagingResponse<AuditLog>> {
  const res: PagingResponse<AuditLog> = await httpClient.get('/audit-logs', {
    params,
  });
  return res;
}

export interface AuditLogResource {
  id: string;
  name: string;
}

export interface AuditLogAction {
  id: string;
  action: string;
  resourceId: string;
  name: string;
}

export async function queryAuditLogResourceApi(
  params: PagingRequest
): Promise<PagingResponse<AuditLogResource>> {
  const res: PagingResponse<AuditLogResource> = await httpClient.get(
    '/audit-logs/resources'
  );
  return res;
}

export async function queryAuditLogActionsApi(
  params: PagingRequest
): Promise<PagingResponse<AuditLogResource>> {
  const res: PagingResponse<AuditLogResource> = await httpClient.get(
    '/audit-logs/actions',
    { params }
  );
  return res;
}
