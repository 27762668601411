/**
 * 商品协议类型
 */
export enum GPOAgreementTypes {
  /**
   * 集采商城
   */
  JiCaiMall = 3,
  /**
   * 货物超市
   */
  GoodMarket = 4,
}

/**
 * 商品模板商品的定义
 */
interface GPOGoodFormGoodDto {
  spuGuid: string;
  goodsClassGuid: string;
  goodsClassName: string;
  goodsBrandGuid: string;
  goodsBrandName: string;
  name: string;
  code: string;
  marketPrice: number;
  salesPrice: number;
  goodsSource: GPOAgreementTypes;
  picturePath: string;
  goodsContent: string;
  pictureList: GPOGoodFormPitureDto[];
  [key: string]: any;
}

export enum GPOMainPicutreTypes {
  Yes = 1,
  No = 0,
}
/**
 * 商品模板图片定义
 */
export interface GPOGoodFormPitureDto {
  pictureGuid: string;
  businessGuid: string;
  businessName: string;
  compression: string | null;
  mainPct: GPOMainPicutreTypes; //是否是主图
  fileName: string | null;
  suffix: string | null;
  filePath: string;
  createDate: string;
  createUserGuid: string;
  createUser: string;
  pctcontent: string | null;
  [key: string]: any;
}

/**
 * 服务承诺定义
 */
interface GPOGoodFormPromiseDto {
  promiseGuid: string;
  agentGuid: string;
  promiseName: string;
  promiseText: string;
  promiseLogoPath: string;
  promiseType: number;
  isFile: number;
  sort: number;
  createDate: string;
  createUserGuid: string;
  modiDate: string;
  modiUserGuid: string;
  isDefault: number;
  isRemark: number | null;
  goodsGuid: string | null;
  checked: boolean;
  promiseFilePath: string | null;
  goodsClassGuidList: string | null;
  regionGuid: string | null;
  [key: string]: any;
}

export enum GPOGoodPriceSoureTypes {
  OnlineShop = 1,
  Contract = 2,
}

/**
 * 商品的价格来源定义
 */
interface GPOGoodPriceSoureDto {
  goodsPriceSourceOptionGuid: string;
  goodsClassGuid: string;
  content: string;
  type: GPOGoodPriceSoureTypes;
  sort: number;
  createUserGuid: string;
  createUser: string;
  createDate: string;
  modiUserGuid: string | null;
  modiUser: string | null;
  modiDate: string | null;
  [key: string]: any;
}

/**
 * 商品分类参数可选项定义
 */
interface GPOGoodClassParamOptionDto {
  paramOptionGuid: string;
  goodsClassParamGuid: string;
  optionValue: string;
  optionName: string;
  interfaceCode: string;
  score: string | null;
  paramLevel: string | null;
  [key: string]: any;
}

/**
 * 商品分类参数定义
 */
export interface GPOGoodClassParamDto {
  goodsClassParamGuid: string;
  goodsClassGuid: string;
  paramTypeGuid: string;
  paramTypeName: string | null;
  paramName: string;
  sort: number;
  paramType: number;
  canChange: number;
  needInput: number;
  useSearch: number;
  show: number;
  paramDesc: string | null;
  createUserGuid: string;
  createUser: string;
  createDate: string;
  modiUserGuid: string | null;
  modiUser: string | null;
  modiDate: string | null;
  remark: string | null;
  isMain: number;
  interfaceCode: string;
  isPu: number;
  interfaceName: string;
  isCompare: string | null;
  compare: string | null;
  paramLevel: string | null;
  paramValue: string | null;
  goodsClassParamType: string | null;
  goodsClassParamTypeVOList: string | null;
  goodsParamValue: string | null;
  paramOptionList: GPOGoodClassParamOptionDto[] | null;
  classification: number;
  [key: string]: any;
}

/**
 * 商品分类参数组定义
 */
export interface GPOGoodClassParamGroupDto {
  paramTypeGuid: string;
  goodsClassGuid: string;
  paramTypeName: string;
  isBasic: number;
  sort: number;
  createUserGuid: string;
  createUser: string;
  createDate: string;
  modiUserGuid: string | null;
  modiUser: string | null;
  modiDate: string | null;
  remark: string | null;
  isInport: number;
  interfaceCode: string;
  paramLevel: string | null;
  goodsParamValueList: string | null;
  goodsClassParamList: GPOGoodClassParamDto[];
  [key: string]: any;
}

export interface PictureListItem {
  filePath: string;
  mainPct: 1 | 0;
}

/**
 * 商品模板DTO
 */
export interface GPOGoodFormTemplateDto {
  /**
   * 商品定义
   */
  goods: GPOGoodFormGoodDto;
  // disabled: boolean;
  goodsPromiseOneList: GPOGoodFormPromiseDto[];
  /**
   * 服务承诺
   */
  goodsPromiseTwoList: GPOGoodFormPromiseDto[];
  /**
   * 商品分类参数分组
   */
  goodsClassParamTypeList: GPOGoodClassParamGroupDto[];
  //   serviceList: any[] | null;
  //   partList: any[] | null;
  /**
   * 价格来源列表
   */
  goodsPriceSourceOptionEntityList: GPOGoodPriceSoureDto[];
  //   enableExternalPriceProofFileAudit: number;
  //   enableGoodsSupplyProof: number;
  //   goodsClassDescription: string | null;
  //   defaultValueFlag: GPOBooleanString;
  //   automaticReviewFlag: boolean;
  [key: string]: any;
}
