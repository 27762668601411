import { useBoolean } from 'ahooks';
import { useContext, useState, useEffect, useMemo } from 'react';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';

export function checkFeatureToggle(
  targetGroup: string,
  feature: string,
  userMetaData?: { k: string; v: string }[]
) {
  const flags: { [k: string]: any } = JSON.parse(
    userMetaData?.find((x) => x.k === JwtMetaItemKeys.flags)?.v || '{}'
  );
  if (!targetGroup || !feature || !flags) {
    return false;
  }
  const groups = Object.keys(flags);
  if (groups.length && groups.includes(targetGroup)) {
    const features = flags[targetGroup];
    if (features.includes('*') || features.includes(feature)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function useFeatureToggle(group: string, feature: string) {
  const [globalContext] = useContext(GlobalContext);
  const [hasFeature, { set }] = useBoolean(false);
  useMemo(() => {
    const res = checkFeatureToggle(group, feature, globalContext.userMetaData);
    set(res);
  }, [
    globalContext.userMetaData?.find((x) => x.k === JwtMetaItemKeys.mobile)?.v,
  ]);
  console.log(group, feature, hasFeature);
  return hasFeature;
}

export function useFeatureValue<T>(group: string) {
  const [globalContext] = useContext(GlobalContext);
  const [featureValue, setFeatureValue] = useState<T | undefined>();
  useMemo(() => {
    const flags: { [k: string]: any } = JSON.parse(
      globalContext.userMetaData?.find((x) => x.k === JwtMetaItemKeys.flags)
        ?.v || '{}'
    );
    if (!flags) {
      return;
    }
    const groups = Object.keys(flags);
    if (groups.length && groups.includes(group)) {
      const features = flags[group];
      setFeatureValue(features);
    }
  }, [
    globalContext.userMetaData?.find((x) => x.k === JwtMetaItemKeys.mobile)?.v,
  ]);
  return featureValue;
}
