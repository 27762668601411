import httpClient from '../http-client';

export interface GPOSupplierCompanyInfo {
  supplierBasicInfoVO: {
    email: string;
    fax: string;
    linkMan: string;
    linkMobile: string;
    registeredAddress: string;
    supplierName: string;
    [key: string]: any;
  };
  supplierCompanySaleInfoVO: {
    afterConsultLine: string;
    beforeConsultLine: string;
    businessLogo: string;
    companyLogo: string;
    qqNumber: string;
    [key: string]: any;
  };
}

export async function getAuthTokenOwner(projectId: string): Promise<{
  orgId: string;
  orgName: string;
  userId: string;
  loginName: string;
  username: string;
}> {
  const res: any = await httpClient.get('/gpo/utils/auth-token-owner', {
    params: { projectId },
  });
  return res;
}

export async function getSupplierCompanyInfo(): Promise<GPOSupplierCompanyInfo> {
  const res: any = await httpClient.get('/gpo/utils/supplier-company-info');
  return res;
}
