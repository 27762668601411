import { useRequest } from 'ahooks';
import { Card, Space, Spin, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../context';
import {
  Project,
  ProjectPlatforms,
  queryProjectApi,
} from '../../../services/project.service';
import { GpoOrderSummaryTable } from './GpoOrderSummaryTable';
import styles from './index.module.less';

export function GpoOrderSummary() {
  const [globalContext] = useContext(GlobalContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [contentList, setContentList] = useState<
    {
      key: string;
      label: string;
      children: React.ReactNode;
    }[]
  >([
    {
      key: '0',
      label: '加载中',
      children: <Spin></Spin>,
    },
  ]);
  const [selectProject, setSelectProject] = useState<Project>();
  const { loading: loadingProjects, run: loadProject } = useRequest(
    queryProjectApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        setProjects(data.list);
        const content: {
          key: string;
          label: string;
          children: React.ReactNode;
        }[] = [];
        data.list.map((x, i) => {
          content.push({
            key: x.id,
            label: x.name,
            children: <GpoOrderSummaryTable key={x.id} project={x} />,
          });
        });
        setContentList(content);
        setSelectProject(data.list?.[0]);
      },
    }
  );

  useEffect(() => {
    if (globalContext.userMetaData) {
      loadProject({
        pageSize: 100,
        current: 1,
        platform: ProjectPlatforms.GPO,
      });
    }
  }, [globalContext.userMetaData]);
  return (
    <Card className={styles['wrapper']} loading={loadingProjects}>
      <Tabs items={contentList}></Tabs>
    </Card>
  );
}
