import { CheckOutlined, CarOutlined } from '@ant-design/icons';
import { Space, Typography, Button } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  executeOrderActionApi,
  GPOOrderDto,
  GPOOrderStatus,
} from '../../../../services/gpo/order.service';

const actionDefs = [
  {
    name: '确认订单',
    action: 'supplierConfirmOrder',
    icon: <CheckOutlined />,
    enable: [GPOOrderStatus.ConfirmPending],
  },
  {
    name: '发货',
    action: 'supplierDeliverGoodsOrder',
    icon: <CarOutlined />,
    enable: [GPOOrderStatus.Confirmed],
  },
];

export function OrderActionButton(props: {
  data: GPOOrderDto;
  action: 'supplierConfirmOrder' | 'supplierDeliverGoodsOrder';
  onSuccess?: () => void;
}) {
  const [messageApi, context] = useMessage();
  const act = actionDefs.find((x) => x.action === props.action);
  if (!act?.enable?.includes(props.data.auditStatus)) {
    return <></>;
  }
  return (
    <CmtPopConfirmAction
      service={executeOrderActionApi}
      successMessage={`${act.name}成功`}
      placement={'top'}
      title={
        <Space direction="vertical">
          <Typography.Text strong>{`确定${act?.name}吗？`}</Typography.Text>
        </Space>
      }
      onOk={({ data, run }) => {
        // 发货必须有物流信息
        if (
          props.action === 'supplierDeliverGoodsOrder' &&
          props.data.auditStatus === GPOOrderStatus.Confirmed
        ) {
          if (
            !props.data.logisticsGuid &&
            !props.data?.orderShippingInfo?.length
          ) {
            messageApi.error('请先添加物流');
            return;
          }
        }
        run({ orderGuid: data.orderGuid, action: props.action });
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="default"
          shape="round"
          disabled={loading || !props.data}
          loading={loading}
          style={{ marginRight: 8 }}
          htmlType={'button'}
        >
          {context}
          {act?.icon} {act?.name}
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
