import { FormOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  getJiCaiProductLiveDetails,
  GpoJiCaiOnSaleProductDto,
  GpoJiCaiOnSaleProductStatus,
  updateJiCaiProductLivePriceAndPromise,
} from '../../../../services/gpo/jicai.service';

function ChangeJiCaiProductForm(props: {
  data: GpoJiCaiOnSaleProductDto;
  onSuccess?: () => void;
}) {
  const [form] = useForm();
  const [promiseOptions, setPromiseOptions] = useState<any[]>([]);
  const { data } = props;
  const {
    data: details,
    loading,
    run: loadDetails,
  } = useRequest(getJiCaiProductLiveDetails, {
    manual: true,
    debounceWait: 300,
    onSuccess: ({ promiseTwoList, goodsQuotePrice }) => {
      const opts = promiseTwoList?.map((x: any) => {
        return { label: x.promiseName, value: x.promiseGuid };
      });
      setPromiseOptions(opts);
      const checkedPromise = promiseTwoList
        .filter((x: any) => x.isChecked)
        .map((x: any) => x.promiseGuid).join('');

      form.setFieldsValue({
        quotePrice: goodsQuotePrice.quotePrice,
        promiseGuids: checkedPromise,
      });
    },
    onError: (err) => {
      message.error(`获取数据失败：` + err.message);
    },
  });

  const { loading: updating, run: updatePriceApi } = useRequest(
    updateJiCaiProductLivePriceAndPromise,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: () => {
        message.success(`提交价格成功`);
        props.onSuccess?.();
      },
      onError: (err) => {
        message.error(`提交价格失败：` + err.message);
      },
    }
  );
  useEffect(() => {
    loadDetails(data);
  }, []);

  return (
    <Spin spinning={loading}>
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Descriptions title={<p></p>} column={2} bordered>
          <Descriptions.Item label="商品编号">
            {details?.goodsCode}
          </Descriptions.Item>
          <Descriptions.Item label="商品名称">
            {details?.name}
          </Descriptions.Item>
          <Descriptions.Item label="商品型号">
            {details?.goods?.code}
          </Descriptions.Item>
          <Descriptions.Item label="品目">
            {details?.goodsClassName}
          </Descriptions.Item>
          <Descriptions.Item label="品牌">
            {details?.goodsBrandName}
          </Descriptions.Item>
          <Descriptions.Item label="协议价（元）">
            {ConvertNumberToCurrency(details?.currentPrice)}
          </Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <Form
          form={form}
          onFinish={() => {
            updatePriceApi(props.data, form.getFieldsValue());
          }}
        >
          <FormItem
            label="我的报价"
            name={'quotePrice'}
            required
            rules={[{ required: true }]}
          >
            <InputNumber min={1} />
          </FormItem>
          {promiseOptions.length ? (
            <FormItem
              label="服务承诺"
              name={'promiseGuids'}
              required
              rules={[{ required: true }]}
            >
              <Radio.Group options={promiseOptions}/>
            </FormItem>
          ) : (
            <></>
          )}
          <FormItem>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={updating}
                disabled={updating}
              >
                提交价格
              </Button>
              <Button type="text" onClick={props.onSuccess}>
                取消
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Space>
    </Spin>
  );
}

export function ChangePriceButton(props: {
  data: GpoJiCaiOnSaleProductDto;
  onSuccess?: () => void;
}) {
  if (props.data.valid === GpoJiCaiOnSaleProductStatus.Published) {
    return null;
  }
  return (
    <CmtModalButton
      title="修改价格"
      trigger={({ show }) => {
        return (
          <Tooltip title={'修改价格'}>
            <Button type="text" size="small" onClick={show}>
              <FormOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeJiCaiProductForm
            data={props.data}
            onSuccess={() => {
              hide();
              props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
