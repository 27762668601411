import { ApartmentOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { message, Row, Col, Space, Card, Alert, Button } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSingleProjectApi,
  deleteSingleProjectApi,
} from '../../../services/project.service';
import { BlankWrapper } from '../../BlankWrapper';
import { CmtPopConfirmAction } from '../../CmtPopConfirmAction';
import { ProjectForm } from '../project-form';
import { DingDingSettingForm } from './dingding-setting-form';
import { MetabaseSettingForm } from './metabase-setting-form';

export function ProjectGeneralSettings() {
  const params = useParams();

  const { data, loading, run } = useRequest(getSingleProjectApi, {
    manual: true,
    onSuccess(data, params) {},
    onError(e) {
      message.error(e.message);
    },
  });

  useEffect(() => {
    run(params.projectId || '');
  }, []);

  return (
    <BlankWrapper
      breadcrumb={[
        { name: '', icon: <ApartmentOutlined /> },
        { name: '项目设置' },
      ]}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          paddingBottom: 30,
        }}
      >
        <Row justify="center">
          <Col>
            <Space direction="vertical" style={{ width: 800 }}>
              {/* <Card title="项目设置">
                <ProjectForm
                  project={data}
                  onSuccess={() => {
                    window.history.go(-1);
                  }}
                />
              </Card> */}
              <Card title="通知设置">
                <DingDingSettingForm project={data} />
              </Card>
              {/* <Card title="报表设置">
                <MetabaseSettingForm project={data} />
              </Card> */}
              {/* <Card title="危险区域">
                <Alert
                  message="删除项目"
                  description="删除项目之后，将无法恢复数据，所有周期任务将会被停止。"
                  type="warning"
                  showIcon
                  action={
                    <CmtPopConfirmAction
                      data={params.projectId}
                      service={deleteSingleProjectApi}
                      onOk={function ({ run, data }): void {
                        run(data);
                      }}
                      title={'确认删除项目吗？'}
                      onSuccess={() => {
                        window.history.go(-1);
                      }}
                    >
                      {({ loading }) => (
                        <Button type="primary" danger loading={loading}>
                          删除
                        </Button>
                      )}
                    </CmtPopConfirmAction>
                  }
                />
              </Card> */}
            </Space>
          </Col>
        </Row>
      </div>
    </BlankWrapper>
  );
}
