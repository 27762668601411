import { useRequest } from 'ahooks';
import { Form, message, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  CmtCascader,
  CmtCascaderOption,
} from '../../../components/CmtCascader';
import {
  GpoCategory,
  buildCategoryTree,
} from '../../../services/gpo/category.service';
import {
  queryGoodsCategoryBySupplierAgreements,
  queryGoodsSupplierAgreements,
} from '../../../services/gpo/standard-goods.service';
const { Text } = Typography;

export function GpoJiCaiDynamicAgreementCategorySelect(props: {
  value?: string;
  style?: React.CSSProperties;
  onChange?: (val: string | null) => void;
  queryParams: { projectId: string; agreementId: string };
  allowClear?: boolean;
  showCheckedStrategy?: any;
}) {
  const [options, setOptions] = useState<CmtCascaderOption[]>([]);
  const createOptionTree = (res: GpoCategory[]) => {
    const opts = buildCategoryTree(res);
    setOptions(opts);
  };
  const [currentValue, setCurrentValue] = useState<string[]>(
    props.value ? [props.value] : []
  );
  const {
    loading,
    data,
    run: querySa,
  } = useRequest(queryGoodsCategoryBySupplierAgreements, {
    debounceWait: 300,
    manual: true,
    onSuccess: createOptionTree,
    onError(e, params) {
      message.error(`获取分类失败：${e.message}`);
    },
  });
  useEffect(() => {
    if (props.queryParams.agreementId) {
      querySa(props.queryParams);
    }
  }, [props.queryParams.projectId, props.queryParams.agreementId]);
  const onChange = (val: string[]) => {
    setCurrentValue(val ? [...val] : []);
    props.onChange?.(val ? val[val.length - 1] : null);
  };
  // const getValuePath = (id: string, cats: GpoCategory[]) => {
  //   const path: string[] = [];
  //   const getCategoryPath = (id: string) => {
  //     const item = cats.find((x) => {
  //       return x.platformId === id;
  //     });
  //     if (item) {
  //       path.push(id);
  //       if (item.platformParentId) {
  //         getCategoryPath(item.platformParentId);
  //       }
  //     }
  //     return;
  //   };
  //   getCategoryPath(id);
  //   return path.reverse();
  // };
  return (
    <CmtCascader
      style={props.style}
      data={options}
      loading={loading}
      onChange={onChange}
      value={currentValue}
      allowClear={props.allowClear}
      showCheckedStrategy={props.showCheckedStrategy}
    />
  );
}
