import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message, Spin, Upload } from 'antd';
import {
  getUploadActionHeaders,
  getUploadActionUrl,
} from '../../../../services/gpo/goodmarket.service';
import { saveInvoicePath } from '../../../../services/gpo/order.service';

export function UploadInvoiceButton(props: {
  orderGuid: string;
  onSuccess?: () => void;
}) {
  const headers = getUploadActionHeaders();
  const [messageApi, contextHolder] = message.useMessage();
  const { loading, run } = useRequest(saveInvoicePath, {
    manual: true,
    debounceWait: 200,
    onSuccess(data, params) {
      messageApi.success('上传发票成功');
      props.onSuccess?.();
    },
    onError(e, params) {
      messageApi.error(e.message, 3);
    },
  });
  return (
    <Spin spinning={loading}>
      {contextHolder}
      <Upload
        accept=".pdf"
        name="file"
        action={getUploadActionUrl}
        headers={headers}
        maxCount={1}
        multiple={false}
        onChange={(info) => {
          if (info.file.status === 'done' && info.file.response.url) {
            run(props.orderGuid, info.file.response.url);
          }
        }}
      >
        <Button icon={<UploadOutlined />}>点击上传</Button>
      </Upload>
    </Spin>
  );
}
