import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import {
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  RJDeliveryType,
  RJSimpleOrderDto,
} from '../../../../services/rjmart/order-modal';
import { confirmRJMDelivertOrder } from '../../../../services/rjmart/order.service';

function ComfirmOrderForm(props: {
  data: RJSimpleOrderDto;
  onSuccess?: () => void;
}) {
  const [form] = useForm();
  const [isLogisticsInfoRequired, setIsLogisticsInfoRequired] = useState(false);
  const [selectedLogisticsCompany, setSelectedLogisticsCompany] = useState('');
  const logisticsCompanies = [
    'EMS快递',
    '顺丰快递',
    '京东快递',
    '圆通快递',
    '中通快递',
    '韵达快递',
    '百世汇通快递',
    '宅急送快递',
    '天天快递',
    '其他',
  ];
  useEffect(() => {
    form.setFieldsValue({
      orderNo: props.data.orderNo,
      deliveryType: RJDeliveryType.SupplierDeliver,
    });
  }, [props.data]);
  const { loading: updating, run: pushChangeApi } = useRequest(
    confirmRJMDelivertOrder,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: () => {
        message.success(`操作成功`);
        props.onSuccess?.();
      },
      onError: (err) => {
        message.error(`操作失败：` + err.message);
      },
    }
  );

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue({ orderNo: props.data.orderNo, additionalRecord: 0 });
    }
  }, [props.data]);

  const submitChanges = () => {
    const payload = {
      ...form.getFieldsValue(),
    };
    payload.deliveryDate = dayjs(payload.deliveryDate).format(
      'YYYY-MM-DD 00:00:00'
    );
    pushChangeApi(payload);
  };

  return (
    <Spin spinning={updating}>
      <Space style={{ width: '100%', marginTop: 24 }} direction={'vertical'}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          labelAlign={'left'}
          onFinish={submitChanges}
        >
          <FormItem label="订单号" name={'orderNo'} hidden>
            <Input readOnly />
          </FormItem>
          <FormItem
            label="发货日期"
            name={'deliveryDate'}
            rules={[{ required: true }]}
          >
            <DatePicker format={'YYYY-MM-DD'} />
          </FormItem>
          <FormItem
            label="发货类型"
            name={'deliveryType'}
            rules={[{ required: true }]}
          >
            <Radio.Group
              onChange={(v) => {
                setIsLogisticsInfoRequired(
                  v.target.value === RJDeliveryType.LogisticsCompany
                );
              }}
            >
              <Radio value={RJDeliveryType.SupplierDeliver}>商家自送</Radio>
              <Radio value={RJDeliveryType.LogisticsCompany}>物流配送</Radio>
            </Radio.Group>
          </FormItem>
          <FormItem label="发货说明" name={'deliveryInfo'}>
            <TextArea />
          </FormItem>
          <FormItem
            label="发货单号"
            name={'deliveryNo'}
            hidden={!isLogisticsInfoRequired}
            rules={[{ required: isLogisticsInfoRequired }]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="物流公司"
            name={'logisticsCompanySelect'}
            hidden={!isLogisticsInfoRequired}
            rules={[{ required: isLogisticsInfoRequired }]}
          >
            <Select
              onChange={(v) => {
                setSelectedLogisticsCompany(v);
                form.setFieldValue('logisticsCompany', v !== '其他' ? v : '');
              }}
              options={logisticsCompanies.map((x) => {
                return { label: x, value: x };
              })}
            ></Select>
          </FormItem>
          <FormItem
            label={<></>}
            colon={false}
            name={'logisticsCompany'}
            rules={[
              { required: isLogisticsInfoRequired, message: '请输入物流公司' },
            ]}
            hidden={
              !isLogisticsInfoRequired || selectedLogisticsCompany !== '其他'
            }
          >
            <Input placeholder="请输入物流公司" />
          </FormItem>
          <FormItem>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={updating}
                disabled={updating}
              >
                确认发货
              </Button>
              <Button type="text" onClick={props.onSuccess}>
                取消
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Space>
    </Spin>
  );
}

export function DeliverProductButton(props: {
  data: RJSimpleOrderDto;
  onSuccess?: () => void;
}) {
  if (props.data.status !== 4) {
    return <></>;
  }
  return (
    <CmtModalButton
      title={`订单发货【${props.data?.orderNo}】`}
      width={500}
      trigger={({ show }) => {
        return (
          <Tooltip title={'订单发货'}>
            <Button type="link" size="small" onClick={show}>
              订单发货
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ComfirmOrderForm
            data={props.data}
            onSuccess={() => {
              hide();
              props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
