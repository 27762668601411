import {
  CarOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Button, Space, Spin, Table, Timeline, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import { LogisticDetailView } from '../../../../components/LogisticDetailView';
import {
  extractPhoneFromLogisticRemark,
  GPOOrderShipInfoDto,
  GPOOrderShippingTypes,
} from '../../../../services/gpo/order.service';
import { queryLogisticDetailApi } from '../../../../services/logistics.service';
import { ShippingInfoButton } from './shipping-info-button';

export function OrderShippingInfoView(props: {
  orderShippingInfo: GPOOrderShipInfoDto[];
  orderGuid: string;
  projectId: string;
  onSuccess?: () => void;
}) {
  const columns = [
    {
      title: '物流方式',
      dataIndex: 'logisticsType',
      key: 'logisticsType',
      render: (value: number) => {
        switch (value) {
          case 1:
            return '供应商自行送货';
          case 2:
            return '快递';
          default:
            return '其他';
        }
      },
    },
    {
      title: '物流名称',
      dataIndex: 'logisticsName',
      key: 'logisticsName',
    },
    {
      title: '物流编号',
      dataIndex: 'logisticsCode',
      key: 'logisticsCode',
      render: (val: string) => {
        return val ? <Typography.Text copyable>{val}</Typography.Text> : <></>;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: '操作',
      key: 'action',
      render: (v: any, record: GPOOrderShipInfoDto) => {
        return (
          <Space>
            <ShippingInfoButton
              projectId={props.projectId}
              orderGuid={props.orderGuid}
              data={record}
              onSuccess={props.onSuccess}
              trigger={({ show }) => {
                return (
                  <Button onClick={show} type="default" size="small">
                    修改
                  </Button>
                );
              }}
            />
            {record.logisticsType === GPOOrderShippingTypes.Express ? (
              <LogisticDetailView
                data={{
                  providerName: record.logisticsName,
                  zipCode: record.logisticsCode,
                  ...extractPhoneFromLogisticRemark(record.remark),
                }}
              ></LogisticDetailView>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        {!props.orderShippingInfo?.length && (
          <ShippingInfoButton
            projectId={props.projectId}
            data={undefined}
            onSuccess={props.onSuccess}
            orderGuid={props.orderGuid}
            trigger={({ show }) => {
              return (
                <Button onClick={show} type="default">
                  <PlusOutlined /> 添加物流信息
                </Button>
              );
            }}
          />
        )}
      </Space>
      <Table
        rowKey={'logisticsGuid'}
        pagination={false}
        dataSource={props.orderShippingInfo}
        columns={columns}
      />
    </Space>
  );
}
