import { useRequest } from 'ahooks';
import { Input, Button, Space, message, Form, Switch } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useContext } from 'react';
import { GlobalContext } from '../../../context';
import {
  getOrganization,
  updateOrganization,
} from '../../../services/organization.service';

export function BasicInfoForm(props: { onSuccess?: () => void }) {
  const [form] = Form.useForm<any>();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const { loading: loading } = useRequest(getOrganization, {
    debounceWait: 300,
    onSuccess(data, params) {
      form.setFieldsValue({
        name: data.name,
        force2fa: data.force2fa,
      });
    },
    onError(e, params) {
      message.error('获取资料失败：' + e.message);
    },
  });

  const { loading: updating, run: runUpdate } = useRequest(updateOrganization, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      setGlobalContext({ ...globalContext });
      message.success('资料更新成功，将在下次登录时生效');
    },
    onError(e, params) {
      message.error('资料更新失败：' + e.message);
    },
  });
  return (
    <Form
      form={form}
      labelCol={{ span: 3 }}
      labelAlign={'left'}
      disabled={updating}
      onFinish={() => {
        runUpdate(form.getFieldsValue());
      }}
    >
      <br />
      <FormItem label="组织名称" name={'name'} rules={[{ required: true }]}>
        <Input maxLength={50} />
      </FormItem>
      <FormItem label="强制开启2FA" name={'force2fa'} valuePropName="checked">
        <Switch />
      </FormItem>
      <Form.Item>
        <Space>
          <Button htmlType="submit" loading={updating} disabled={updating}>
            保存
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
