import { PictureOutlined } from '@ant-design/icons';
import { Avatar, Image, Popover } from 'antd';

export function CmtAvator(props: { url: string }) {
  return (
    <>
      {props.url && (
        <Popover content={<Image src={props.url} height={240} />}>
          <Avatar src={props.url} size="large" />
        </Popover>
      )}
      {!props.url && <Avatar icon={<PictureOutlined />} />}
    </>
  );
}
