import { useDynamicList, useRequest } from 'ahooks';
import {
  message,
  Form,
  FormInstance,
  Space,
  Card,
  Col,
  Input,
  Row,
  Typography,
  InputNumber,
  Select,
  Affix,
  Button,
  Spin,
  Empty,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REGEXS } from '../../../../../common/utils/formats';
import { Editor } from '../../../../../components/Editor';
import { InfiniteSelect } from '../../../../../components/InfiniteSelect';
import { saveGood } from '../../../../../services/gpo/goodmarket.service';
import {
  getCategoryAttributes,
  getRootRJMCategory,
  RJAttributeRequried,
  RJCategoryAttributeDTO,
  RJMCategory,
} from '../../../../../services/rjmart/category.service';
import {
  getSupportBrands,
  queryUnitAndSpecification,
  saveRJMProduct,
} from '../../../../../services/rjmart/product-mamagement.service';
import {
  RJDeliveryTimes,
  RJProductAttribute,
  RJProductStatus,
  RJSupportBrand,
} from '../../../../../services/rjmart/product-modal';

interface Attr extends RJProductAttribute {
  required: RJAttributeRequried;
}

export function AttributeInput(props: {
  value?: RJProductAttribute[];
  onChange?: (v: RJProductAttribute[]) => void;
  categoryId: number;
  projectId: string;
}) {
  const key = 'AttributeInput';
  const [messageApi, contextHolder] = message.useMessage();
  const [rootCategory, setRootCategory] = useState<RJMCategory>();
  const { list, remove, resetList, insert, replace } = useDynamicList<Attr>([]);
  const { run: loadCategoryAttributes, loading } = useRequest(
    getCategoryAttributes,
    {
      manual: true,
      debounceWait: 300,
      onBefore() {
        resetList([]);
      },
      onSuccess(res) {
        if (res?.attributeDTOS?.length) {
          res.attributeDTOS.forEach((x, index) => {
            insert(index, {
              attributeName: x.attributeName,
              required: x.required,
              attributeNameValue:
                props.value?.find((y) => y.attributeName === x.attributeName)
                  ?.attributeNameValue || '',
            });
          });
        }
      },
      onError(e, params) {
        messageApi.open({
          key,
          type: 'error',
          content: e.message,
          duration: 3,
        });
      },
      onFinally() {},
    }
  );
  useEffect(() => {
    if (props?.categoryId) {
      getRootRJMCategory(props.projectId || '', props?.categoryId).then((b) => {
        if (b) {
          setRootCategory(b);
          loadCategoryAttributes({
            categoryId: b.platformId,
            projectId: props.projectId,
          });
        } else {
          messageApi.error('没有找到一级分类');
        }
      });
    }
  }, [props.categoryId]);
  return (
    <Spin spinning={loading}>
      {contextHolder}
      <Row gutter={24}>
        {list.map((element, index) => {
          return (
            <Col span={12} key={element.attributeName}>
              <FormItem
                label={element.attributeName}
                rules={[
                  {
                    required: element.required === RJAttributeRequried.Required,
                  },
                ]}
              >
                <Input
                  defaultValue={element.attributeNameValue}
                  onChange={(v) => {
                    replace(index, {
                      ...element,
                      attributeNameValue: v.target.value,
                    });
                    props.onChange?.(list);
                  }}
                />
              </FormItem>
            </Col>
          );
        })}
      </Row>
      {!list.length && (
        <Typography.Text type="secondary">
          当前品类无商品属性填写要求
        </Typography.Text>
      )}
    </Spin>
  );
}
