import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  GpoJiCaiOnSaleProductDto,
  removeJiCaiProductApi,
} from '../../../../services/gpo/jicai.service';

const { Text } = Typography;
export function BatchRemoveGoodButton(props: {
  data: GpoJiCaiOnSaleProductDto[];
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={removeJiCaiProductApi}
      placement="top"
      disabled={!props.data?.length}
      title={
        <Space direction="vertical" style={{ maxWidth: 300 }}>
          <Text strong>{`确定批量移除${
            props.data?.length || 0
          }个商品吗？`}</Text>
        </Space>
      }
      onOk={({ data, run }) => {
        run(data);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="default"
          shape="round"
          disabled={!props.data?.length || loading}
          loading={loading}
        >
          批量移除 ({props.data?.length || 0})
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
