import { useRequest, useTitle } from 'ahooks';
import { message, Spin } from 'antd';
import Item from 'antd/es/list/Item';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { queryOrderDetailApi } from '../../../../services/gpo/order.service';
import { getSupplierCompanyInfo } from '../../../../services/gpo/util.service';
import print from './print.module.less';

export const PrintableDeliverList = React.forwardRef<
  any,
  { orderGuid: string; projectId: string }
>((props, ref) => {
  const { data, loading, run } = useRequest(queryOrderDetailApi, {
    manual: true,
    debounceWait: 100,
  });
  const { data: supplier, loading: supplierLoading } = useRequest(
    getSupplierCompanyInfo,
    {
      debounceWait: 100,
      onError() {
        message.error('获取供应商信息失败');
      },
    }
  );

  useTitle(`${data?.orderCode} / ${data?.orgName} / ${data?.supplierName}`, {
    restoreOnUnmount: true,
  });
  useEffect(() => {
    if (props.orderGuid && props.projectId) {
      run({
        projectId: props.projectId,
        orderGuid: props.orderGuid,
      });
    }
  }, [props.orderGuid, props.projectId]);

  return (
    <Spin spinning={loading || supplierLoading}>
      <div
        className={print['table']}
        ref={ref}
        style={{ width: 720 }}
        contentEditable={true}
      >
        <style type="text/css" media="print">
          {
            '\
  @page { size: a4; margin: 1cm 0.5cm; }\
'
          }
        </style>
        <table
          style={{
            textAlign: 'center',
            padding: '0',
            width: '100%',
          }}
        >
          <tr>
            <td style={{ fontSize: 24, paddingBottom: 12 }}>
              {data?.supplierName}
            </td>
          </tr>
          <tr>
            <td>智慧云平台送货单</td>
          </tr>
        </table>
        <table style={{ width: '100%' }}>
          <tr>
            <td style={{ textAlign: 'left' }}>政采单号：{data?.orderCode}</td>
            <td style={{ textAlign: 'right' }}>日期：{data?.createDate}</td>
          </tr>
        </table>
        <table
          className={print['bordered']}
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ width: '100%' }}
        >
          <tr>
            <td style={{ textAlign: 'left', width: '50%' }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: 80 }}> 客户名称：</td>
                  <td>{data?.orgName}</td>
                </tr>
                <tr>
                  <td>联系人：</td>
                  <td>{data?.linkman}</td>
                </tr>
                <tr>
                  <td>联系电话：</td>
                  <td>{data?.linkMobile}</td>
                </tr>
              </table>
            </td>
            <td style={{ width: '50%' }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ textAlign: 'left', width: 80 }}>送货地址：</td>
                  <td>{data?.deliveryAddress}</td>
                </tr>
                <tr>
                  <td>备注：</td>
                  <td>{data?.remark}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table
          className={print['bordered']}
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ width: '100%' }}
        >
          <tr>
            <td width="34" align="center">
              序号
            </td>
            <td width="80" align="center">
              型号
            </td>
            <td>商品名称</td>
            <td width="34" align="center">
              数量
            </td>
            <td width="70" align="right">
              单价
            </td>
            <td width="70" align="right">
              金额
            </td>
          </tr>
          {data?.orderGoodsVOList.map((item, index) => (
            <tr>
              <td align="center">{index + 1}</td>
              <td align="center">{item.code}</td>
              <td>{item.goodsName}</td>
              <td align="center">{item.qty}</td>
              <td align="right">{ConvertNumberToCurrency(item.finalPrice)}</td>
              <td align="right">
                {ConvertNumberToCurrency(item.agreementTotal)}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={3} align="right">
              合计：
            </td>
            <td align="center">
              {data?.orderGoodsVOList
                .map((x) => x.qty)
                .reduce((x, count) => x + count, 0) || 0}
            </td>
            <td colSpan={2} align="right">
              {ConvertNumberToCurrency(data?.goodsTotal || 0)}
            </td>
          </tr>
        </table>
        <table
          className={print['bordered']}
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ width: '100%' }}
        >
          <tr>
            <td style={{ textAlign: 'left', width: '50%' }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td>发票编号：</td>
                  <td></td>
                </tr>
                <tr>
                  <td>商品签收：</td>
                  <td></td>
                </tr>
                <tr>
                  <td>收货时间：</td>
                  <td>
                    <table border={0} cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderBottom: '1px solid #000',
                              width: 70,
                            }}
                          ></td>
                          <td>年</td>
                          <td
                            style={{
                              borderBottom: '1px solid #000',
                              width: 30,
                            }}
                          ></td>
                          <td>月</td>
                          <td
                            style={{
                              borderBottom: '1px solid #000',
                              width: 30,
                            }}
                          ></td>
                          <td>日</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ width: '50%' }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ textAlign: 'left', width: 80 }}>（盖章）：</td>
                  <td></td>
                </tr>
                <tr>
                  <td>售后热线：</td>
                  <td>
                    {supplier?.supplierCompanySaleInfoVO?.afterConsultLine ||
                      ''}
                  </td>
                </tr>
                <tr>
                  <td>地址：</td>
                  <td>{supplier?.supplierBasicInfoVO?.registeredAddress}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </Spin>
  );
});
