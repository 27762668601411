export interface MartBasePagingQueryParams {
  /**
   *分页参数，每页多少个
   */
  count: number;
  /**
   * 分页参数，第几页
   */
  page: number;
}

export enum MartProductKeywordType {
  /**商品货号 */
  ProductCode = '0',
  /**商品名称 */
  ProductName = '1',
  /**生产企业 */
  ProductFactory = '2',
  /**热销商品 */
  ProductBestSeller = '3',
}

export enum MartProductBatchType {
  /**上架 */
  Publish = 5,
  /**下架 */
  Unpublish = 6,
}

export enum MartProductStatus {
  /**全部商品 */
  All = '',
  /**已上架 */
  Publish = '1',
  /**已下架 */
  Unpublish = '2',
  /**系统下架 */
  SystemOff = '4',
  /**未审核 */
  PendingApproval = '5',
  /**审核不通过 */
  ApprovalFailed = '3',
}

/**
 * 页码查询
 */
export interface MartProductQueryParams extends MartBasePagingQueryParams {
  /**
   * 查询参数
   */
  params: {
    /**
     * 空字符串
     */
    brandName: string;
    cateId: string;
    tabIndex: MartProductStatus;
    stationId: string;
    /**
     * 品牌ID
     */
    brandId: string;
    /**
     * 关键词类型
     */
    searchType: MartProductKeywordType;
    search: string;
  };
}

export interface MartSimpleProductDto {
  addTime: string;
  auditStatus: number;
  auditStatusInfo: string;
  bissnessType: number;
  brandId: number;
  brandName: string;
  cateId: number;
  cateName: string;
  code: string;
  deliveryCycle: string;
  hotSaleStatus: number;
  id: number;
  img: string;
  insaleStatus: MartProductSaleStatus;
  insaleStatusInfo: string;
  maker: string;
  mktPrice: number;
  modified: string;
  name: string;
  noStock: number;
  price: number;
  saleAmount: number;
  saleTime: string;
  spec: string;
  stockAmount: number;
  subName: string;
  supplierId: number;
  tempPrice: number;
  typeId: number;
  unSaleTime: string;
  unit: string;
}

export enum MartProductSaleStatus {
  Publish = 1,
  Unpublish = 0,
}

export interface MartProductPayload {
  deliveryObj?: {
    flag: string;
    type: string;
    msg: string;
  };
  productBasic: {
    /**
     * 商品ID，更新时需传入
     * */
    id?: string;
    cateId: string;
    cateName: string;
    typeId: string;
    name: string;
    code: string;
    spec: string;
    brandId: string;
    unit: string;
    maker: string;
    price: string;
    mktPrice: string;
    deliveryCycle: string;
    stockAmount: string;
    /**
     * 最小起订量， 1
     */
    groupNum: string;
    insaleStatus: MartProductSaleStatus;
    /**
     * 是否赠品 1-赠品， 0 非赠品
     */
    giftStatus: string;
    /**
     * 分组的IDs， 逗号连接
     */
    xmSelect: string;
    img: string;
  };
  extendList: {
    extendId: string;
    value: string;
  }[];
  productIntro: {
    /**
     * 商品ID，更新时需传入
     * */
    productId?: string;
    content: string;
  };
  imgList: {
    path: string;
    type: 1;
  }[];
  /**
   * 商品分组ID
   */
  productCategoryList: {
    /**
     * 商品ID，更新时需传入
     * */
    productId?: string;
    supplierCategoryId: string;
  }[];
}

export interface MartBatchTaskQueryParam extends MartBasePagingQueryParams {
  param: {
    type: MartProductBatchType;
  };
}

/**
 * 批量任务状态
 */
export enum MartBatchtaskStatus {
  /**
   * 已结束
   */
  Completed = 1,
  /**
   * 排队处理中
   */
  Processing = 0,
}

export interface MartBatchTask {
  created: string;
  current: number;
  id: number;
  operator: string;
  status: MartBatchtaskStatus;
  supplierId: number;
  /**
   * 操作总数
   */
  total: number;
  type: MartProductBatchType;
  /**
   * 类型描述
   */
  typeText: string;
}
