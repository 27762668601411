import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../context';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const [globalContext] = useContext(GlobalContext);
  let location = useLocation();

  if (!globalContext.userMetaData) {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
