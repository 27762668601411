import { Cascader } from 'antd';
import { GpoDeliverRegion } from '../../../services/gpo/goodmarket.service';
import { useEffect, useState } from 'react';

export function GpoDeliverRegionCascader(props: {
  regions: GpoDeliverRegion[] | undefined;
  onChange?: (val: any) => void;
  value?: any;
  loading: boolean;
  disabled?: boolean;
}) {
  const [currentValue, setCurrentValue] = useState<any>([]);
  useEffect(() => {
    if (props.regions?.length && props.regions?.[0].children?.length) {
      const paths = getValuePath(props.regions?.[0].children);
      console.log('current', paths);
      setCurrentValue(paths);
      emitChange(paths);
    }
  }, [props.regions]);

  const getValuePath = (input: GpoDeliverRegion[]) => {
    const findCheckedLeafPaths = (
      node: GpoDeliverRegion,
      currentPath: string[] = []
    ): string[][] => {
      const path = [...currentPath, node.id];
      let paths: string[][] = [];

      if (!node.children || node.children.length === 0) {
        if (node.checked) {
          paths.push(path);
        }
      } else {
        node.children.forEach((child) => {
          paths = paths.concat(findCheckedLeafPaths(child, path));
        });
      }
      return paths;
    };
    const allPaths: string[][] = [];

    input.forEach((childNode) => {
      allPaths.push(...findCheckedLeafPaths(childNode));
    });
    return allPaths;
  };

  const onSelectChange = (value: any[]) => {
    console.log(`origin region`, value);
    setCurrentValue(value);
    emitChange(value);
  };

  const emitChange = (value: any[]) => {
    const ids = value.map((x: string[]) => {
        return x[x.length - 1];
      });
      console.log(ids);
      props.onChange?.(ids);
  }

  return (
    <Cascader
      loading={props.loading}
      options={props.regions?.[0].children}
      multiple
      value={currentValue}
      maxTagCount="responsive"
      showCheckedStrategy="SHOW_CHILD"
      onChange={onSelectChange}
      disabled={props.disabled}
      fieldNames={{ label: 'name', value: 'id', children: 'children' }}
    />
  );
}
