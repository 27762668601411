import { Button } from 'antd';
import { CmtPrint } from '../../../components/CmtPrint';
import { PrintableContract } from './printable-contract';

export function PrintContractButton(props: {
  contractGuid: string;
  projectId: string;
}) {
  return props?.contractGuid ? (
    <CmtPrint
      trigger={({ show }) => (
        <Button type="default" onClick={show}>
          打印合同
        </Button>
      )}
    >
      {({ ref }) => (
        <PrintableContract
          ref={ref}
          contractId={props?.contractGuid || ''}
          projectId={props?.projectId || ''}
        />
      )}
    </CmtPrint>
  ) : (
    <></>
  );
}
