import {
  BarsOutlined,
  SyncOutlined,
  CaretDownOutlined,
  FormOutlined,
  CaretUpOutlined,
  CloudOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Form,
  Input,
  Result,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { Permission } from '../../../../components/Permission';
import { GpoCategoryGroup } from '../../../../services/gpo/category.service';
import {
  getGpoJiCaiOnSaleProductStatusName,
  GpoJiCaiOnSaleProductDto,
  GpoJiCaiOnSaleProductStatusOptions,
  queryLocalJiCaiOnSaleProductsApi,
} from '../../../../services/gpo/jicai.service';
import { GpoCategoryCascader } from '../../../components/CategoryCascader';
import { CompetitorPrice } from '../../../components/CompetitorPrice';
import { GoodsBrandSelect } from '../../../components/GoodsBrandSelect';
import { ProductName } from '../../../components/ProductName';
import { BatchRemoveGoodButton } from './batch-remove-good-button';
import { ChangeFrozenButton } from './change-frozen-button';
import { ChangePriceButton } from './change-price-button';
import { ChangeJiCaiStatusButton } from './change-status-button';
import { RemoveGoodButton } from './remove-good-button';
import { ProjectInternalPlanButton } from '../../../../components/ProjectInternalPlan';
import { FeatureToggle } from '../../../../components/FeatureToggle';
const { Text } = Typography;
export function LocalJiCaiOnSaleProductListPage() {
  const tableRef = useRef<any>(null);
  const params = useParams();
  useTitle('本地商品库 | 集采商城');
  const statusOptions = [
    { value: '', label: '全部' },
    ...GpoJiCaiOnSaleProductStatusOptions,
  ];

  const columns: ColumnsType<GpoJiCaiOnSaleProductDto> = [
    {
      title: '商品编号',
      dataIndex: 'goodsCode',
      key: 'goodsCode',
      width: 200,
      fixed: 'left',
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      fixed: 'left',
      render(value, record, index) {
        return (
          <ProductName
            picturePath={record.picturePath}
            name={record.name}
            goodsPriceGuid={record.goodsPriceGuid}
          />
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'valid',
      key: 'valid',
      width: 70,
      render: (value) => {
        return <Space>{getGpoJiCaiOnSaleProductStatusName(value)}</Space>;
      },
    },
    {
      title: '品目',
      dataIndex: 'goodsClassName',
      key: 'goodsClassName',
      width: 120,
      render: (value) => {
        return (
          <Text style={{ width: 120 }} ellipsis={{ tooltip: value }}>
            {value}
          </Text>
        );
      },
    },
    {
      title: '品牌',
      dataIndex: 'goodsBrandName',
      key: 'goodsBrandName',
      width: 120,
      render: (value) => {
        return (
          <Text style={{ width: 120 }} ellipsis={{ tooltip: value }}>
            {value}
          </Text>
        );
      },
    },
    {
      title: '厂商指导价',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      render: (value) => {
        return ConvertNumberToCurrency(value);
      },
    },
    {
      title: '我的报价',
      dataIndex: 'quotePrice',
      key: 'quotePrice',
      width: 100,
      render: (value, record) => {
        return (
          <div>
            {ConvertNumberToCurrency(value)}{' '}
            {Number(record.goodsPriceCurrentPrice) >
              Number(record.quotePrice) && (
              <Text type={'success'}>
                <CaretDownOutlined />
              </Text>
            )}
            {Number(record.goodsPriceCurrentPrice) <
              Number(record.quotePrice) && (
              <Text type={'danger'}>
                <CaretUpOutlined />
              </Text>
            )}
          </div>
        );
      },
    },
    // {
    //   title: '对手价',
    //   dataIndex: 'competitorQuotePrice',
    //   width: 180,
    //   render(value, record, index) {
    //     return (
    //       <CompetitorPrice
    //         priceInput={record.competitorQuotePrice}
    //         date={record.competitorQuotePriceCreatedAt}
    //       ></CompetitorPrice>
    //     );
    //   },
    // },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 150,
    },
    {
      title: '冻结',
      key: 'frozen',
      width: 50,
      render(value, record, index) {
        return (
          <ChangeFrozenButton
            data={record}
            onSuccess={tableRef.current?.refresh}
          />
        );
      },
    },
    {
      title: '操作',
      width: 100,
      key: 'actions',
      render: (value, record: GpoJiCaiOnSaleProductDto, index) => {
        return (
          <div>
            <ChangeJiCaiStatusButton
              data={record}
              onSuccess={tableRef.current?.refresh}
            ></ChangeJiCaiStatusButton>
            <ChangePriceButton data={record} />
            <RemoveGoodButton
              data={record}
              onSuccess={() => tableRef.current.refresh()}
            />
          </div>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '集采商城' },
        { name: '本地商品库' },
      ]}
    >
      <Permission
        resource="gpo"
        action="jicai.local"
        error={
          <Result
            status={'error'}
            title="您没有权限访问"
            subTitle={'请联系管理员调整权限'}
          ></Result>
        }
      >
        <CmtTable
          ref={tableRef}
          title="本地商品库"
          description=""
          rowKey="goodsGuid"
          api={queryLocalJiCaiOnSaleProductsApi}
          colums={columns}
          extends={({ formRef, tableSearch }) => {
            const { submit } = tableSearch;
            return (
              <Space>
                <Form form={formRef} layout={'inline'}>
                  <Form.Item
                    name={'goodsClassGuid'}
                    label={'品目'}
                    initialValue={''}
                  >
                    <GpoCategoryCascader
                      style={{ width: 120 }}
                      queryParams={{
                        projectId: params.projectId || '',
                        group: GpoCategoryGroup.JiCai,
                      }}
                      onChange={submit}
                      allowClear={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name="goodsBrandGuid"
                    label="品牌"
                    initialValue={''}
                    dependencies={['goodsClassGuid']}
                  >
                    <GoodsBrandSelect
                      style={{ width: 120 }}
                      allowClear
                      onChange={submit}
                      queryParams={{
                        agreementType: '3',
                        goodsClassGuid: formRef.getFieldValue('goodsClassGuid'),
                      }}
                    />
                  </Form.Item>{' '}
                  <Form.Item name="name" label="名称" initialValue={''}>
                    <Input
                      placeholder="商品名称"
                      onChange={submit}
                      allowClear
                    ></Input>
                  </Form.Item>
                  <Form.Item name={'valid'} label={'状态'} initialValue={''}>
                    <Select
                      style={{ width: 120 }}
                      onChange={submit}
                      options={statusOptions}
                    ></Select>
                  </Form.Item>
                </Form>
                <Button type="default" onClick={submit}>
                  <SyncOutlined /> 刷新
                </Button>
              </Space>
            );
          }}
          selectable={true}
          secondaryExtends={({ selectedRows }) => {
            return (
              <Space>
                <Button
                  type="default"
                  shape="round"
                  htmlType={'button'}
                  onClick={() => tableRef.current.selectAll()}
                >
                  全选
                </Button>
                <BatchRemoveGoodButton
                  data={selectedRows}
                  onSuccess={() => {
                    tableRef.current.refresh();
                  }}
                />
                <FeatureToggle group="org.features" feature="rjm.products">
                  <ProjectInternalPlanButton
                    actionId="rjmart-migrate-jicai-products-to-rjm"
                    projectId={params.projectId || ''}
                    layoutResponsive={true}
                    defaultPayload={{
                      sourceProductIds: selectedRows.map((x) => x.id),
                    }}
                  >
                    {({ show }) => (
                      <Button disabled={!selectedRows.length} onClick={show}>
                        迁移到锐竞
                      </Button>
                    )}
                  </ProjectInternalPlanButton>
                </FeatureToggle>
                <FeatureToggle group="org.features" feature="cas.products">
                  <ProjectInternalPlanButton
                    actionId="casmart-migrate-jicai-products-to-cas"
                    projectId={params.projectId || ''}
                    layoutResponsive={true}
                    defaultPayload={{
                      sourceProductIds: selectedRows.map((x) => x.id),
                    }}
                  >
                    {({ show }) => (
                      <Button disabled={!selectedRows.length} onClick={show}>
                        迁移到CAS
                      </Button>
                    )}
                  </ProjectInternalPlanButton>
                </FeatureToggle>
              </Space>
            );
          }}
        ></CmtTable>
      </Permission>
    </BlankWrapper>
  );
}
