import { useRequest } from 'ahooks';
import { Alert, Button, Form, Space, Spin, Typography } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { useState, useEffect, ReactNode } from 'react';
import { ConvertJsonSchemaPayloadToString } from '../../common/utils/json-schema-payload';
import { EventList } from '../../pages/tasks/event-list';
import { createInternalPlanApi } from '../../services/plan.service';
import {
  querySingleActionApi,
  Action,
  queryMyTaskApi,
} from '../../services/task.service';
import { CmtModalButton } from '../CmtModalButton';
import { useFeatureToggle } from '../FeatureToggle/use-feature-toggle';
import { JsonSchemaInput } from '../JsonSchemaInput';

function ProjectInternalPlan(props: {
  actionId: string;
  projectId: string;
  defaultPayload?: { [key: string]: unknown };
  onSuccess?: (taskId: string) => void;
  layoutResponsive?: boolean;
}) {
  const [form] = Form.useForm();
  const [planId, setPlanId] = useState('');
  const [attemptMade, setAttemptMade] = useState(0);
  const [message, context] = useMessage();
  const { loading, run: getActionInfo } = useRequest(querySingleActionApi, {
    manual: true,
    onBefore() {},
    onSuccess(data) {
      setSelectedAction(data);
      form.setFieldsValue({ payload: props.defaultPayload });
    },
    onError({ message: text }) {
      message.error('获取任务内容失败' + text);
    },
  });
  const { loading: loadingTask, run: queryTask } = useRequest(queryMyTaskApi, {
    manual: true,
    debounceWait: 500,
    onSuccess(data, params) {
      if (data.total > 0) {
        message.success('任务创建成功');
        props.onSuccess?.(data.list?.[0].id);
      } else if (attemptMade < 5) {
        setAttemptMade(attemptMade + 1);
        queryTask({
          pageSize: 50,
          current: 1,
          planId: planId,
          internal: 'true',
        });
      } else {
        message.success('任务创建成功');
        props.onSuccess?.('');
      }
    },
    onError(e, params) {
      message.success(
        '任务创建成功，但未能找到任务ID， 请到右上角的任务运行记录中查看'
      );
    },
  });
  const { loading: creating, run: createPlan } = useRequest(
    createInternalPlanApi,
    {
      manual: true,
      debounceWait: 400,
      onSuccess(data, params) {
        form.resetFields();
        setPlanId(data.id);
        queryTask({
          pageSize: 50,
          current: 1,
          planId: data.id,
          internal: 'true',
        });
      },
      onError({ message: text }, params) {
        message.error('任务创建失败：' + text);
      },
    }
  );
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);
  const hasFeaturePermission = useFeatureToggle('org.actions', props.actionId);
  useEffect(() => {
    if (props?.actionId) {
      getActionInfo(props.actionId);
    }
  }, [props.actionId, props.projectId]);

  if (!hasFeaturePermission) {
    return <></>;
  }
  return (
    <div>
      {context}
      <Typography.Title level={4}>
        {selectedAction?.internalName || selectedAction?.name}
      </Typography.Title>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={(v) => {
          v.payload = ConvertJsonSchemaPayloadToString(v.payload);
          v.actionId = props.actionId;
          createPlan(v);
        }}
      >
        {!loading && selectedAction?.payloadSchema && props.actionId && (
          <Form.Item name={'payload'} dependencies={['actionId']}>
            <JsonSchemaInput
              projectId={props.projectId || ''}
              schema={selectedAction?.payloadSchema}
              layoutResponsive={props.layoutResponsive || false}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading || creating || loadingTask}
              loading={loading || creating || loadingTask}
            >
              提交执行
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export function ProjectInternalPlanButton(props: {
  actionId: string;
  projectId: string;
  children: (actions: { show: () => void; hide: () => void }) => ReactNode;
  defaultPayload?: { [key: string]: unknown };
  layoutResponsive?: boolean;
}) {
  const [taskId, setTaskId] = useState('');
  const [switching, setSwitching] = useState(false);
  return (
    <CmtModalButton
      title={'快捷任务'}
      trigger={props.children}
      onClose={() => {
        setTaskId('');
      }}
    >
      {({ hide }) => (
        <Space direction="vertical" style={{ width: 'calc(100% - 8px)' }}>
          <Spin spinning={switching}>
            <div style={{ minHeight: 100 }}>
              {!taskId ? (
                <ProjectInternalPlan
                  projectId={props.projectId}
                  actionId={props.actionId}
                  defaultPayload={props.defaultPayload}
                  layoutResponsive={props.layoutResponsive}
                  onSuccess={(taskId) => {
                    if (taskId) {
                      setSwitching(true);
                      setTimeout(() => {
                        setTaskId(taskId);
                        setSwitching(false);
                      }, 1000);
                    } else {
                      hide();
                    }
                  }}
                />
              ) : (
                <div style={{ height: '50vh', maxHeight: 400, paddingTop: 12 }}>
                  <EventList
                    taskId={taskId}
                    pollingInterval={5000}
                    containerPaddingSize={1}
                  />
                </div>
              )}
            </div>
          </Spin>
        </Space>
      )}
    </CmtModalButton>
  );
}
