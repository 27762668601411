import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';

export enum UserStatus {
  /**
   * 待激活
   */
  Pending = 'Pending',
  /**
   * 激活状态
   */
  Active = 'Active',
  /**
   * 离职状态
   */
  Dimission = 'Dimission',
}

export const GpoUserStatusOptions = [
  { label: '待激活', value: UserStatus.Pending },
  { label: '激活中', value: UserStatus.Active },
  { label: '已离职', value: UserStatus.Dimission },
];

export function getGpoUserStatusOptionName(status: UserStatus) {
  return GpoUserStatusOptions.find((x) => x.value === status)?.label || '';
}

export interface UserDto {
  id: string;
  name: string;
  organizationId: string;
  email: string;
  mobilePhone: string;
  status: UserStatus;
  createdAt: string;
  role: string;
  isTwoFactorAuthenticationEnabled: boolean;
}

export async function getMyProfile(): Promise<UserDto> {
  const res: any = await httpClient.get('/me');
  return res;
}

export async function updateMyProfile(params: {
  name: string;
  email: string;
  mobilePhone: string;
}): Promise<any> {
  const res: any = await httpClient.patch('/me', params);
  return res;
}

export async function updateMyPassword(params: {
  oldPwd: string;
  newPwd: string;
  confirmPwd: string;
}): Promise<any> {
  const res: any = await httpClient.patch('/me/pwd', params);
  return res;
}

export async function queryUsersApi(
  params: PagingRequest
): Promise<PagingResponse<UserDto>> {
  const res: PagingResponse<UserDto> = await httpClient.get('/users', {
    params,
  });
  return res;
}

export async function queryActiveUsersApi(
  params: PagingRequest
): Promise<PagingResponse<UserDto>> {
  const res: PagingResponse<UserDto> = await httpClient.get('/users/active-users', {
    params,
  });
  return res;
}

export async function updateSingleUserApi(
  id: string,
  params: any
): Promise<UserDto> {
  const res: any = await httpClient.patch(`/users/${id}`, params);
  return res;
}

export async function deleteSingleProjectApi(id: string): Promise<UserDto> {
  const res: any = await httpClient.delete(`/users/${id}`);
  return res;
}

export async function createSingleUserApi(params: any): Promise<UserDto> {
  const res: any = await httpClient.post(`/users`, params);
  return res;
}

export async function setup2FAApi(): Promise<{
  otpauthUrl: string;
  secret: string;
}> {
  const res: any = await httpClient.post(`/me/two-factor-authentication/setup`);
  return res;
}

export async function active2FAApi(param: { code: string }): Promise<void> {
  const res: any = await httpClient.post(
    `/me/two-factor-authentication/active`,
    param
  );
  return res;
}

export async function disable2FAApi(param: { code: string }): Promise<void> {
  const res: any = await httpClient.post(
    `/me/two-factor-authentication/disable`,
    param
  );
  return res;
}

export async function getUserRoles() {
  return Promise.resolve([
    { name: 'Owner', displayName: '管理员' },
    { name: 'Member', displayName: '普通成员' },
    { name: 'ProductAssitance', displayName: '产品运营' },
  ]);
}
