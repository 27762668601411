import {
  useDeepCompareEffect,
  useInfiniteScroll,
  useThrottleEffect,
  useThrottleFn,
  useUpdateEffect,
} from 'ahooks';
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import { ENV_CONFIG } from '../../env-config';

export interface InfiniteSelectProps extends SelectProps {
  defaultOptions?: DefaultOptionType[];
  defaultParams?: { [key: string]: any };
  option: (item: any) => DefaultOptionType;
  api: (req: PagingRequest) => Promise<PagingResponse<any>>;
  mode?: 'multiple' | 'tags';
  showSearch?: boolean;
}
const PAGE_SIZE = Number(ENV_CONFIG.DEFAULT_PAGE_SIZE || '20');

export function InfiniteSelect(props: InfiniteSelectProps) {
  const [failed, setFailed] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const { data, loading, loadMore, mutate, reload } = useInfiniteScroll(
    (d) => {
      const page = d ? Math.ceil(d.list.length / PAGE_SIZE) + 1 : 1;
      return props.api({
        current: page,
        pageSize: PAGE_SIZE,
        ...props.defaultParams,
        _searchKeyword: searchKeyword,
      });
    },
    {
      manual: true,
      threshold: 10,
      onSuccess() {
        setFailed('');
      },
      onError(e) {
        setFailed(e.message);
      },
    }
  );

  useThrottleEffect(
    () => {
      reload();
    },
    [searchKeyword],
    {
      wait: 300,
    }
  );

  const { run } = useThrottleFn(
    (e: any) => {
      const { target } = e;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        const hasMore = data && data.list.length < data.total;
        console.log('到底了');
        hasMore && loadMore();
      }
    },
    { wait: 200 }
  );
  let options = data?.list.map((x) => props.option(x)) || [];
  if (props.defaultOptions?.length) {
    options = props.defaultOptions.concat(options);
  }

  useDeepCompareEffect(() => {
    reload();
  }, [props.defaultOptions, props.defaultParams]);

  return (
    <Select
      showSearch={props.showSearch}
      loading={loading}
      {...props}
      options={options}
      status={failed ? 'error' : ''}
      onPopupScroll={run}
      mode={props.mode}
      getPopupContainer={(t) => t.parentNode}
      onSearch={(v) => {
        setSearchKeyword(props.showSearch ? v || '' : '');
      }}
    />
  );
}
