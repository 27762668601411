import { Button, message, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from './CmtPopConfirmAction';
import { PropsWithChildren } from 'react';
const { Text } = Typography;

interface Props<ObjectType> {
  data: ObjectType[];
  onSuccess?: () => void;
  actionName: string;
  action: (data: ObjectType) => Promise<any>;
  enable: (data: ObjectType[]) => boolean;
}

export function BatchListActionButton<ObjectType extends { name: string }>(
  props: PropsWithChildren<Props<ObjectType>>
) {
  const [messageApi, contextHolder] = message.useMessage();
  const batchRemoveGoodMarketProducts = async (input: ObjectType[]) => {
    const key = `msg-${new Date().getTime()}`;
    const loadingKey = key + 'loading';
    messageApi.success({
      key: loadingKey,
      type: 'loading',
      duration: 0,
      content: `正在执行${props.actionName}，请稍后...0/${input.length}`,
    });
    for (let index = 0; index < input.length; index++) {
      messageApi.success({
        key: loadingKey,
        type: 'loading',
        duration: 0,
        content: `正在执行${props.actionName}，请稍后...${index + 1}/${
          input.length
        }`,
      });
      const element = input[index];
      try {
        const res = await props.action(element);
        if (!res?.success) {
          throw new Error(res?.message);
        }
      } catch (error: any) {
        messageApi.error(
          `${props.actionName}【${element?.name}】失败，原因：${
            error?.message || ''
          }`
        );
      }
    }
    messageApi.destroy(loadingKey);
  };

  return (
    <>
      {contextHolder}
      <CmtPopConfirmAction
        service={batchRemoveGoodMarketProducts}
        title={
          <Space direction="vertical" style={{ maxWidth: 300 }}>
            <Text strong>{`确定批量操作${
              props.data?.length || 0
            }个商品吗？`}</Text>
          </Space>
        }
        onOk={({ data, run }) => {
          run(data);
        }}
        data={props.data}
        onSuccess={props.onSuccess}
        successMessage="操作完成"
        placement="top"
        disabled={!props.data?.length || !props.enable(props.data)}
      >
        {({ loading }) => (
          <Button
            type="default"
            shape="round"
            loading={loading}
            disabled={
              loading || !props.data?.length || !props.enable(props.data)
            }
          >
            批量{props.actionName} ({props.data?.length || 0})
          </Button>
        )}
      </CmtPopConfirmAction>
    </>
  );
}
