import { Select } from 'antd';
import { RJDeliveryTimes } from '../../../../../services/rjmart/product-modal';

export function RJMDeliveryTimeSelect(props: {
  value?: number;
  style?: React.CSSProperties;
  onChange?: (val: number) => void;
}) {
  return (
    <Select
      style={props.style}
      value={props.value}
      onChange={props.onChange}
      options={RJDeliveryTimes.map((x) => {
        return { label: x.name, value: x.value };
      })}
    ></Select>
  );
}
