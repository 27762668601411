import {
  Cascader,
  Col,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { GpoCategoryCascader } from '../../gpo/components/CategoryCascader';
import { GoodsBrandSelect } from '../../gpo/components/GoodsBrandSelect';
import { GpoJiCaiAgreementSelect } from '../../gpo/components/JiCaiAgreementSelect';
import { GpoMultipleCategoryCascader } from '../../gpo/components/MultipleCategoryCascader';
import { GpoMultipleJiCaiDynamicAgreementCategoryCascader } from '../../gpo/components/MultipleJiCaiAgreementCategoryCascader';
import { RJMMultipleCategoryCascader } from '../../rjmart/components/MultipleCategoryCascader';
import { queryProjectApi, Project } from '../../services/project.service';
import { InfiniteSelect } from '../InfiniteSelect';
import { RJMCategoryCascader } from '../../rjmart/components/CategoryCascader';
import { RJMBrandSelect } from '../../rjmart/pages/sub-pages/product-edit-form/product-form/RJMBrandSelect';
import { RJMDeliveryTimeSelect } from '../../rjmart/pages/sub-pages/product-edit-form/product-form/delivery-time-select';
import { CarryFeeTemplateIdInput } from '../../rjmart/pages/sub-pages/product-edit-form/product-form/carry-fee-template-input';
import { RJMUnitSelect } from '../../rjmart/pages/sub-pages/product-edit-form/product-form/unit-select';
import { GpoJiCaiDynamicAgreementCategorySelect } from '../../gpo/components/JiCaiAgreementCategorySelect';
import { CasCategoryCascader } from '../../cas/components/CategoryCascader';
import { CasBrandSelect } from '../../cas/components/CasBrandSelect';
import { CasCategoryTypeSelect } from '../../cas/components/CategoryTypeSelect';
import { CasCategoryUnitSelect } from '../../cas/components/CategoryUnitSelect';
import { CasGroupCascader } from '../../cas/components/GroupCascader';
const { SHOW_CHILD } = Cascader;

export interface JsonSchemaInputProps {
  projectId: string;
  value?: any;
  onChange?: (value: any) => void;
  schema: any | null;
  layoutResponsive?: boolean;
}

export function JsonSchemaInput(props: JsonSchemaInputProps) {
  const [form] = Form.useForm();
  const [formItemDefs, setFormItemDefs] = useState<
    { name: string; conf: any }[]
  >([]);
  const onChange = () => {
    const v = form.getFieldsValue();
    console.log('JsonSchemaInput', v);
    props.onChange?.(v);
  };
  const initFormItemDefs = (schema: any) => {
    const { properties } = schema;
    const tempFormItemDefs = Object.keys(properties).map((x) => {
      return { name: x, conf: properties[x] };
    });
    setFormItemDefs(tempFormItemDefs);
    return tempFormItemDefs;
  };

  const setDefaultFormValue = (tempFormItemDefs: any[]) => {
    // 设置表单值
    const defaultVals: any = {};
    const instanceValue = props.value || {};
    tempFormItemDefs.forEach((item) => {
      defaultVals[item.name] = [null, undefined].includes(
        instanceValue[item.name]
      )
        ? item.conf.default
        : instanceValue[item.name];
    });
    form.setFieldsValue(defaultVals);
    props.onChange?.(defaultVals);
  };

  useEffect(() => {
    if (!props.schema) {
      props.onChange?.(null);
      setFormItemDefs([]);
      return;
    }
    if (props.schema?.type !== 'object') {
      throw new Error('Schema不正确');
    }
    const tempFormItemDefs = initFormItemDefs(props.schema);
    setDefaultFormValue(tempFormItemDefs);
  }, [props.schema, props.projectId]);

  useEffect(() => {
    console.log('JsonSchemeInput', props.value);
  }, []);

  const explainDefaultParams = (defaultParams?: { [key: string]: any }) => {
    const res: any = {};
    if (!defaultParams) {
      return res;
    }
    const keys = Object.keys(defaultParams);
    keys.forEach((key: string) => {
      const value = defaultParams[key];
      if (value.dependencies) {
        res[key] = form.getFieldValue(value.dependencies);
      } else if (value.global) {
        const globalKey: string = value.global;
        res[key] = Object.keys(props).includes(globalKey)
          ? (props as any)[globalKey]
          : '';
      } else {
        res[key] = value;
      }
    });
    return res;
  };

  const getFormControl = (conf: {
    anyOf?: any[];
    type: string;
    contentMediaType?: string;
    defaultParams?: {
      [key: string]: string | number | { dependencies: string[] };
    };
    [key: string]: any;
  }) => {
    if (conf.anyOf?.length) {
      const options = [
        ...conf.anyOf.map((x: any) => {
          return { label: x.title, value: x.default };
        }),
      ];
      return <Select options={options} onChange={onChange} allowClear></Select>;
    }
    if (conf.contentMediaType === 'hidden') {
      return <Input />;
    }
    if (conf.type === 'integer' || conf.type === 'number') {
      if (conf.contentMediaType === 'entity(RJMCategory)') {
        return (
          <RJMCategoryCascader
            style={{ width: '100%' }}
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(RJSupportBrand)') {
        return (
          <RJMBrandSelect
            style={{ width: '100%' }}
            queryParams={{ ...explainDefaultParams(conf.defaultParams) }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(RJDeliveryTimes)') {
        return (
          <RJMDeliveryTimeSelect
            style={{ width: '100%' }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(RJDeliverCostTemplate)') {
        return (
          <CarryFeeTemplateIdInput
            style={{ width: '100%' }}
            queryParams={{ ...explainDefaultParams(conf.defaultParams) }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(CASCategory)') {
        return (
          <CasCategoryCascader
            style={{ width: '100%' }}
            single
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(CASCategoryType)') {
        return (
          <CasCategoryTypeSelect
            style={{ width: '100%' }}
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(CASGroup)') {
        return (
          <CasGroupCascader
            style={{ width: '100%' }}
            single
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(CASBrand)') {
        return (
          <CasBrandSelect
            style={{ width: '100%' }}
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            allowClear
            onChange={onChange}
          />
        );
      }
      return (
        <InputNumber
          style={{ width: '100%' }}
          min={conf.minimum}
          max={conf.maximum}
          onChange={onChange}
        ></InputNumber>
      );
    }
    // 字符串在这里， 数组在后面
    if (conf.type === 'string' && conf.contentMediaType) {
      if (conf.contentMediaType === 'entity(Project)') {
        return (
          <InfiniteSelect
            style={{ width: '100%' }}
            api={(req) => {
              return queryProjectApi({
                ...req,
                ...explainDefaultParams(conf.defaultParams),
              });
            }}
            onChange={onChange}
            placeholder="请选择执行任务的项目"
            option={(item: Project) => {
              return {
                label:
                  item.name +
                  `${!item.hasAuthValue ? '（未设置身份Token）' : ''}`,
                value: item.id,
                raw: item,
                disabled: !item.hasAuthValue,
              };
            }}
          />
        );
      } else if (conf.contentMediaType === 'entity(GpoCategory)') {
        return (
          <GpoCategoryCascader
            queryParams={{
              projectId: props.projectId,
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(GpoBrand)') {
        return (
          <GoodsBrandSelect
            allowClear
            onChange={onChange}
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
          />
        );
      } else if (conf.contentMediaType === 'entity(SupplierAgreement)') {
        return (
          <GpoJiCaiAgreementSelect
            queryParams={{
              projectId: props.projectId,
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'component(TextArea)') {
        return <TextArea rows={4} onChange={onChange} allowClear />;
      } else if (conf.contentMediaType === 'entity(RJMUnit)') {
        return (
          <RJMUnitSelect
            style={{ width: '100%' }}
            onChange={onChange}
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
          />
        );
      } else if (
        conf.contentMediaType === 'entity(SupplierAgreementCategory)'
      ) {
        return (
          <GpoJiCaiDynamicAgreementCategorySelect
            queryParams={{
              projectId: props.projectId,
              ...explainDefaultParams(conf.defaultParams),
            }}
            showCheckedStrategy={SHOW_CHILD}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(CASCategoryUnit)') {
        return (
          <CasCategoryUnitSelect
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      }
    } else if (conf.type === 'array' && conf.contentMediaType) {
      if (conf.contentMediaType === 'entity(GpoCategory[])') {
        return (
          <GpoMultipleCategoryCascader
            queryParams={{
              projectId: props.projectId,
              ...explainDefaultParams(conf.defaultParams),
            }}
            showCheckedStrategy={SHOW_CHILD}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(RJMCategory[])') {
        return (
          <RJMMultipleCategoryCascader
            queryParams={{
              projectId: props.projectId,
              ...explainDefaultParams(conf.defaultParams),
            }}
            showCheckedStrategy={SHOW_CHILD}
            onChange={onChange}
          />
        );
      } else if (
        conf.contentMediaType === 'entity(SupplierAgreementCategory[])'
      ) {
        return (
          <GpoMultipleJiCaiDynamicAgreementCategoryCascader
            queryParams={{
              projectId: props.projectId,
              ...explainDefaultParams(conf.defaultParams),
            }}
            showCheckedStrategy={SHOW_CHILD}
            onChange={onChange}
          />
        );
      } else if (conf.contentMediaType === 'entity(GpoBrand[])') {
        return (
          <GoodsBrandSelect
            onChange={onChange}
            mode="multiple"
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
          />
        );
      } else if (conf.contentMediaType === 'entity(CasCategory[])') {
        return (
          <CasCategoryCascader
            style={{ width: '100%' }}
            queryParams={{
              ...explainDefaultParams(conf.defaultParams),
            }}
            onChange={onChange}
          />
        );
      }
    } else {
      return <Input onChange={onChange} allowClear></Input>;
    }
  };
  let formSpan = ((itemCount: number) => {
    if (props.layoutResponsive === false) {
      return 24;
    }
    if (itemCount === 1) {
      return 24;
    } else if (itemCount % 3 === 0) {
      return 8;
    } else if (itemCount % 2 === 0) {
      return 12;
    }
    return 8;
  })(formItemDefs.length);
  return (
    <Form form={form} component={false}>
      <Row gutter={12}>
        {formItemDefs.map((def) => {
          const { name, conf } = def;
          let finalSpan = formSpan;
          const isHidden = conf.contentMediaType === 'hidden';
          const formItemProps: FormItemProps<any> = {
            name: name,
            label: conf.title,
            help: conf.description,
            hidden: isHidden,
            rules: props.schema?.required?.includes(name)
              ? [{ required: true }]
              : [],
          };
          if (conf.contentMediaType === 'component(TextArea)') {
            finalSpan = 24;
          }
          return (
            <Col
              span={finalSpan}
              key={name}
              style={isHidden ? { display: 'none' } : {}}
            >
              <Form.Item {...formItemProps}>{getFormControl(conf)}</Form.Item>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
}
