import { useContext, useState, useEffect, useMemo } from 'react';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';

export function checkPermission(
  resource: string,
  action: string,
  userMetaData?: { k: string; v: string }[]
) {
  if (userMetaData?.length) {
    const actionDef =
      userMetaData.find((x) => x.k === JwtMetaItemKeys.actions)?.v || '{}';
    const permissions: Record<string, string[]> = JSON.parse(actionDef);
    const keys = Object.keys(permissions);
    if (
      keys.includes(resource) &&
      (permissions[resource].includes('*') ||
        permissions[resource].includes(action))
    ) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function usePermission(resource: string, action: string) {
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [hasPermission, setHasPermisson] = useState(false);
  useMemo(() => {
    setHasPermisson(
      checkPermission(resource, action, globalContext.userMetaData)
    );
  }, [
    globalContext.userMetaData?.find((x) => x.k === JwtMetaItemKeys.mobile)?.v,
  ]);
  return hasPermission;
}
