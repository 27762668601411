import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  Descriptions,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { useEffect } from 'react';
import {
  GPOOrderDto,
  queryOrderDetailWithoutMetaApi,
} from '../../../services/gpo/order.service';

export function BuyInfoView(props: { order: GPOOrderDto; projectId: string }) {
  const { data, loading, run } = useRequest(queryOrderDetailWithoutMetaApi, {
    manual: true,
  });
  useEffect(() => {
    if (props.order) {
      run({ orderGuid: props.order.orderGuid, projectId: props.projectId });
    }
  }, [props.order]);
  return (
    <Spin spinning={loading}>
      <div style={{ maxWidth: 300 }}>
        <Row gutter={12}>
          <Col>客户单位：</Col>
          <Col>{data?.orgName}</Col>
        </Row>
        <Row gutter={12}>
          <Col>客户姓名：</Col>
          <Col>{data?.linkman}</Col>
        </Row>
        <Row gutter={12}>
          <Col>联系电话：</Col>
          <Col>{data?.linkMobile}</Col>
        </Row>
        <Row gutter={12}>
          <Col>收货地址：</Col>
          <Col>{data?.deliveryAddress}</Col>
        </Row>
      </div>
    </Spin>
  );
}

export function BuyInfoButton(props: {
  order: GPOOrderDto;
  projectId: string;
}) {
  return (
    <Popover
      title={'收货客户资料'}
      content={<BuyInfoView order={props.order} projectId={props.projectId} />}
    >
      <Typography.Text ellipsis={true} style={{ width: 80 }}>
        {props.order?.deliveryAddress}
      </Typography.Text>
    </Popover>
  );
}
