import { BarsOutlined, SyncOutlined, PictureOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Avatar,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtPrint } from '../../../../components/CmtPrint';
import { CmtTable } from '../../../../components/CmtTable';
import { CmtWaterMark } from '../../../../components/CmtWaterMark';
import {
  getOrderStatusName,
  GPOOrderDto,
  GPOOrderStatus,
  GpoOrderStatusOptions,
  queryOrderApi,
} from '../../../../services/gpo/order.service';
import { PrintContractButton } from '../../../components/PrintContractButton';
import { ProductName } from '../../../components/ProductName';
import { OrderDetailView } from './order-detail';
import { PrintableDeliverList } from './printable-deliver-list';
import { PrintableReceivingForm } from './printable-receiving-form';

const { Text } = Typography;

export function OrderListPage() {
  const params = useParams();
  const [selectedOrder, setSelectedOrder] = useState<GPOOrderDto | null>(null);
  const [activeTabKey, setActiveTabKey] = useState<string>('2');
  useTitle('订单中心', { restoreOnUnmount: true });
  const statusOptions = [
    { value: '', label: '全部' },
    ...GpoOrderStatusOptions,
  ];

  const columns: ColumnsType<GPOOrderDto> = [
    {
      title: '订单编号',
      dataIndex: 'orderCode',
      key: 'orderCode',
      width: 140,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedOrder(record);
              setActiveTabKey('1');
            }}
          >
            {value}
          </Button>
        );
      },
    },
    {
      title: '订单商品',
      key: 'goods',
      render: (value, record, index) => {
        return record.orderGoodsVOList.map((x) => (
          <Space size={'small'} key={x.orderGoodsGuid}>
            <ProductName
              picturePath={x.picturePath}
              name={x.goodsName}
              goodsPriceGuid={x.goodsPriceGuid}
              extends={
                <Text code>
                  {ConvertNumberToCurrency(x.finalPrice)}x{x.qty}
                </Text>
              }
            />
          </Space>
        ));
      },
    },
    {
      title: '采购单位',
      dataIndex: 'orgName',
      key: 'orgName',
      width: 300,
      render(value, record, index) {
        return (
          <Tooltip title={record.orgName}>
            <Typography.Text ellipsis={true}>{record.orgName}</Typography.Text>
          </Tooltip>
        );
      },
    },
    // {
    //   title: '区域',
    //   dataIndex: 'regionfullName',
    //   key: 'regionfullName',
    //   width: 180,
    // },
    {
      title: '订单总价',
      dataIndex: 'goodsTotal',
      width: 100,
      key: 'goodsTotal',
      render: (value) => {
        return ConvertNumberToCurrency(value);
      },
    },
    {
      title: '订单状态',
      dataIndex: 'auditStatus',
      key: 'auditStatus',
      width: 100,
      render: (value) => {
        return getOrderStatusName(value);
      },
    },
    {
      title: '下单时间',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 150,
    },
    {
      title: '操作',
      width: 100,
      key: 'actions',
      fixed: 'right',
      render: (value, record, index) => {
        if (record.auditStatus === GPOOrderStatus.Cancelled) {
          return (<>-</>);
        }
        return (
          <Space direction="vertical" size={'small'}>
            {record.auditStatus !== GPOOrderStatus.ConfirmPending &&
              (record.auditStatus === GPOOrderStatus.Confirmed ? (
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    setSelectedOrder(record);
                    setActiveTabKey(record.logisticsGuid ? '1' : '3');
                  }}
                >
                  <Typography.Text type="danger">
                    {record.logisticsGuid ? '发货' : '添加物流'}
                  </Typography.Text>
                </Button>
              ) : (
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    setSelectedOrder(record);
                    setActiveTabKey('3');
                  }}
                >
                  查看物流
                </Button>
              ))}
            <Button
              type="link"
              size="small"
              onClick={() => {
                setSelectedOrder(record);
                setActiveTabKey('5');
              }}
            >
              {record.contractGuid ? (
                '查看合同'
              ) : (
                <Typography.Text type="danger">起草合同</Typography.Text>
              )}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setSelectedOrder(record);
                setActiveTabKey('6');
              }}
            >
              {record.invoicePath ? (
                '查看发票'
              ) : (
                <Typography.Text type="danger">上传发票</Typography.Text>
              )}
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '订单管理' },
        { name: '订单中心' },
      ]}
    >
      <CmtTable
        title="订单中心"
        description="订单中心的数据直接读取自智慧云，可能会存在波动"
        rowKey="orderGuid"
        api={queryOrderApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="projectId"
                  initialValue={params.projectId}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  name={'auditStatus'}
                  label={'状态'}
                  initialValue={''}
                >
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'orderCode'} label={'订单编号'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入订单编号"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'orgName'} label={'采购单位'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入采购单位"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
      <Drawer
        width={800}
        open={!!selectedOrder}
        title={`${selectedOrder?.orderCode}订单详情`}
        onClose={() => setSelectedOrder(null)}
        destroyOnClose={true}
        extra={
          <Space>
            <PrintContractButton
              contractGuid={selectedOrder?.contractGuid || ''}
              projectId={params.projectId || ''}
            />
            <CmtPrint
              trigger={({ show }) => <Button onClick={show}>打印送货单</Button>}
            >
              {({ ref }) => (
                <PrintableDeliverList
                  ref={ref}
                  orderGuid={selectedOrder?.orderGuid || ''}
                  projectId={params.projectId || ''}
                />
              )}
            </CmtPrint>
            <CmtPrint
              trigger={({ show }) => (
                <Button onClick={show}>打印送验收单</Button>
              )}
            >
              {({ ref }) => (
                <PrintableReceivingForm
                  ref={ref}
                  orderGuid={selectedOrder?.orderGuid || ''}
                  projectId={params.projectId || ''}
                />
              )}
            </CmtPrint>
          </Space>
        }
      >
        <CmtWaterMark>
          <OrderDetailView
            simpleOrder={selectedOrder}
            activeTabKey={activeTabKey}
            projectId={params.projectId || ''}
          />
        </CmtWaterMark>
      </Drawer>
    </BlankWrapper>
  );
}
