import { Navigate, RouteObject } from 'react-router-dom';
import { RequireAuth } from './components/RequireAuth';
import { ENV_CONFIG } from './env-config';
import { gpoRouters } from './gpo/routers';
import { MainLayout } from './layouts/main-layout/main-layout';
import { AdminSettingsPage } from './pages/admin';
import { adminRouters } from './pages/admin/routers';
import { Dashboard } from './pages/dashboard';
import { Exception404 } from './pages/exception/404';
import { LoginPage } from './pages/login';
import { TwoFactorForceEnablePage } from './pages/login/two-factor-force-page';
import { PlanPage } from './pages/plans';
import { UserProfilePage } from './pages/profile';
import { TaskPage } from './pages/tasks';
import { rjmartRouters } from './rjmart/routers';
import { casRouters } from './cas/routers';
export const routers: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <Exception404 />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'gpo',
        children: gpoRouters,
      },
      {
        path: 'rjmart',
        children: rjmartRouters,
      },
      {
        path: 'cas',
        children: casRouters,
      },
      {
        path: 'plans',
        element: (
          <RequireAuth>
            <PlanPage />
          </RequireAuth>
        ),
      },
      {
        path: 'tasks',
        element: (
          <RequireAuth>
            <TaskPage />
          </RequireAuth>
        ),
      },
      {
        path: 'me',
        element: <UserProfilePage />,
      },
      {
        path: 'admin',
        element: <AdminSettingsPage />,
        children: adminRouters,
      },
      {
        path: '',
        element: <Navigate to={ENV_CONFIG.DEFAULT_PAGE} replace />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/enable-2fa',
    element: <TwoFactorForceEnablePage />,
  },
];
