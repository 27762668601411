import { useRequest } from 'ahooks';
import { Select } from 'antd';
import { useEffect } from 'react';
import { CategoryId } from '../../../common/dtos/base-category';
import { queryAllBrandsByClassAndAgreements } from '../../../services/gpo/standard-goods.service';

export function GoodsBrandSelect(props: {
  queryParams: {
    agreementType: string;
    goodsClassGuid: CategoryId;
    projectId?: string;
  };
  mode?: 'multiple' | 'tags';
  value?: string;
  style?: React.CSSProperties;
  onChange?: (v: string) => void;
  onChangeWithRaw?: (raw: {
    goodsBrandName: string;
    goodsBrandGuid: string;
  }) => void;
  allowClear?: boolean;
}) {
  const { data, loading, run } = useRequest(
    queryAllBrandsByClassAndAgreements,
    {
      debounceWait: 300,
      manual: true,
    }
  );
  useEffect(() => {
    if (props.queryParams.goodsClassGuid || props.queryParams.projectId) {
      run({
        goodsClassGuid: props.queryParams.goodsClassGuid,
        agreementType: props.queryParams.agreementType,
        projectId: props.queryParams.projectId,
      });
    }
  }, [props.queryParams.goodsClassGuid, props.queryParams.projectId]);
  return (
    <Select
      style={props.style}
      mode={props.mode}
      loading={loading}
      onChange={(v, opt) => {
        props.onChange?.(v);
        const { label } = (opt as any) || {};
        props.onChangeWithRaw?.({
          goodsBrandName: label,
          goodsBrandGuid: v,
        });
      }}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={data?.map((x) => {
        return {
          label: x.goodsBrandName,
          value: x.goodsBrandGuid,
        };
      })}
      value={props.value}
      allowClear={props.allowClear}
      placeholder="品牌"
    ></Select>
  );
}
