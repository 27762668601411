import useUrlState from '@ahooksjs/use-url-state';
import { useTitle, usePagination, useSessionStorageState } from 'ahooks';
import {
  Typography,
  message,
  Space,
  Input,
  Button,
  Skeleton,
  Empty,
  Row,
  Col,
  Card,
  Pagination,
  Image,
} from 'antd';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ConvertDate } from '../../../common/utils/convert-date';
import { ENV_CONFIG } from '../../../env-config';
import {
  ProjectPlatforms,
  queryProjectApi,
} from '../../../services/project.service';
import { CmtModalButton } from '../../CmtModalButton';
import { PageHeader } from '../../PageHeader';
import { usePermission } from '../../Permission/use-permission';
import { OpenProjectButton } from './OpenProjectButton';
import styles from './index.module.less';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;

export function ProjectList(props: { platform: ProjectPlatforms }) {
  useTitle('项目列表');
  const [lastProjectId, setLastProjectId] = useSessionStorageState<string>(
    'cmt-selected-pid',
    { defaultValue: '' }
  );
  const navigate = useNavigate();
  const canUpdateProject = usePermission('project', 'update');
  const [state, setState] = useUrlState({
    current: '1',
    pageSize: ENV_CONFIG.DEFAULT_PAGE_SIZE,
    keyword: '',
  });
  const { data, loading, pagination, run, params, refresh } = usePagination(
    ({ current, pageSize }, keyword: string) => {
      return queryProjectApi({
        current,
        pageSize,
        keyword,
        platform: props.platform,
      });
    },
    {
      manual: true,
      loadingDelay: 200,
      debounceWait: 500,
      onError(e) {
        message.error(e.message);
      },
    }
  );
  useEffect(() => {
    run(
      {
        current: Number(state.current),
        pageSize: params[0]?.pageSize || Number(state.pageSize),
      },
      state.keyword
    );
  }, [state]);
  useEffect(() => {
    if (lastProjectId && lastProjectId.startsWith(`/${props.platform}/`)) {
      navigate(`${lastProjectId}`);
    } else {
    }
  }, []);
  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <PageHeader
        title="项目列表"
        description={``}
        extends={
          <Space>
            <Input.Search
              placeholder="输入项目名称进行搜索"
              allowClear={true}
              onChange={(e) => setState({ ...state, keyword: e.target.value })}
            ></Input.Search>
            {/* <Permission resource="project" action="create">
              <CmtModalButton
                title={'添加项目'}
                trigger={({ show }) => (
                  <Button type="default" onClick={show}>
                    添加项目
                  </Button>
                )}
              >
                {({ show, hide }) => (
                  <ProjectForm
                    onSuccess={() => {
                      hide();
                      refresh();
                    }}
                  />
                )}
              </CmtModalButton>
            </Permission> */}
          </Space>
        }
      ></PageHeader>
      {loading && <Skeleton />}
      {data?.list && !data.list.length && !loading && (
        <Empty description="无项目数据"></Empty>
      )}
      <Row gutter={[16, 16]}>
        {data?.list.map((item) => (
          <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
            <Card
              className={styles['project-card']}
              actions={
                canUpdateProject
                  ? [
                      <OpenProjectButton
                        project={item}
                        onSuccess={(p) =>
                          setLastProjectId(
                            `/${props.platform}/projects/${p.id}/`
                          )
                        }
                      />,
                      <NavLink to={`./${item.id}/settings`}>设置</NavLink>,
                    ]
                  : [
                      <OpenProjectButton
                        project={item}
                        onSuccess={(p) =>
                          setLastProjectId(
                            `/${props.platform}/projects/${p.id}/`
                          )
                        }
                      />,
                    ]
              }
            >
              <Image preview={false} height={40} src={item.logoUrl} />
              <Title level={5}>{item.name}</Title>
              <Paragraph>{item.description}</Paragraph>
              <Text type={'secondary'}>{ConvertDate(item.createdAt)}</Text>
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ textAlign: 'right' }}>
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={data?.total}
          onChange={(current, pageSize) =>
            setState({ ...state, current, pageSize })
          }
        />
      </div>
    </Space>
  );
}
