import { BarsOutlined } from '@ant-design/icons';
import { useRequest, useTitle } from 'ahooks';
import {
  Button,
  Card,
  message,
  Result,
  Space,
  Spin,
  Steps,
  Typography,
} from 'antd';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GPOGoodFormTemplateDto } from '../../../../../common/dtos/AddGoodTypes';
import { BlankWrapper } from '../../../../../components/BlankWrapper';
import {
  getCreationGoodForm,
  getCreationValidAgreementTypes,
} from '../../../../../services/gpo/goodmarket.service';
import { ProductForm } from '../product-form';
import {
  CreationCategoryCascader,
  CreationCategoryCascaderOutputDto,
} from '../product-form/creation-category-cascader';

export function ProductFormPage() {
  useTitle('添加商品', { restoreOnUnmount: true });
  const [selectedOption, setSelectedOption] =
    useState<CreationCategoryCascaderOutputDto>();
  const [current, setCurrent] = useState(0);
  const [goodForm, setGoodForm] = useState<GPOGoodFormTemplateDto>();
  const { data, loading } = useRequest(getCreationValidAgreementTypes, {
    debounceWait: 300,
    manual: false, // 临时设置
  });
  const { loading: loadingGoodForm, run: loadGoodForm } = useRequest(
    getCreationGoodForm,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        setGoodForm(data);
        setCurrent(current + 1);
      },
      onError(e, params) {
        message.error(e.message);
      },
    }
  );
  const stepItems = [
    {
      title: '选择品类和品牌',
      content: (
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={3}>选择品类和品牌</Typography.Title>
            <Typography.Paragraph>
              至少选择品目和品牌， 数据直接读取智慧云，请耐心等待。
            </Typography.Paragraph>
            <CreationCategoryCascader
              type={data?.[0] || 4}
              onChange={setSelectedOption}
            />
            <Button
              type="primary"
              disabled={
                !selectedOption?.goodsClassGuid ||
                !selectedOption.goodsBrandGuid ||
                loadingGoodForm
              }
              loading={loadingGoodForm}
              onClick={() => loadGoodForm(selectedOption)}
            >
              下一步
            </Button>
          </Space>
        </Card>
      ),
    },
    {
      title: '商品信息',
      content: goodForm && (
        <ProductForm
          goodForm={goodForm}
          onCancel={() => setCurrent(current - 1)}
          hasBack={true}
          onSuccess={() => setCurrent(current + 1)}
        ></ProductForm>
      ),
    },
    {
      title: '提交',
      content: (
        <Card>
          <Result
            status="success"
            title={'商品添加成功'}
            subTitle="添加完成的商品处于「未上架」状态，可以到「我的商品」中进行管理"
            extra={[
              <Button type="primary" onClick={() => setCurrent(0)}>
                继续添加
              </Button>,
              <NavLink to={`../goodmarket-products`}>
                <Button>前往管理</Button>
              </NavLink>,
            ]}
          ></Result>
        </Card>
      ),
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '货物超市' },
        { name: '添加商品' },
      ]}
    >
      <Spin spinning={loading}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Card>
            <Steps current={current} items={stepItems}></Steps>
          </Card>
          <div className="steps-content">{stepItems[current].content}</div>
        </Space>
      </Spin>
    </BlankWrapper>
  );
}
