import {
  BarsOutlined,
  SyncOutlined,
  PictureOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Avatar,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { ProductName } from '../../../../gpo/components/ProductName';
import {
  publishRJMProductApi,
  queryRJMartProductsApi,
  removeRJMProductApi,
  unPublishRJMProductApi,
} from '../../../../services/rjmart/product-mamagement.service';
import {
  RJProductStatuOptions,
  RJProductStatus,
  RJSimpleProductDto,
} from '../../../../services/rjmart/product-modal';
import { RJMCategoryName } from '../../../components/category-name';
import { RJMCategoryCascader } from '../../../components/CategoryCascader';
import { RJMProductStatusName } from '../../../components/status-name';
import { ChangeRJMStatusButton } from './change-status-button';
import { RemoveRJMGoodButton } from './remove-good-button';
import { EditButton } from './edit-button';
import { CancelReviewButton } from './cancel-review-button';
import { BatchListActionButton } from '../../../../components/batch-list-action-button';
import { InternalActionButton } from '../../../../components/InternalActionButton';

export function ProductListPage() {
  const params = useParams();
  useTitle('商品列表', { restoreOnUnmount: true });
  const statusOptions = [{ value: '', name: '全部' }, ...RJProductStatuOptions];
  const tableRef = useRef<any>(null);

  const columns: ColumnsType<RJSimpleProductDto> = [
    {
      title: '#',
      key: 'index',
      width: 40,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Typography.Text type={'secondary'}>
            {(index + 1).toString().padStart(3, '0')}
          </Typography.Text>
        );
      },
    },
    {
      title: '货号/型号',
      dataIndex: 'code',
      width: 140,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Space direction="vertical" size={'small'}>
            <span>{value}</span>
            {record.casNo ? <Tag>CAS:{record.casNo}</Tag> : null}
          </Space>
        );
      },
    },
    {
      title: '名称',
      dataIndex: 'platformId',
      width: 400,
      fixed: 'left',
      render(value, record, index) {
        return (
          <ProductName
            picturePath={record.photo}
            name={record.name}
            onClick={() => {
              window.open(
                `https://www.rjmart.cn/#/productDetail?productId=${record.id}`
              );
            }}
          />
        );
      },
    },
    {
      title: '品牌',
      width: 120,
      dataIndex: 'brandName',
    },

    {
      title: '价格（元）',
      width: 80,
      dataIndex: 'price',
      render: (value) => {
        return ConvertNumberToCurrency(value);
      },
    },
    {
      title: '库存',
      width: 80,
      dataIndex: 'sku',
    },
    {
      title: '分类',
      dataIndex: 'categoryId',
      width: 120,
      render(value, record, index) {
        return <RJMCategoryName id={value} />;
      },
    },
    {
      title: '单位',
      width: 60,
      dataIndex: 'unit',
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 60,
      render(value, record, index) {
        return (
          <RJMProductStatusName
            data={value}
            rejectComment={record.rejectComment}
          />
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: 120,
      render(value, record, index) {
        return (
          <Space>
            <EditButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            />
            <ChangeRJMStatusButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            ></ChangeRJMStatusButton>
            <CancelReviewButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            ></CancelReviewButton>
            <RemoveRJMGoodButton
              data={record}
              onSuccess={() => {
                setTimeout(() => tableRef.current.refresh(), 500);
              }}
            ></RemoveRJMGoodButton>
          </Space>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '锐竞', icon: <BarsOutlined /> },
        { name: '商品管理' },
        { name: '商品列表' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="商品列表"
        description=""
        rowKey="id"
        selectable={true}
        api={queryRJMartProductsApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="projectId"
                  initialValue={params.projectId}
                  hidden
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item name={'categoryId'} label={'品目'} initialValue={''}>
                  <RJMCategoryCascader
                    queryParams={{
                      projectId: params.projectId || '',
                    }}
                    style={{ width: 140 }}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item name={'status'} label={'状态'} initialValue={''}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions.map((x) => {
                      return { label: x.name, value: x.value };
                    })}
                  ></Select>
                </Form.Item>
                <Form.Item name={'productName'} label={'商品名称'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入商品名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'productNum'} label={'商品货号'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入商品货号"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              <NavLink to={'../create-product'}>
                <Button type="default">
                  <PlusOutlined /> 添加
                </Button>
              </NavLink>
              <InternalActionButton slot="rjmart-product-listing">
                <ThunderboltOutlined /> 采集
              </InternalActionButton>
            </Space>
          );
        }}
        secondaryExtends={({ selectedRows: rows }) => {
          const selectedRows: RJSimpleProductDto[] = rows;
          return (
            <Space>
              <Button
                type="default"
                shape="round"
                htmlType={'button'}
                onClick={() => tableRef.current.selectAll()}
              >
                全选
              </Button>
              <BatchListActionButton
                data={selectedRows}
                actionName={'下架'}
                action={async (item) => {
                  return await unPublishRJMProductApi([item.id]);
                }}
                enable={(d) =>
                  d.every((x) => [RJProductStatus.Publish].includes(x.status))
                }
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />

              <BatchListActionButton
                data={selectedRows}
                actionName={'上架'}
                action={async (item) => {
                  return await publishRJMProductApi([item.id]);
                }}
                enable={(d) =>
                  d.every((x) =>
                    [RJProductStatus.Unpublish, RJProductStatus.Draft].includes(
                      x.status
                    )
                  )
                }
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />
              <BatchListActionButton
                data={selectedRows}
                actionName={'删除'}
                action={async (item) => {
                  return await removeRJMProductApi([item.id]);
                }}
                enable={(d) =>
                  d.every((x) =>
                    [RJProductStatus.Unpublish, RJProductStatus.Draft].includes(
                      x.status
                    )
                  )
                }
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
