import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  GpoJiCaiOnSaleProductDto,
  GpoJiCaiOnSaleProductStatus,
  removeJiCaiProductApi,
} from '../../../../services/gpo/jicai.service';
import { removeRJMProductApi } from '../../../../services/rjmart/product-mamagement.service';
import {
  RJProductStatus,
  RJSimpleProductDto,
} from '../../../../services/rjmart/product-modal';

const { Text, Paragraph } = Typography;
export function RemoveRJMGoodButton(props: {
  data: RJSimpleProductDto;
  onSuccess?: () => void;
}) {
  if (
    ![
      RJProductStatus.Unpublish,
      RJProductStatus.Draft,
      RJProductStatus.Reject,
    ].includes(props.data.status)
  ) {
    return <></>;
  }
  return (
    <CmtPopConfirmAction
      service={removeRJMProductApi}
      title={
        <Space direction="vertical" style={{ maxWidth: 300 }}>
          <Text strong>{`确定移除吗？`}</Text>
          <Paragraph>{props.data.name}</Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run([data.id]);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button type="text" size="small" disabled={loading} loading={loading}>
          <DeleteOutlined />
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
