import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';

export enum GpoJiCaiOnSaleProductStatus {
  Published = 1,
  Unpublished = 0,
}

export const GpoJiCaiOnSaleProductStatusOptions = [
  { label: '已上架', value: GpoJiCaiOnSaleProductStatus.Published },
  { label: '未上架', value: GpoJiCaiOnSaleProductStatus.Unpublished },
];

export function getGpoJiCaiOnSaleProductStatusName(
  status: GpoJiCaiOnSaleProductStatus
) {
  return (
    GpoJiCaiOnSaleProductStatusOptions.find((x) => x.value === status)?.label ||
    ''
  );
}

export interface GpoJiCaiOnSaleProductDto {
  id: string;

  goodsGuid: string;

  goodsQuotePriceGuid: string;

  regionGuid: string;

  supplierGuid: string;

  agentSupplierGuid: string;

  name: string;

  goodsCode: string;

  goodsClassGuid: string;

  goodsClassName: string;

  goodsBrandGuid: string;

  goodsBrandName: string;

  picturePath: string;

  price: number;

  /**
   * 我的报价
   */
  quotePrice: number;

  createDate: string;

  goodsPriceGuid: string;

  valid: GpoJiCaiOnSaleProductStatus;

  sarightGuid: string;

  goodsQuotePriceChannel: string;

  /**
   * 厂商指导价
   */
  goodsPriceCurrentPrice: number;

  regionName: string;

  accessoryQuotePriceList: string;

  goodsQuotePromiseList: string;

  promiseGuids: string;

  sarightRegionList: string;

  supplierAgreementGuid: string;

  projectId: string;

  organiaztionId: string;

  frozen: boolean;

  competitorQuotePrice: string;
  competitorQuotePriceCreatedAt: string;

  [key: string]: any;
}

export async function queryJiCaiOnSaleProductsApi(
  params: PagingRequest
): Promise<PagingResponse<GpoJiCaiOnSaleProductDto>> {
  const res: any = await httpClient.get('/gpo/jicai-on-sale-products', {
    params,
  });
  return res;
}

export async function queryLocalJiCaiOnSaleProductsApi(
  params: PagingRequest
): Promise<PagingResponse<GpoJiCaiOnSaleProductDto>> {
  const res: any = await httpClient.get('/gpo/jicai-on-sale-products/local', {
    params,
  });
  return res;
}

export async function changeJiCaiOnSalProductStatusApi(
  product: GpoJiCaiOnSaleProductDto
) {
  const res: any = await httpClient.post(
    `/gpo/jicai-on-sale-products/toggle-status`,
    product
  );
  return res;
}

export async function changeJiCaiOnSalProductFrozenApi(
  product: GpoJiCaiOnSaleProductDto
) {
  const res: any = await httpClient.post(
    `/gpo/jicai-on-sale-products/switch-frozen-status`,
    product
  );
  return res;
}

export async function getJiCaiProductLiveDetails(
  good: GpoJiCaiOnSaleProductDto
): Promise<GpoJiCaiOnSaleProductDto> {
  const res: any = await httpClient.post(
    `/gpo/jicai-on-sale-products/good-platform-details`,
    good
  );
  return res;
}

export async function updateJiCaiProductLivePriceAndPromise(
  product: GpoJiCaiOnSaleProductDto,
  payload: {
    quotePrice: number;
    promiseGuids: string[];
  }
): Promise<GpoJiCaiOnSaleProductDto> {
  const res: any = await httpClient.post(
    `/gpo/jicai-on-sale-products/update-price-and-promises`,
    {
      product: product,
      payload: {
        quotePrice: payload.quotePrice,
        promiseGuids: payload.promiseGuids,
      },
    }
  );
  return res;
}

export async function removeJiCaiProductApi(
  product: GpoJiCaiOnSaleProductDto[]
) {
  const goodsQuotePriceGuids: string[] = product.map(
    (x) => x.goodsQuotePriceGuid
  );
  const res: any = await httpClient.post(
    `/gpo/jicai-on-sale-products/remove-good`,
    goodsQuotePriceGuids
  );
  return res;
}
