import { ReactNode } from 'react';
import { useFeatureToggle } from './use-feature-toggle';

export function FeatureToggle(props: {
  children: ReactNode;
  group: string;
  feature: string;
  callback?: (value: any, group: string) => boolean;
}) {
  const hasPermission = useFeatureToggle(props.group, props.feature);
  return hasPermission ? <>{props.children}</> : null;
}
