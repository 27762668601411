import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  batchDeleteDraftProductsApi,
  DraftProductDto,
} from '../../../../services/gpo/draft-product.service';

const { Text, Paragraph } = Typography;
export function SingleDeleteDraftProductButton(props: {
  data: DraftProductDto;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={batchDeleteDraftProductsApi}
      successMessage="批量添加商品成功"
      placement={'top'}
      title={
        <Space direction="vertical">
          <Text strong>{`确定删除临时库商品吗？`}</Text>
        </Space>
      }
      onOk={({ data, run }) => {
        run([props.data.id]);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button
          type="text"
          disabled={loading || !props.data}
          size="small"
          loading={loading}
          htmlType={'button'}
        >
          <DeleteOutlined />
        </Button>
      )}
    </CmtPopConfirmAction>
  );
  return null;
}
