import { useRequest } from 'ahooks';
import {
  Input,
  Select,
  Button,
  Space,
  message,
  Form,
  Divider,
  Popover,
  Typography,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REGEXS } from '../../../common/utils/formats';
import { CmtPasswordRules } from '../../../components/CmtPasswordRules';
import {
  createSingleUserApi,
  getUserRoles,
  GpoUserStatusOptions,
  updateSingleUserApi,
  UserDto,
  UserStatus,
} from '../../../services/user.service';

export function UserForm(props: {
  data?: UserDto | null | undefined;
  onSuccess?: (done: boolean) => void;
}) {
  const [form] = Form.useForm<any>();
  const [formMode, setFormMode] = useState<'new' | 'update'>('new');
  const { data: roles } = useRequest(getUserRoles);
  const { loading: updating, run: runUpdate } = useRequest(
    updateSingleUserApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('成员信息更新成功');
        props.onSuccess?.(true);
      },
      onError(e, params) {
        message.error('成员信息更新失败：' + e.message);
      },
    }
  );

  const { loading: creating, run: runCreate } = useRequest(
    createSingleUserApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('成员创建成功');
        props.onSuccess?.(true);
      },
      onError(e, params) {
        message.error('项目创建失败：' + e.message);
      },
    }
  );

  useEffect(() => {
    if (props.data) {
      setFormMode('update');
      form.setFieldsValue(props.data);
    } else {
      form.setFieldsValue({ role: 'Member', status: UserStatus.Pending });
    }
  }, [props.data]);
  return (
    <Form
      form={form}
      labelCol={{ span: 3 }}
      labelAlign={'left'}
      disabled={updating || creating}
      onFinish={() => {
        formMode === 'update'
          ? runUpdate(props.data?.id || '', form.getFieldsValue())
          : runCreate(form.getFieldsValue());
      }}
    >
      <br />
      <FormItem label="姓名" name={'name'} rules={[{ required: true }]}>
        <Input maxLength={50} />
      </FormItem>
      <FormItem
        label="手机"
        name={'mobilePhone'}
        rules={[
          { required: true },
          { pattern: REGEXS.mobilePhone, message: '格式不正确' },
        ]}
      >
        <Input maxLength={100} />
      </FormItem>
      <FormItem
        label="邮箱"
        name={'email'}
        rules={[
          { required: true },
          { pattern: REGEXS.email, message: '格式不正确' },
        ]}
      >
        <Input maxLength={200} />
      </FormItem>

      <Divider>安全设置</Divider>
      <FormItem
        label="密码"
        name={'pwd'}
        rules={[{ required: formMode === 'new' }]}
        help={
          <Space size={'small'}>
            <Popover title="密码规则" content={<CmtPasswordRules />}>
              <Button type="link">密码规则</Button>
            </Popover>
            {formMode === 'update' && (
              <Typography.Text type="secondary">
                如需重置，请填入密码
              </Typography.Text>
            )}
          </Space>
        }
      >
        <Input.Password />
      </FormItem>
      <FormItem label="角色" name={'role'} rules={[{ required: true }]}>
        <Select
          options={roles?.map((x) => {
            return { label: x.displayName, value: x.name };
          })}
        ></Select>
      </FormItem>
      <FormItem label="状态" name={'status'} rules={[{ required: true }]}>
        <Select options={GpoUserStatusOptions}></Select>
      </FormItem>

      <Form.Item>
        <Space>
          <Button
            htmlType="submit"
            type="primary"
            loading={updating || creating}
            disabled={updating || creating}
          >
            {formMode === 'new' ? '添加' : '保存'}
          </Button>
          <Button type={'text'} onClick={() => props.onSuccess?.(false)}>
            取消
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
