import { useRequest } from 'ahooks';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { changeProject, saveAccessToken } from '../../../services/auth.service';
import { Project } from '../../../services/project.service';

const { Text } = Typography;

export function OpenProjectButton(props: {
  project: Project;
  onSuccess?: (project: Project) => void;
}) {
  const navigate = useNavigate();
  const { loading, run } = useRequest(changeProject, {
    debounceWait: 300,
    manual: true,
    onSuccess({ accessToken }) {
      saveAccessToken(accessToken);
      props.onSuccess?.(props.project);
      navigate(`./${props.project.id}`);
    },
  });
  return (
    <Button
      type="link"
      onClick={() => {
        run({ projectId: props.project.id });
      }}
      loading={loading}
      disabled={!props.project.hasAuthValue}
    >
      打开
    </Button>
  );
}
