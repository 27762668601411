import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { GPOGoodFormTemplateDto } from '../../../../common/dtos/AddGoodTypes';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  batchDeleteDraftProductsApi,
  DraftProductDto,
} from '../../../../services/gpo/draft-product.service';
import {
  checkLocalGoodMarketProductModelExists,
  getCreationGoodForm,
} from '../../../../services/gpo/goodmarket.service';
import { ProductForm } from '../good-market-product/product-form';

function ChangeGoodMarketProductForm(props: {
  data: DraftProductDto;
  onSuccess?: (res: boolean) => void;
}) {
  const { data } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const addPayload = JSON.parse(data.raw || '{}');
  if (addPayload.promiseGuids === undefined) {
    delete addPayload.promiseGuids;
  } else {
    addPayload.promiseGuids = addPayload.promiseGuids
      ? addPayload.promiseGuids.split(',')
      : [];
  }

  const [goodForm, setGoodForm] = useState<GPOGoodFormTemplateDto>();

  const { loading, run } = useRequest(getCreationGoodForm, {
    manual: true,
    debounceWait: 300,
    onSuccess: (res) => {
      setGoodForm(res);
    },
    onError(e) {
      messageApi.error(e.message);
    },
  });

  const { loading: checking, run: checkModelExists } = useRequest(
    checkLocalGoodMarketProductModelExists,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: (res, param) => {
        res
          ? messageApi.error(`型号【${param?.[0].code}】重复`)
          : messageApi.success(
              `型号【${param?.[0].code}】可用，由于数据延迟，提交GPO仍有存在的可能`
            );
      },
      onError(e) {
        messageApi.error(e.message);
      },
    }
  );

  useEffect(() => {
    run({
      goodsClassGuid: addPayload.goodsClassGuid,
      goodsBrandGuid: addPayload.goodsBrandGuid,
      code: addPayload.code,
      goodsSource: addPayload.goodsSource,
      spuGuid: '',
      goodsClassName: addPayload.goodsClassName,
      goodsBrandName: addPayload.goodsBrandName,
    });
  }, []);

  return (
    <Spin spinning={loading}>
      {contextHolder}
      <div style={{ padding: '10px 0', width: '100%', minHeight: 200 }}>
        {goodForm && (
          <ProductForm
            containerStyle={{ marginBottom: 0 }}
            goodForm={goodForm}
            formValue={addPayload}
            onSuccess={() => props.onSuccess?.(true)}
            reupload={true}
            checkPromiseGuids={true}
            extendAction={({ getPayload, form }) => {
              return (
                <>
                  <Button onClick={() => props.onSuccess?.(false)}>关闭</Button>
                  <Button
                    htmlType="button"
                    disabled={checking}
                    loading={checking}
                    onClick={() => {
                      const payload = getPayload();
                      if (!payload.code) {
                        messageApi.open({
                          type: 'error',
                          content: '请输入型号',
                          duration: 3,
                        });
                        return;
                      }
                      checkModelExists({
                        goodsClassGuid: payload.goodsClassGuid,
                        goodsBrandGuid: payload.goodsBrandGuid,
                        code: payload.code,
                      });
                    }}
                  >
                    检查型号
                  </Button>
                </>
              );
            }}
          ></ProductForm>
        )}
      </div>
    </Spin>
  );
}

export function DraftEditButton(props: {
  data: DraftProductDto;
  onSuccess?: () => void;
}) {
  const { loading, run: runDelete } = useRequest(batchDeleteDraftProductsApi, {
    manual: true,
    debounceWait: 300,
    onSuccess: (res) => {
      message.success(`临时商品「${props.data.name}」已被移除`);
      props.onSuccess?.();
    },
    onError(e) {
      message.error(`临时商品「${props.data.name}」移除失败，${e.message}`);
    },
  });
  return (
    <CmtModalButton
      title="编辑临时商品"
      width={'95%'}
      trigger={({ show }) => {
        return (
          <Tooltip title={'编辑商品'}>
            <Button type="text" size="small" onClick={show} loading={loading}>
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeGoodMarketProductForm
            data={props.data}
            onSuccess={(res) => {
              hide();
              res && runDelete([props.data.id]);
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
