import { BaseCategory, CategoryId } from '../../common/dtos/base-category';
import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import { CmtCascaderOption } from '../../components/CmtCascader';
import httpClient from '../http-client';

export enum GpoCategoryGroup {
  /**
   * JiCai
   */
  JiCai = 'JiCai',
  /**
   * GoodMarket
   */
  GoodMarket = 'GoodMarket',
}

export interface GpoCategory extends BaseCategory {
  id: string;
  maxDiscount: number;
  projectId: string;
  group: GpoCategoryGroup;
  createdAt: string;
  updatedAt: string;
  /**
   * 支持自动调价比例
   */
  autoPriceAdjustmentThrottle?: number;
  /**
   * 品牌设置的调价比例， {[goodsBrandGuid]: number}
   */
  brandTrottles?: string;
}

export interface GpoCategoryBrandTrottle {
  goodsClassName: string;
  goodsClassGuid: CategoryId;
  goodsBrandGuid: string;
  goodsBrandName: string;
  throttle: number;
}

export async function queryGpoCategoryApi(
  params: PagingRequest
): Promise<PagingResponse<GpoCategory>> {
  const res: any = await httpClient.get('/gpo/categories', { params });
  return res;
}

export async function getSingleCategoryApi(
  categoryId: string,
  projectId: string
): Promise<GpoCategory> {
  const res: any = await httpClient.get(`/gpo/categories/${categoryId}`, {
    params: { projectId },
  });
  return res;
}

export async function getAllGpoCategoriesApi(params: {
  group: GpoCategoryGroup;
  projectId: string;
}): Promise<GpoCategory[]> {
  const res: GpoCategory[] = await httpClient.get('/gpo/categories/all', {
    params,
  });
  return res;
}

export async function updateGpoCategoryAutomationSettings(
  id: string,
  params: {
    /**
     * 支持自动调价比例
     */
    autoPriceAdjustmentThrottle?: number;
    brandTrottles?: string;
  }
): Promise<GpoCategory[]> {
  const res: GpoCategory[] = await httpClient.patch(
    `/gpo/categories/${id}/automation-settings`,
    params
  );
  return res;
}

export function buildCategoryTree(items: BaseCategory[]): CmtCascaderOption[] {
  const rootNodes: CmtCascaderOption[] = [];
  const itemIds = new Set();
  const buildNode = (cat: BaseCategory): CmtCascaderOption => {
    const opt: CmtCascaderOption = {
      label: cat.platformName,
      value: cat.platformId,
    };
    const children = buildChidNode(cat.platformId);
    if (children.length) {
      opt.children = children;
    } else {
      opt.isLeaf = true;
    }
    return opt;
  };

  const buildChidNode = (parentId: number | string) => {
    const children = items
      .filter((x) => x.platformParentId === parentId)
      .map((x) => buildNode(x));
    return children;
  };
  items.forEach((x) => itemIds.add(x.platformId));
  items.forEach((x) => {
    // 如果父ID出现在列表，表示它不是当前分类里面的根节点
    if (!itemIds.has(x.platformParentId)) {
      const node = buildNode(x);
      rootNodes.push(node);
    }
  });
  return rootNodes;
}
