import {
  AccountBookOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  FormOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import { RJSimpleOrderDto } from '../../../../services/rjmart/order-modal';
import { confirmRJMartOrderApi } from '../../../../services/rjmart/order.service';

function ComfirmOrderForm(props: {
  data: RJSimpleOrderDto;
  onSuccess?: () => void;
}) {
  const [form] = useForm();

  const { loading: updating, run: pushChangeApi } = useRequest(
    confirmRJMartOrderApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: () => {
        message.success(`操作成功`);
        props.onSuccess?.();
      },
      onError: (err) => {
        message.error(`操作失败：` + err.message);
      },
    }
  );

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue({ orderNo: props.data.orderNo, additionalRecord: 0 });
    }
  }, [props.data]);

  const submitChanges = () => {
    const payload = {
      ...form.getFieldsValue(),
    };
    pushChangeApi(payload);
  };

  return (
    <Spin spinning={updating}>
      <Space style={{ width: '100%', marginTop: 24 }} direction={'vertical'}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          labelAlign={'left'}
          onFinish={submitChanges}
        >
          <FormItem label="订单号" name={'orderNo'} hidden>
            <Input readOnly />
          </FormItem>
          <FormItem
            label="是否补录"
            name={'additionalRecord'}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={0}>否</Radio>
              <Radio value={1}>是</Radio>
            </Radio.Group>
          </FormItem>
          <FormItem
            label="备注"
            name={'remark'}
            rules={[{ required: true, max: 500 }]}
          >
            <TextArea />
          </FormItem>
          <FormItem>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={updating}
                disabled={updating}
              >
                确认订单
              </Button>
              <Button type="text" onClick={props.onSuccess}>
                取消
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Space>
    </Spin>
  );
}

export function ConfirmOrderButton(props: {
  data: RJSimpleOrderDto;
  onSuccess?: () => void;
}) {
  if (props.data.status !== 8) {
    return <></>;
  }
  return (
    <CmtModalButton
      title={`确认订单【${props.data?.orderNo}】`}
      width={500}
      trigger={({ show }) => {
        return (
          <Tooltip title={'确认订单'}>
            <Button type="link" size="small" onClick={show}>
              确认订单
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ComfirmOrderForm
            data={props.data}
            onSuccess={() => {
              hide();
              props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
