import { useRequest } from 'ahooks';
import { Button, Form, Input, message, Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ReactNode, useEffect, useState } from 'react';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import { InfiniteSelect } from '../../../../components/InfiniteSelect';
import {
  GPOOrderShipInfoDto,
  GPOOrderShippingTypeList,
  GPOOrderShippingTypes,
  saveShoppingInfo,
} from '../../../../services/gpo/order.service';
import { queryLogisticProvidersApi } from '../../../../services/logistics.service';
export function ShippingInfoForm(props: {
  orderGuid: string;
  data: GPOOrderShipInfoDto | undefined;
  projectId: string;
  onSuccess?: () => void;
}) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [logisticsType, setLogisticsType] = useState(
    GPOOrderShippingTypes.SupplierOwn
  );
  const { run, loading } = useRequest(saveShoppingInfo, {
    manual: true,
    debounceWait: 200,
    onSuccess() {
      messageApi.success('保存成功');
      props.onSuccess?.();
    },
    onError(e, params) {
      messageApi.error(e.message, 3);
    },
  });

  function appendRemark(content: string) {
    let remark = form.getFieldValue('remark');
    remark += `${remark.length ? '\n' : ''}${content}`;
    form.setFieldValue('remark', remark);
  }

  useEffect(() => {
    if (props.data) {
      setLogisticsType(props.data.logisticsType);
      form.setFieldsValue({ orderGuid: props.orderGuid, ...props.data });
    } else {
      form.setFieldsValue({
        orderGuid: props.orderGuid,
        logisticsType,
        logisticsName: '',
        logisticsCode: '',
        remark: '',
      });
    }
  }, [props.data]);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        const val = form.getFieldsValue();
        run(props.projectId, val);
      }}
    >
      {contextHolder}
      <Form.Item
        label="物流方式"
        name="logisticsType"
        required={true}
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={(e) => {
            if (e.target.value !== GPOOrderShippingTypes.Express) {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                logisticsName: '',
                logisticsCode: '',
              });
            }
            setLogisticsType(e.target.value);
          }}
        >
          {GPOOrderShippingTypeList.map((x) => (
            <Radio key={x.value} value={x.value}>
              {x.name}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        hidden={logisticsType !== GPOOrderShippingTypes.Express}
        label="物流名称"
        name="logisticsName"
        required={true}
        rules={[{ required: logisticsType === GPOOrderShippingTypes.Express }]}
      >
        <InfiniteSelect
          api={queryLogisticProvidersApi}
          showSearch={true}
          defaultParams={{ pageSize: 999 }}
          option={(item: { name: string; code: string }) => {
            return { label: item.name, value: item.name };
          }}
        />
      </Form.Item>
      <Form.Item
        hidden={logisticsType !== GPOOrderShippingTypes.Express}
        label="物流编号"
        name="logisticsCode"
        required={true}
        rules={[{ required: logisticsType === GPOOrderShippingTypes.Express }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="备注"
        name="remark"
        extra={
          logisticsType === GPOOrderShippingTypes.Express ? (
            <div>
              添加
              <Button
                size="small"
                type="link"
                onClick={() => appendRemark('[发件手机号:]')}
              >
                发件人
              </Button>
              或
              <Button
                size="small"
                type="link"
                onClick={() => appendRemark('[收件手机号:]')}
              >
                收件人
              </Button>
              手机号以便查询物流 ，示例：[发件手机号:13888888888]
            </div>
          ) : (
            <></>
          )
        }
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item hidden name="logisticsGuid">
        <Input />
      </Form.Item>
      <Form.Item hidden name="orderGuid">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          保存
        </Button>
      </Form.Item>
    </Form>
  );
}

export function ShippingInfoButton(props: {
  orderGuid: string;
  projectId: string;
  data: GPOOrderShipInfoDto | undefined;
  onSuccess?: () => void;
  trigger: (actions: { show: () => void; hide: () => void }) => ReactNode;
}) {
  return (
    <CmtModalButton width={500} title="物流信息" trigger={props.trigger}>
      {({ hide }) => {
        return (
          <ShippingInfoForm
            orderGuid={props.orderGuid}
            projectId={props.projectId}
            data={props.data}
            onSuccess={() => {
              hide();
              props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
