import { AlertOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Result, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../context';
import { getSingleProjectMetabaseReportLinkApi } from '../../../../../services/project.service';

export function MetabaseReportView(props: { height: any; width: any }) {
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [loaded, setLoaded] = useState(false);
  const { loading, data, run } = useRequest(
    getSingleProjectMetabaseReportLinkApi,
    {
      manual: true,
      debounceWait: 200,
      onSuccess() {
        setTimeout(() => setLoaded(true), 1500);
      },
    }
  );
  useEffect(() => {
    if (globalContext.currentProject?.id) {
      run(globalContext.currentProject?.id);
    }
  }, [globalContext.currentProject]);
  return (
    <Spin spinning={loading || !loaded}>
      {!data && (
        <Result
          icon={<AlertOutlined />}
          status="info"
          title={`这里是${globalContext.currentProject?.name || '项目中心'}`}
          subTitle="请点击左边的菜单开始吧"
        />
      )}
      {data && (
        <iframe
          style={{ borderRadius: 15, background: '#0000' }}
          frameBorder={0}
          src={data}
          onLoad={() => setLoaded(true)}
          width={props.width}
          height={props.height}
        ></iframe>
      )}
    </Spin>
  );
}
