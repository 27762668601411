import Logo from '@/logo.svg';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
  JwtMetaItem,
  JwtMetaItemKeys,
} from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';
import styles from './style/index.module.less';
import {
  DollarOutlined,
  LogoutOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  authLogout,
  decodeJwtToken,
  getAccessToken,
  saveAccessToken,
} from '../../services/auth.service';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { ActionModal } from '../ActionModal';
import { CmtModalButton } from '../CmtModalButton';
import { usePermission } from '../Permission/use-permission';
import { TwoFactorStatusIcon } from '../TwoFactorStatusIcon';
import { Permission } from '../Permission';
import { CmtInternalTaskStatus } from '../CmtInternalTaskStatus';
import { useFeatureToggle } from '../FeatureToggle/use-feature-toggle';
import { ServiceHealthyButton } from '../ServiceHealthy';
const { confirm } = Modal;

export function NavBar() {
  const LOGIN_URL = '/login';
  const canUpdateOrganization = usePermission('organization', 'update');
  const planRead = usePermission('plan', 'read');
  const taskRead = usePermission('task', 'read');
  const gpoFeature = useFeatureToggle('org.platforms', 'gpo');
  const rjmFeature = useFeatureToggle('org.platforms', 'rjm');
  const casFeature = useFeatureToggle('org.platforms', 'cas');
  const canVisitDashboard = usePermission('organization', 'dashboard.all');
  const mainNavItems: ItemType[] = [];
  if (canVisitDashboard) {
    mainNavItems.push({
      label: <NavLink to="dashboard">工作台</NavLink>,
      key: 'dashboard',
    });
  }
  if (gpoFeature) {
    mainNavItems.push({
      label: <NavLink to="gpo">GPO</NavLink>,
      key: 'gpo',
    });
  }
  if (rjmFeature) {
    mainNavItems.push({
      label: <NavLink to="rjmart">锐竞</NavLink>,
      key: 'rjmart',
    });
  }
  if (casFeature) {
    mainNavItems.push({
      label: <NavLink to="cas">喀斯玛</NavLink>,
      key: 'cas',
    });
  }
  if (planRead) {
    mainNavItems.push({
      label: <NavLink to="plans">自动化</NavLink>,
      key: 'plans',
    });
  }
  if (taskRead) {
    mainNavItems.push({
      label: <NavLink to="tasks">运行记录</NavLink>,
      key: 'tasks',
    });
  }

  const logout = () => {
    confirm({
      title: '提示',
      content: '确认退出吗？',
      onOk: async () => {
        await authLogout();
        setGlobalContext({});
        saveAccessToken('');
        navigate(LOGIN_URL);
      },
    });
  };
  const userMenu: ItemType[] = [
    {
      label: <NavLink to="me">个人设置</NavLink>,
      key: 'profile',
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: '退出登录',
      key: 'exit',
      onClick: logout,
      icon: <LogoutOutlined />,
    },
  ];
  if (canUpdateOrganization) {
    userMenu.splice(1, 0, {
      label: <NavLink to="admin">组织管理</NavLink>,
      key: 'org-settings',
      icon: <DollarOutlined />,
    });
  }
  const navigate = useNavigate();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [jwtMetas, setJwtMetas] = useState<{
    userName: JwtMetaItem | undefined;
    twoFactorEnable: JwtMetaItem | undefined;
    orgName: JwtMetaItem | undefined;
  }>();

  useEffect(() => {
    const jwt = getAccessToken();
    if (jwt) {
      const decoded = decodeJwtToken(jwt);
      setGlobalContext({ ...globalContext, userMetaData: decoded.meta });
    } else {
      navigate(LOGIN_URL);
    }
  }, []);

  useEffect(() => {
    const userMetaData = globalContext.userMetaData;
    const userName = userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.userName
    );
    const twoFactorEnable = userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.twoFactorEnable
    );
    const orgName = userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.organizationName
    );
    setJwtMetas({ userName, twoFactorEnable, orgName });
  }, [globalContext.userMetaData]);

  return (
    <Row className={styles['navbar']}>
      <Col flex={'120px'} className={styles['section']}>
        <Logo />
      </Col>
      <Col flex="auto">
        <Menu
          mode="horizontal"
          items={mainNavItems}
          style={{ minWidth: 0, flex: 'auto', border: 'none' }}
        />
      </Col>
      <Col flex={'200px'} className={styles['section']}>
        <div className={styles['sub']}>
          <Space>
            <ServiceHealthyButton />
            <CmtInternalTaskStatus></CmtInternalTaskStatus>
            <Permission resource="plan" action="write">
              <CmtModalButton
                title="创建计划"
                width={600}
                trigger={({ show }) => {
                  return (
                    <Button type="primary" ghost onClick={show}>
                      <PlusOutlined />
                      创建计划
                    </Button>
                  );
                }}
              >
                {({ show, hide }) => {
                  return <ActionModal onOk={hide}></ActionModal>;
                }}
              </CmtModalButton>
            </Permission>
            <Divider type="vertical" />
            <Dropdown menu={{ items: userMenu }} placement="bottomLeft">
              <Space size={'small'}>
                <Avatar size={28}>{jwtMetas?.userName?.v?.split('')[0]}</Avatar>
                <div className={styles['user']}>
                  <Typography.Text>
                    {jwtMetas?.userName?.v}{' '}
                    {jwtMetas?.twoFactorEnable?.v === '1' && (
                      <TwoFactorStatusIcon active={true} />
                    )}
                  </Typography.Text>
                  <Typography.Text type={'secondary'} ellipsis={true}>
                    {jwtMetas?.orgName?.v}
                  </Typography.Text>
                </div>
              </Space>
            </Dropdown>
          </Space>
        </div>
      </Col>
    </Row>
  );
}
