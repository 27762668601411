import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { getSingleCategoryApi } from '../../../../services/gpo/category.service';

export function CategoryName(props: { id: string; projectId: string }) {
  const { loading, data, run } = useRequest(getSingleCategoryApi, {
    manual: true,
    debounceWait: 300,
  });
  useEffect(() => {
    run(props.id, props.projectId);
  }, []);
  return <>{(data as any)?.platformName}</>;
}
