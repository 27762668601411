export function ConvertJsonSchemaPayloadToString(input: any) {
  if (input) {
    // 移除空的属性
    const output: any = {};
    Object.keys(input)
      .filter((x) => {
        return input[x] !== null && input[x] !== undefined;
      })
      .forEach((key) => {
        output[key] = input[key];
      });
    input = JSON.stringify(output);
  }
  return input;
}
