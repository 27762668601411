import ReactQuill, { Quill } from 'react-quill';
import ImageEdit from 'quill-image-edit-module';
import 'react-quill/dist/quill.snow.css';

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

class ImageFormat extends BaseImageFormat {
  static formats(domNode: any) {
    return ImageFormatAttributesList.reduce(function (formats: any, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name: any, value: any) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
Quill.register('modules/imageEdit', ImageEdit);
export function Editor(props: {
  value?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
}) {
  return (
    <ReactQuill
      theme="snow"
      value={props.value}
      onChange={props.onChange}
      modules={{
        toolbar: [
          [{ font: [] }, { size: [] }],
          [{ align: [] }, 'direction'],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          ['blockquote', 'code-block'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        imageEdit: {
          modules: ['Resize', 'DisplaySize', 'Toolbar', 'Delete'],
        },
      }}
      formats={[
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'style',
      ]}
      style={{
        height: 'calc(80vh)',
        display: 'inline-block',
        width: '100%',
      }}
    />
  );
}
