import { BarsOutlined } from '@ant-design/icons';
import { Spin, Space, Card, Steps, Typography, Button, Result } from 'antd';
import { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { useTitle } from 'ahooks';
import { CasProductForm } from './product-form';

export function ProductFormPage() {
  const params = useParams();
  const navigate = useNavigate();
  useTitle('商品管理', { restoreOnUnmount: true });

  return (
    <BlankWrapper
      breadcrumb={[
        { name: 'CAS', icon: <BarsOutlined /> },
        { name: '商品管理' },
        { name: '添加商品' },
      ]}
    >
      <CasProductForm
        projectId={params.projectId || ''}
        onSuccess={() => {
          navigate('../products');
        }}
      ></CasProductForm>
    </BlankWrapper>
  );
}
