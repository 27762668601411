import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ActionModal } from '../../components/ActionModal';
import { CmtModalButton } from '../../components/CmtModalButton';
import { Plan } from '../../services/plan.service';
import { useMemo } from 'react';

export function PlanCloneButton(props: { data: Plan; onSuccess?: () => void }) {
  const data = useMemo(() => {
    return { ...props.data, id: '' };
  }, [props.data]);
  return (
    <CmtModalButton
      title={`克隆计划`}
      width={600}
      trigger={({ show }) => {
        return (
          <Tooltip title="克隆计划">
            <Button type="text" size="small" onClick={show}>
              <CopyOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ show, hide }) => {
        return (
          <ActionModal
            onOk={(done) => {
              hide();
              if (done) {
                props.onSuccess?.();
              }
            }}
            value={data}
          ></ActionModal>
        );
      }}
    </CmtModalButton>
  );
}
