import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Button, Space, Tooltip, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../components/CmtPopConfirmAction';
import { deletePlanApi, Plan, runPlanApi } from '../../services/plan.service';
const { Text, Paragraph } = Typography;

export function PlanDeleteButton(props: {
  data: Plan;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={deletePlanApi}
      title={
        <Space direction="vertical">
          <Text strong>删除确认</Text>
          <Paragraph style={{ maxWidth: 300 }}>
            确定删除这个计划吗?
            <div>
              <Text type="secondary">提示：正在执行的任务不受影响</Text>
            </div>
          </Paragraph>
        </Space>
      }
      onOk={({ data, run, loading }) => {
        run(data.id);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Tooltip title="删除计划">
          <Button
            type="text"
            size="small"
            danger
            disabled={loading}
            loading={loading}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </CmtPopConfirmAction>
  );
}
