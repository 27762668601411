export interface RJResponse<T extends unknown> {
  message: string;
  data?: T;
  success: boolean;
}

export enum RJProductStatus {
  /**
   * 草稿
   */
  Draft = 2,
  /**
   * 上架
   */
  Publish = 3,
  /**
   * 下架
   */
  Unpublish = 4,
  /**
   * 强制下架
   */
  ForceUnpublish = 5,
  /**
   * 待审核
   */
  ApprovalPending = 12,
  /**
   * 已驳回
   */
  Reject = 15,
}

export const RJProductStatuOptions = [
  { name: '草稿', value: RJProductStatus.Draft },
  { name: '已上架', value: RJProductStatus.Publish },
  { name: '已下架', value: RJProductStatus.Unpublish },
  { name: '强制下架', value: RJProductStatus.ForceUnpublish },
  { name: '待审核', value: RJProductStatus.ApprovalPending },
  { name: '已驳回', value: RJProductStatus.Reject },
];

export const RJDangerousTypes = [
  { name: '其他', value: 1 },
  { name: '其他危化品', value: 2 },
  { name: '易制毒', value: 3 },
  { name: '易制爆', value: 4 },
  { name: '剧毒品', value: 5 },
  { name: '精神/麻醉品', value: 6 },
  { name: '民用爆炸物品', value: 7 },
  { name: '医用毒性品', value: 8 },
  { name: '常规化学品', value: 9 },
  { name: '放射性同位素', value: 10 },
  { name: '菌毒株', value: 11 },
  { name: '病毒', value: 12 },
];

/**
 * 货期
 */
export const RJDeliveryTimes = [
  { name: '现货', value: 1 },
  { name: '1周内', value: 7 },
  { name: '2周', value: 14 },
  { name: '3周', value: 21 },
  { name: '1个月', value: 30 },
  { name: '2个月', value: 60 },
  { name: '2个月以上', value: 61 },
  { name: '咨询供应商客服', value: -1 },
];

export interface RJProductQueryParams {
  /**
   * 分页参数，第几页
   */
  page: number;
  /**
   *分页参数，每页多少个
   */
  size: number;
  /**
   * 商品货号(支持分词搜索)
   */
  productNum?: string;
  /**
   * 商品名称(支持分词搜索)
   */
  productName?: string;
  /**
   * 品牌id集合
   * 查询商品可选择的品牌接口: /openapi/product/getSuppBrands
   */
  brandIds?: number[];
  /**
   * 分类id集合
   * 查询树形结构的分类列表接口: /openapi/product/queryAllCategoryTree
   */
  categoryId?: number;

  /**
   * 状态(2:草稿，3:上架，4下架，5强制下架，12待审核，15已驳回)
   */
  status?: RJProductStatus;
}

export interface RJSimpleProductDto {
  id: number;
  brandName: string;
  /**
   * 品牌id(若为0是无品牌)
   */
  brandId: number;
  /**
   * 商品分类id（为子集的分类）
   */
  categoryId: number;
  photo: string;
  /**
   * 商品货号
   */
  code: string;
  name: string;
  /**
   * 商品库存
   */
  sku: number;
  unit: string;
  supplierName: string;
  status: RJProductStatus;
  /**
   * 规格描述
   */
  specification: string;
  price: number;
  /**
   * cas号
   */
  casNo: string;
  /**
   * 危化品类型
   */
  dangerousType: number;
  // 驳回原因
  rejectComment: string;
}

export interface RJCategoryModel {
  id: number;
  name: string;
}

export interface RJProductSpecificationDto {
  packingSpecificationValue: number;
  packingSpecificationUnit: string;
  minSpecificationValue: number;
  minSpecificationUnit: string;
}

export interface RJDangerousDto {
  type: number;
  casNoname: string;
}

export interface RJBrandDto {
  id: number;
  name: string;
  cname: string;
  ename: string;
  logo: string;
  manufacture: string;
}

export interface RJPhotoDto {
  photo: string;
}

export interface RJPriceDifferent {
  orgId: number;
  orgName: string;
  productId: number;
  price: number;
}

export interface RJProductAttribute {
  attributeName: string;
  attributeNameValue: string;
}

export interface RJProductDetailDto {
  id: string;
  productNum: string;
  saleAmount: number;
  price: number;
  directoryPrice: number;
  name: string;
  photo: string;
  specification: string;
  status: RJProductStatus;
  category: RJCategoryModel[];
  productSpecificationDTO: RJProductSpecificationDto;
  dangerousDto: RJDangerousDto;
  brand: RJBrandDto;
  sku: number;
  unit: string;
  desc: string;
  photos: RJPhotoDto[];
  priceDifferents: RJPriceDifferent[];
  deliveryTime: number;
  carryFeeTemplateId: number;
  attributes: RJProductAttribute[];
}

/**
 * 新增或修改商品Payload
 */
export interface RJProductPayload {
  /**
   * 商品id
   * 若传入的productNum已存在系统中，系统判定为编辑商品
   * 若传入的productNum不存在系统中，系统判定为新增商品
   */
  id?: number;
  /**
   * 商品货号（必须要具有商品唯一性）
   */
  productNum: string;
  price: number;
  directoryPrice?: number;
  name: string;
  /**
   * 商品规格, 除一级分类为化学试剂或危险化学品或工业品无需传外, 其他分类必传
   */
  specification?: string;
  /**
   * 状态： RJProductStatus.Draft | RJProductStatus.Publish
   */
  status: RJProductStatus;
  /**
   * 商品所属分类id, 必须是二级或者三级, 医疗试剂耗材类的必须是三级. (数据来源: 查询分类列表的接口/openapi/product/queryAllCategoryTree)
   */
  categoryId: number;
  brandId: number;
  /**
   * 库存数量
   */
  sku: number;
  /**
   * 销售单位, 不可自定义, 必须使用平台的标准销售单位 (数据来源: 查询标准单位、包装规格和最小包装规格列表的接口/openapi/product/queryUnitAndSpecification)
   */
  unit: string;
  /**
   * 商品介绍 (支持富文本格式)
   */
  desc: string;
  photos: RJPhotoDto[];
  priceDifferents?: { orgId: string; price: number }[];
  deliveryTime: number;
  carryFeeTemplateId: number;
  attributes: RJProductAttribute[];
  productSpecificationDTO?: RJProductSpecificationDto[];
  dangerousDto?: RJDangerousDto[];
  /**
   * 产品规格(此字段只对实验耗材分类下的商品生效), 指产品的尺寸、容量、类型等，例如培养皿的产品规格60mm,90mm,100mm等；孔板的产品规格6孔、12孔、96孔等。
   */
  goodsSpecification?: string;
}

export interface RJSupportBrand {
  brandId: number;
  cname: string;
  ename: string;
}

export interface RJUnitAndSpecificationDef {
  /**
   * 标准单位列表数据
   */
  unitList: string[];
  /**
   * 标准规格列表数据
   */
  specificationList: {
    name: string;
    minNeed: number;
  }[];
  /**
   * 标准最小规格列表数据
   */
  minSpecificationList: string[];
}
export interface RJDeliverCostTemplate {
  id: number;
  templateName: string;
}

export interface PictureListItem {
  photo: string;
}
