import { useRequest, useTitle } from 'ahooks';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { queryContractDetailApi } from '../../../services/gpo/contract.service';
import print from './print.module.less';

export const PrintableContract = React.forwardRef<
  any,
  { contractId: string; projectId: string }
>((props, ref) => {
  const { data, loading, run } = useRequest(queryContractDetailApi, {
    manual: true,
    debounceWait: 100,
  });
  useTitle(data?.contractName || '', { restoreOnUnmount: true });
  useEffect(() => {
    if (props.contractId && props.projectId) {
      run({
        contractId: props.contractId,
        projectId: props.projectId,
      });
    }
  }, [props.contractId, props.projectId]);

  return (
    <Spin spinning={loading}>
      <div className={print['printView']} ref={ref} contentEditable={true}>
        <style type="text/css" media="print">
          {
            '\
  @page { size: a4; margin: 1cm 0.5cm; }\
'
          }
        </style>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.contractContentVO.content || '',
          }}
        ></div>
      </div>
    </Spin>
  );
});
