import { BlankWrapper } from '../../../components/BlankWrapper';
import { ProjectPlatforms } from '../../../services/project.service';
import { ProjectList } from '../../../components/CmtProjects/ProjectList';

export function Projects() {
  return (
    <BlankWrapper>
      <ProjectList platform={ProjectPlatforms.GPO} />
    </BlankWrapper>
  );
}
