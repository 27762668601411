import { Button, Spin } from 'antd';
import Link from 'antd/es/typography/Link';
import { useState } from 'react';
import { ENV_CONFIG } from '../../env-config';

export function PdfViewer(props: { src: string; height?: number }) {
  // const [loading, setLoading] = useState(true);
  // const url = `${ENV_CONFIG.BASE_URL}/gpo/utils/pdf?file=${encodeURIComponent(
  //   props.src
  // )}`;
  // return (
  //   <Spin spinning={loading}>
  //     <iframe
  //       src={url}
  //       style={{ border: 'none', width: '100%', height: props.height || 500 }}
  //       onLoad={() => setLoading(false)}
  //     ></iframe>
  //   </Spin>
  // );
  return (
    <Button type="primary" onClick={() => window.open(props.src)}>
      点击查看
    </Button>
  );
}
