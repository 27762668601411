import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { GPOGoodFormTemplateDto } from '../../../../common/dtos/AddGoodTypes';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  getModifitionGoodForm,
  GoodSellStatus,
  GpoGoodMarketProduct,
} from '../../../../services/gpo/goodmarket.service';
import {
  RJProductPayload,
  RJProductStatus,
  RJSimpleProductDto,
} from '../../../../services/rjmart/product-modal';
import { ProductForm } from '../product-edit-form/product-form';
import { useParams } from 'react-router-dom';
import { getRJMartProductsDetailApi } from '../../../../services/rjmart/product-mamagement.service';

function ChangeRJMProductForm(props: {
  data: RJSimpleProductDto;
  projectId: string;
  onSuccess?: (res: boolean) => void;
}) {
  const { data } = props;
  const [payload, setPayload] = useState<RJProductPayload>();

  const { loading, run } = useRequest(getRJMartProductsDetailApi, {
    manual: true,
    debounceWait: 300,
    onSuccess: (res) => {
      if (res.data) {
        const product = res.data;
        const tempPayload: RJProductPayload = {
          id: Number(product.id),
          productNum: product.productNum,
          price: product.price,
          name: product.name,
          status: product.status,
          categoryId: product.category[product.category.length - 1].id,
          brandId: product.brand.id,
          sku: product.sku,
          unit: product.unit,
          desc: product.desc,
          photos: product.photos,
          deliveryTime: product.deliveryTime,
          carryFeeTemplateId: product.carryFeeTemplateId,
          attributes: product.attributes,
          specification: product.specification,
          directoryPrice: product.directoryPrice
        };
        setPayload(tempPayload);
      }
    },
    onError(e) {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (data.status !== RJProductStatus.Publish) {
      // get detail
      run(data.id);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '20px 0', width: '100%', minHeight: 200 }}>
        {payload && (
          <ProductForm
            formValue={payload}
            projectId={props.projectId}
            onCancel={() => props.onSuccess?.(false)}
            onSuccess={() => props.onSuccess?.(true)}
            extendAction={() => (
              <Button onClick={() => props.onSuccess?.(false)}>关闭</Button>
            )}
          ></ProductForm>
        )}
      </div>
    </Spin>
  );
}

export function EditButton(props: {
  data: RJSimpleProductDto;
  onSuccess?: () => void;
}) {
  const params = useParams();
  if (
    ![
      RJProductStatus.Unpublish,
      RJProductStatus.Draft,
      RJProductStatus.Reject,
    ].includes(props.data.status)
  ) {
    return <></>;
  }

  return (
    <CmtModalButton
      title="修改商品"
      width={'95%'}
      trigger={({ show }) => {
        return (
          <Tooltip title={'修改商品'}>
            <Button type="text" size="small" onClick={show}>
              <EditOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeRJMProductForm
            data={props.data}
            projectId={params.projectId || ''}
            onSuccess={(res) => {
              hide();
              res && props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
