import { useRequest } from 'ahooks';
import { Select } from 'antd';
import { useEffect } from 'react';
import {
  queryCasCategoryUnits,
  queryCasTypeAndExtendAttrByCatId,
} from '../../services/cas/product.service';

export function CasCategoryUnitSelect(props: {
  queryParams: {
    projectId: string;
    categoryId: number;
  };
  value?: number;
  style?: React.CSSProperties;
  onChange?: (v: number) => void;
  allowClear?: boolean;
}) {
  const { data, loading, run } = useRequest(queryCasCategoryUnits, {
    debounceWait: 300,
    manual: true,
  });
  useEffect(() => {
    if (props.queryParams.projectId) {
      run(props.queryParams);
    }
  }, [props.queryParams.projectId, props.queryParams.categoryId]);
  return (
    <Select
      style={props.style}
      loading={loading}
      onChange={(v: number, opt) => {
        props.onChange?.(v);
      }}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={data?.map((x) => {
        return {
          label: x.name,
          value: x.name,
        };
      })}
      value={props.value}
      allowClear={props.allowClear}
      placeholder="计量单位"
    ></Select>
  );
}
