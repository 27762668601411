import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';

export interface ECSInstance {
  instanceId: string;
  instanceName: string;
  region: string;
  status: string;
  ipaddress: string;
  createdAt: string;
}

export interface ECSImage {
  imageId: string;
  imageName: string;
  description: string;
  OSName: string;
  OSType: string;
  platform: string;
}

export const EcsStatus = [
  { value: 'Pending', label: '创建中' },
  { value: 'Running', label: '运行中' },
  { value: 'Starting', label: '启动中' },
  { value: 'Stopping', label: '停止中' },
  { value: 'Stopped', label: '已停止' },
];

export const EcsRegionIds = [{ label: '广州', value: 'cn-guangzhou' }];

export async function queryEcsApi(
  params: PagingRequest
): Promise<PagingResponse<ECSInstance>> {
  const res: any = await httpClient.get('/ecs', { params });
  return res;
}

export async function queryEcsImagesApi(
  params: PagingRequest
): Promise<PagingResponse<ECSImage>> {
  const res: any = await httpClient.get('/ecs/images', { params });
  return res;
}

export async function createEcsInstanceApi(params: {
  regionId: string;
  imageId: string;
  instanceName: string;
  description: string;
}): Promise<any> {
  const res: any = await httpClient.post('/ecs', { ...params, dryRun: false });
  return res;
}

export async function removeEcsInstancesApi(params: {
  regionId: string;
  instanceIds: string[];
}): Promise<any> {
  const res: any = await httpClient.post('/ecs/delete-instances', params);
  return res;
}

export async function upgradeCmtApi(params: {
  regionId: string;
  instanceIds: string[];
}): Promise<any> {
  const res: any = await httpClient.post('/ecs/upgrade-cmt-instances', params);
  return res;
}

export async function getServiceHealthy(
  path: string,
  projectId: string
): Promise<{
  timestamp: string;
  type: string;
  status: number;
  version: string;
} | null> {
  try {
    const res: any = await httpClient.get(path, {
      params: { projectId },
      timeout: 10000,
    });
    return res;
  } catch {
    return null;
  }
}
