import {
  LinkOutlined,
  PlusOutlined,
  RocketOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useRequest, useTitle } from 'ahooks';
import {
  Typography,
  Space,
  Input,
  Button,
  Form,
  message,
  Dropdown,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ConvertDate } from '../../../../common/utils/convert-date';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { ProjectInternalPlanButton } from '../../../../components/ProjectInternalPlan';
import { GpoCategoryGroup } from '../../../../services/gpo/category.service';
import {
  DraftProductDto,
  queryDraftProductsApi,
} from '../../../../services/gpo/draft-product.service';
import { queryInternalActionsApi } from '../../../../services/task.service';
import { GpoCategoryCascader } from '../../../components/CategoryCascader';
import { GoodsBrandSelect } from '../../../components/GoodsBrandSelect';
import { ProductName } from '../../../components/ProductName';
import { BatchDeleteDraftProductButton } from './batch-delete-button';
import { DraftEditButton } from './edit-button';
import { SingleDeleteDraftProductButton } from './single-delete-button';
import { InternalActionButton } from '../../../../components/InternalActionButton';
const { Paragraph } = Typography;

export function DraftProductPage() {
  useTitle('临时商品库');
  const params = useParams();
  const tableRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const columns: ColumnsType<DraftProductDto> = [
    {
      title: '#',
      key: 'index',
      width: 40,
      render(value, record, index) {
        return (
          <Typography.Text type={'secondary'}>
            {(index + 1).toString().padStart(3, '0')}
          </Typography.Text>
        );
      },
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      render(value, record, index) {
        return (
          <ProductName
            picturePath={record.picturePath}
            name={record.name}
            goodsPriceGuid={''}
            extends={
              <>
                {record.source.includes('京东') ? (
                  <Tooltip title="点击打开京东链接">
                    <Typography.Link>
                      <LinkOutlined
                        onClick={() => {
                          const payload = JSON.parse(record.raw);
                          let url =
                            payload.goodsExternalPriceList?.[0]?.externalUrl;
                          if (!url) {
                            const k = encodeURIComponent(record.name);
                            url = `https://search.jd.com/Search?keyword=${k}&enc=utf-8&wq=${k}`;
                          }
                          window.open(url);
                        }}
                      />
                    </Typography.Link>
                  </Tooltip>
                ) : null}
              </>
            }
          />
        );
      },
    },
    {
      title: '品目',
      dataIndex: 'goodsClassName',
      width: 120,
    },
    {
      title: '品牌',
      dataIndex: 'goodsBrandName',
      width: 120,
    },
    {
      title: '市场价',
      dataIndex: 'marketPrice',
      width: 120,

      render(value, record, index) {
        return ConvertNumberToCurrency(value);
      },
    },
    {
      title: '来源',
      dataIndex: 'source',
    },
    {
      title: '备注',
      dataIndex: 'reason',
      render(value, record, index) {
        return (
          <Paragraph ellipsis={{ rows: 1, expandable: true, tooltip: true }}>
            {value}
          </Paragraph>
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 140,
      render(value, record) {
        return ConvertDate(value);
      },
    },
    {
      title: '操作',
      width: 80,
      key: 'actions',
      render(value, record, index) {
        return (
          <div>
            <DraftEditButton
              data={record}
              onSuccess={() => {
                tableRef.current.refresh();
              }}
            />
            <SingleDeleteDraftProductButton
              data={record}
              onSuccess={() => {
                tableRef.current.refresh();
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <BlankWrapper>
      {contextHolder}
      <CmtTable
        ref={tableRef}
        title="临时商品库"
        description="执行任务过程中，无法直接添加到货物超市的商品"
        rowKey="id"
        colums={columns}
        api={queryDraftProductsApi}
        selectable={true}
        secondaryExtends={({ selectedRows, formRef }) => {
          return (
            <Space>
              <Button
                type="default"
                shape="round"
                htmlType={'button'}
                onClick={() => tableRef.current.selectAll()}
              >
                全选
              </Button>
              <BatchDeleteDraftProductButton
                data={selectedRows}
                onSuccess={() => {
                  tableRef.current.refresh();
                }}
              />
              <ProjectInternalPlanButton
                actionId="mark-duplicate-model-draft-products"
                projectId={params.projectId || ''}
              >
                {({ show }) => <Button onClick={show}>标记重复型号</Button>}
              </ProjectInternalPlanButton>
            </Space>
          );
        }}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name={'goodsClassGuid'}
                  label={'品目'}
                  initialValue={''}
                >
                  <GpoCategoryCascader
                    queryParams={{
                      projectId: params.projectId || '',
                      group: GpoCategoryGroup.GoodMarket,
                    }}
                    style={{ width: 120 }}
                    onChange={submit}
                  />
                </Form.Item>
                <Form.Item
                  name="goodsBrandGuid"
                  label="品牌"
                  initialValue={''}
                  dependencies={['goodsClassGuid']}
                >
                  <GoodsBrandSelect
                    style={{ width: 120 }}
                    allowClear
                    onChange={submit}
                    queryParams={{
                      agreementType: '',
                      goodsClassGuid: formRef.getFieldValue('goodsClassGuid'),
                    }}
                  />
                </Form.Item>
                <Form.Item name={'name'} label={'名称'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'reason'} label={'备注'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="备注模糊查询"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              <InternalActionButton slot="grap-draft-product">
                <PlusOutlined /> 添加
              </InternalActionButton>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
