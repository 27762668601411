import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';

export enum ProjectPlatforms {
  GPO = 'gpo',
  RJMart = 'rjmart',
  CAS = 'cas',
}

export enum ProjectAuthType {
  /**
   * AccessToken
   */
  AccessToken = 'AccessToken',
  /**
   * Cookie
   */
  Cookie = 'Cookie',
  /**
   * Credentials
   */
  Credentials = 'Credentials',
}
export interface Project {
  id: string;
  organizationId: string;
  name: string;
  description: string;
  platform: ProjectPlatforms;
  authType: 'AccessToken' | 'Cookie' | 'Credentials';
  authValue: string;
  hasAuthValue: boolean;
  dingdingWebhookUrl: string;
  weixinWebhookUrl: string;
  createdAt: string;
  updatedAt: string;
  logoUrl: string;
  metabaseSecretKey: string;
  metabasePayload: string;
}

export async function queryProjectApi(
  params: PagingRequest
): Promise<PagingResponse<Project>> {
  const res: PagingResponse<Project> = await httpClient.get('/projects', {
    params,
  });
  const list1 = res.list.filter((x) => x.hasAuthValue);
  const list2 = res.list.filter((x) => !x.hasAuthValue);
  res.list = [...list1, ...list2];
  return res;
}

export async function getSingleProjectApi(projectId: string): Promise<Project> {
  const res: any = await httpClient.get(`/projects/${projectId}`);
  return res;
}

export async function getSingleProjectMetabaseReportLinkApi(
  projectId: string
): Promise<string> {
  const res: any = await httpClient.get(`/projects/${projectId}/metabase-link`);
  return res;
}

export async function updateSingleProjectApi(
  projectId: string,
  params: any
): Promise<Project> {
  const res: any = await httpClient.patch(`/projects/${projectId}`, params);
  return res;
}

export async function deleteSingleProjectApi(
  projectId: string
): Promise<Project> {
  const res: any = await httpClient.delete(`/projects/${projectId}`);
  return res;
}

export async function createSingleProjectApi(params: any): Promise<Project> {
  const res: any = await httpClient.post(`/projects`, params);
  return res;
}
