import { useTitle } from 'ahooks';
import { Result, Button, Card } from 'antd';
import { BlankWrapper } from '../../components/BlankWrapper';
import { GpoOrderSummary } from './GpoOrderSummary/GpoOrderSummary';

export function Dashboard() {
  useTitle('工作台', { restoreOnUnmount: true });
  return (
    <BlankWrapper>
      <GpoOrderSummary />
    </BlankWrapper>
  );
}
