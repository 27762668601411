import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Descriptions,
  Space,
  Spin,
  Table,
  Tabs,
  Image,
  Timeline,
  Typography,
  Alert,
  Divider,
  Button,
  Result,
  Steps,
  Empty,
} from 'antd';
import { useEffect, useState } from 'react';
import { queryCasOrderDetailApi } from '../../../../services/cas/order.service';

function NameValueTable(props: {
  data: { name: string; value: string }[][];
  width?: { [key: string]: number | string };
}) {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [columns, setColumns] = useState<
    {
      title: string;
      dataIndex: string;
      width: number | string;
    }[]
  >([]);
  useEffect(() => {
    const dataSources: any[] =
      props.data?.map((row) => {
        const item: any = {};
        row.forEach((y) => {
          item[y.name] = y.value;
        });
        return item;
      }) || [];
    const cols =
      props.data?.[0]?.map((x) => {
        return {
          title: x.name,
          dataIndex: x.name,
          width: props.width?.[x.name] || '',
        };
      }) || [];
    setDataSource(dataSources);
    setColumns(cols);
  }, [props.data]);
  return <Table dataSource={dataSource} columns={columns}></Table>;
}

export function OrderDetailView(props: {
  orderNo: string | undefined;
  projectId: string;
}) {
  const { data, loading, run, refresh } = useRequest(queryCasOrderDetailApi, {
    manual: true,
    debounceWait: 300,
  });

  useEffect(() => {
    if (props.orderNo && props.projectId) {
      run(props.orderNo);
    }
  }, [props.orderNo]);

  return (
    <Spin spinning={loading}>
      {data && (
        <Tabs>
          <Tabs.TabPane key={1} tab="订单信息">
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Steps
                labelPlacement="vertical"
                size="small"
                items={data.statusHistory?.map((x) => {
                  return {
                    title: x.action,
                    subTitle: x.date,
                    status: x.date ? 'finish' : 'wait',
                  };
                })}
              ></Steps>
              <Descriptions
                title={'订单信息'}
                bordered
                column={1}
                labelStyle={{ width: 150 }}
              >
                <Descriptions.Item label="订单编号">
                  {data?.sn}
                </Descriptions.Item>
                {data.mainDetails?.map((x) => {
                  return (
                    <Descriptions.Item label={x.name}>
                      <Typography.Text>
                        <pre
                          dangerouslySetInnerHTML={{ __html: x.value }}
                          style={{ border: 'none', background: 'none' }}
                        ></pre>
                      </Typography.Text>
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab="商品清单">
            <NameValueTable
              data={data.productList || []}
              width={{ 商品信息: 300 }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane key={4} tab="验货信息">
            <NameValueTable
              data={data.verifyInfo?.productsTable || []}
              width={{ 商品名称: 300 }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={5} tab="订单日志">
            <Table
              dataSource={data.orderLogs}
              columns={[
                {
                  title: '日期',
                  dataIndex: 'date',
                  width: 150,
                },
                {
                  title: '操作人',
                  dataIndex: 'actor',
                  width: 180,
                },
                {
                  title: '操作',
                  dataIndex: 'action',
                },
              ]}
            ></Table>
          </Tabs.TabPane>
          <Tabs.TabPane key={3} tab="物流跟踪">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Spin>
  );
}
