import { useRequest } from 'ahooks';
import { message, Switch } from 'antd';
import { useState } from 'react';
import { Plan, updatePlanActive } from '../../services/plan.service';

export function PlanStatusSwitch(props: { data: Plan; onChange?: () => void }) {
  const { data } = props;
  const [active, setActive] = useState(data.active);
  const [messageApi] = message.useMessage();
  const loadingMessageKey = 'interrupt-message-key' + data.id;
  const { run: runUpdatePlanActive, loading: updating } = useRequest(
    updatePlanActive,
    {
      manual: true,
      debounceWait: 200,
      onSuccess: (res) => {
        messageApi.open({
          type: 'success',
          key: loadingMessageKey,
          content: '更新成功',
          duration: 2,
        });
        setActive(!active);
        props.onChange?.();
      },
      onError: (err) => {
        messageApi.open({
          type: 'error',
          key: loadingMessageKey,
          content: '更新失败' + err.message,
          duration: 2,
        });
      },
    }
  );

  return (
    <Switch
      checkedChildren="ON"
      unCheckedChildren="OFF"
      checked={active}
      size="small"
      loading={updating}
      disabled={updating}
      onChange={() => runUpdatePlanActive(data.id, { active: !data.active })}
    />
  );
}
