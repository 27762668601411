import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  Descriptions,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { useEffect } from 'react';
import { OrderShippingInfoView } from '../../../gpo/pages/sub-pages/order/order-shipping-info-view';
import {
  GPOOrderDto,
  GPOOrderStatus,
  queryOrderDetailWithoutMetaApi,
  queryOrderShippingInfoApi,
} from '../../../services/gpo/order.service';

export function ShippingInfoView(props: {
  order: GPOOrderDto;
  projectId: string;
}) {
  const { data, loading, run, refresh } = useRequest(
    queryOrderShippingInfoApi,
    {
      manual: true,
    }
  );
  useEffect(() => {
    if (props.order) {
      run({ orderGuid: props.order.orderGuid, projectId: props.projectId });
    }
  }, [props.order]);
  return (
    <Spin spinning={loading}>
      <div style={{ width: 500, minHeight: 240 }}>
        <OrderShippingInfoView
          projectId={props.projectId}
          orderShippingInfo={data || []}
          orderGuid={props.order.orderGuid}
          onSuccess={refresh}
        />
      </div>
    </Spin>
  );
}

export function ShippingInfoButton(props: {
  order: GPOOrderDto;
  projectId: string;
}) {
  return props.order.auditStatus !== GPOOrderStatus.ConfirmPending ? (
    <Popover
      title={'物流信息'}
      zIndex={999}
      content={
        <ShippingInfoView order={props.order} projectId={props.projectId} />
      }
    >
      <div style={{ cursor: 'pointer' }}>
        {props.order.auditStatus === GPOOrderStatus.Confirmed ? (
          <Typography.Text type="danger">
            {props.order.logisticsGuid ? '未发货' : '未添加物流'}
          </Typography.Text>
        ) : (
          '查看物流'
        )}
      </div>
    </Popover>
  ) : (
    <>-</>
  );
}
