import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  MartOrderDeliveryPayload,
  MartOrderStatusEnum,
  MartSimpleOrderDto,
} from '../../../../services/cas/order-modal';
import {
  deliveryCasOrderApi,
  queryCasOrderCompanyListApi,
} from '../../../../services/cas/order.service';
import { useEffect, useState } from 'react';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';

function ComfirmOrderForm(props: {
  data: MartSimpleOrderDto;
  onSuccess?: () => void;
}) {
  const [form] = useForm<MartOrderDeliveryPayload>();
  const [selfDelivery, setSelfDelivery] = useState<0 | 1>(1);
  const [companies, setCompanies] = useState<
    { id: number; shipperCode: string; shipperName: string }[]
  >([]);
  const { loading: updating, run: runDelivery } = useRequest(
    deliveryCasOrderApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: () => {
        message.success(`操作成功`);
        props.onSuccess?.();
      },
      onError: (err) => {
        message.error(`操作失败：` + err.message);
      },
    }
  );
  const { loading: searching, run: runSearch } = useRequest(
    queryCasOrderCompanyListApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: (data) => {
        setCompanies(data);
      },
      onError: (err) => {
        message.error(`操作失败：` + err.message);
        setCompanies([]);
      },
    }
  );
  useEffect(() => {
    const payload: MartOrderDeliveryPayload = {
      ordersId: props.data.id,
      name: '默认模块',
      selfDelivery: selfDelivery,
      addrName: '',
      code: '',
      companyName: '',
      deliveryMan: '',
      deliveryMobile: '',
      note: '',
      deliveryGoodsList: props.data.ordersGoodsList.map((x) => {
        return {
          checked: true,
          goodsId: x.id,
          productId: x.productId,
          goodsMark: '',
          productCateId: x.productCateId,
          productCode: x.productCode,
          productName: x.productName,
          productImg: x.productImg,
          productSpec: x.productSpec,
          productPrice: x.productPrice,
          /**
           * 发货数量
           */
          productAmount: x.amount,
          receivedAmount: x.receivedAmount,
        };
      }),
      attachments: [],
    };
    form.setFieldsValue(payload);
  }, [props.data]);
  useEffect(() => {
    if (selfDelivery === 1) {
      form.setFieldsValue({ code: '', companyName: '' });
    } else {
      form.setFieldsValue({ deliveryMan: '', deliveryMobile: '' });
    }
  }, [selfDelivery]);
  return (
    <Spin spinning={updating}>
      <Space style={{ width: '100%', marginTop: 24 }} direction={'vertical'}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          labelAlign={'left'}
          onFinish={() => {
            const rs: MartOrderDeliveryPayload = form.getFieldsValue();
            runDelivery(rs);
          }}
        >
          <FormItem label="订单号" name={'ordersId'} hidden>
            <Input readOnly />
          </FormItem>
          <FormItem label="默认模块" name={'name'} hidden>
            <Input readOnly />
          </FormItem>
          <FormItem
            label="发货类型"
            name={'selfDelivery'}
            rules={[{ required: true }]}
          >
            <Radio.Group
              onChange={(v) => {
                setSelfDelivery(v.target.value);
              }}
            >
              <Radio value={1}>商家自送</Radio>
              <Radio value={0}>物流配送</Radio>
            </Radio.Group>
          </FormItem>
          <FormItem label="发货人" name={'addrName'}>
            <Input />
          </FormItem>
          <FormItem
            label="物流单号"
            name={'code'}
            hidden={selfDelivery === 1}
            rules={[{ required: selfDelivery === 0 }]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="物流公司"
            name={'companyName'}
            hidden={selfDelivery === 1}
            rules={[{ required: selfDelivery === 0 }]}
          >
            <Select
              showSearch={true}
              getPopupContainer={(t) => t.parentNode}
              options={companies.map((x) => {
                return { label: x.shipperName, value: x.shipperName };
              })}
              loading={searching}
              onSearch={runSearch}
            ></Select>
          </FormItem>
          <FormItem
            label="送货人"
            name={'deliveryMan'}
            hidden={selfDelivery === 0}
            rules={[{ required: selfDelivery === 1 }]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="送货人电话"
            name={'deliveryMobile'}
            hidden={selfDelivery === 0}
            rules={[{ required: selfDelivery === 1 }]}
          >
            <Input />
          </FormItem>
          <FormItem label="配送备注" name={'note'}>
            <Input />
          </FormItem>
          <Card style={{ marginBottom: 24 }}>
            <Form.List name="deliveryGoodsList">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Row gutter={8}>
                      <Col span={13}>
                        #&nbsp;&nbsp;&nbsp;产品信息（请勾选发货商品）
                      </Col>
                      <Col span={3}>价格</Col>
                      <Col span={2}>购买数量</Col>
                      <Col span={3}>已验货数量</Col>
                      <Col span={2}>发货数量</Col>
                    </Row>
                    {fields.map(({ key, name }, index) => {
                      return (
                        <Row gutter={8} key={key}>
                          <Col span={13}>
                            <FormItem name={[name, 'goodsId']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'productId']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'goodsMark']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'productCode']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'productName']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'productImg']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'productSpec']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem name={[name, 'productPrice']} hidden>
                              <Input />
                            </FormItem>
                            <FormItem
                              name={[name, 'checked']}
                              valuePropName="checked"
                            >
                              <Checkbox>
                                <Typography.Text>
                                  {form.getFieldValue([
                                    'deliveryGoodsList',
                                    name,
                                    'productName',
                                  ])}
                                </Typography.Text>
                                <Typography.Paragraph type="secondary">
                                  <small>
                                    货号：
                                    {form.getFieldValue([
                                      'deliveryGoodsList',
                                      name,
                                      'productCode',
                                    ])}
                                    <br />
                                    规格：
                                    {form.getFieldValue([
                                      'deliveryGoodsList',
                                      name,
                                      'productSpec',
                                    ])}
                                  </small>
                                </Typography.Paragraph>
                              </Checkbox>
                            </FormItem>
                          </Col>
                          <Col span={4}>
                            {ConvertNumberToCurrency(
                              form.getFieldValue([
                                'deliveryGoodsList',
                                name,
                                'productPrice',
                              ])
                            )}
                          </Col>
                          <Col span={2}>
                            {form.getFieldValue([
                              'deliveryGoodsList',
                              name,
                              'productAmount',
                            ])}
                          </Col>
                          <Col span={2}>
                            {form.getFieldValue([
                              'deliveryGoodsList',
                              name,
                              'receivedAmount',
                            ])}
                          </Col>
                          <Col span={2}>
                            <FormItem
                              name={[name, 'productAmount']}
                              rules={[
                                {
                                  required: true,
                                  message: '请输入正确的发货数量',
                                },
                              ]}
                            >
                              <InputNumber
                                size="small"
                                min={0}
                                max={form.getFieldValue([
                                  'deliveryGoodsList',
                                  name,
                                  'productAmount',
                                ])}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                );
              }}
            </Form.List>
          </Card>
          <FormItem>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={updating}
                disabled={updating}
              >
                确认发货
              </Button>
              <Button type="text" onClick={props.onSuccess}>
                取消
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Space>
    </Spin>
  );
}

export function DeliverProductButton(props: {
  data: MartSimpleOrderDto;
  onSuccess?: () => void;
}) {
  if (
    props.data.deliveryStatus !== 0 ||
    props.data.status !== MartOrderStatusEnum.Processing
  ) {
    return <></>;
  }
  return (
    <CmtModalButton
      title={`订单发货【${props.data?.sn}】`}
      width={800}
      trigger={({ show }) => {
        return (
          <Tooltip title={'订单发货'}>
            <Button type="link" size="small" onClick={show}>
              订单发货
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ComfirmOrderForm
            data={props.data}
            onSuccess={() => {
              hide();
              props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
