import { GPOGoodFormTemplateDto } from '../../common/dtos/AddGoodTypes';
import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import { ENV_CONFIG } from '../../env-config';
import { getAccessToken } from '../auth.service';
import httpClient from '../http-client';
import { GpoCategory } from './category.service';
/**
 * 查询商品状态
 */

/**
 * 商品上架状态
 */
export enum GoodSellStatus {
  /**
   * 已上架
   */
  Published = 1,
  /**
   * 未上架
   */
  Unpublished = 0,
}

/**
 * 查询商品状态
 */
export enum GoodQuerySellStatus {
  /**
   * 全部
   */
  All = 0,
  /**
   * 已上架
   */
  Published = 3,
  /**
   * 未上架
   */
  Unpublished = 1,
  /**
   * 审核中
   */
  Auditing = 2,
}

export const GpoGoodmarketProductQuerySellStatusOptions = [
  { label: '全部', value: GoodQuerySellStatus.All },
  { label: '已上架', value: GoodQuerySellStatus.Published },
  { label: '未上架', value: GoodQuerySellStatus.Unpublished },
  { label: '审核中', value: GoodQuerySellStatus.Auditing },
];

export const GpoGoodmarketProductSellStatusOptions = [
  { label: '已上架', value: GoodSellStatus.Published },
  { label: '未上架', value: GoodSellStatus.Unpublished },
];

export function getGpoGoodmarketProductStatusName(status: GoodSellStatus) {
  return (
    GpoGoodmarketProductSellStatusOptions.find((x) => x.value == status)
      ?.label || ''
  );
}

export interface GpoGoodMarketProduct {
  id: string;

  projectId: string;

  organizationId: string;

  /**
   * 商品类型
   */
  goodsSource: string;
  /**
   * 分类名
   */
  goodsClassName: string;
  /**
   * 分类ID
   */
  goodsClassGuid: string;

  /**
   * 品牌名
   */
  goodsBrandName: string;
  /**
   * 品牌ID
   */
  goodsBrandGuid: string;

  /**
   * 商品名称
   */
  name: string;

  /**
   * 主图地址
   */
  picturePath: string;

  /**
   * 销售价格
   */
  salesPrice: number;

  /**
   * 市场价格
   */
  marketPrice: number;

  /**
   * 商品编号
   */
  goodsCode: string;
  /**
   * 商品GUID
   */
  goodsGuid: string;
  /**
   * 商品价格ID
   */
  goodsPriceGuid: string;

  /**
   * 上下架状态
   */

  sellStatus: GoodSellStatus;

  createDate: string;
  sourceTag: string;
  /**
   * 新销售价
   */
  competitorQuotePrice?: string;

  /**
   * 新销售价产生日期
   */
  competitorQuotePriceCreatedAt?: string;
  [key: string]: any;
}

/**
 * 经销商申请的协议DTO
 */
export interface GPOSupplierAgreementDto {
  supplierAgreementGuid: string;
  agreementCode: string;
  regionGuid: string;
  agreementName: string | null;
  regionName: string;
  agreementStatus: number;
}

/**
 * 商品型号
 */
export type GPOGoodModel = string;
/**
 * 商品版本
 */
export interface GPOGoodVersion {
  spuGuid: string;
  version: string;
}

const BASE_PATH = '/gpo/good-market-products';

export function getUploadActionUrl() {
  return `${ENV_CONFIG.BASE_URL}${BASE_PATH}/creation-good-picture-upload`;
}

export function getUploadActionHeaders() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

export async function queryGoodMarketProductsApi(
  params: PagingRequest
): Promise<PagingResponse<GpoGoodMarketProduct>> {
  const res: any = await httpClient.get(BASE_PATH, {
    params,
  });
  return res;
}

export async function queryLocalGoodMarketProductsApi(
  params: PagingRequest
): Promise<PagingResponse<GpoGoodMarketProduct>> {
  const res: any = await httpClient.get(`${BASE_PATH}/local`, {
    params,
  });
  return res;
}

export async function checkLocalGoodMarketProductModelExists(params: {
  goodsClassGuid: string;
  goodsBrandGuid: string;
  code: string;
}): Promise<boolean> {
  const res = await queryLocalGoodMarketProductsApi({
    ...params,
    current: 1,
    pageSize: 100,
  });
  return res.total > 0;
}

export interface ChangeGoodMarketStatusDto {
  currentPrice: number;
  goodsGuid: string;
  goodsPriceGuid: string;
  regionGuid: string;
  sellStatus: GoodSellStatus;
  supplierAgreementGuid: string;
}

export async function changeGoodMarketProductStatusApi(
  product: GpoGoodMarketProduct,
  params: ChangeGoodMarketStatusDto
) {
  const res: any = await httpClient.post(
    `${BASE_PATH}/change-good-sell-status`,
    { params, product }
  );
  return res;
}

export async function changeGoodMarketProductFrozenApi(
  product: GpoGoodMarketProduct
) {
  const res: any = await httpClient.post(
    `${BASE_PATH}/switch-frozen-status`,
    product
  );
  return res;
}

export async function removeGoodMarketProductApi(
  product: GpoGoodMarketProduct
) {
  const res: any = await httpClient.post(`${BASE_PATH}/remove-good`, product);
  return res;
}

export async function unpublishGoodMarketProduct(
  product: GpoGoodMarketProduct
) {
  try {
    const preInfo = await getSaleManagementInfo(product);
    product.supplierAgreementGuid= preInfo.supplierAgreements?.[0].supplierAgreementGuid;
    const info = await getSaleItemInfo(product);
    const changeDto: ChangeGoodMarketStatusDto = {
      currentPrice: info.currentPrice,
      goodsGuid: product.goodsGuid,
      goodsPriceGuid: product.goodsPriceGuid,
      regionGuid: info.regionGuid,
      sellStatus: GoodSellStatus.Unpublished,
      supplierAgreementGuid: info.supplierAgreementGuid,
    };
    await changeGoodMarketProductStatusApi(product, changeDto);
    return { success: true };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
}

export async function getSaleManagementInfo(
  product: GpoGoodMarketProduct
): Promise<{
  supplierAgreements: GPOSupplierAgreementDto[];
  goodsPriceList: {
    [key: string]: any;
    goodsPriceGuid: string;
    regionName: string;
    currentPrice: number;
  }[];
  goods: GpoGoodMarketProduct;
}> {
  const res: any = await httpClient.post(
    `${BASE_PATH}/sale-management-info`,
    product
  );
  return res;
}

export async function getSaleItemInfo(
  product: GpoGoodMarketProduct
): Promise<any> {
  const res: any = await httpClient.post(
    `${BASE_PATH}/get-sale-item-info`,
    product
  );
  return res;
}

export interface GpoDeliverRegion {
  id: string;
  name: string;
  parentId: string;
  children?: GpoDeliverRegion[];
  checked: boolean;
}
export async function getDeliverRegions(params: {
  goodsGuid: string;
  supplierAgreementGuid: string;
}): Promise<GpoDeliverRegion[]> {
  const res: any = await httpClient.get(`${BASE_PATH}/deliver-regions`, {
    params,
  });
  return res;
}

export async function getCreationValidAgreementTypes(): Promise<number[]> {
  const res: any = await httpClient.get(
    `${BASE_PATH}/creation-valid-agreement-types`
  );
  return res;
}

export async function getCreationCategories(params: {
  type: number;
}): Promise<
  { goodsClassGuid: string; goodsClassName: string; goodsClassPguid: string }[]
> {
  const res: any = await httpClient.get(`${BASE_PATH}/creation-categories`, {
    params,
  });
  return res;
}

export async function getCreationBrands(params: {
  type: number;
  goodsClassGuid: string;
}): Promise<{ goodsBrandGuid: string; goodsBrandName: string }[]> {
  const res: any = await httpClient.get(`${BASE_PATH}/creation-brands`, {
    params,
  });
  return res;
}

export async function getCreationCodes(params: {
  goodsBrandGuid: string;
  goodsClassGuid: string;
}): Promise<GPOGoodModel[]> {
  const res: any = await httpClient.get(`${BASE_PATH}/creation-codes`, {
    params,
  });
  return res;
}

export async function getCreationVersions(params: {
  goodsBrandGuid: string;
  goodsClassGuid: string;
  goodsModal: string;
}): Promise<GPOGoodVersion[]> {
  const res: any = await httpClient.get(`${BASE_PATH}/creation-versions`, {
    params,
  });
  return res;
}

export async function getCreationGoodForm(
  params: any
): Promise<GPOGoodFormTemplateDto> {
  const res: any = await httpClient.post(
    `${BASE_PATH}/creation-good-form`,
    params
  );
  return res;
}

export async function saveGood(payload: any, reupload = false): Promise<any> {
  const res: any = await httpClient.post(`${BASE_PATH}/save-good`, {
    payload,
    reupload,
  });
  return res;
}

export async function getModifitionGoodForm(params: {
  goodsGuid: string;
}): Promise<GPOGoodFormTemplateDto> {
  const res: any = await httpClient.get(`${BASE_PATH}/modification-good-form`, {
    params,
  });
  return res;
}
