import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import {
  GoodSellStatus,
  GpoGoodMarketProduct,
  removeGoodMarketProductApi,
} from '../../../../services/gpo/goodmarket.service';

const { Text, Paragraph } = Typography;
export function RemoveGoodButton(props: {
  data: GpoGoodMarketProduct;
  onSuccess?: () => void;
}) {
  return props.data.sellStatus === GoodSellStatus.Unpublished ? (
    <CmtPopConfirmAction
      service={removeGoodMarketProductApi}
      title={
        <Space direction="vertical" style={{ maxWidth: 300 }}>
          <Text strong>{`确定移除吗？`}</Text>
          <Paragraph>{props.data.name}</Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run(data);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button type="text" size="small" disabled={loading} loading={loading}>
          <DeleteOutlined />
        </Button>
      )}
    </CmtPopConfirmAction>
  ) : (
    <></>
  );
}
