import { CarOutlined, ShopOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Input,
  Popover,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import {
  PagingRequest,
  PagingResponse,
} from '../../../common/dtos/paging-request';
import { ConvertNumberToCurrency } from '../../../common/utils/convert-number-to-currency';
import { CmtTable } from '../../../components/CmtTable';
import { ProductName } from '../../../gpo/components/ProductName';
import {
  getOrderStatusName,
  GPOOrderDto,
  GpoOrderMetaDto,
  GpoOrderStatusOptions,
  queryOrderSummaryApi,
} from '../../../services/gpo/order.service';
import { Project } from '../../../services/project.service';
import { BuyInfoButton } from './BuyInfo';
import { ContractInfoButton } from './ContractInfo';
import { OrderMetaEdit } from './OrderMetaEdit';
import { ShippingInfoButton } from './ShippingInfo';

export function GpoOrderSummaryTable(props: { project: Project | undefined }) {
  const tableRef = useRef<any>(null);
  const columns: ColumnsType<{
    order: GPOOrderDto;
    meta: GpoOrderMetaDto;
    detail: any;
  }> = [
    {
      title: '#',
      key: 'index',
      width: 40,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Typography.Text type={'secondary'}>
            {(index + 1).toString().padStart(3, '0')}
          </Typography.Text>
        );
      },
    },
    {
      title: '下单时间',
      key: 'createDate',
      width: 150,
      fixed: 'left',
      render(value, record, index) {
        return record.order.createDate;
      },
    },

    {
      title: '采购单位',
      key: 'orgName',
      fixed: 'left',
      width: 200,
      render(value, { order }, index) {
        return (
          <Tooltip title={order.orgName}>
            <Typography.Text ellipsis={true}>{order.orgName}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: '订单商品',
      key: 'goods',
      width: 300,
      render: (value, { order }, index) => {
        return order.orderGoodsVOList.map((x: any) => (
          <Space size={'small'} key={x.orderGoodsGuid}>
            <ProductName
              picturePath={x.picturePath}
              name={x.goodsName}
              goodsPriceGuid={x.goodsPriceGuid}
              extends={<Typography.Text code>x{x.qty}</Typography.Text>}
            />
          </Space>
        ));
      },
    },
    {
      key: 'orderCode',
      title: '订单编号',
      width: 150,
      render(value, { order }, index) {
        return <Typography.Text copyable>{order.orderCode}</Typography.Text>;
      },
    },
    {
      title: '订单状态',
      key: 'auditStatus',
      width: 100,
      render: (value, { order }) => {
        return getOrderStatusName(order.auditStatus);
      },
    },
    {
      title: '合同状态',
      key: 'contractStatus',
      width: 100,
      render: (value, { order }) => {
        return (
          <ContractInfoButton
            order={order}
            projectId={props.project?.id || ''}
          />
        );
      },
    },
    {
      title: '发票状态',
      key: '',
      width: 80,
      render(value, record, index) {
        return record.order.invoicePath ? (
          <Typography.Text>已上传</Typography.Text>
        ) : (
          <Typography.Text type="danger">未上传</Typography.Text>
        );
      },
    },
    {
      title: '物流信息',
      key: 'deliveryStatus',
      width: 100,
      render: (value, { order }) => {
        return (
          <ShippingInfoButton
            order={order}
            projectId={props.project?.id || ''}
          />
        );
      },
    },
    {
      title: '商务资料',
      key: 'note1',
      width: 100,
      render: (value, { meta, order }) => {
        return (
          <Space direction="vertical">
            <OrderMetaEdit
              projectId={props.project?.id || ''}
              meta={meta}
              order={order}
              showFields={['note1']}
              content={
                <Popover
                  title="商务资料"
                  content={
                    <div style={{ width: 200 }}>{meta?.note1 || '-'}</div>
                  }
                >
                  <Typography.Text ellipsis={true} style={{ maxWidth: 70 }}>
                    {meta?.note1 || '-'}
                  </Typography.Text>
                </Popover>
              }
              onSuccess={tableRef.current?.refresh}
            />
            <OrderMetaEdit
              projectId={props.project?.id || ''}
              meta={meta}
              order={order}
              showFields={['note2']}
              icon={<CarOutlined />}
              content={
                <Popover
                  title="商务物流"
                  content={
                    <div style={{ width: 200 }}>
                      {meta?.note2 ? '已发物流' : '-'}
                    </div>
                  }
                >
                  <Typography.Text ellipsis={true} style={{ maxWidth: 70 }}>
                    {meta?.note2 ? '已发物流' : '-'}
                  </Typography.Text>
                </Popover>
              }
              onSuccess={tableRef.current?.refresh}
            />
          </Space>
        );
      },
    },
    // {
    //   title: '是否需要售后',
    //   key: 'note2',
    //   width: 100,
    //   render: (value, { meta, order }) => {
    //     return (
    //       <OrderMetaEdit
    //         projectId={props.project?.id || ''}
    //         meta={meta}
    //         order={order}
    //         showFields={['note2']}
    //         content={meta?.note2 || '-'}
    //         onSuccess={tableRef.current?.refresh}
    //       />
    //     );
    //   },
    // },

    {
      title: '客户资料',
      key: 'buyerAddress',
      width: 100,
      render: (value, { order }) => {
        return (
          <BuyInfoButton order={order} projectId={props.project?.id || ''} />
        );
      },
    },
    {
      title: '合同/验收报告',
      key: 'note4',
      width: 100,
      render: (value, { meta, order }) => {
        return (
          <OrderMetaEdit
            projectId={props.project?.id || ''}
            meta={meta}
            order={order}
            showFields={['note4']}
            content={meta?.note4 || '-'}
            onSuccess={tableRef.current?.refresh}
          />
        );
      },
    },
    {
      title: '业务跟进',
      key: 'note5',
      width: 100,
      render: (value, { meta, order }) => {
        return (
          <OrderMetaEdit
            projectId={props.project?.id || ''}
            meta={meta}
            order={order}
            showFields={['note5']}
            content={meta?.note5 || '-'}
            onSuccess={tableRef.current?.refresh}
          />
        );
      },
    },
    {
      title: '备注',
      key: 'note3',
      width: 100,
      render: (value, { meta, order }) => {
        return (
          <OrderMetaEdit
            projectId={props.project?.id || ''}
            meta={meta}
            order={order}
            showFields={['note3']}
            content={
              <Popover
                title="备注"
                content={<div style={{ width: 200 }}>{meta?.note3 || '-'}</div>}
              >
                <Typography.Text ellipsis={true} style={{ maxWidth: 70 }}>
                  {meta?.note3 || '-'}
                </Typography.Text>
              </Popover>
            }
            onSuccess={tableRef.current?.refresh}
          />
        );
      },
    },
  ];
  const statusOptions = [
    { value: '', label: '全部' },
    ...GpoOrderStatusOptions,
  ];

  const [project, setProject] = useState<any>();
  useEffect(() => {
    if (props.project?.id) {
      setProject(props.project);
    }
  }, [props.project?.id]);
  return (
    <div style={{ height: '100%' }}>
      <CmtTable
        key={props.project?.id}
        ref={tableRef}
        colums={columns}
        title={`「${project?.name}」的订单进度`}
        rowKey={'id'}
        api={queryOrderSummaryApi}
        containerPaddingSize={12}
        horizontalPadding={0}
        defaultPageSize={10}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name={'projectId'}
                  initialValue={props.project?.id}
                ></Form.Item>
                <Form.Item
                  name={'auditStatus'}
                  label={'状态'}
                  initialValue={''}
                >
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'orderCode'} label={'订单编号'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入订单编号"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'orgName'} label={'采购单位'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入采购单位"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                {/* <Form.Item name={'note5'} label={'业务跟进'}>
                <Input
                  style={{ width: 120 }}
                  placeholder="输入业务跟进"
                  onChange={submit}
                  allowClear
                ></Input>
              </Form.Item> */}
                <Form.Item>
                  <Button type="default" onClick={submit}>
                    <SyncOutlined /> 刷新
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          );
        }}
      ></CmtTable>
    </div>
  );
}
