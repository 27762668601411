import { useRequest } from 'ahooks';
import { Input, Select, Button, Space, message, Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REGEXS } from '../../common/utils/formats';
import { GlobalContext } from '../../context';
import { getMyProfile, updateMyProfile } from '../../services/user.service';

export function BasicInfoForm(props: { onSuccess?: () => void }) {
  const [form] = Form.useForm<any>();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);

  const { loading: loading } = useRequest(getMyProfile, {
    debounceWait: 300,
    onSuccess(data, params) {
      form.setFieldsValue({
        name: data.name,
        email: data.email,
        mobilePhone: data.mobilePhone,
      });
    },
    onError(e, params) {
      message.error('获取资料失败：' + e.message);
    },
  });

  const { loading: updating, run: runUpdate } = useRequest(updateMyProfile, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      setGlobalContext({ ...globalContext });
      message.success('资料更新成功');
    },
    onError(e, params) {
      message.error('资料更新失败：' + e.message);
    },
  });
  return (
    <Form
      form={form}
      labelCol={{ span: 3 }}
      labelAlign={'left'}
      disabled={updating}
      onFinish={() => {
        runUpdate(form.getFieldsValue());
      }}
    >
      <br />
      <FormItem label="姓名" name={'name'} rules={[{ required: true }]}>
        <Input maxLength={50} readOnly bordered={false} />
      </FormItem>
      <FormItem
        label="邮箱"
        name={'email'}
        rules={[
          { required: true },
          { pattern: REGEXS.email, message: '格式不正确' },
        ]}
      >
        <Input maxLength={200} readOnly bordered={false} />
      </FormItem>
      <FormItem
        label="手机"
        name={'mobilePhone'}
        rules={[
          { required: true },
          { pattern: REGEXS.mobilePhone, message: '格式不正确' },
        ]}
      >
        <Input maxLength={15} readOnly bordered={false} />
      </FormItem>
      {/* <Form.Item>
        <Space>
          <Button htmlType="submit" loading={updating} disabled={updating}>
            保存
          </Button>
        </Space>
      </Form.Item> */}
    </Form>
  );
}
