import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';

/**
 * 协议状态
 */
export enum GPOAgreementStatus {
  /**
   * 草稿
   */
  Draft = -1,
  /**
   * 审核中
   */
  Auditing = 0,
  /**
   * 已生效
   */
  Active = 1,
  /**
   * 已退回
   */
  Returned = 2,
  /**
   * 已失效
   */
  Invalid = 3,
}

export const GPOAgreementStatusOptions = [
  { label: '草稿', value: GPOAgreementStatus.Draft },
  { label: '审核中', value: GPOAgreementStatus.Auditing },
  { label: '已生效', value: GPOAgreementStatus.Active },
  { label: '已退回', value: GPOAgreementStatus.Returned },
  { label: '已失效', value: GPOAgreementStatus.Invalid },
];

export function getAgreementStatusName(status: GPOAgreementStatus) {
  return GPOAgreementStatusOptions.find((x) => x.value === status)?.label || '';
}

/**
 * 协议DTO
 */
export interface GPOAgreementDto {
  agreementCode: string;
  agreementName: string;
  supplierAgreementGuid: string;
  agreementType: number;
  agreementStatus: GPOAgreementStatus;
  [key: string]: any;
}

export async function queryAgreementApi(
  params: PagingRequest
): Promise<PagingResponse<GPOAgreementDto>> {
  const res: any = await httpClient.get('/gpo/agreements', {
    params,
  });
  return res;
}
