import { useRequest, useTitle } from 'ahooks';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import print from './print.module.less';
import { MartSimpleOrderDto } from '../../../../../services/cas/order-modal';
import {
  getCasOrderDeliveryPrint,
  queryCasOrderDetailApi,
} from '../../../../../services/cas/order.service';

import QRCode from 'qrcode';

export const PrintableOrderDelivery = React.forwardRef<
  any,
  { data: MartSimpleOrderDto; projectId: string }
>((props, ref) => {
  const [html, setHtml] = useState('');
  const { loading, run } = useRequest(getCasOrderDeliveryPrint, {
    manual: true,
    debounceWait: 100,
    onSuccess: async (data, params) => {
      const dom = new DOMParser().parseFromString(data, 'text/html');
      let tableHtml =
        dom.querySelector('.container .usertable')?.parentElement?.innerHTML ||
        '';
      const matchs = /"\/common\/qrcode\?content=(\d+)"/gim.exec(tableHtml);
      if (matchs?.length) {
        const dataUrl = await QRCode.toDataURL(matchs[1], {
          width: 86,
          margin: 0,
        });
        tableHtml = tableHtml.replace(matchs[0], `"${dataUrl}"`);
      }
      setHtml(tableHtml);
    },
  });
  const { loading: loadingDetail, run: getDetails } = useRequest(
    queryCasOrderDetailApi,
    {
      manual: true,
      debounceWait: 100,
      onSuccess(data, params) {
        run(data.id, data.deliveryIds || []);
      },
    }
  );
  useTitle(props.data.sn || '', { restoreOnUnmount: true });
  useEffect(() => {
    if (props.data.id && props.projectId) {
      getDetails(props.data.sn);
    }
  }, [props.data, props.projectId]);

  return (
    <Spin spinning={loading || loadingDetail}>
      <div className={print['printView']} ref={ref}>
        {/* <style type="text/css" media="print">
          {
            '\
  @page { size: a4; margin: 1cm 0.5cm; }\
'
          }
        </style> */}
        <div
          dangerouslySetInnerHTML={{
            __html: html || '',
          }}
        ></div>
      </div>
    </Spin>
  );
});
