import { BarsOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Result,
  Segmented,
  Select,
  Space,
  Switch,
  Tabs,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BlankWrapper } from '../../../../components/BlankWrapper';
import { CmtTable } from '../../../../components/CmtTable';
import { Permission } from '../../../../components/Permission';
import {
  GpoCategory,
  GpoCategoryGroup,
  queryGpoCategoryApi,
} from '../../../../services/gpo/category.service';
import { CategoryAutomationSettings } from './category-automation-settings';
import { CategoryName } from './category-name';

export function CategoryListPage() {
  const tableRef = useRef<any>(null);
  const params = useParams();
  useTitle('商品分类');
  const catGroupOptions = [
    {
      label: '集采商城',
      value: GpoCategoryGroup.JiCai,
    },
    {
      label: '货物超市',
      value: GpoCategoryGroup.GoodMarket,
    },
  ];
  const columns: ColumnsType<GpoCategory> = [
    {
      title: '分类ID',
      dataIndex: 'platformId',
    },
    {
      title: '分类名称',
      dataIndex: 'platformName',
    },
    {
      title: '父分类ID',
      dataIndex: 'platformParentId',
      render(value) {
        return <CategoryName id={value} projectId={params.projectId || ''} />;
      },
    },
    {
      title: '自动化配置',
      key: 'CategoryAutomationSettings',
      width: 280,
      render: (value, record) => {
        return (
          <CategoryAutomationSettings
            data={record}
            group={tableRef.current.form.getFieldValue('group')}
            onSuccess={tableRef.current?.refresh}
          />
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '智慧云', icon: <BarsOutlined /> },
        { name: '系统设置' },
        { name: '商品分类' },
      ]}
    >
      <Permission
        resource="gpo"
        action="category.all"
        error={
          <Result
            status={'error'}
            title="您没有权限访问"
            subTitle={'请联系管理员调整权限'}
          ></Result>
        }
      >
        <CmtTable
          ref={tableRef}
          title={
            <Segmented
              defaultValue={
                catGroupOptions.find((x) => x.label === GpoCategoryGroup.JiCai)
                  ?.label
              }
              options={catGroupOptions.map((x) => {
                return x.label;
              })}
              onChange={(key) => {
                const group = catGroupOptions.find(
                  (x) => x.label === key
                )?.value;
                tableRef.current.form.setFieldsValue({ group });
                tableRef.current.submit();
              }}
            />
          }
          description=""
          rowKey="id"
          api={queryGpoCategoryApi}
          colums={columns}
          extends={({ formRef, tableSearch }) => {
            const { submit } = tableSearch;
            return (
              <Space>
                <Form form={formRef} layout={'inline'}>
                  <Form.Item
                    name="projectId"
                    initialValue={params.projectId}
                    hidden
                  >
                    <Input></Input>
                  </Form.Item>
                  <Form.Item
                    hidden={true}
                    name={'group'}
                    label={'场馆'}
                    initialValue={GpoCategoryGroup.JiCai}
                  >
                    <Select
                      style={{ width: 120 }}
                      onChange={submit}
                      options={catGroupOptions}
                    ></Select>
                  </Form.Item>
                  <Form.Item name={'platformName'} label={'名称'}>
                    <Input
                      style={{ width: 120 }}
                      placeholder="输入分类名称"
                      onChange={submit}
                      allowClear
                    ></Input>
                  </Form.Item>
                </Form>
                <Button type="default" onClick={submit}>
                  <SyncOutlined /> 刷新
                </Button>
              </Space>
            );
          }}
        ></CmtTable>
      </Permission>
    </BlankWrapper>
  );
}
