import { useRequest } from 'ahooks';
import { Radio, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { findCarryFeeTemplate } from '../../../../../services/rjmart/product-mamagement.service';
import { RJDeliverCostTemplate } from '../../../../../services/rjmart/product-modal';

export function CarryFeeTemplateIdInput(props: {
  value?: number;
  style?: React.CSSProperties;
  queryParams: { projectId: string };
  onChange?: (v: number) => void;
}) {
  const [defaultValue, setDefaultValue] = useState(0);
  const [templates, setTemplates] = useState<RJDeliverCostTemplate[]>([]);

  const { run, loading } = useRequest(findCarryFeeTemplate, {
    manual: true,
    onSuccess: (v) => {
      setTemplates([{ templateName: '全国包邮', id: 0 }, ...v]);
      setDefaultValue(props.value || 0);
    },
  });
  useEffect(() => {
    if (props.queryParams?.projectId) {
      run(props.queryParams);
    }
    if (props.value === null) {
      setDefaultValue(0);
      props.onChange?.(0);
    }
  }, [props.value, props.queryParams?.projectId]);
  return !loading ? (
    <Select
      style={props.style ? props.style : { width: 240 }}
      onChange={(v) => {
        setDefaultValue(v);
        props.onChange?.(v);
      }}
      defaultValue={defaultValue}
      options={templates.map((x) => {
        return { label: x.templateName, value: x.id };
      })}
    ></Select>
  ) : null;
}
