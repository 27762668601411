import { DeleteOutlined } from '@ant-design/icons';
import { Space, Button, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../../../components/CmtPopConfirmAction';
import { removeInvoicePath } from '../../../../services/gpo/order.service';

export function RemoveInvoiceButton(props: {
  orderGuid: string;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={removeInvoicePath}
      title={
        <Space direction="vertical" style={{ maxWidth: 300 }}>
          <Typography.Text strong>{`确定移除发票吗？`}</Typography.Text>
        </Space>
      }
      onOk={({ data, run }) => {
        run(data);
      }}
      data={props.orderGuid}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button type="default" disabled={loading} loading={loading}>
          <DeleteOutlined /> 移除发票
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
