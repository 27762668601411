import styles from './style/index.module.less';
import Logo from '@/logo.svg';
import { Footer } from '../../components/Footer';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Space,
  Typography,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTitle, useRequest, useLocalStorageState, useSet } from 'ahooks';
import {
  authLogin,
  decodeJwtToken,
  saveAccessToken,
} from '../../services/auth.service';
import { ENV_CONFIG } from '../../env-config';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context';
import {
  JwtMetaItemKeys,
  JwtTokenPayload,
} from '../../common/dtos/jwt-token-payload';
import { useLocation, useNavigate } from 'react-router-dom';
import { REGEXS } from '../../common/utils/formats';
import { TwoFactorConfirm } from './two-factor-confirm';

export function LoginPage() {
  const [form] = Form.useForm<any>();
  let location = useLocation();
  sessionStorage.removeItem('cmt-selected-pid');
  let from = location.state?.from?.pathname || ENV_CONFIG.DEFAULT_PAGE;
  const [show2FAModal, setShow2FAModal] = useState('');
  const [loginOptions, setLoginOptions] = useLocalStorageState<{
    account: string;
    remember: boolean;
  }>('cmt-login-options', {
    defaultValue: { account: '', remember: false },
  });
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const doLogin = (formData: {
    email: string;
    password: string;
    remember: null | true;
  }) => {
    setErrorMessage('');
    run(formData.email, formData.password);
  };
  const { loading, run } = useRequest(authLogin, {
    manual: true,
    debounceWait: 400,
    onSuccess(data, params) {
      const formData = form.getFieldsValue();
      if (formData.remember) {
        setLoginOptions({ account: formData.email, remember: true });
      } else {
        setLoginOptions({ account: '', remember: false });
      }
      check2FAStatus(data.accessToken);
    },
    onError({ message }, params) {
      setErrorMessage(message);
    },
  });

  const check2FAStatus = (accessToken: string) => {
    const jwt: JwtTokenPayload = decodeJwtToken(accessToken);
    const enabled =
      jwt.meta.find((x) => x.k === JwtMetaItemKeys.twoFactorEnable)?.v === '1';
    const force2Fa =
      jwt.meta.find((x) => x.k === JwtMetaItemKeys.forceTwoFactor)?.v === '1';
    saveAccessToken(accessToken);
    if (enabled) {
      setShow2FAModal(accessToken);
    } else if (force2Fa) {
      setGlobalContext({ ...globalContext, userMetaData: jwt.meta });
      navigate('/enable-2fa');
    } else {
      // 无效？
      setGlobalContext({ ...globalContext, userMetaData: jwt.meta });
      navigate(from);
    }
  };

  useTitle('登录');

  useEffect(() => {
    form.setFieldsValue({
      email: loginOptions.account,
      remember: loginOptions.remember,
    });
    setGlobalContext({ userMetaData: undefined, currentProject: undefined });
  }, []);
  return (
    <div className={styles['login-form-container']}>
      <Space
        direction="vertical"
        size={'large'}
        className={styles['login-form']}
      >
        <header>
          <Logo />
          <p>
            <Typography.Text type={'secondary'}>
              {ENV_CONFIG.SLOGAN}
            </Typography.Text>
          </p>
          {errorMessage && (
            <Alert type={'error'} message={errorMessage}></Alert>
          )}
        </header>
        <section>
          {!show2FAModal && (
            <Form
              form={form}
              autoComplete="off"
              size={'large'}
              onFinish={doLogin}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: '请输入账号' },
                  {
                    pattern: REGEXS.mobilePhone,
                    message: '请输入正确的手机号码',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="账号"
                  prefix={<UserOutlined />}
                ></Input>
              </Form.Item>
              <Form.Item
                name="password"
                initialValue={ENV_CONFIG.ACCOUNT_PWD}
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="密码"
                  prefix={<LockOutlined />}
                ></Input.Password>
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>记住账号</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  size={'large'}
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loading}
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          )}
          {show2FAModal && (
            <TwoFactorConfirm
              tempAccessToken={show2FAModal}
              onSuccess={({ accessToken: newAccessToken }) => {
                saveAccessToken(newAccessToken);
                const jwt2: JwtTokenPayload = decodeJwtToken(newAccessToken);
                setGlobalContext({ ...globalContext, userMetaData: jwt2.meta });
                navigate(from);
              }}
            />
          )}
        </section>
        <footer>
          <Footer></Footer>
        </footer>
      </Space>
    </div>
  );
}
