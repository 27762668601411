import {
  AccountBookOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  FormOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Button,
  Cascader,
  Checkbox,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';
import { ConvertNumberToCurrency } from '../../../../common/utils/convert-number-to-currency';
import { CmtModalButton } from '../../../../components/CmtModalButton';
import {
  changeGoodMarketProductStatusApi,
  getDeliverRegions,
  getSaleItemInfo,
  getSaleManagementInfo,
  GoodSellStatus,
  GpoGoodMarketProduct,
} from '../../../../services/gpo/goodmarket.service';
import { GpoDeliverRegionCascader } from '../../../components/DeliverRegionCascader';

function ChangeGoodMarketProductForm(props: {
  data: GpoGoodMarketProduct;
  onSuccess?: () => void;
}) {
  const { data } = props;

  const [form] = useForm();
  const [supplierAgreementGuid, setSupplierAgreementGuid] = useState('');
  const [editable] = useState(data.sellStatus === GoodSellStatus.Unpublished);
  const {
    data: details,
    loading,
    run: loadDetails,
  } = useRequest(getSaleManagementInfo, {
    manual: true,
    debounceWait: 300,
    onSuccess: (res) => {
      if (res.supplierAgreements.length) {
        loadSaleItem({
          ...data,
          supplierAgreementGuid:
            res.supplierAgreements[0]?.supplierAgreementGuid || '',
        });
        setSupplierAgreementGuid(
          props.data.supplierAgreementGuid ||
            res.supplierAgreements[0]?.supplierAgreementGuid
        );
      }
    },
    onError(e) {
      message.error(e.message);
    },
  });

  const { loading: loadingSaleItem, run: loadSaleItem } = useRequest(
    getSaleItemInfo,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: (res) => {
        form.setFieldsValue({
          supplierAgreementGuid: res.supplierAgreementGuid,
          regionGuid: res.regionGuid,
          currentPrice: res.currentPrice,
        });
      },
    }
  );

  const {
    data: regions,
    loading: loadingRegion,
    run: loadRegion,
  } = useRequest(getDeliverRegions, {
    manual: true,
    debounceWait: 300,
  });

  const { loading: updating, run: pushChangeApi } = useRequest(
    changeGoodMarketProductStatusApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: () => {
        message.success(`操作成功`);
        props.onSuccess?.();
      },
      onError: (err) => {
        message.error(`操作失败：` + err.message);
      },
    }
  );
  useEffect(() => {
    loadDetails(data);
  }, []);

  useEffect(() => {
    console.log('supplierAgreementGuid', supplierAgreementGuid);
    if (supplierAgreementGuid) {
      loadRegion({ goodsGuid: data.goodsGuid, supplierAgreementGuid });
    }
  }, [supplierAgreementGuid]);

  const submitChanges = () => {
    const payload = {
      ...form.getFieldsValue(),
      goodsGuid: data.goodsGuid,
      goodsPriceGuid: data.goodsPriceGuid || '',
      sellStatus:
        data.sellStatus === GoodSellStatus.Unpublished
          ? GoodSellStatus.Published
          : GoodSellStatus.Unpublished,
    };
    console.log(payload);
    pushChangeApi(data, payload);
  };

  return (
    <Spin spinning={loading || loadingSaleItem || updating}>
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Descriptions
          title={<p></p>}
          column={2}
          bordered
          labelStyle={{ whiteSpace: 'nowrap' }}
        >
          <Descriptions.Item label="商品编号">
            {details?.goods?.goodsCode}
          </Descriptions.Item>
          <Descriptions.Item label="商品名称">
            {details?.goods?.name}
          </Descriptions.Item>
          <Descriptions.Item label="商品型号">
            {details?.goods?.code}
          </Descriptions.Item>
          <Descriptions.Item label="品目">
            {details?.goods?.goodsClassName}
          </Descriptions.Item>
          <Descriptions.Item label="品牌">
            {details?.goods?.goodsBrandName}
          </Descriptions.Item>
          <Descriptions.Item label="市场价">
            {ConvertNumberToCurrency(details?.goods?.salesPrice || 0)}
          </Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          labelAlign={'left'}
          onFinish={submitChanges}
        >
          <FormItem
            label="入驻区域-协议编号"
            name={'supplierAgreementGuid'}
            rules={[{ required: true }]}
          >
            <Select
              disabled={!editable}
              onChange={setSupplierAgreementGuid}
              options={details?.supplierAgreements.map((x) => {
                return {
                  label: x.regionName + '-' + x.agreementCode,
                  value: x.supplierAgreementGuid,
                };
              })}
            ></Select>
          </FormItem>
          <FormItem
            label="上架区域"
            name={'toSaleRegionList'}
            rules={[{ required: true }]}
          >
            <GpoDeliverRegionCascader
              disabled={!editable}
              loading={loadingRegion}
              regions={regions}
            />
          </FormItem>
          <FormItem
            label="站点区域"
            hidden
            name={'regionGuid'}
            rules={[{ required: true }]}
          >
            <Input/>
          </FormItem>
          <FormItem
            label="主商品价格"
            name={'currentPrice'}
            rules={[{ required: true }]}
          >
            <InputNumber disabled={!editable} min={1} />
          </FormItem>
          <FormItem>
            <Space>
              <Button
                type="primary"
                danger={!editable}
                htmlType="submit"
                loading={updating}
                disabled={updating}
              >
                {editable ? '上架' : '下架'}
              </Button>
              <Button type="text" onClick={props.onSuccess}>
                取消
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Space>
    </Spin>
  );
}

export function SellMgmtButton(props: {
  data: GpoGoodMarketProduct;
  onSuccess?: () => void;
}) {
  return (
    <CmtModalButton
      title="上架管理"
      trigger={({ show }) => {
        return (
          <Tooltip title={'上架管理(改价)'}>
            <Button type="text" size="small" onClick={show}>
              <CloudOutlined />
            </Button>
          </Tooltip>
        );
      }}
    >
      {({ hide }) => {
        return (
          <ChangeGoodMarketProductForm
            data={props.data}
            onSuccess={() => {
              hide();
              props.onSuccess?.();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
