import { useRequest } from 'ahooks';
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Result,
  Skeleton,
  Space,
  Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { ConvertNumberToCurrency } from '../../../common/utils/convert-number-to-currency';
import { PdfViewer } from '../../../components/PdfViewer';
import {
  getContractStatusName,
  GPOContractAuditStatus,
  GPOContractDetailDto,
  queryContractDetailApi,
} from '../../../services/gpo/contract.service';
import { ContractActions } from './actions';

export function ContractView(props: {
  contractId: string;
  projectId: string;
  pdfViewHeight?: number;
  hideDetail?: boolean;
  hideActions?: boolean;
  hidePrice?: boolean;
  refresh?: () => void;
}) {
  const [ready, setReady] = useState(false);
  const { data, loading, run, refresh } = useRequest(queryContractDetailApi, {
    manual: true,
    debounceWait: 100,
    onFinally: () => {
      setReady(true);
    },
  });

  useEffect(() => {
    if (props?.contractId && props.projectId) {
      run({
        contractId: props.contractId,
        projectId: props.projectId,
      });
    }
  }, [props?.contractId, props.projectId]);

  const getBasicInfos = (dto: GPOContractDetailDto) => {
    return (
      <Descriptions title={'合同信息'} bordered column={1}>
        <Descriptions.Item label={'采购单位'}>
          {dto.buyerOrgName}
        </Descriptions.Item>
        <Descriptions.Item label={'供应商'}>
          {dto.supplierName}
        </Descriptions.Item>
        <Descriptions.Item label={'合同编号'}>
          {dto.contractCode}
        </Descriptions.Item>
        <Descriptions.Item label={'合同状态'}>
          {getContractStatusName(
            data?.auditStatus || GPOContractAuditStatus.Draft,
            (data as any).doSign
          )}
        </Descriptions.Item>
        <Descriptions.Item label={'合同金额'}>
          {props.hidePrice ? '******' : ConvertNumberToCurrency(dto.totalMoney)}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const getContent = (dto: GPOContractDetailDto) => {
    return dto.pdfFilePath ? (
      <PdfViewer src={dto.pdfFilePath} height={props.pdfViewHeight || 500} />
    ) : (
      <div
        dangerouslySetInnerHTML={{ __html: dto.contractContentVO.content }}
      ></div>
    );
  };

  return (
    <Spin spinning={loading}>
      {ready && !data && (
        <Result
          title={'合同不存在'}
          subTitle={'请核对数据'}
          status={'warning'}
        />
      )}
      {loading && <Skeleton />}
      {!loading && data && (
        <>
          <Space style={{ width: '100%' }} direction={'vertical'}>
            {!props.hideActions && (
              <ContractActions
                data={data}
                onSuccess={() => props.refresh?.()}
              />
            )}
            {getBasicInfos(data)}
            {!props.hideDetail && (
              <>
                <Divider />
                <Alert
                  type={'info'}
                  showIcon
                  message={'此合同具有法律效力，请认真填写'}
                ></Alert>
                {getContent(data)}
              </>
            )}
          </Space>
        </>
      )}
    </Spin>
  );
}
