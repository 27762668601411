import { DollarCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Space,
  Input,
  DatePicker,
  Button,
  Form,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { ConvertDate } from '../../../common/utils/convert-date';
import { BlankWrapper } from '../../../components/BlankWrapper';
import { CmtTable } from '../../../components/CmtTable';
import { InfiniteSelect } from '../../../components/InfiniteSelect';
import {
  AuditLog,
  AuditLogAction,
  AuditLogResource,
  queryAuditLogActionsApi,
  queryAuditLogResourceApi,
  queryAuditLogsApi,
} from '../../../services/organization.service';
import { Project, queryProjectApi } from '../../../services/project.service';
const { RangePicker } = DatePicker;

export function AuditLogsPage() {
  useTitle('操作日志', { restoreOnUnmount: true });
  const tableRef = useRef<any>(null);
  const [selectedResource, setSelectedResource] = useState('');
  const columns: ColumnsType<AuditLog> = [
    {
      title: '项目',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '资源',
      dataIndex: 'resourceName',
      key: 'resource',
    },

    {
      title: '用户',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '操作',
      dataIndex: 'actionName',
      key: 'action',
    },
    {
      title: '操作备注',
      dataIndex: 'remark',
      key: 'remark',
      render(value, record, index) {
        return (
          <Tooltip title={record.remark}>
            <Typography.Text ellipsis={true}>{record.remark}</Typography.Text>
          </Tooltip>
        );
      },
    },

    {
      title: '操作时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(value, record, index) {
        return ConvertDate(value);
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '组织管理', icon: <DollarCircleOutlined /> },
        { name: '操作日志' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="操作日志"
        description=""
        rowKey="id"
        api={queryAuditLogsApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  name="daterange"
                  label="日期"
                  initialValue={[dayjs(), dayjs()]}
                >
                  <RangePicker
                    style={{ width: 180 }}
                    onChange={submit}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item name={'projectId'} label={'项目'} initialValue={''}>
                  <InfiniteSelect
                    defaultOptions={[{ label: '全部', value: '' }]}
                    style={{ width: 120 }}
                    api={queryProjectApi}
                    onChange={submit}
                    option={(item: Project) => {
                      return { label: item.name, value: item.id };
                    }}
                  />
                </Form.Item>
                <Form.Item name="userName" label="用户" initialValue={''}>
                  <Input
                    style={{ width: 120 }}
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name="resource" label="资源" initialValue={''}>
                  <InfiniteSelect
                    defaultOptions={[{ label: '全部', value: '' }]}
                    style={{ width: 120 }}
                    api={queryAuditLogResourceApi}
                    onChange={(v) => {
                      setSelectedResource(v);
                      formRef.setFieldValue('action', '');
                      submit();
                    }}
                    option={(item: AuditLogResource) => {
                      return { label: item.name, value: item.id };
                    }}
                  />
                </Form.Item>
                <Form.Item name="action" label="操作" initialValue={''}>
                  <InfiniteSelect
                    defaultOptions={[{ label: '全部', value: '' }]}
                    defaultParams={{ resourceId: selectedResource }}
                    style={{ width: 120 }}
                    api={queryAuditLogActionsApi}
                    onChange={submit}
                    option={(item: AuditLogAction) => {
                      return { label: item.name, value: item.action };
                    }}
                  />
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
