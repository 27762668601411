import { useRequest, useTitle } from 'ahooks';
import {
  message,
  Form,
  FormInstance,
  Space,
  Card,
  Col,
  Input,
  Row,
  Typography,
  InputNumber,
  Select,
  Affix,
  Button,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ReactNode, useEffect, useState } from 'react';
import { REGEXS } from '../../../../../common/utils/formats';
import { Editor } from '../../../../../components/Editor';
import { InfiniteSelect } from '../../../../../components/InfiniteSelect';
import { saveRJMProduct } from '../../../../../services/rjmart/product-mamagement.service';
import { RJProductStatus } from '../../../../../services/rjmart/product-modal';
import { CarryFeeTemplateIdInput } from './carry-fee-template-input';
import { CreationPictureUpload } from './creation-picture-upload';
import { AttributeInput } from './attribute-input';
import { RJMBrandSelect } from './RJMBrandSelect';
import { RJMDeliveryTimeSelect } from './delivery-time-select';
import { RJMUnitSelect } from './unit-select';
import { RJMCategoryCascader } from '../../../../components/CategoryCascader';

export function ProductForm(props: {
  formValue?: { categoryId?: number; id?: number; [key: string]: any };
  projectId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  extendAction?: (actions: {
    getPayload: () => any;
    form: FormInstance<any>;
  }) => ReactNode;
}) {
  const key = 'updatable';
  const [categoryId, setCategoryId] = useState(
    props.formValue?.categoryId || 0
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { run: runSaveGood, loading } = useRequest(saveRJMProduct, {
    manual: true,
    debounceWait: 300,
    onBefore() {
      messageApi.open({
        key,
        type: 'loading',
        content: '正在保存，请稍后...',
        duration: 0,
      });
    },
    onSuccess(res) {
      messageApi.open({
        key,
        type: 'success',
        content: '保存成功',
        duration: 5,
      });
      props.onSuccess?.();
    },
    onError(e, params) {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
        duration: 5,
      });
    },
    onFinally() {},
  });

  const submitForm = (
    submitStatus: RJProductStatus.Draft | RJProductStatus.Publish
  ) => {
    form.validateFields().then(
      () => {
        // save
        runSaveGood({ ...form.getFieldsValue(), status: submitStatus });
      },
      (e) => {
        messageApi.open({
          key,
          type: 'error',
          content: '请检查表单',
          duration: 3,
        });
      }
    );
  };

  const initForm = async () => {
    form.setFieldsValue({ ...props.formValue });
  };

  useEffect(() => {
    // if (props.formValue?.categoryId) {
    initForm();
    // }
  }, [props.formValue]);
  return (
    <>
      {contextHolder}
      <Form
        form={form}
        labelAlign={'left'}
        layout="vertical"
        disabled={loading}
        scrollToFirstError={true}
      >
        <FormItem hidden label="id" name="id">
          <Input />
        </FormItem>

        <Space style={{ width: '100%' }} direction="vertical">
          <Card>
            <Typography.Title level={4}>基本信息</Typography.Title>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="分类"
                  name="categoryId"
                  rules={[{ required: true }]}
                >
                  <RJMCategoryCascader
                    onChange={(value) => {
                      console.log(value);
                      setCategoryId(value);
                    }}
                    queryParams={{ projectId: props.projectId || '' }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="商品名称"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="品牌" name="brandId">
                  <RJMBrandSelect
                    queryParams={{ projectId: props.projectId }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="productNum"
                  label="商品货号"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="货期"
                  name="deliveryTime"
                  rules={[{ required: true }]}
                >
                  {/* <Select
                    options={RJDeliveryTimes.map((x) => {
                      return { label: x.name, value: x.value };
                    })}
                  ></Select> */}
                  <RJMDeliveryTimeSelect />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Typography.Title level={4}>商品属性</Typography.Title>
            <FormItem name="attributes" label="" rules={[{ required: false }]}>
              <AttributeInput
                categoryId={categoryId}
                projectId={props.projectId}
              />
            </FormItem>
          </Card>
          <Card>
            <Typography.Title level={4}>销售信息</Typography.Title>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="销售单位"
                  name="unit"
                  rules={[{ required: true }]}
                >
                  {/* <Select
                    options={unitAndSpecification?.unitList.map((x) => {
                      return { label: x, value: x };
                    })}
                  ></Select> */}
                  <RJMUnitSelect
                    queryParams={{
                      projectId: props.projectId,
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="specification"
                  label="商品规格"
                  help={
                    '除一级分类为化学试剂或危险化学品或工业品无需传外, 其他分类必传'
                  }
                  rules={[{ required: false }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="库存数量"
                  name="sku"
                  rules={[
                    { required: true },
                    { pattern: REGEXS.number, message: '格式不正确' },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="目录售价"
                  name="directoryPrice"
                  rules={[{ pattern: REGEXS.number, message: '格式不正确' }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="统一售价"
                  name="price"
                  rules={[
                    { required: true },
                    { pattern: REGEXS.number, message: '格式不正确' },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Typography.Title level={4}>运费模板</Typography.Title>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="运费设置"
                  name="carryFeeTemplateId"
                  rules={[{ required: true }]}
                >
                  <CarryFeeTemplateIdInput
                    queryParams={{ projectId: props.projectId }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Typography.Title level={4}>图文详情</Typography.Title>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  label="商品图片"
                  name="photos"
                  rules={[{ required: true }]}
                >
                  <CreationPictureUpload />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  label="商品介绍"
                  name="desc"
                  rules={[{ required: true }]}
                >
                  <Editor></Editor>
                </FormItem>
              </Col>
            </Row>
          </Card>
        </Space>
        <Affix
          offsetBottom={20}
          style={{ position: 'absolute', bottom: 0, right: 30 }}
        >
          <Space>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              disabled={loading}
              onClick={() => {
                submitForm(RJProductStatus.Draft);
              }}
            >
              保存草稿
            </Button>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              disabled={loading}
              onClick={() => {
                submitForm(RJProductStatus.Publish);
              }}
            >
              保存并上架
            </Button>
          </Space>
        </Affix>
      </Form>
    </>
  );
}
