import { Button } from 'antd';
import { PrintableOrderArchive } from './PrintableOrderArchive';
import { CmtPrint } from '../../../../../components/CmtPrint';
import { MartSimpleOrderDto } from '../../../../../services/cas/order-modal';

export function PrintOrderArchiveButton(props: {
  data: MartSimpleOrderDto;
  projectId: string;
}) {
  return props?.data?.id ? (
    <CmtPrint
      trigger={({ show }) => (
        <Button type="default" onClick={show}>
          订单归档
        </Button>
      )}
    >
      {({ ref }) => (
        <PrintableOrderArchive
          ref={ref}
          data={props?.data}
          projectId={props?.projectId || ''}
        />
      )}
    </CmtPrint>
  ) : (
    <></>
  );
}
